
import React, { Component, useEffect, useState } from 'react';
import { auth } from '../firebase';
import { Route, Redirect } from 'react-router-dom';
import { Usertypes} from '../Helpers/constants'; 
import Apilib from "../api";
import {useStateValue} from "../StateProvider";
// import './style.css';
  
const LoginLayout = ({ children }) => (                         
    <div className="main-wrapper account-wrapper">
        <div className="account-page">
            <div className="account-center">
                    {children}
            </div>                 
        </div>
    </div>  
  );  
  
  const LoginLayoutRoute = ({component: Component, ...rest}) => {  

    const [redirect, setRedirect] = useState(null);
    const [dispatch] = useStateValue();

    useEffect(() => {
      let API = new Apilib();
      let isSubscribed = true;
      auth.onAuthStateChanged(user => {
        if(user){
          if(isSubscribed){
            setRedirect(Usertypes[localStorage.getItem('@displayName') - 1][1]);
          }
        }
      });

      API.getLocation()
      .then((res)=> res.json())
      .then((res) => {
        dispatch({
          type: 'SET_LOCATION',
          lat: res.latitude,
          long: res.longitude,
          country_code: res.country_code
        })
      })
      .catch((err) => {
        console.log("geoError", err)
      })
      return () => (isSubscribed = false);
    },[])

    if (redirect) {
      return <Redirect to={redirect} />
    }
    return ( 
      <Route {...rest} render={matchProps => (  
        <LoginLayout>  
            <Component {...matchProps} />  
        </LoginLayout>  
      )} />  
    )  
  };  
  
export default LoginLayoutRoute; 