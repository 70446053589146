import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import OtpInput from "react-otp-input";
import Loader from "../Loader/Loader";
import { auth } from "../../firebase";
import { Router, Link } from "react-router-dom";
import { Usertypes } from "../../Helpers/constants";
import firebase from "firebase";
import Apilib from "../../api";
import Notify from "../../Notify"
import "./style.css";

const Otp = (props) => {
  
  if(!props.location.verificationId){
    props.history.push("/register")
  }

  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [userData, setUserData] = useState({});
  const [varificationId, setVarificationId] = useState("");
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  useEffect(() => {
    if (props.location.state) {
      console.log("props.location", props.location);
      let userDataTemp = {...props.location.state.data};
      delete userDataTemp.family_uid;
      setUserData(userDataTemp);
      setVarificationId(props.location.verificationId);
    }
  },[]);

  const submitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    var credential = firebase.auth.PhoneAuthProvider.credential(varificationId, code);
    let API = new Apilib();
    firebase.auth().signInWithCredential(credential)
    .then((res) => {
      let data = {...userData};
      data["uid"] = res.user.uid;
      let uid = res.user.uid;
      API.addPatient(JSON.stringify(data))
      .then((res) => res.json())
      .then(res => {
        console.log("res",res);
        setSuccess("Otp verified successfully!");
        if(props.location.familyAcc){
          let dataToPost = {
            relation: data.relation,
            head_email:props.location.state.data.family_uid.email,
            uid:uid,
            member_email:props.location.state.data.email,
            member_name:props.location.state.data.firstName + " " + props.location.state.data.lastName
          }
          API.joinFamily(JSON.stringify(dataToPost)).then((res) => res.json())
          .then(res => {
            console.log("res family",res);
            auth.signOut().then((res) => {
              props.history.push("/login");
              Notify({
                alert: true,
                type: "success",
                title: "Otp verified successfully, you can login now! You will be added into the Family account once your request is approved."
              });
              console.log("Sign-out successful",res);
              setLoading(false);
            }).catch((error) => {
              setLoading(false);
              console.log("An error happened",error);
            });
          }).catch(err => {
            console.log(err);
          });
        }else{
          auth.signOut().then((res) => {
            props.history.push("/login");
            Notify({
              alert: true,
              type: "success",
              title: "Otp verified successfully, you can login now!"
            });
            console.log("Sign-out successful",res);
            setLoading(false);
          }).catch((error) => {
            setLoading(false);
            console.log("An error happened",error);
          });
        }
      })
      .catch(err => {
        props.history.push("/");
      });
      
    })
    .catch((err) => {
      setLoading(false);
      if(err.code === "auth/invalid-verification-code"){
        setError("Invalid Verification Code")
      }
      console.log("err", err)
    })
    console.log("credential", credential)
  }

  const handleOtpChange = otp => setCode(otp)
  
  return (
    <div className="main-content account-content">
      {loading && <Loader />}
      <div className="content">
        <div className="container">
          <div className="account-box">
            {/* {this.state.loading && <Loader />} */}
            <form
              onSubmit={(e) => submitHandler(e)}
              className="form-signin"
              action="#"
            >
              <div className="account-title">
                <h3>Enter OTP</h3>
              </div>
              {error && (
                <div
                  className="alert alert-danger alert-dismissible fade show"
                  role="alert"
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                    <span className="sr-only">Close</span>
                  </button>
                  {error}
                </div>
              )}
              {success && (
                <div
                  className="alert alert-success alert-dismissible fade show"
                  role="alert"
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                    <span className="sr-only">Close</span>
                  </button>
                  {success}
                </div>
              )}
              <div className="form-group">
                <label htmlFor="email" className="otphead">
                  Check Phone For OTP
                </label>
                <OtpInput
                  value={code}
                  onChange={handleOtpChange}
                  numInputs={6}
                  separator={<span> - </span>}
                  shouldAutoFocus
                  // className="form-control"
                  containerStyle={{
                    width: "80%",
                    alignContent: "center",
                    //   display: "flex",
                    margin: "auto",
                    //   padding: "10px",
                    textAlign: "center",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                  inputStyle={{
                    width: "3em",
                    height: "3em",
                    alignContent: "center",
                    display: "flex",
                  }}
                />
              </div>

              <div className="form-group text-center mt-50">
                <a>
                  <button type="submit" className="btn btn-primary submit-btn account-btn">
                    Verify Otp
                  </button>
                </a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Otp;

