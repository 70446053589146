// jshint esversion:6

import React, { Component } from "react";
import { Link } from "react-router-dom";
export default class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-3">
                <div className="footer-widget">
                  <h4 className="footer-title">Sitemap</h4>
                  <ul className="footer-menu">
                    <li>
                      <a href="#">Our Solutions</a>
                    </li>
                    <li>
                      <Link to="/Team">About Us</Link>
                    </li>
                    <li>
                      <Link to="/Contact">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/Login">Login</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-3">
                <div className="footer-widget">
                  <h4 className="footer-title">Legal</h4>
                  <ul className="footer-menu">
                    <li>
                      <Link to="/privacy-policy-india" target="_blank">
                        Privacy Policy India
                      </Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy-australia" target="_blank">
                        Privacy Policy Australia
                      </Link>
                    </li>
                    <li>
                      <Link to="/us-hippa-policy" target="_blank">
                        US – Hippa Policy
                      </Link>
                    </li>
                    <li>
                      <Link to="/EU-GDPR" target="_blank">
                        EU – GDPR
                      </Link>
                    </li>
                    <li>
                      <Link to="/Compliance" target="_blank">
                        Compliance
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-3">
                <div className="footer-widget">
                  <h4 className="footer-title">Terms of use</h4>
                  <ul className="footer-menu">
                    <li>
                      <Link to="/MobileAppTerms" target="_blank">
                        Mobile App Terms
                      </Link>
                    </li>
                    <li>
                      <Link to="/WebsiteTerms" target="_blank">
                        Website terms and condition of use
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-3">
                <div className="footer-widget">
                  <Link to="/">
                    <img
                      className="bottomLogo"
                      alt="Logo"
                      src="assets/img/logo1.png"
                    />
                  </Link>
                  <div className="appointment-btn">
                    <ul className="social-icons clearfix">
                      <li>
                        <a
                          href="https://www.facebook.com/Medvatic"
                          target="_blank"
                          title="Facebook"
                        >
                          <i className="fa fa-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/MedVatic"
                          target="_blank"
                          title="Twitter"
                        >
                          <i className="fa fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/company/medvatic/"
                          target="_blank"
                          title="Linkedin"
                        >
                          <i className="fa fa-linkedin"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/medvatic/"
                          target="_blank"
                          title="Instagram"
                        >
                          <i className="fa fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="copyright">
              <div className="row">
                <div className="col-12">
                  <div className="copy-text text-center">
                    <p>
                      &#xA9; 2020 <Link to="/">Medvatic</Link>. All rights
                      reserved.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
