import React, { useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import { Avatar, Grid } from "@material-ui/core";
import moment from "moment";
import { firestore } from "../../firebase";

const ExternalChat = (props) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [comments, setComments] = useState([]);
  const [appointment, setAppointment] = useState();
  const [userData, setUserData] = useState();
  const dummy = useRef();

  useEffect(() => {
      if(props.appointment){
        setAppointment(props.appointment);
      }
      if(props.userData){
        setUserData(props.userData);
      }
  }, [props])

  useEffect(() => {
    if(appointment){
        console.log({appointment, props})
        firestore
            .collection("appointments")
            .doc(appointment.appointmendId)
            .collection("comments")
            .where("commenttype", "==", 2)
            .orderBy("createdAt")
            .onSnapshot((snapshot) => {
                let comments = [];
                snapshot.docs.forEach(doc => {
                    const data = doc.data();
                    comments.push(data);
                })
                setComments(comments)
                console.log("External chat ", {comments});
            });
    }
  }, [appointment])

  const chatHandler = (e) => {
    let message = e.target.value;
    setMessage(message)
  }
  const chatSubmitHandler = async (e, type) => {
    e.preventDefault();
    const commentData = {
      appointment_id: appointment?.appointmendId,
      userType: localStorage.getItem("@displayName"),
      userId: userData?.uid,
      userName: `${userData?.firstName} ${userData?.lastName}`,
      comment: message,
      commenttype: type,
      createdAt: Date.parse(new Date())
    };
    setMessage('');

    await firestore
        .collection("appointments")
        .doc(appointment.appointmendId)
        .collection("comments")
        .add(commentData);
    

    console.log("comments", commentData);
  }

  useEffect(() => {
    if (dummy.current) {
      dummy.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [comments, props.externally]);

  return (
    <>
      <Modal
        size="lg"
        centered
        show={props.externally}
        onHide={props.externalChat}
        >
        <Modal.Header closeButton>
            <Modal.Title>Chat With Patient</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="chat-window-appointment">
            {comments.length !== 0
            ? comments.map((chat) => {
                return (
                    <Grid
                    justify={
                        chat.userId === userData?.uid
                        ? "flex-end"
                        : "flex-start"
                    }
                    container
                    wrap="nowrap"
                    spacing={2}
                    >
                    <Grid item>
                        <Avatar
                        alt="Remy Sharp"
                        src={props.pp_url}
                        />
                    </Grid>
                    <Grid item>
                        <h5 style={{ margin: 0, textAlign: "left" }}>
                        {chat.userId === userData?.uid
                            ? "You"
                            : chat.userName}
                        </h5>
                        <p
                        className="chat-content"
                        style={{ textAlign: "left" }}
                        >
                        {chat.comment}
                        </p>
                        <p
                        className="chat-date"
                        style={{ textAlign: "left", color: "gray" }}
                        >
                        posted{" "}
                        {moment(chat.createdAt).fromNow()}
                        </p>
                    </Grid>
                    </Grid>
                );
                })
            : null}
            <span ref={dummy}></span>
            </div>
            
            <div>
            
            <input
                className="comment-area doctor-comment"
                style={{borderRadius:"none"}}
                placeholder="Enter Text Here...."
                onChange={(e) => chatHandler(e)}
                value={message}
            />
            <button
                onClick={(e) => chatSubmitHandler(e, 2)}
                className="send-button"
                disabled={!message}
            >
                Submit
            </button>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button
            variant="secondary"
            onClick={props.externalChat}
            >
            Close
            </Button>
        </Modal.Footer>
        </Modal>
    </>
  );
};
export default ExternalChat;
