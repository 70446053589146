// jshint esversion:6

import React, { useState, useEffect } from "react";
import Apilib from "../../api";
import Loader from "../Loader/Loader";
// import { Dropdown, NavDropdown, NavItem } from "react-bootstrap";

const Contact = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: {
      value: "",
      validation: {
        minLength: 3,
        required: true,
        onlyAlphabets: true,
      },
      errMsg: "Invalid First Name!",
      valid: true,
    },
    email: {
      value: "",
      validation: {
        required: true,
        isEmail: true,
      },
      errMsg: "Invalid Email!",
      valid: true,
    },
    contact_no: {
      value: "",
      validation: {
        required: true,
        isNumeric: true,
        minLength: 10,
        maxLength: 10,
      },
      errMsg: "Invalid Contact Number!",
      valid: true,
    },
    message: {
      value: "",
      validation: {},
      valid: true,
    },
  });
  const [formIsValid, setFormIsValid] = useState(false);
  const [postData, setPostData] = useState();
  const [sendRequest, setSendRequest] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const formSubmitHandler = (event) => {
    event.preventDefault();
    if (formIsValid) {
      const data = {};
      for (let key in formData) {
        data[key] = formData[key].value;
      }
      setPostData({
        subject: "Inquiry from website",
        email: "info@medvatic.com",
        message: `<p>Name: ${formData.name.value}</p> \n 
        <p>Email: ${formData.email.value}</p> \n 
        <p>Mobile No.: ${formData.contact_no.value}</p> \n\
        <p>Message: ${formData.message.value}</p>`,
      });

      setSendRequest(true);
    } else {
      // Notify({
      //   alert: true,
      //   type: "error",
      //   title: "Invalid form",
      // });
      setSendRequest(false);
      return;
    }
  };

  console.log("data form", postData);

  useEffect(() => {
    if (sendRequest) {
      post();
    }
  }, [sendRequest]);

  const post = () => {
    if (postData) {
      setLoading(true);
      let API = new Apilib();
      API.contactUs(JSON.stringify(postData))
        .then((res) => {
          console.log(res);
          setLoading(false);
          const updatedForm = { ...formData };
          for (let inputName in updatedForm) {
            const updatedFormElement = { ...updatedForm[inputName] };
            updatedFormElement.value = "";
            updatedForm[inputName] = updatedFormElement;
            setFormData(updatedForm);
          }
          setSuccess("Successfully Sent!");
          setSendRequest(false);
          setFormIsValid(false);
        })
        .catch((err) => {
          setLoading(false);
          setError("Please fill the valid details!");
          console.log(err);
        });
    }
  };

  const inputChangeHandler = (event, inputName) => {
    const updatedForm = { ...formData };
    const updatedFormElement = { ...updatedForm[inputName] };
    updatedFormElement.value = event.target.value;
    updatedForm[inputName] = updatedFormElement;
    updatedForm[inputName].valid = checkValidity(
      updatedForm[inputName].value,
      updatedForm[inputName].validation
    );
    let formValid = true;
    for (let key in updatedForm) {
      formValid = updatedForm[key].valid && formValid;
    }
    setFormIsValid(formValid);
    setFormData(updatedForm);
    setError("");
    setSuccess("");
  };

  function checkValidity(value, rules) {
    let isValid = true;
    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }

    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid;
    }

    if (rules.maxLength) {
      isValid = value.length <= rules.minLength && isValid;
    }

    if (rules.isNumeric) {
      const pattern = /^\d+$/;
      isValid = pattern.test(value) && isValid;
    }

    if (rules.isEmail) {
      const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      isValid = pattern.test(value) && isValid;
    }

    if (rules.onlyAlphabets) {
      const pattern = /^[A-Za-z ]+$/;
      isValid = pattern.test(value) && isValid;
    }

    return isValid;
  }

  return (
    <>
      {loading ? <Loader /> : null}
      <div className="contact-us">
        {/* <!-- Page Header --> */}
        <section className="section contact-banner row-middle">
          <div className="inner-bg"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="section-header text-center">
                  <h3 className="header-title contact-head">Contact Us</h3>
                  <div className="line"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="content">
          <div className="container">
            <div className="row">
              <aside className="col-md-4">
                <div className="contact-left">
                  <div className="contact-address">
                    <h3 className="company-name">Australia</h3>
                    <p>Medvatic PTY LTD</p>
                    <p>
                      Unit 10/3 Jamison Centre,
                      <br />
                      Macquarie Canberra,
                      <br />
                      ACT 2614
                    </p>
                    <p className="m-b-0">
                      <strong>Phone</strong>:
                      <a href="tel:+8503867896">(02) 6156 8615</a>,
                      <br />
                      <strong>Email</strong>:
                      <a href="mailto:info@medvatic.com.au">
                        info@medvatic.com.au
                      </a>
                    </p>
                  </div>

                  <div className="contact-address">
                    <h3 className="company-name">India</h3>
                    <p>Medvatic LLP</p>
                    <p>
                      GRB103, Wework Chromium,
                      <br />
                      Milind Nagar, L&amp;T Flyover,
                      <br />
                      Marol, Andheri East,
                      <br />
                      Mumbai, <br />
                      Maharashtra 400072
                    </p>
                    <p className="m-b-0">
                      <strong>Phone</strong>:
                      <a href="tel:7506779019">+91 7506779019</a>,
                      <br />
                      <strong>Email</strong>:
                      <a href="mailto:info@medvatic.com">info@medvatic.com</a>
                    </p>
                  </div>
                </div>
              </aside>
              <div className="col-md-8">
                {error && (
                  <div
                    className="alert alert-danger alert-dismissible fade show"
                    role="alert"
                  >
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                      <span className="sr-only">Close</span>
                    </button>
                    {error}
                  </div>
                )}
                {success && (
                  <div
                    className="alert alert-success alert-dismissible fade show"
                    role="alert"
                  >
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                      <span className="sr-only">Close</span>
                    </button>
                    {success}
                  </div>
                )}
                <form onSubmit={formSubmitHandler}>
                  <div className="form-group">
                    <label>
                      Name <span className="text-red">*</span>{" "}
                    </label>
                    <input
                      required
                      onChange={(e) => inputChangeHandler(e, "name")}
                      value={formData["name"].value}
                      error={!formData["name"].valid}
                      type="text"
                      className={
                        formData["name"].valid
                          ? "form-control"
                          : "form-control error"
                      }
                    />
                    {!formData["name"].valid ? (
                      <p className="error-messege" style={{ marginBottom: "20px", marginTop:"0px"}}>
                        <small style={{ color: "red" }}>
                          {formData["name"].errMsg}
                        </small>
                      </p>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <label>
                      Email <span className="text-red">*</span>{" "}
                    </label>
                    <input
                      required
                      onChange={(e) => inputChangeHandler(e, "email")}
                      value={formData["email"].value}
                      type="text"
                      className={
                        formData["email"].valid
                          ? "form-control"
                          : "form-control error"
                      }
                    />
                      {!formData["email"].valid ? (
                      <p className="error-messege" style={{ marginBottom: "20px", marginTop:"0px"}}>
                        <small style={{ color: "red" }}>
                          {formData["email"].errMsg}
                        </small>
                      </p>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <label>
                      Mobile <span className="text-red">*</span>{" "}
                    </label>
                    <input
                      required
                      onChange={(e) => inputChangeHandler(e, "contact_no")}
                      value={formData["contact_no"].value}
                      type="text"
                      className={
                        formData["contact_no"].valid
                          ? "form-control"
                          : "form-control error"
                      }
                    />
                      {!formData["contact_no"].valid ? (
                      <p className="error-messege" style={{ marginBottom: "20px", marginTop:"0px"}}>
                        <small style={{ color: "red" }}>
                          {formData["contact_no"].errMsg}
                        </small>
                      </p>
                    ) : null}
                  </div>
                  {/* <!-- <div className="form-group">
                                          <label>Select Consultation Type <span className="text-red">*</span>
                                          </label>
                                          <select className="select">
                                              <option value="">Please select</option>
                                              <option value="Blood Checkup">Blood Checkup</option>
                                              <option value="Diabetology">Diabetology</option>
                                              <option value="Endocrinology">Endocrinology</option>
                                              <option value="Vaccination Center">Vaccination Center</option>
                                              <option value="Interventional Cardiology">Interventional Cardiology</option>
                                              <option value="Pulmonology">Pulmonology</option>
                                              <option value="Gastro Entorology">Gastro Entorology</option>
                                              <option value="Nephrology">Nephrology</option>
                                              <option value="Psychiatry">Psychiatry</option>
                                              <option value="Obstetrics &amp; Gynaecology">Obstetrics &amp; Gynaecology</option>
                                              <option value="Urology">Urology</option>
                                              <option value="Physiotherapy">Physiotherapy</option>
                                              <option value="Premarital Counseling">Premarital Counseling</option>
                                          </select>
                                      </div>--> */}
                  <div className="form-group">
                    <label>Message (optional)</label>
                    <textarea
                      onChange={(e) => inputChangeHandler(e, "message")}
                      value={formData["message"].value}
                      className={
                        formData["message"].valid
                          ? "form-control"
                          : "form-control error"
                      }
                    ></textarea>
                  </div>
                  <div className="form-group text-center m-b-0">
                    <input
                      disabled={!formIsValid}
                      type="submit"
                      className="btn btn-primary account-btn contact-button"
                      value="Submit"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
