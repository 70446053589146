import moment from "moment";
import React, { Component } from "react";
import * as Datetime from "react-datetime";
import { Link } from "react-router-dom";
import BaseSelect from "react-select";
import Apilib from "../../api";
import RequiredSelect from "../../Helpers/requiredSelect";
import Notify from "../../Notify";
import Loader from "../Loader/Loader";

const options = [
  { value: 1, label: "1 - One" },
  { value: 2, label: "2 - Two" },
  { value: 3, label: "3 - Three" },
];

const Select = (props) => (
  <RequiredSelect
    {...props}
    SelectComponent={BaseSelect}
    options={props.options}
  />
);

export default class AddAppointment extends Component {
  constructor(props) {
    super(props);
    // console.log("props", props);
    this.props = props;
    this.state = {
      mainLoading: false,
      loading: false,
      formData: {
        doctorId: this.props.user?.uid,
        doctorName:
          this.props.userData.firstName + " " + this.props.userData.lastName,
        doctor_email: this.props.userData.email,
        hospitalId: this.props.userData.hospitalId,
        slot_date: moment().format("DD/MM/YYYY"),
        appointmentType: 1,
        variable_fees: 0,

        base_fee: props.userData.base_fee,
      },
      patientId: null,
      appointmentType: null,
      slot_date: moment().format("DD/MM/YYYY"),
      slot_time: "",
      error: "",
      success: false,
      patients: [],
      slots: {},
      selectedSlots: [],
      addPatientSuccess: false,

      daysNum: null,
    };
  }

  onChange = (e) => {
    let formObj = { ...this.state.formData };
    formObj[e.target.name] = e.target.value;
    this.setState({
      formData: formObj,
    });
  };

  onSelect = (val, name) => {
    let formObj = { ...this.state.formData };
    // console.log("Initial formobj--->", formObj);
    console.log("val--->", val, name);

    formObj[name] = val.value;
    if (name == "patientId") {
      formObj["name"] = val.fullName || "";
      formObj["phoneno"] = val.phoneno || "";
      formObj["email"] = val.email || "";
      formObj["patient_email"] = val.email || "";
      formObj["basefee"] = val.base_fee || "";
    }
    this.setState({
      formData: formObj,
      [name]: val,
    });
    console.log("formdata", formObj, name + ":" + val.value);
  };

  fetchSchedule() {
    // let from = moment().valueOf();
    // let to = moment().add(30,'days').valueOf();
    // let from = moment().parseZone("Australia/Melbourne").valueOf();
    let aestTimeTo = new Date().toLocaleString("en-US", {
      timeZone: "Australia/Brisbane",
    });
    let from = moment(aestTimeTo, "M/D/YYYY, H:mm:ss a").valueOf();
    from = from / 1000;
    let to = "";
    let API = new Apilib();
    API.listSchedule(this.props.userData.uid, from, to)
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        if (this._isMounted) {
          if (res.code == 200) {
            let slotsArr = [];
            let slots = [];
            let daysTxt = [
              "Sunday",
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
            ];
            let daysNum = [];
            console.log("response", res.data);
            if (res.data) {
              res.data.map((value, index) => {
                daysNum.push(daysTxt.indexOf(value.day));
              });
              slotsArr = res.data.filter((value, map) => {
                return (
                  moment(value.date, "DD/MM/YYYY").day() ===
                  moment(this.state.slot_date, "DD/MM/YYYY").day()
                );
              });

              if (slotsArr.length > 0) {
                slotsArr[0].slots
                  .filter((slotDetails) => {
                    if (
                      moment(this.state.slot_date, "DD/MM/YYYY").date() ===
                      moment().date()
                    ) {
                      return moment(new Date()).isSameOrBefore(
                        moment(slotDetails.time, "hh:mm a")
                      );
                    }
                    return true;
                  })
                  .map((value, index) => {
                    console.log(value);
                    if (value.booked == false) {
                      slots.push({ value: value.time, label: value.time });
                      //   console.log("Slots ::", value.label, value.time);
                    }
                  });
              }
              // console.log("Slots state ::", this.state.slots);
              // console.log("Slots ::", slots);
            }

            // console.log("state.formdate::", this.state.formData);

            this.setState({
              daysNum: daysNum,
              selectedSlots: slots,
              slots: res,
              mainLoading: false,
            });
          } else if (res.code == 202) {
            this.setState({
              error: "No schedule added, please add schedule first",
              mainLoading: false,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        if (this._isMounted) {
          this.setState({
            mainLoading: false,
          });
        }
      });
  }

  componentDidMount() {
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.addPatientSuccess) {
        this.setState({ addPatientSuccess: true });
      }
    }
    this._isMounted = true;
    this.setState({
      mainLoading: true,
    });
    let API = new Apilib();
    API.getUsers(5, this.props.userData.hospitalId)
      .then((res) => res.json())
      .then((res) => {
        if (this._isMounted) {
          // console.log("Patients", res.patients);
          this.setState(
            {
              patients: res.patients,
            },
            () => {
              this.fetchSchedule();
            }
          );
        }
      })
      .catch((error) => {
        if (this._isMounted) {
          this.setState({});
        }
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onSubmit = (e) => {
    e.preventDefault();
    let baseState = { ...this.state.formData };
    this.setState({
      error: "",
      loading: true,
    });
    let API = new Apilib();
    let data = {
      ...baseState,
      created_at: Date.parse(new Date()),
      from: moment(
        baseState.slot_date + " " + baseState.slot_time,
        "DD/MM/YYYY h:mm a"
      ).valueOf(),
      to: moment(
        baseState.slot_date + " " + baseState.slot_time,
        "DD/MM/YYYY h:mm a"
      )
        .add(+this.state.slots.slot_time.split(":")[0], "minutes")
        .valueOf(),
      for_family_member: false,
      userId: this.props.userData.uid,
      userType: localStorage.getItem("@displayName").toString(),
    };
    // console.log("submit data", data);
    API.addAppointment(JSON.stringify(data))
      .then(async (res) => {
        this.setState({
          loading: false,
        });

        if (res.ok) {
          const response = await res.json();

          if (response.code === 204) {
            this.setState({
              loading: false,
              slot_date: moment().format("DD/MM/YYYY"),
              slot_time: "",
              error: "",
              success: true,
            });
            Notify({
              alert: true,
              type: "error",
              title: response.message,
            });
            return;
          }
          this.setState({
            loading: false,
            formData: {},
            patientId: null,
            appointmentType: null,
            slot_date: moment().format("DD/MM/YYYY"),
            slot_time: "",
            error: "",
            success: true,
          });

          Notify({
            alert: true,
            type: "success",
            title: "Appointment Created Successfully",
          });
          this.props.history.push("/doctor/appointments");
        } else {
          res.text().then((data) => {
            this.setState({
              error: JSON.parse(data).message,
            });
          });
        }
      })
      .catch((error) => {
        this.setState({
          error: error.message,
          loading: false,
        });
      });
  };

  onDateSelect(value) {
    let formObj = { ...this.state.formData };
    formObj["slot_date"] = value.format("DD/MM/YYYY");
    formObj["slot_time"] = null;
    if (this.state.slots) {
      let filteredSlots = this.state.slots.data.filter((slot) => {
        return (
          slot.date === value.format("DD/MM/YYYY") ||
          moment(value.format("DD/MM/YYYY"), "DD/MM/YYYY").day() ===
            moment(slot.date, "DD/MM/YYYY").day()
        );
      });
      if (filteredSlots.length === 0) {
        Notify({
          alert: true,
          type: "error",
          title: "No Slots available",
        });
        this.setState({
          slot_date: value.format("DD/MM/YYYY"),
          slot_time: null,
          formData: formObj,
          selectedSlots: [],
        });
      } else {
        this.setState({
          slot_date: value.format("DD/MM/YYYY"),
          slot_time: null,
          formData: formObj,
          selectedSlots: filteredSlots[0].slots
            .filter((slot) => {
              if (
                moment(new Date()).format("DD/MM/YYYY") ===
                value.format("DD/MM/YYYY")
              ) {
                return (
                  !slot.booked &&
                  moment(new Date()).isSameOrBefore(
                    moment(slot.time, "hh:mm a")
                  )
                );
              }
              return !slot.booked;
            })
            .map((slot) => ({
              value: slot.time,
              label: slot.time,
            })),
        });
      }
    }
  }

  valid = (current) => {
    if (this.state.daysNum !== null) {
      let yesterday = Datetime.moment().subtract(1, "day");
      let afterMonth = Datetime.moment().add(30, "year");
      return (
        current.isAfter(yesterday) &&
        current.isBefore(afterMonth) &&
        this.state.daysNum.includes(current.day())
      );
    }
  };
  render() {
    let patients = [];
    let patientsName = [];
    const appointment_types = [
      { value: "1", label: "Physical" },
      { value: "2", label: "Video Conference" },
    ];

    if (this.state.patients.length) {
      this.state.patients.map((item, i) => {
        patients.push({
          value: item.uid,
          label: item.firstName + " " + item.lastName,
          fullName: item.firstName + " " + item.lastName,
          phoneno: item.phoneno,
          email: item.email,
          base_fee: item.base_fee,
        });
        patients.push({});
      });
    }

    if (this.state.mainLoading) {
      return <Loader />;
    } else {
      return (
        <React.Fragment>
          {this.state.loading && <Loader />}

          <div className="row">
            <div className="col-md-10">
              <h4 className="page-title">Add Appointment</h4>
            </div>
            <div className="col-md-2">
              <h4 className="page-title">
                <Link
                  to={{
                    pathname: "/doctor/add-patient",
                    // search: "?sort=name",
                    // hash: "#the-hash",
                    state: {
                      redirect: true,
                    },
                  }}
                  className="btn btn-primary float-right btn-rounded"
                >
                  <i className="fa fa-plus"></i> Add Patient
                </Link>
              </h4>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <form onSubmit={(e) => this.onSubmit(e)}>
                {this.state.error && (
                  <div
                    className="alert alert-danger alert-dismissible fade show"
                    role="alert"
                  >
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                      <span className="sr-only">Close</span>
                    </button>
                    {this.state.error}
                  </div>
                )}
                {/* {this.state.success && (
                  <div
                    className="alert alert-success alert-dismissible fade show"
                    role="alert"
                  >
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                      <span className="sr-only">Close</span>
                    </button>
                    Appointment added successfully
                  </div>
                )} */}
                {this.state.addPatientSuccess && (
                  <div
                    className="alert alert-success alert-dismissible fade show"
                    role="alert"
                  >
                    <button
                      type="button"
                      className="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                      <span className="sr-only">Close</span>
                    </button>
                    Patient added successfully
                  </div>
                )}
                <div className="row">
                  {/* <div className="col-sm-6">
                    <div className="form-group">
                      <label>
                        Patient Name
                        <span className="text-danger">*</span>
                      </label>
                      <Select
                        className="form-control"
                        onChange={(e) => this.onChange(e)}
                        value={this.state.formData.name}
                        type="text"
                        name="patientName"
                      />
                        
                    </div>
                  </div> */}
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>
                        Patient Name <span className="text-danger">*</span>
                      </label>
                      <Select
                        required
                        value={this.state.patientId}
                        onChange={(item) => this.onSelect(item, "patientId")}
                        options={patients}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>
                        Appointment Date <span className="text-danger">*</span>
                      </label>
                      {/* <input name="date" min={moment().format('YYYY-MM-DD')} value={this.state.date} onChange={(e) => this.onChange(e)} required className="form-control" type="date" /> */}
                      <Datetime
                        isValidDate={this.valid}
                        closeOnSelect={true}
                        dateFormat="DD/MM/YYYY"
                        onChange={(val) => this.onDateSelect(val)}
                        value={this.state.slot_date}
                        // inputProps={{ required: true }}
                        input={true}
                        timeFormat={false}
                        viewMode="days"
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>
                        Available Slots <span className="text-danger">*</span>
                      </label>
                      <Select
                        required
                        value={this.state.slot_time}
                        onChange={(item) => this.onSelect(item, "slot_time")}
                        options={this.state.selectedSlots}
                        placeholder={
                          this.state.selectedSlots.length
                            ? "Select a slot"
                            : "No slots available"
                        }
                      />
                    </div>
                  </div>
                  {/* <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Time <span className="text-danger">*</span></label>
                                            <input name="time" value={this.state.time} onChange={(e) => this.
                                              
                                              (e)} required className="form-control" type="time" />
                                        </div>
                                    </div> */}
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>
                        Appointment Type <span className="text-danger">*</span>
                      </label>
                      <Select
                        required
                        value={this.state.appointmentType}
                        onChange={(val) =>
                          this.onSelect(val, "appointmentType")
                        }
                        isSearchable={false}
                        options={appointment_types}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Patient Email</label>
                      <input
                        readOnly
                        className="form-control"
                        value={this.state.formData.email || ""}
                        type="email"
                        name="patientemail"
                      />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Base Fee</label>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span
                            class="input-group-text"
                            id="inputGroupPrepend3"
                          >
                            $
                          </span>
                        </div>
                        <input
                          className="form-control"
                          // value={this.state.formData.base_fee}
                          type="number"
                          name="basefee"
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-sm-12">
                    <div className="form-group">
                      <label>Chief Complaints</label>
                      <textarea
                        name="chiefComplaints"
                        onChange={(e) => this.onChange(e)}
                        className="form-control"
                        value={this.state.formData.chiefComplaints || ""}
                      >
                        {this.state.formData.chiefComplaints || ""}
                      </textarea>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>Relevant points from history</label>
                      <textarea
                        name="pointsFromHistory"
                        onChange={(e) => this.onChange(e)}
                        className="form-control"
                        value={this.state.formData.pointsFromHistory || ""}
                      >
                        {this.state.formData.pointsFromHistory || ""}
                      </textarea>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>Examination / Lab Findings</label>
                      <textarea
                        name="labFindings"
                        onChange={(e) => this.onChange(e)}
                        className="form-control"
                        value={this.state.formData.labFindings || ""}
                      >
                        {this.state.formData.labFindings || ""}
                      </textarea>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="form-group">
                      <label>Suggested Investigations</label>
                      <textarea
                        name="suggestedInvestigations"
                        onChange={(e) => this.onChange(e)}
                        className="form-control"
                        value={
                          this.state.formData.suggestedInvestigations || ""
                        }
                      >
                        {this.state.formData.suggestedInvestigations || ""}
                      </textarea>
                    </div>
                  </div> */}
                </div>
                <div className="m-t-20 text-center">
                  <button className="btn btn-primary submit-btn">
                    Add Appointment
                  </button>
                </div>
              </form>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}
