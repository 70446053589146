import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loader from "../Loader/Loader";
import Apilib from "../../api";
import { Modal, Button } from "react-bootstrap";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import Notify from '../../Notify'

export default class AddFamilyMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      formData: {},
      fullName: "",
      email: "",
      phone: "",
      relation: "",
      bloodGroup: "",
      modalLoading: false,
      familyPatients: [],
      currentPatient: {},
      currentIndex: "",
      modalOpen: false,
    };
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({
      loading: true,
    });
    let API = new Apilib();
    API.listFamilyMembers(this.props.userData.uid)
      .then(res=>res.json())
      .then((res) => {
        if (this._isMounted) {

          let data = res.family_members;
          this.setState(
            {
              loading: false,
              familyPatients: [...data],
            },
            () => {
              if ($(".datatable").length > 0) {
                $(".datatable").DataTable({
                  order: [],
                });
              }
            }
          );
        }
      })
      .catch((error) => {
        if (this._isMounted) {
          this.setState({
            loading: false,
          });
        }
      });
  }

  inputChangeHandler(e) {
    e.preventDefault();
    let form_data = { ...this.state.formData };
    form_data[e.target.name] = e.target.value;
    this.setState({
      formData: form_data,
    });
  }

  onSubmit(e){
    e.preventDefault();
    let data = {
      uid: this.props.userData.uid,
      member_email: this.state.formData.email,
      relation: this.state.formData.relation,
      bloodGroup: this.state.formData.bloodGroup
    }

    console.log("DATA>>>>",data)

    let API = new Apilib();
    API.addFamilyMember(JSON.stringify(data)).then(res =>res.json()).then(res=>{
      if(res.code == 200){
        this.setState({
          formData: {
            fullName: "",
            email: "",
            phone:"",
            relation:"",
            bloodGroup:""
          }
        })

        let API = new Apilib();
    API.listFamilyMembers(this.props.userData.uid)
      .then(res=>res.json())
      .then((res) => {
        if (this._isMounted) {

          let data = res.family_members;
          this.setState(
            {
              loading: false,
              familyPatients: [...data],
            },
            () => {
              if ($(".datatable").length > 0) {
                $(".datatable").DataTable({
                  order: [],
                });
              }
            }
          );
        }
      })
      .catch((error) => {
        if (this._isMounted) {
          this.setState({
            loading: false,
          });
        }
      });

        Notify({
          alert: true,
          type: "success",
          title: "Family member added successfully",
        });

      }else{
        this.setState({
          formData: {
            fullName: "",
            email: "",
            phone:"",
            relation:"",
            bloodGroup:""
          }
        })
        Notify({
          alert: true,
          type: "error",
          title: res.message,
        });
      }
    }).catch(err=>{
      this.setState({
        formData: {
          fullName: "",
          email: "",
          phone:"",
          relation:"",
          bloodGroup:""
        }
      })
      Notify({
        alert: true,
        type: "error",
        title: err.message,
      });
    })
  }

  render() {
    return (
      <div className="add-family-members">
        <div class="content">
          <div class="row">
            <div class="col-sm-12">
              <h4 class="page-title">Add Family Members</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="card-box">
                <h4 class="card-title">Basic Information</h4>
                <form
                  onSubmit={(e)=> this.onSubmit(e)}
                  >
                  {/* <div class="form-group row">
                    <label class="col-form-label col-md-2">Full Name</label>
                    <div class="col-md-10">
                      <input
                        required
                        value={this.state.formData.fullName}
                        name="fullName"
                        onChange={(e) => this.inputChangeHandler(e)}
                        type="text"
                        class="form-control"
                        onInvalid={function (e) {
                          e.target.setCustomValidity("");
                          if (!e.target.validity.valid) {
                            e.target.setCustomValidity("Full name cannot be left blank");
                          }
                        }}
                      />
                    </div>
                  </div> */}
                  <div class="form-group row">
                    <label class="col-form-label col-md-2">Email Id</label>
                    <div class="col-md-10">
                      <input
                        required
                        value={this.state.formData.email}
                        name="email"
                        onChange={(e) => this.inputChangeHandler(e)}
                        type="email"
                        class="form-control"
                        onInvalid={function (e) {
                          e.target.setCustomValidity("");
                          if (!e.target.validity.valid) {
                            e.target.setCustomValidity("Please enter email in proper format");
                          }
                        }}
                      />
                    </div>
                  </div>
                  {/* <div class="form-group row">
                    <label class="col-form-label col-md-2">Phone Number</label>
                    <div class="col-md-10">
                      <input
                        required
                        value={this.state.formData.phone}
                        pattern="^[0-9]{8,}$"
                        name="phone"
                        onChange={(e) => this.inputChangeHandler(e)}
                        type="text"
                        class="form-control"
                        onInvalid={function (e) {
                          e.target.setCustomValidity("");
                          if (!e.target.validity.valid) {
                            e.target.setCustomValidity("Enter phone number greater than equal to 8 digits");
                          }
                        }}
                      />
                    </div>
                  </div> */}
                  <div class="form-group row">
                    <label class="col-form-label col-md-2">Relation</label>
                    <div class="col-md-10">
                      <input
                        required
                        value={this.state.formData.relation}
                        name="relation"
                        onChange={(e) => this.inputChangeHandler(e)}
                        type="text"
                        class="form-control"
                        onInvalid={function (e) {
                          e.target.setCustomValidity("");
                          if (!e.target.validity.valid) {
                            e.target.setCustomValidity("Please mention a relation");
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-md-2">Blood Group</label>
                    <div class="col-md-10">
                      <select
                        required
                        value={this.state.formData.bloodGroup}
                        name="bloodGroup"
                        onChange={(e) => this.inputChangeHandler(e)}
                        class="form-control"
                      >
                        <option>-- Select --</option>
                        <option>A+</option>
                        <option>A-</option>
                        <option>B+</option>
                        <option>B-</option>
                        <option>O+</option>
                        <option>O-</option>
                        <option>AB+</option>
                        <option>AB-</option>
                      </select>
                    </div>
                  </div>
                  <div className="member-submit">
                    <button
                      class="btn btn-primary member-submit-btn"
                      type="submit"
                    >
                      Add
                    </button>
                  </div>
                </form>
              </div>
              <div class="card-box">
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      {this.state.familyPatients.length == 0 ? <h2>No data available</h2>:(
                        <>
                          <table className="table table-border table-striped custom-table datatable m-b-0">
                        <thead>
                          <tr>
                            <th>Full Name</th>
                            <th>Email Id</th>
                            <th>Relation</th>
                            <th>Blood Group</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.familyPatients && this.state.familyPatients.map(familyPatient =>(<>
                            <tr>
                            <td>
                                {familyPatient.name}
                            </td>
                            <td>{familyPatient.email}</td>
                            <td>{familyPatient.relation}</td>
                            <td>{familyPatient.bloodGroup}</td>
                          </tr>
                          </>))}
                          {/* {this.state.patients &&
                      this.state.patients.map((patient, index) => ( */}
                          
                        </tbody>
                      </table>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
