// import React from "react";
// import { withRouter } from "react-router-dom";
// import { analytics } from "../../firebase";
// const RouteChangeTracker = ({ history }) => {
//   history.listen((location, action) => {
//     let pageLocation = location.pathname;
//     analytics.logEvent("page_visit", {
//       page_path: pageLocation,
//       page_title: document.title,
//     });
//   });

//   return <></>;
// };

// export default withRouter(RouteChangeTracker);

import React from "react";
import ReactGA from "react-ga";
import { withRouter } from "react-router-dom";
const RouteChangeTracker = ({ history }) => {
  const scrollTopLocations = [
    "/",
    "/Product",
    "/Practitioner",
    "/Monitoring",
    "/Treatment",
    "/Team",
    "/Contact",
    "/login",
  ];
  history.listen((location, action) => {
    console.log("location changed", location.pathname);
    if (scrollTopLocations.includes(location.pathname)) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  });

  return <></>;
};

export default withRouter(RouteChangeTracker);
