// jshint esversion:6

import React, { useState, useEffect } from "react";
import Loader from "../Loader/Loader";
import { Link, Route ,useParams} from "react-router-dom";
import { storage } from "../../firebase";
import "./style.css";
import Axios from "axios";
import {useStateValue} from "../../StateProvider";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import SearchBar from './SearchBar'
import LocationBar from './LocationBar'

const SearchResults = (props) => {

  const [loading, setLoading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [searchQuery, setSearchQuery] = useState();
  const [{lat, long}, dispatch] = useStateValue();
  const [noResults, setNoResults] = useState(false);
  
  if(!props.location.search){
      props.history.push("/")
    }

  useEffect(() => {
      console.log("patient location", lat, long)
      if(lat && long){
        const urlParams = new URLSearchParams(props.location.search);
        const search = urlParams.get('search')
        setSearchQuery(search);

      }
  },[lat, long,props.location.search])

  useEffect(() => {
    if(searchQuery){
      console.log("Location searhc>>>>>>>>>>>>>",props.location.search)
      setNoResults(false);
      setLoading(true)
        Axios.get(
          // "https://australia-southeast1-medvatic-438af.cloudfunctions.net/appointment/searchForAppointment" + searchQuery + "&lat=" + lat + "&longitude=" + long

          "https://australia-southeast1-medvatic-438af.cloudfunctions.net/appointment/searchDoctorsInRegion?name="+searchQuery+"&lat="+lat+"&longitude="+long

          )
        .then((res) => {
          console.log("Ress search>>>>>>>",res.data.result)
          let dataAPI =[];
          dataAPI = res.data.result;
          setLoading(false);
          if(dataAPI.length === 0){
            setSearchResult([])
            setNoResults(true);
            console.log("no result >>>")
          } else{
            if(noResults){
              setSearchResult([])
            }
            setSearchResult(dataAPI);
          }
        })
        .catch((err) => {
          setLoading(false);
          
          setSearchResult([])
          setNoResults(false);
        });
      }
  }, [searchQuery])


  const days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
  console.log("path",props.location.search)
  return (
    <>
      <div className="container">
          <div className="search-bar">
            <>
              <div className="location-bar">
                <LocationBar />
              </div>
              <div>
                <SearchBar />
              </div>
            </>
          </div>
      </div>
      <div className="main-content account-content">
        <script src="https://use.fontawesome.com/cee0220b65.js"></script>
        {loading ? <Loader /> : null}
        <div className="content">
          <div className="container">
            <div className="content">
              <div className="row">
                <div className="col-md-12 appointment-cards">
                  {noResults && <h3>No search result found!</h3>}
                  {searchResult.length !==0 ? (
                    searchResult.map((item) => {
                      return (
                        <div className="card mb-3">
                          <div className="row">
                            <div className="date col-md-2">
                              <p>Doctor</p>
                              {/* <img src="../../../public/assets/img/Dr_drake_ramoray-.jpg" /> */}
                            </div>
                            <div className="col-md-10">
                              <div className="card-body">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                  className="head-card"
                                >
                                  <h5 className="card-title2 m-b-10">
                                    Dr. {item.firstName + " " + item.lastName}
                                  </h5>

                                  <div className="appointment-available mt-50">
                                    <a>
                                      <p className="available">
                                        {
                                          (item.days) ? (
                                            (item.days.includes(new Date(Date.now()).getDay())) ? 
                                              "Available Today!"
                                            : 
                                            "Available on " + item.days.map((day, i) =>  {
                                              if (i === 0) {
                                                return days[day];
                                              } else {
                                                return " " + days[day];
                                              }
                                            })
                                          ) : "No schedule available" 
                                        }
                                      </p>
                                      <Link
                                        to={{
                                          pathname: "/add-appointment",
                                          state: { item }
                                        }}
                                      >
                                        <button
                                        className="btn btn-primary appointment2-btn account-btn"
                                        disabled={!item.days}>
                                          Book Appointment
                                        </button>
                                      </Link>
                                    </a>
                                  </div>
                                </div>
                                <p className="speciality">{
                                  (item.specialities) ?
                                  item.specialities.map((spe,i) => {
                                    if (i === 0) {
                                      return spe;
                                    } else {
                                      return ", " + spe;
                                    }
                                  })
                                  : <h3>No search result found!</h3>
                                }</p>
                                <p className="speciality">
                                  {item.experience} Years of Overall Experience
                                </p>
                                <p className="card-text location">
                                  {/* experience
                                  <br /> */}
                                  {item.hospitalName ? item.hospitalName : <h3>No search result found!</h3>}
                                </p>
                                {/* <p className="card-text speciality">
                                  ₹1800 Consultation fee at clinic
                                </p> */}
                                {/* <hr />
                                <div className="card-text ">
                                  <p className="card-end">
                                    This is a wider card with supporting text
                                    below as a natural lead-in to additional
                                    content. This content is a little bit longer.
                                  </p>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  
                  ) :noResults ? null : <h3>No search result found!</h3>}
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}


export default SearchResults;