// jshint esversion:6

import React, { Component } from "react";
import { Link } from "react-router-dom";
import Apilib from "../../api";
import { auth, storage } from "../../firebase";
import Loader from "../Loader/Loader";
// import { Dropdown, NavDropdown, NavItem } from "react-bootstrap";
// import "../Patient/style.css";
// import "./style.css";

export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      userData: null,
      pp_url: "",
    };
  }

  makeDownloadUrl = (path) => {
    this.setState({ loading: true });
    let gsReference = storage.refFromURL(
      "gs://medvatic-438af.appspot.com/" + path
    );
    gsReference
      .getDownloadURL()
      .then((url) => {
        console.log(url);
        this.setState({
          pp_url: url,
          loading: false,
        });

        console.log("PPurl>>.", this.state.pp_url);
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  };

  async componentDidMount() {
    let API = new Apilib();
    auth.onAuthStateChanged((user) => {
      console.log(user);
      if (user) {
        this.setState({ loading: true });
        API.getUserDetails(user.displayName, user.uid)
          .then((res) => res.json())
          .then((res) => {
            console.log("ressss", res.user);
            this.setState({
              loading: false,
              userData: res.user,
            });

            this.props.updateUserData(res.user);
            if (res.user.profile_picture) {
              console.log("USE PROFILE", res.user.profile_picture);
              this.makeDownloadUrl(res.user.profile_picture);
            }
          })
          .catch((err) => {
            this.setState({ loading: false });
            console.log(err);
          });
      } else {
        this.props.history.push("/login");
      }
    });
  }

  render() {
    // let userData = this.state.userData || {};
    // console.log("doctorData", userData);

    return (
      <>
        {this.state.loading ? <Loader /> : null}
        <div className="page-header profile-header">
          <div className="content profile-content">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-3 mt-md-5 doctor-sidebar">
                  <div className="doctor-list doctor-view">
                    <div className="doctor-inner">
                      <img
                        className="img-fluid"
                        alt=""
                        src={
                          this.state.pp_url !== ""
                            ? this.state.pp_url
                            : "../assets/img/doctor-06.jpg"
                        }
                      ></img>
                      <div className="doctor-details">
                        <div className="doctor-info">
                          <h4 className="doctor-name">
                            {this.props.user && (
                              <span>
                                {this.state.userData?.firstName}{" "}
                                {this.state.userData?.lastName}
                              </span>
                            )}
                          </h4>
                          {/* <p>
                              <span class="depart">Physical Therapist</span>
                            </p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-9">
                  <div className="about-doctor">
                    <div className="d-flex justify-content-end mb-4 ">
                      {/* <Link
                        to="/add-member"
                        className="btn custom-btn btn-primary d-block btn-rounded"
                      >
                        Add Family Account
                      </Link> */}
                      {/* <Link
                        to="/edit-profile"
                        className="btn custom-btn btn-primary ml-2 d-block  btn-rounded"
                      >
                        Edit Profile
                      </Link>  */}
                    </div>

                    {/* <div className="about-doctor">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In porta luctus est interdum pretium. Fusce id tortor fringilla, suscipit turpis ac, varius
                      ex. Cras vel metus ligula. Nam enim ligula, bibendum a iaculis ut, cursus id augue. Proin vitae purus id tortor vehicula scelerisque non in libero.</p>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In porta luctus est interdum pretium. Fusce id tortor fringilla, suscipit turpis ac, varius
                      ex. Cras vel metus ligula. Nam enim ligula, bibendum a iaculis ut, cursus id augue. Proin vitae purus id tortor vehicula scelerisque non in libero.</p>
                    </div> */}
                    <div className="row">
                      <div className="experience-widget-h col-md-4">
                        <div className="experience-box">
                          <h3 className="sub-title-h">Name</h3>
                          <ul className="experience-list">
                            <li>
                              <div className="timeline-content">
                                <h4>
                                  {this.state.userData?.firstName}{" "}
                                  {this.state.userData?.lastName}
                                </h4>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="experience-widget-h col-md-4">
                        <h3 className="sub-title-h">Email Id</h3>
                        <div className="experience-box">
                          <ul className="experience-list">
                            <li>
                              <div className="timeline-content">
                                <h4>{this.state.userData?.email}</h4>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="experience-widget-h col-md-4">
                        <h3 className="sub-title-h">Contact details</h3>
                        <div className="experience-box">
                          <ul className="experience-list">
                            <li>
                              <div className="timeline-content">
                                <h4>{this.state.userData?.mobile}</h4>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      {/* <div className="experience-widget-h col-md-4">
                        <h3 className="sub-title-h">Height</h3>
                        <div className="experience-box">
                          <ul className="experience-list">
                            <li>
                              <div className="timeline-content">
                                <h4>{userData.height}</h4>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div> */}
                      {/* 
                      <div className="experience-widget-h col-md-4">
                        <h3 className="sub-title-h">Weight</h3>
                        <div className="experience-box">
                          <ul className="experience-list">
                            <li>
                              <div className="timeline-content">
                                <h4>{userData.weight}</h4>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="experience-widget-h col-md-4">
                        <h3 className="sub-title-h">Allergies</h3>
                        <div className="experience-box">
                          <ul className="experience-list">
                            <li>
                              <div className="timeline-content">
                                <h4>
                                  {userData.allergies
                                    ? userData.allergies.join(", ")
                                    : ""}
                                </h4>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div> */}
                    </div>

                    {/* <div className="row">
                      <div className="experience-widget-h col-md-4">
                        <h3 className="sub-title-h">Hospital Name</h3>
                        <div className="experience-box">
                          <ul className="experience-list">
                            <li>
                              <div className="timeline-content">
                                <h4>{this.state.userData?.hospitalName}</h4>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="experience-widget-h col-md-4">
                        <h3 className="sub-title-h">Experience</h3>
                        <div className="experience-box">
                          <ul className="experience-list">
                            <li>
                              <div className="timeline-content">
                                <h4>{this.state.userData?.experience}</h4>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="experience-widget-h col-md-4">
                        <h3 className="sub-title-h">Qualifications</h3>
                        <div className="experience-box">
                          <ul className="experience-list">
                            <li>
                              <div className="timeline-content">
                                {this.state.userData?.qualifications.map((item) => {
                                  return <h4>{item} </h4>;
                                })}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div> */}
                    {/* 
                    <div className="row">
                      <div className="experience-widget-h col-md-4">
                        <h3 className="sub-title-h">City</h3>
                        <div className="experience-box">
                          <ul className="experience-list">
                            <li>
                              <div className="timeline-content">
                                <h4>{userData.city}</h4>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="experience-widget-h col-md-4">
                        <h3 className="sub-title-h">State</h3>
                        <div className="experience-box">
                          <ul className="experience-list">
                            <li>
                              <div className="timeline-content">
                                <h4>{userData.state}</h4>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="experience-widget-h col-md-4">
                        <h3 className="sub-title-h">Postal Code</h3>
                        <div className="experience-box">
                          <ul className="experience-list">
                            <li>
                              <div className="timeline-content">
                                <h4>{userData.pincode}</h4>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </>
    );
  }
}
