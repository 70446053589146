import React,{useState,useEffect} from "react";
import { Avatar } from "@material-ui/core";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import Loader from "../Loader/Loader";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import Notify from "../../Notify";
import Apilib from "../../api";
import { IconButton } from "@material-ui/core";

function Requests(props) {

  let [loading, setLoading] = useState(false);
  let [userData,setUserData] = useState(null);
  let [reqList,setReqList] = useState([]);
  let [noReuests,setNoRequests] = useState(false);
  let [deletePatient,setNoDeletePatient] = useState(null);

  useEffect(() => {
    if(props.userData !== 'loading'){
      setLoading(true);
      setUserData(props.userData);
      console.log(props.userData);
      let Api = new Apilib();
      Api.getFamilyRequests(props.userData.uid).then((res) => res.json()).then((res) => {
        console.log(res);
        setReqList(res.data);
        if(res.data.length == 0){
          setNoRequests(true);
        }
        setLoading(false);
      }).catch(err => {
        console.log(err);
        setLoading(false);
      })
    }
  },[props]);

  function approveMember(member,i){
    // console.log(member);
    let dataToPost = {
      member_email: member.email,
      uid: userData.uid,
      relation: member.relation
    }
    setLoading(true);
    let Api = new Apilib();
    Api.addFamilyMember(JSON.stringify(dataToPost)).then((res) => res.json()).then((res) => {
      if(res.code == 200 ){
        console.log(res);
      setLoading(false);
      let arr = [...reqList];
      arr.splice(i,1);
      setReqList(arr);
      if(arr.length == 0){
        setNoRequests(true);
      }
      Notify({
        alert: true,
        type: "success",
        title: res.message,
      });
      }else{
        Notify({
          alert: true,
          type: "error",
          title: "Error occured",
        });
      }
    }).catch(err => {
      console.log(err);
      setLoading(false);
    });
    // console.log(dataToPost);
  }

  function deleteFamilyAccount (listItem,i){
    let required_data = {
      uid : props.userData.uid,
      member_id : listItem.uid
    }
    let API = new Apilib();
    API.rejectRequest(JSON.stringify(required_data)).then(res=>res.json()).then(res=>{
      Notify({
        alert: true,
        type: "success",
        title: "Family member deleted successfully",
      });
    }).catch(err=>{
      Notify({
        alert: true,
        type: "error",
        title: "Error occured",
      });
    })
    console.log("Listitem",listItem)
  }

 

  return (
    <>
      {loading ? <Loader /> : null}
      <div className="req-head">
        <h3>All Requests</h3>
      </div>
      <div className="container2">
        {reqList.length ? 
        <>
        {reqList.map((listItem,i) => (
          <React.Fragment key={"user-"+i}>
            <div className="sidebarOptions">
              <Avatar />
              <h2>{listItem.name}</h2>
              <div className="req-icon">
                <IconButton onClick={() => approveMember(listItem,i)} className="check-icon">
                  <CheckIcon />
                </IconButton>
                <IconButton onClick={()=> deleteFamilyAccount(listItem,i)} className="close-icon">
                  <CloseIcon />
                </IconButton>
              </div>
            </div>
            <hr />
          </React.Fragment>
        ))}
        </>
        : <>{noReuests && <h3>No Requests Found</h3>}</>}
      </div>
    </>
  );
}

export default Requests;
