import React, { Component } from "react";

export default class PrivacyPolicy extends Component {
  render() {
    return (
      <section className="section section-padding">
        <div className="section-header2">
          <h3 className="header-title text-center">PRIVACY POLICY</h3>
          <div className="line"></div>
          <div class="policy">
            <p>
              Your trust in us is our most valuable asset. That&rsquo;s why we
              insist upon the highest standards for secure transactions and
              customer information privacy.
            </p>
            <p>
              Medvatic LLP ( which also include its affiliates), having its
              registered office address at 203, Parshwa Residency, Mundra,
              Kachchh, Gujarat &ndash; 370421. Medvatic provides health
              monitoring services for patients and allows healthcare providers
              to manage post consultation care of their patients. Medvatic is
              committed to respecting the privacy of every person who shares
              information with it or whose information it receives.
            </p>

            <p>
              This privacy policy ("<strong>Privacy Policy</strong>") explains
              how we collect, use, share, disclose and protect Personal
              information about the Users of the Services, including the
              Practitioners (as defined in the Terms of Use), the End-Users (as
              defined in the Terms of Use), and the visitors of Website (jointly
              and severally referred to as &ldquo;you&rdquo; or
              &ldquo;Users&rdquo; in this Privacy Policy). We created this
              Privacy Policy to demonstrate our commitment to the protection of
              your privacy and your personal information. Your use of and access
              to the Services is subject to this Privacy Policy and our Terms of
              Use. Any capitalized term used but not defined in this Privacy
              Policy shall have the meaning attributed to it in our Terms of
              Use. Please read the following statement to learn about our
              (defined below) information gathering and dissemination practices.
            </p>

            <p>
              The terms &lsquo;<strong>You&rsquo;</strong>&nbsp;or &lsquo;
              <strong>Your&rsquo;</strong>&nbsp;refer to you as the user
              (registered or unregistered) of the Website, Application or
              Services and the terms &lsquo;<strong>We&rsquo;</strong>, &lsquo;
              <strong>Us</strong>&rdquo; and &lsquo;<strong>Our&rsquo;</strong>
              &nbsp;refer to Medvatic.
            </p>

            <p>
              BY USING THE SERVICES OR BY OTHERWISE GIVING US YOUR INFORMATION,
              YOU WILL BE DEEMED TO HAVE READ, UNDERSTOOD AND AGREED TO THE
              PRACTICES AND POLICIES OUTLINED IN THIS PRIVACY POLICY AND AGREE
              TO BE BOUND BY THE PRIVACY POLICY. YOU HEREBY CONSENT TO OUR
              COLLECTION, USE AND SHARING, DISCLOSURE OF YOUR INFORMATION AS
              DESCRIBED IN THIS PRIVACY POLICY. IF YOU USE THE SERVICES ON
              BEHALF OF SOMEONE ELSE (SUCH AS YOUR CHILD) OR AN ENTITY (SUCH AS
              YOUR EMPLOYER), YOU REPRESENT THAT YOU ARE AUTHORISED BY SUCH
              INDIVIDUAL OR ENTITY TO (I) ACCEPT THIS PRIVACY POLICY ON SUCH
              INDIVIDUAL&rsquo;S OR ENTITY&rsquo;S BEHALF, AND (II) CONSENT ON
              BEHALF OF SUCH INDIVIDUAL OR ENTITY TO OUR COLLECTION, USE AND
              DISCLOSURE OF SUCH INDIVIDUAL&rsquo;S OR ENTITY&rsquo;S
              INFORMATION AS DESCRIBED IN THIS PRIVACY POLICY.
            </p>

            <ol>
              <li>
                <strong>CONSENT:</strong>
              </li>
            </ol>
            <ul>
              <li>
                You acknowledge that this Privacy Policy is a part of the Terms
                of Use of the Website and the other Services, by accessing the
                Website or Application or by otherwise providing Us Your
                Personal Information Yourself or through a Primary User or by
                making use of the Services provided by the Website or
                Application, You unconditionally signify Your (i) assent to the
                Privacy Policy, and (ii) consent to the Utilization of your
                Personal Information in accordance with the provisions of this
                Privacy Policy.
              </li>
            </ul>

            <ul>
              <li>
                YOU HAVE THE OPTION NOT TO PROVIDE US THE PERSONAL INFORMATION
                SOUGHT TO BE COLLECTED. YOU WILL ALSO HAVE AN OPTION TO WITHDRAW
                YOUR CONSENT AT ANY POINT, PROVIDED SUCH WITHDRAWAL OF THE
                CONSENT IS INTIMATED TO US IN WRITING. If You do not provide Us
                Your Personal Information or if You withdraw the consent to
                provide Us Your Personal Information at any point in time, We
                shall have the option not to fulfill the purposes for which the
                said Personal Information was sought and We may restrict You
                from using the Website, Application or Services
              </li>
            </ul>

            <ul>
              <li>
                Our Website or Application are not directed at children and We
                do not knowingly collect any Personal Information from children.
                Please contact us at&nbsp;<strong>info@medvatic.com</strong>if
                you are aware that we may have inadvertently collected Personal
                Information from a child, and we will delete that information as
                soon as possible.
              </li>
            </ul>

            <ol start="2">
              <li>
                <strong>CHANGES TO THE PRIVACY POLICY:</strong>
              </li>
            </ol>
            <ul>
              <li>
                We reserve the right to update (change, modify, add and/or
                delete) this Privacy Policy from time to time at our sole
                discretion. There is a tab at the end of the Privacy Policy
                which indicates when the Privacy Policy was last updated.
              </li>
            </ul>

            <ul>
              <li>
                When we update Our Privacy Policy, we will intimate you of the
                amendments on your registered email ID or on the Website or
                Application. Alternatively, Medvatic may cause your account to
                be logged-off and make your subsequent account log-in
                conditional on acceptance of the Agreement. If you do not agree
                to the amendments, please do not use the Website, Application or
                Services any further.
              </li>
            </ul>

            <ol start="3">
              <li>
                <strong>COLLECTION OF PERSONAL INFORMATION:</strong>
                <br />
                In order to provide Services to You we might require You to
                voluntarily provide Us certain information that personally
                identifies You or Secondary Users related to You. You hereby
                consent to the collection of such information by Medvatic. The
                information that We may collect from You, about You or Secondary
                Users related to You, may include but are not limited to, the
                following:
              </li>
            </ol>
            <ul>
              <li>Patient/Caregiver/Doctor/Health Care Professional Name,</li>
              <li>Birth date/age,</li>
              <li>Blood group,</li>
              <li>Gender,</li>
              <li>Address (including country and pin/postal code),</li>
              <li>Location information, including Your GPS location,</li>
              <li>Phone number/mobile number,</li>
              <li>Email address,</li>
              <li>
                Physical, physiological and mental health condition, provided by
                You and/or Your Healthcare Service provider or accessible from
                Your medical records,
              </li>
              <li>Personal medical records and history,</li>
              <li>
                Valid financial information at time of purchase of
                product/Services and/or online payment,
              </li>
              <li>Medvatic Login ID and password,</li>
              <li>
                User details as provided at the time of registration or
                thereafter,
              </li>
              <li>Records of interaction with Medvatic representatives,</li>
              <li>
                Your usage details such as time, frequency, duration and pattern
                of use, features used and the amount of storage used,
              </li>
              <li>
                Master and transaction data and other data stored in Your user
                account,
              </li>
              <li>
                Internet Protocol address, browser type, browser language,
                referring URL, files accessed, errors generated, time zone,
                operating system and other visitor details collected in Our log
                files, the pages of our Website or Application that You visit,
                the time and date of Your visit, the time spent on those pages
                and other statistics ("<strong>Log Data</strong>"),
              </li>
              <li>
                User&rsquo;s tracking Information such as, but not limited to
                the device ID, Google Advertising ID and Android ID,
              </li>
              <li>Any other information that is willingly shared by you.</li>
            </ul>
            <p>
              (Collectively referred to as &ldquo;
              <strong>Personal Information</strong>&rdquo;)
            </p>

            <ol start="4">
              <li>
                <strong>HOW WE COLLECT PERSONAL INFORMATION:</strong>
                <br />
                The methods by which We collect Your Personal Information
                include but are not limited to the following:
              </li>
            </ol>
            <ul>
              <li>When You register on Our Website or Application,</li>
              <li>When You provide Your Personal Information to Us,</li>
              <li>During the course of Services provided to You by Us,</li>
              <li>When You use the features on Our Website or Application,</li>
              <li>
                Through Your device, once You have granted permissions to Our
                Application (discussed below),
              </li>
              <li>
                Through HSP pursuant to consultation on the Website or the
                Application,
              </li>
              <li>By the use of cookies (also discussed below),</li>
              <li>
                We collect information that your browser/app sends whenever you
                visit Our Website or Application, such as, the Log Data. In
                addition, we may use third party services such as Pixel that
                collect, monitor and analyze this. This information is kept
                completely secure.
              </li>
            </ul>

            <ol start="5">
              <li>
                <strong>USE OF PERSONAL INFORMATION:</strong>
                <br />
                YOUR PERSONAL INFORMATION MAY BE USED FOR VARIOUS PURPOSES
                INCLUDING BUT NOT LIMITED TO THE FOLLOWING:
              </li>
            </ol>
            <ul>
              <li>To provide effective Services;</li>
              <li>To debug customer support related issues;</li>
              <li>To operate and improve the Website or Application;</li>
              <li>
                TO PERFORM ACADEMIC/STUDIES, CLINICAL OR OTHER RESEARCH AND
                ANALYSIS FOR OUR UNDERSTANDING, INFORMATION, ANALYSIS, SERVICES
                AND TECHNOLOGIES IN ORDER TO PROVIDE ALL USERS IMPROVED QUALITY
                OF CARE; AND ENSURING THAT THE CONTENT AND ADVERTISING DISPLAYED
                ARE CUSTOMIZED TO YOUR INTERESTS AND PREFERENCES;
              </li>
              <li>
                To contact You via phone, SMS, email or third-party
                communication services such as Whatsapp, etc. for appointments,
                technical issues, payment reminders, obtaining feedback and
                other security announcements;
              </li>
              <li>
                To send promotional and marketing emails from Us or any of Our
                channel partners via SMS, email, snail mail or third-party
                communication services such as WhatsApp, Facebook etc.;
              </li>
              <li>
                To advertise products and Services of Medvatic and third
                parties;
              </li>
              <li>
                To transfer information about You, if We are acquired by or
                merged with another company;
              </li>
              <li>
                To share with Our business partners for provision of specific
                services You have ordered so as to enable them to provide
                effective Services to You;
              </li>
              <li>
                To administer or otherwise carry out Our obligations in relation
                to any Agreement You have with Us;
              </li>
              <li>To build Your profile on the Website or Application;</li>
              <li>
                To respond to subpoenas, court orders, or legal process, or to
                establish or exercise Our legal rights or defend against legal
                claims;&nbsp;
              </li>
              <li>
                To investigate, prevent, or take action regarding illegal
                activities, suspected fraud, violations of Our Terms of Use,
                breach of Our agreement with You or as otherwise required by
                law;
              </li>
              <li>
                TO AGGREGATE PERSONAL INFORMATION FOR RESEARCH FOR
                ACADEMIC/STUDIES, CLINICAL OR OTHER RESEARCH, , STATISTICAL
                ANALYSIS AND BUSINESS INTELLIGENCE PURPOSES, AND TO SELL OR
                OTHERWISE TRANSFER SUCH RESEARCH, STATISTICAL OR INTELLIGENCE
                DATA IN AN AGGREGATED AND/OR NON-PERSONALLY IDENTIFIABLE FORM TO
                THIRD PARTIES AND AFFILIATES WITH A PURPOSE OF PROVIDING
                SERVICES TO THE USERS OR FOR THE ADVANCEMENT OF SCIENTIFIC
                KNOWLEDGE ABOUT HEALTH AND DISEASE.
              </li>
            </ul>
            <p>
              (Collectively referred to as &ldquo;<strong>Purpose(s)</strong>
              &rdquo;)
            </p>

            <ol start="6">
              <li>
                <strong>
                  SHARING AND TRANSFERRING OF PERSONAL INFORMATION:
                </strong>
              </li>
            </ol>
            <ul>
              <li>
                You authorize Us to exchange, transfer, share, part with all or
                any of Your Personal Information, across borders and from Your
                country to any other countries across the world with Our
                affiliates / agents / third party service providers / partners /
                banks and financial institutions for the Purposes specified
                under this Policy or as may be required by applicable law.
              </li>
              <li>
                You hereby consent and authorize us to publish feedback obtained
                by You on Our Website or Application.
              </li>
              <li>
                User&rsquo;s financial information is transacted upon secure
                sites of approved payment gateways which are digitally under
                encryption, thereby providing the highest possible degree of
                care as per current technology. However, User is advised to
                exercise discretion while saving the payment details.
              </li>
              <li>
                You acknowledge that some countries where we may transfer Your
                Personal Information may not have data protection laws which are
                as stringent as the laws of Your own country. You acknowledge
                that it is adequate that when Medvatic transfers Your Personal
                Information to any other entity within or outside Your country
                of residence, Medvatic will place contractual obligations on the
                transferee which will oblige the transferee to adhere to the
                provisions of this Privacy Policy. You acknowledge that Medvatic
                may be obligated to by law to disclose or transfer your Personal
                Information with Courts and Government agencies in certain
                instances such as for verification of identity, or for
                prevention, detection, investigation, prosecution, and
                punishment for offences, or in compliance with laws such as
                intimation of diagnosis of an epidemic disease. You hereby
                consent to disclosure or transfer of Your Personal Information
                in these instances.
              </li>
              <li>
                Notwithstanding the above, We are not responsible for the
                confidentiality, security or distribution of Your Personal
                Information by third-parties outside the scope of Our Agreement
                with such third-parties. Further, We shall not be responsible
                for any breach of security or for any actions of any
                third-parties or events that are beyond the reasonable control
                of Us including but not limited to, acts of government, computer
                hacking, unauthorized access to computer data and storage
                device, computer crashes, breach of security and encryption,
                poor quality of Internet service or telephone service of the
                User etc.
              </li>
              <li>
                We may share Your Personal Information with Our other corporate
                and/or associate entities and affiliates to (i) help detect and
                prevent identity theft, fraud and other potentially illegal acts
                and cyber security incidents, and (ii) help and detect
                co-related/related or multiple accounts to prevent abuse of Our
                Services.
              </li>
            </ul>
            <ol start="7">
              <li>
                <strong>PERMISSIONS:</strong>
                <br />
                Once You download and install Our Application, You may be
                prompted to grant certain permissions to allow the Application
                to perform certain actions on Your device. These actions include
                permission to:
              </li>
            </ol>
            <ul>
              <li>
                read/write/modify/delete data in relation to the Application on
                Your device&rsquo;s storage;
              </li>
              <li>
                view/access information relating to networks/access networks,
                including permission to send and receive data through such
                networks/access networks;
              </li>
              <li>
                determine Your approximate location from sources like, but not
                limited to, mobile towers and connected Wi-Fi networks;
              </li>
              <li>
                determine Your exact location from sources such as, but not
                limited to, GPS;
              </li>
              <li>
                view/access device information, including but not limited to the
                model number, IMEI number, operating system information and
                phone number of Your device;
              </li>
              <li>
                access device information including device identification number
                required to send notification/push notifications.
              </li>
            </ul>

            <ol start="8">
              <li>
                <strong>USE OF COOKIES:</strong>
              </li>
            </ol>
            <ul>
              <li>
                Cookies are files with small amount of data, which may include
                an anonymous unique identifier. Cookies are sent to You on the
                Website and/or Application.
              </li>
              <li>
                We may store temporary or permanent &lsquo;cookies&rsquo; on
                Your computer/device to store certain data (that is not
                Sensitive Personal Data or Information). You can erase or choose
                to block these cookies from Your computer. You can configure
                Your computer&rsquo;s browser to alert You when We attempt to
                send You a cookie with an option to accept or refuse the cookie.
                If You have turned cookies off, You may be prevented from using
                certain features of the Website or Application.
              </li>
              <li>
                We do not control the use of Cookies by third parties.&nbsp;
              </li>
            </ul>

            <ol start="9">
              <li>
                <strong>SECURITY:</strong>
              </li>
            </ol>
            <ul>
              <li>
                The security of Your Personal Information is important to Us. We
                have adopted reasonable security practices and procedures
                including role-based access, secure communication, password
                protection, encryption, etc. to ensure that the Personal
                Information collected is secure. You agree that such measures
                are secured and adequate. We restrict access to Your Personal
                Information to Our and Our affiliates&rsquo; employees, agents,
                third party service providers, partners, and agencies who need
                to know such Personal Information in relation to the Purposes as
                specified above in this Policy, provided that such entities
                agree to abide by this Privacy Policy.&nbsp;
              </li>
              <li>
                While we will endeavor to take all reasonable and appropriate
                steps to keep secure any information which We hold about You and
                prevent unauthorized access, You acknowledge that the internet
                is not 100% secure and that We cannot guarantee absolute
                security of Your Personal Information. Further, if you are
                Secondary User, You hereby acknowledge and agree that Your
                Personal Information may be freely accessible by the Primary
                User and other Secondary Users and that Medvatic will not be
                able to restrict, control or monitor access by Primary User or
                other Secondary Users to your Personal Information. We will not
                be liable in any way in relation to any breach of security or
                unintended loss or disclosure of information caused in relation
                to Your Personal Information.
              </li>
            </ul>

            <ol start="10">
              <li>
                {" "}
                <strong>THIRD PARTY LINKS</strong>:{" "}
              </li>
            </ol>
            <ul>
              During Your interactions with us, it may happen that we
              provide/include links and hyperlinks of third-party websites not
              owned or managed by us (&ldquo;
              <strong>Third-party Websites</strong>&rdquo;). It may also happen
              that you or other Users may include links and hyperlinks of
              Third-party Websites. The listing of such Third-Party Websites (by
              you, other Users or by us) does not imply endorsement of such
              Third-party Websites by Medvatic. Such Third-party Websites are
              governed by their own terms and conditions and when you access
              such Third-party Websites, You will be governed by the terms of
              such Third-party Websites. You must use your own discretion while
              accessing or using Third-party Websites. We do not make any
              representations regarding the availability and performance of any
              of the Third-party Websites. We are not responsible for the
              content, terms of use, privacy policies and practices of such
              Third-party Websites. We do not bear any liability arising out of
              your use of Third-party Websites.
            </ul>

            <ol start="11">
              <li>
                <strong>CONFIDENTIALITY AND SECURITY</strong>
              </li>
            </ol>
            <ul>
              <li>
                Your Personal Information is maintained by Medvatic in
                electronic form on its equipment, and on the equipment of its
                employees. Such information may also be converted to physical
                form from time to time. Medvatic takes all necessary precautions
                to protect your personal information both online and off-line,
                and implements reasonable security practices and measures
                including certain managerial, technical, operational and
                physical security control measures that are commensurate with
                respect to the information being collected and the nature of
                Medvatic&rsquo;s business.
              </li>
            </ul>

            <ul>
              <li>
                No administrator at Medvatic will have knowledge of your
                password. It is important for you to protect against
                unauthorized access to your password, your computer and your
                mobile phone. Be sure to log off from the Website when finished.
                Medvatic does not undertake any liability for any unauthorized
                use of your account and password. If you suspect any
                unauthorized use of your account, you must immediately notify
                Medvatic by sending an email to{" "}
                <strong>info@medvatic.com</strong>. You shall be liable to
                indemnify Medvatic due to any loss suffered by it due to such
                unauthorized use of your account and password.
              </li>
            </ul>

            <ul>
              <li>
                Medvatic makes all User information accessible to its employees,
                agents or partners and third parties only on a need-to-know
                basis, and binds only its employees to strict confidentiality
                obligations.
              </li>
            </ul>

            <ul>
              <li>
                Part of the functionality of Medvatic is assisting the doctors
                to maintain and organize such information. Medvatic may,
                therefore, retain and submit all such records to the appropriate
                authorities, or to doctors who request access to such
                information.
              </li>
            </ul>

            <ul>
              <li>
                Part of the functionality of the Medvatic is assisting the
                patients to access information relating to them. Medvatic may,
                therefore, retain and submit all such records to the relevant
                patients, or to their doctors.
              </li>
            </ul>

            <ul>
              <li>
                Notwithstanding the above, Medvatic is not responsible for the
                confidentiality, security or distribution of your Personal
                Information by our partners and third parties outside the scope
                of our agreement with such partners and third parties. Further,
                Medvatic shall not be responsible for any breach of security or
                for any actions of any third parties or events that are beyond
                the reasonable control of Medvatic including but not limited to,
                acts of government, computer hacking, unauthorized access to
                computer data and storage device, computer crashes, breach of
                security and encryption, poor quality of Internet service or
                telephone service of the User etc.
              </li>
            </ul>

            <ol start="12">
              <li>
                <strong>ACCESS:</strong>
              </li>
            </ol>
            <ul>
              If You need to update or correct Your Personal Information or have
              any grievance with respect to the processing or use of Your
              Personal Information, or request that We no longer use Your
              Personal Information to provide You Services, or opt-out of
              receiving communications such as promotional and marketing-related
              information regarding the Services, for any reason, You may send
              Us an email at <strong>info@medvatic.com</strong> and We will take
              all reasonable efforts to incorporate the changes within a
              reasonable period of time.
            </ul>

            <ol start="13">
              <li>
                <strong>COMPLIANCE WITH LAWS:</strong>
              </li>
            </ol>
            <ul>
              You are not allowed to use the services of the Website or
              Application if any of the terms of this Privacy Policy are not in
              accordance with the applicable laws of your country.
            </ul>

            <ol start="14">
              <li>
                <strong>TERM OF STORAGE OF PERSONAL INFORMATION:</strong>
              </li>
            </ol>
            <ul>
              <li>
                Medvatic may keep records of communications, including phone
                calls received and made for making enquiries, orders, feedback
                or other purposes for&nbsp;rendering services effectively and
                efficiently.&nbsp;Medvatic&nbsp;will be the exclusive owner of
                such data and records.&nbsp;However, all records are regarded as
                confidential. Therefore, will not be divulged to any third
                party, unless required by law.
              </li>
              <li>
                Medvatic shall store Your Personal Information at least for a
                period of three years from the last date of use of the Services,
                Website or Application or for such period as may be required by
                law.
              </li>
            </ul>

            <ol start="15">
              <li>
                <strong>GRIEVANCE:</strong>
              </li>
            </ol>
            <ul>
              To address any concerns or grievances that You may have regarding
              the processing of Your Personal Information. Please write to us
              at&nbsp;<strong>info@medvatic.com</strong> and we will attempt to
              resolve Your issues in a timely manner.
            </ul>
            <p>
              <u>&nbsp;</u>
            </p>
          </div>
        </div>
      </section>
    );
  }
}
