import { auth } from "../firebase";

export const Usertypes = [
  ["/admin", "/admin/users"],
  ["/hospital", "/hospital/doctors"],
  ["/doctor", "/doctor/patients"],
  ["/receptionist", "/receptionist/patients"],
  ["/patient", "/my-profile"],
];

const BusinessAdminSidebarOptions = [
  { label: "Users", icon: "user", link: "/admin/users" },
  { label: "Hospitals", icon: "hospital-o", link: "/admin/hospitals" },
  {
    label: "Settings",
    isExpanding: true,
    icon: "cog",
    options: [
      {
        label: "Add Hospital Chain",
        icon: "link",
        link: "/admin/add-hospital-chain",
      },
      {
        label: "Add Speciality",
        icon: "star",
        link: "/admin/add-speciality",
      },
    ],
  },
  // {
  //   label: "Add Hospital Chain",
  //   icon: "plus",
  //   link: "/admin/add-hospital-chain",
  // },
  // {
  //   label: "Add Speciality",
  //   icon: "plus",
  //   link: "add-speciality",
  // },
  // { label: "Add Hospital", icon: "plus", link: "/admin/add-hospital" },
  // { label: "Add User", icon: "plus", link: "/admin/add-user" },
  // { label: "Change Password", icon: "key", link: "/admin/change-password" },
];

export const sideNavs = [
  [
    ...BusinessAdminSidebarOptions,
    // {
    //   label: "Add Hospital Chain",
    //   icon: "plus",
    //   link: "/admin/add-hospital-chain",
    // },
    // {
    //   label: "Add Speciality",
    //   icon: "plus",
    //   link: "add-speciality",
    // },
    // { label: "Hospitals", icon: "hospital-o", link: "/admin/hospitals" },
    // // { label: "Add Hospital", icon: "plus", link: "/admin/add-hospital" },
    // { label: "Users", icon: "user", link: "/admin/users" },
    // // { label: "Add User", icon: "plus", link: "/admin/add-user" },
    // { label: "Change Password", icon: "key", link: "/admin/change-password" },
  ],
  [
    { label: "Doctors", icon: "user-md", link: "/hospital/doctors" },
    // { label: "Add Doctor", icon: "plus", link: "/hospital/add-doctor" },
    { label: "Receptionists", icon: "user-o", link: "/hospital/receptionists" },
    // {
    //   label: "Add Receptionist",
    //   icon: "user-plus",
    //   link: "/hospital/add-receptionist",
    // },
    {
      label: "Requests",
      icon: "user-md",
      link: "/hospital/view-requests",
    },
    {
      label: "Appointments",
      icon: "calendar",
      link: "/hospital/appointment-list",
    },
    {
      label: "Patients",
      icon: "users",
      link: "/hospital/patients-list",
    },
    // {
    //   label: "Change Password",
    //   icon: "key",
    //   link: "/hospital/change-password",
    // },
  ],
  [
    { label: "Patients", icon: "users", link: "/doctor/patients" },
    // { label: "Add Patient", icon: "user-plus", link: "/doctor/add-patient" },
    { label: "Appointments", icon: "calendar", link: "/doctor/appointments" },
    // {
    //   label: "Add Appointment",
    //   icon: "calendar-plus-o",
    //   link: "/doctor/add-appointment",
    // },
    {
      label: "Add Schedule",
      icon: "calendar-plus-o",
      link: "/doctor/add-schedule",
    },
    // { label: "Change Password", icon: "key", link: "/doctor/change-password" },
    // {'label':'View Schedule','icon':'calendar','link':'/doctor/view-schedule'}
  ],
  [
    { label: "Patients", icon: "users", link: "/receptionist/patients" },
    // {
    //   label: "Add Patient",
    //   icon: "user-plus",
    //   link: "/receptionist/add-patient",
    // },
    {
      label: "Appointments",
      icon: "calendar",
      link: "/receptionist/appointments",
    },
    {
      label: "Add Schedule",
      icon: "calendar-plus-o",
      link: "/receptionist/add-schedule",
    },
    // {
    //   label: "Add Appointments",
    //   icon: "calendar-plus-o",
    //   link: "/receptionist/add-appointment",
    // },
    // {
    //   label: "Change Password",
    //   icon: "key",
    //   link: "/receptionist/change-password",
    // },
  ],
  [
    {
      label: "My Appointments",
      icon: "calendar",
      link: "/patient/appointments",
    },
    { label: "My Profile", icon: "calendar", link: "/patient/profile" },
    { label: "Change Password", icon: "key", link: "/patient/change-password" },
  ],
];

export const frontNavs = [
  [{ label: "Hospitals", link: "/admin/hospitals" }],
  [{ label: "Doctors", link: "/hospital/doctors" }],
  [{ label: "Patients", link: "/doctor/patients" }],
  [{ label: "Patients", link: "/receptionist/patients" }],
  [
    { label: "My Appointments", link: "/my-appointments" },
    { label: "View Profile", link: "/my-profile" },
    { label: "Family Account", link: "/family-account" },
    { label: "Change Password", link: "/patient/change-password" },
  ],
];

export const getDefaultPageUrl = (userType) => {
  switch (userType) {
    case "1":
      return "/admin/users";
    case "2":
      return "/hospital/doctors";
    case "3":
      return "/doctor/patients";
    case "4":
      return "/receptionist/patients";

    default:
      return "/my-profile";
  }
};

export function isFooterLink(checkLink) {
  const footerLinks = [
    "privacy-policy",
    "us-hippa-policy",
    "EU-GDPR",
    "Compliance",
    "MobileAppTerms",
    "WebsiteTerms",
  ];
  return footerLinks.filter((link) => checkLink.includes(link)).length > 0;
}

export async function logout() {
  if (auth().currentUser) {
    await auth().signOut();
    localStorage.removeItem("@displayName");
  }
}
