import { Avatar, Grid } from "@material-ui/core";
import "datatables.net-dt/css/jquery.dataTables.min.css";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import { Formik } from "formik";
import moment from "moment";
import React, { Component } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Prompt } from "react-router";
import BaseSelect from "react-select";
import * as Yup from "yup";
import Apilib from "../../api";
import { storage } from "../../firebase";
import RequiredSelect from "../../Helpers/requiredSelect";
import Notify from "../../Notify";
import Loader from "../Loader/Loader";
import InternalChat from "./InternalChat";
import ExternalChat from "./ExternalChat";
var QRCode = require("qrcode.react");

let allowedFiles = ".pdf,.doc,.docx,.jpg,.png,.jpeg";

const Select = (props) => (
  <RequiredSelect
    {...props}
    SelectComponent={BaseSelect}
    options={props.options}
  />
);

// const imgLink =
//   "https://images.pexels.com/photos/1681010/pexels-photo-1681010.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260";

let resolver, rejector;
export default class EditAppointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isFormDirty: false,
      appointments: [],
      prescription: [],
      pp_url: "",
      medicinesToDelete: [],
      modalLoading: false,
      modalChat: false,
      Internally: false,
      Externally: false,
      chat: [],
      messages: "",
      currentAppointment: {},
      medicine: {
        name: "",
        subtitle: "",
        intake_time: [],
        am_bm_stat: [],
        num_days: "0",
        qty: "0",
      },
      intake_time: {
        MN: 0,
        AF: 0,
        EV: 0,
        NT: 0,
      },
      am_bm_stat: {
        MN_AM: { value: "2" },
        AF_AM: { value: "2" },
        EV_AM: { value: "2" },
        NT_AM: { value: "2" },
      },
      medicineArray: [],
      medicineTable: [],
      inputFileData: "",
      fileExtError: "",
      fileSizeError: "",
      error: "",
      success: false,
      fetchComments: false,
      addMedicineValid: false,
      internalComments: [],
      externalComments: [],
      fees: {
        video_call: 0,
        medicine: 0,
        consultation: 0,
        misc: 0,
        base_fee: +props.userData.base_fee,
        total: +props.userData.base_fee,
      },
    };
    console.log("props 2", props);
    this.chatSubmitHandler = this.chatSubmitHandler.bind(this);
  }

  async componentDidMount() {
    let API = new Apilib();
    let medicines = [];
    if (this.props.location.state !== undefined || null) {
      this._isMounted = true;
      this.setState({
        loading: true,
      });
      console.log("edit appointment propsss", this.props.location.state)
      API.getAppointmentDetails(this.props.location.state.name.appointmendId)
        .then((res) => res.json())
        .then((res) => {
          let prescription_details = res.prescription_details;
          if (this._isMounted) {
            this.makeDownloadUrl(this.props.userData.profile_picture);
            console.log("get appointment details res", res);
            this.setState({
              loading: false,
              currentAppointment: {
                id: res.appointment_details.appointmendId,
                name: res.appointment_details.name,
                appointmentType: res.appointment_details.appointmentType,
                slot_time: res.appointment_details.slot_time,
                slot_date: res.appointment_details.slot_date,
                phoneno: res.appointment_details.phoneno,
                email: res.appointment_details.email,
                chiefComplaints: res.appointment_details.chiefComplaints,
                pointsFromHistory: res.appointment_details.pointsFromHistory,
                labFindings: res.appointment_details.labFindings,
                suggestedInvestigations:
                  res.appointment_details.suggestedInvestigations,
                diagnosis: res.appointment_details.diagnosis,
                provisionalDiagnosis:
                  res.appointment_details.provisionalDiagnosis,
                specialInstruction: res.appointment_details.specialInstruction,
                fees: res.appointment_details.fees,
              },
              prescription_details,
              fees: res.appointment_details.fees
                ? { ...this.state.fees, ...res.appointment_details.fees }
                : this.state.fees,
            });
            if (prescription_details.length !== 0) {
              for (let key of prescription_details) {
                if (key.medicines.length !== 0) {
                  let medIndex = 0;
                  for (let item of key.medicines) {
                    item.prescriptionId = key.prescriptionId;
                    item.medIndex = medIndex;
                    medicines.push(item);
                    medIndex += 1;
                  }
                }
              }
            }
            this.setState({
              medicineTable: [...medicines],
            });
          }
        })
        .catch((error) => {
          if (this._isMounted) {
            this.setState({
              loading: false,
            });
          }
        });
    } else {
      this.props.history.push("/doctor/appointments");
    }
    console.log("edit appointments", this.props);

    // if ($(".datatable-medicines").length > 0) {
    //   $(".datatable-medicines").DataTable({
    //     "order": []
    //   });
    // }
  }

  makeDownloadUrl = (path) => {
    this.setState({ loading: true });
    let gsReference = storage.refFromURL(
      "gs://medvatic-438af.appspot.com/" + path
    );
    gsReference
      .getDownloadURL()
      .then((url) => {
        console.log(url);
        this.setState({
          pp_url: url,
          loading: false,
        });

        console.log("PPurl>>.", this.state.pp_url);
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  };

  confirmMedicineDelete = () => {
    this.setState({
      deleteMedicineModal: true,
    });

    return new Promise((res, rej) => {
      resolver = res;
      rejector = rej;
    });
  };

  handleMedicineDelete = async (prescriptionId, presMedIndex, medIndex) => {
    try {
      await this.confirmMedicineDelete();
      if (prescriptionId) {
        this.setState({
          isFormDirty: true,
          deleteMedicineModal: false,
          medicinesToDelete: {
            ...this.state.medicinesToDelete,
            [prescriptionId]: this.state.medicinesToDelete[prescriptionId]
              ? [...this.state.medicinesToDelete[prescriptionId]].map(
                  (med, index) => (index !== presMedIndex ? med : null)
                )
              : [
                  ...this.state.prescription_details.find(
                    (ele) => ele.prescriptionId === prescriptionId
                  ).medicines,
                ].map((med, index) => (index !== presMedIndex ? med : null)),
          },
          medicineTable: this.state.medicineTable.filter(
            (val, Index) => Index !== medIndex
          ),
        });
        return;
      }

      this.setState({
        isFormDirty: true,
        deleteMedicineModal: false,
        medicineTable: this.state.medicineTable.filter(
          (val, Index) => Index !== medIndex
        ),
        medicineArray: this.state.medicineArray.filter(
          (val, Index) =>
            this.state.medicineTable.length -
              medIndex -
              1 -
              (this.state.medicineArray.length - 1) !==
            Index
        ),
      });
    } catch (error) {
      this.setState({
        deleteMedicineModal: false,
      });
    }
  };

  updateData = (e) => {
    e.preventDefault();
    let data = { ...this.state.currentAppointment };
    data["id"] = this.state.currentAppointment.id;
    data["chiefComplaints"] = this.state.currentAppointment.chiefComplaints;
    data["pointsFromHistory"] = this.state.currentAppointment.pointsFromHistory;
    data["labFindings"] = this.state.currentAppointment.labFindings;
    data["suggestedInvestigations"] =
      this.state.currentAppointment.suggestedInvestigations;
    data["diagnosis"] = this.state.currentAppointment.diagnosis;
    data["provisionalDiagnosis"] =
      this.state.currentAppointment.provisionalDiagnosis;
    data["specialInstruction"] =
      this.state.currentAppointment.specialInstruction;
    data["medicines"] = this.state.medicineArray;
    data["fees"] = this.state.fees;
    data["prescriptionUpdates"] = this.state.medicinesToDelete;
    Object.keys(data["prescriptionUpdates"]).forEach(
      (key) =>
        (data["prescriptionUpdates"][key] = data["prescriptionUpdates"][
          key
        ].filter((val) => val !== null))
    );
    console.log("send data::", data);
    let API = new Apilib();
    this.setState({
      loading: true,
    });
    API.updateAppointment(JSON.stringify(data))
      .then((res) => {
        if (res.ok) {
          this.setState({
            isFormDirty: false,
            loading: false,
            medicineArray: [],
            medicinesToDelete: [],
          });
          Notify({
            alert: true,
            type: "success",
            title: "Appointment updated successfully",
          });
        } else {
          res.text().then((data) => {
            // console.log("error", data);
            this.setState({
              loading: false,
            });
            Notify({
              alert: true,
              type: "error",
              title: "Error in updating the appointment",
            });
          });
        }
      })
      .catch((error) => {
        this.setState({
          error: error.message,
          loading: false,
        });
        Notify({
          alert: true,
          type: "error",
          title: "Error in updating the appointment",
        });
      });
  };

  onChange = (e) => {
    let formObj = { ...this.state.currentAppointment };
    formObj[e.target.name] = e.target.value;
    this.setState({
      currentAppointment: formObj,
      isFormDirty: true,
    });
  };

  uploadFile() {
    if (
      this.state.fileExtError == "" &&
      this.state.fileSizeError == "" &&
      this.state.inputFileData !== ""
    ) {
      ///chainid/hospitalid/appointments/appointmendId/prescriptions

      let chainId = this.props.userData.hospitalChainId;
      let hospitalId = this.props.userData.hospitalId;
      let appId = this.state.currentAppId;

      let file = this.state.inputFileData;
      this.setState(
        {
          modalLoading: true,
        },
        () => {
          const uploadTask = storage
            .ref(
              `/${chainId}/${hospitalId}/appointments/${appId}/prescriptions/${file.name}`
            )
            .put(file);
          uploadTask.on(
            "state_changed",
            (snapShot) => {
              console.log(snapShot);
            },
            (err) => {
              console.log(err);
            },
            () => {
              let data = {};
              data["id"] = appId;
              data[
                "prescription_path"
              ] = `/${chainId}/${hospitalId}/appointments/${appId}/prescriptions/${file.name}`;
              let API = new Apilib();
              API.updateAppointment(JSON.stringify(data))
                .then((res) => {
                  this.setState({
                    modalLoading: false,
                  });
                  if (res.ok) {
                    let dataApp = {
                      ...this.state.appointments[this.state.currentIndex],
                    };
                    dataApp[
                      "prescription_path"
                    ] = `/${chainId}/${hospitalId}/appointments/${appId}/prescriptions/${file.name}`;
                    let appointments = [...this.state.appointments];
                    appointments[this.state.currentIndex] = dataApp;
                    this.setState({
                      fileModalOpen: false,
                      fileUploaded: true,
                      currentAppId: "",
                      appointments,
                    });
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          );
        }
      );
    }
  }

  validateFileSize(file) {
    let fileSize = file.size / 1024 / 1024;
    if (fileSize > 5) {
      this.setState(
        {
          fileSizeError: "Sorry, Max filesize allowed is 5MB",
        },
        () => {
          return false;
        }
      );
    } else {
      return true;
    }
  }

  validateFileExtension(file) {
    var _validFileExtensions = allowedFiles.split(",");
    var sFileName = file.name;
    if (sFileName.length > 0) {
      var blnValid = false;
      for (var j = 0; j < _validFileExtensions.length; j++) {
        var sCurExtension = _validFileExtensions[j];
        if (
          sFileName
            .substr(
              sFileName.length - sCurExtension.length,
              sCurExtension.length
            )
            .toLowerCase() == sCurExtension.toLowerCase()
        ) {
          blnValid = true;
          break;
        }
      }

      if (!blnValid) {
        this.setState(
          {
            fileExtError:
              "Sorry, " +
              sFileName +
              " is invalid, allowed extensions are: " +
              _validFileExtensions.join(", "),
          },
          () => {
            return false;
          }
        );
      } else {
        return true;
      }
    }
  }

  handleFileChange(e) {
    // console.log(e.target.files[0]);
    let file = e.target.files[0];
    this.setState({
      fileExtError: "",
      fileSizeError: "",
    });
    if (this.validateFileExtension(file)) {
      if (this.validateFileSize(file)) {
        this.setState({
          inputFileData: file,
        });
      }
    }
  }

  calculateQuantity() {
    let medicine = { ...this.state.medicine };
    let intake = { ...this.state.intake_time };
    let num_days = Number(medicine.num_days);
    let quantity = 0;
    let addition = 0;
    for (let key in intake) {
      addition += Number(intake[key]);
    }
    quantity = num_days * addition;
    medicine.qty = String(quantity);
    this.setState({
      medicine: medicine,
      isFormDirty: true,
    });
  }

  medicinesOnChange = (e) => {
    let medicine = { ...this.state.medicine };
    medicine[e.target.name] = /(.|\s)*\S(.|\s)*/.test(e.target.value)
      ? e.target.value.trimStart()
      : "";
    this.setState(
      {
        isFormDirty: true,
        medicine: medicine,
      },
      () => this.calculateQuantity()
    );
  };

  intakeOnChange = (e) => {
    let intake = { ...this.state.intake_time };
    intake[e.target.name] = e.target.value;
    this.setState(
      {
        intake_time: intake,
      },
      () => this.calculateQuantity()
    );
  };

  AM_BM_OnChange = (val, name) => {
    let ambm = { ...this.state.am_bm_stat };
    ambm[name] = val;
    this.setState(
      {
        am_bm_stat: ambm,
      },
      () => {
        console.log(this.state.am_bm_stat);
      }
    );
  };

  addMedicineHandler(e) {
    e.preventDefault();
    let allMedicine = [...this.state.medicineArray];
    let allMedicineTable = [...this.state.medicineTable];
    let medicine = { ...this.state.medicine };
    let intake_time = { ...this.state.intake_time };
    let am_bm_stat = { ...this.state.am_bm_stat };

    let time = [];
    for (let key in intake_time) {
      if (intake_time[key] !== 0) {
        time.push(key);
      }
    }
    medicine.intake_time = intake_time;

    let stat = [];
    for (let key in am_bm_stat) {
      if (am_bm_stat[key].value !== "2") {
        let str = "";
        switch (am_bm_stat[key].value) {
          case "0":
            str = "After";
            break;
          case "1":
            str = "Before";
            break;
          //no default
        }
        stat.push(str);
      }
    }
    medicine.am_bm_stat = stat;

    allMedicine.push(medicine);
    allMedicineTable.push(medicine);
    this.setState(
      {
        medicineTable: allMedicineTable,
        medicineArray: allMedicine,
      },
      () => {
        console.log("allMedicine", this.state.medicineArray);
        let medicine = {
          name: "",
          subtitle: "",
          intake_time: [],
          am_bm_stat: [],
          num_days: 0,
          qty: "0",
        };
        let intake_time = {
          MN: 0,
          AF: 0,
          EV: 0,
          NT: 0,
        };
        let am_bm_stat = {
          MN_AM: { value: "2" },
          AF_AM: { value: "2" },
          EV_AM: { value: "2" },
          NT_AM: { value: "2" },
        };
        this.setState({
          medicine: medicine,
          intake_time: intake_time,
          am_bm_stat: am_bm_stat,
          isFormDirty: true,
        });
      }
    );
  }

  chatHandler(e) {
    let message = e.target.value;
    this.setState({ message: message }, () => {
      console.log("message", this.state.message);
    });
  }
  chatSubmitHandler(e, type) {
    e.preventDefault();
    let messages = this.state.message;
    const commentData = {
      appointment_id: this.state.currentAppointment.id,
      userType: localStorage.getItem("@displayName"),
      userId: this.props.userData.uid,
      userName: `${this.props.userData.firstName} ${this.props.userData.lastName}`,
      comment: messages,
      commenttype: type,
    };

    console.log("comments", commentData);
    this.setState({ loading: true });
    const API = new Apilib();
    API.addComment(JSON.stringify(commentData))
      .then((res) => res.json())
      .then((res) => {
        console.log("add_comment res", res);
        this.setState({ loading: false, fetchComments: true, message: "" });
      })
      .catch((err) => {
        this.setState({ loading: false, fetchComments: false });
        console.log("add_comment err", err);
      });
  }

  modalClose(e) {
    // e.preventDefault();
    this.setState({
      modalChat: !this.state.modalChat,
    });
  }

  internalChat(e) {
    // e.preventDefault();
    this.setState({
      Internally: !this.state.Internally,
    });
  }

  externalChat(e) {
    // e.preventDefault();
    this.setState({
      Externally: !this.state.Externally,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const API = new Apilib();
    if (
      (this.state.Internally !== prevState.Internally &&
        this.state.Internally) ||
      (this.state.Internally !== prevState.Internally &&
        this.state.Internally &&
        this.state.fetchComments)
    ) {
      API.fetchComments(this.state.currentAppointment.id, 1)
        .then((res) => res.json())
        .then((res) => {
          console.log("comments res", res.data);
          let data = res.data;
          let sorted = data.sort((a, b) => {
            return (
              a.createdAt._seconds * 1000 +
              a.createdAt._nanoseconds / 1000000 -
              (b.createdAt._seconds * 1000 + b.createdAt._nanoseconds / 1000000)
            );
          });
          this.setState({
            internalComments: sorted,
            loading: false,
            fetchComments: false,
          });
        })
        .catch((err) => {
          if (this._isMounted) {
            this.setState({ fetchComments: false, loading: false });
          }
          console.log("comments res", err);
        });
    }
    if (
      (this.state.Externally !== prevState.Externally &&
        this.state.Externally) ||
      (this.state.Externally !== prevState.Externally &&
        this.state.Externally &&
        this.state.fetchComments)
    ) {
      API.fetchComments(this.state.currentAppointment.id, 2)
        .then((res) => res.json())
        .then((res) => {
          console.log("comments res", res.data);
          let data = res.data;
          let sorted = data.sort((a, b) => {
            return (
              a.createdAt._seconds * 1000 +
              a.createdAt._nanoseconds / 1000000 -
              (b.createdAt._seconds * 1000 + b.createdAt._nanoseconds / 1000000)
            );
          });
          this.setState({
            externalComments: sorted,
            loading: false,
            fetchComments: false,
          });
        })
        .catch((err) => {
          if (this._isMounted) {
            this.setState({ fetchComments: false, loading: false });
          }
          console.log("comments res", err);
        });
    }
  }
  render() {
    const AM_BM_Stat = [
      { value: "0", label: "After Meal" },
      { value: "1", label: "Before Meal" },
    ];
    // if ($(".datatable-medicines").length > 0) {
    //   $(".datatable-medicines").DataTable({
    //     "order": []
    //   });
    // }
    return (
      <React.Fragment>
        <Prompt
          when={this.state.isFormDirty}
          message="You have unsaved changes, are you sure you want to leave?"
        />
        {this.state.loading && <Loader />}
        {/* <div className="row">
          <div className="col-6">
            <h4 className="page-title doc-title">Edit Appointment</h4>
          </div>
          <div className="col-6">
          
          </div>
        </div> */}
        <div className="row">
          <div className="col-md-8">
            <h4 className="page-title">Edit Appointment</h4>
          </div>
          <div className="col-md-4 pl-5">
            <button
              onClick={(e) => this.internalChat(e)}
              className="btn btn-primary px-3 btn-rounded join-btn"
            >
              Chat With Team
            </button>
            <button
              onClick={(e) => this.externalChat(e)}
              className="btn btn-primary px-3 btn-rounded pay-btn"
            >
              Chat With Patient
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <form>
              {this.state.error && (
                <div
                  className="alert alert-danger alert-dismissible fade show"
                  role="alert"
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                    <span className="sr-only">Close</span>
                  </button>
                  {this.state.error}
                </div>
              )}
              {this.state.success && (
                <div
                  className="alert alert-success alert-dismissible fade show"
                  role="alert"
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                    <span className="sr-only">Close</span>
                  </button>
                  Appointment Updated Successfully
                </div>
              )}
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Patient Name <span className="text-danger">*</span>
                    </label>
                    <input
                      name="name"
                      value={this.state.currentAppointment.name || ""}
                      readOnly
                      className="form-control"
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Appointment Type <span className="text-danger">*</span>
                    </label>
                    <input
                      name="appointmenttype"
                      value={
                        this.state.currentAppointment.appointmentType == 1
                          ? "Physical"
                          : "Video"
                      }
                      readOnly
                      className="form-control"
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="row">
                    <div className="col-6">
                      {" "}
                      <div className="form-group">
                        <label>Appointment Date</label>
                        <div className="time-icon">
                          <input
                            name="time"
                            value={this.state.currentAppointment.slot_date}
                            readOnly
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      {" "}
                      <div className="form-group">
                        <label>Time</label>
                        <div className="time-icon">
                          <input
                            name="time"
                            value={this.state.currentAppointment.slot_time}
                            readOnly
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Patient Phone Number{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      name="phonenumber"
                      value={this.state.currentAppointment.phoneno}
                      readOnly
                      className="form-control"
                      type="email"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Patient Email</label>
                    <input
                      className="form-control"
                      value={this.state.currentAppointment.email}
                      type="email"
                      name="patientemail"
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-sm-6"></div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Chief Complaints</label>
                    <textarea
                      name="chiefComplaints"
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                      value={
                        this.state.currentAppointment.chiefComplaints || ""
                      }
                    >
                      {this.state.currentAppointment.chiefComplaints || ""}
                    </textarea>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Relevant points from history</label>
                    <textarea
                      name="pointsFromHistory"
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                      value={
                        this.state.currentAppointment.pointsFromHistory || ""
                      }
                    >
                      {this.state.currentAppointment.pointsFromHistory || ""}
                    </textarea>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Examination / Lab Findings</label>
                    <textarea
                      name="labFindings"
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                      value={this.state.currentAppointment.labFindings || ""}
                    >
                      {this.state.currentAppointment.labFindings || ""}
                    </textarea>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Suggested Investigations</label>
                    <textarea
                      name="suggestedInvestigations"
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                      value={
                        this.state.currentAppointment.suggestedInvestigations ||
                        ""
                      }
                    >
                      {this.state.currentAppointment.suggestedInvestigations ||
                        ""}
                    </textarea>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Diagnosis</label>
                    <textarea
                      name="diagnosis"
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                      value={this.state.currentAppointment.diagnosis || ""}
                    >
                      {this.state.currentAppointment.diagnosis || ""}
                    </textarea>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Provisional Diagnosis</label>
                    <textarea
                      name="provisionalDiagnosis"
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                      value={
                        this.state.currentAppointment.provisionalDiagnosis || ""
                      }
                    >
                      {this.state.currentAppointment.provisionalDiagnosis || ""}
                    </textarea>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Special Instruction</label>
                    <textarea
                      name="specialInstruction"
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                      value={
                        this.state.currentAppointment.specialInstruction || ""
                      }
                    >
                      {this.state.currentAppointment.specialInstruction || ""}
                    </textarea>
                  </div>
                </div>
              </div>
              <form onSubmit={(e) => this.addMedicineHandler(e)}>
                <div className="row">
                  <div className="col-sm-12 ">
                    <h4 className="page-title">Medicines</h4>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>
                        Name <span className="text-danger">*</span>
                      </label>
                      <input
                        name="name"
                        onInvalid={function (e) {
                          e.target.setCustomValidity("");
                          if (!e.target.validity.valid) {
                            e.target.setCustomValidity(
                              "Name cannot be left blank"
                            );
                          }
                        }}
                        required
                        onChange={(e) => this.medicinesOnChange(e)}
                        value={this.state.medicine.name || ""}
                        className="form-control"
                        type="text"
                      />
                      <span className="form-text text-danger">
                        {this.state.medicine.name.length < 3 &&
                          !!this.state.medicine.name &&
                          "Minimum 3 characters required"}
                      </span>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>Comments</label>
                      <input
                        onChange={(e) => this.medicinesOnChange(e)}
                        name="subtitle"
                        value={this.state.medicine.subtitle || ""}
                        className="form-control"
                        type="text"
                      />
                    </div>
                  </div>

                  <div
                    className="col-sm-6"
                    style={{
                      display:
                        this.state.medicine.name.length >= 3 ? "block" : "none",
                    }}
                  >
                    <div className="row mb-3 text-center">
                      <div className="col-sm-4">Time Slot</div>
                      <div className="col-sm-4">Intake</div>
                      <div className="col-sm-4">Intake Time</div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4">Morning</div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <input
                            placeholder="MN"
                            name="MN"
                            min="0"
                            onChange={(e) =>
                              +e.target.value >= 0 && this.intakeOnChange(e)
                            }
                            value={this.state.intake_time.MN || ""}
                            className="form-control"
                            type="number"
                          />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <Select
                            isDisabled={this.state.intake_time.MN == 0}
                            required
                            name="MN_AM"
                            value={
                              this.state.am_bm_stat.MN_AM.value === "2"
                                ? null
                                : this.state.am_bm_stat.MN_AM
                            }
                            onChange={(val) =>
                              this.AM_BM_OnChange(val, "MN_AM")
                            }
                            isSearchable={false}
                            defaultValue={AM_BM_Stat[0]}
                            options={AM_BM_Stat}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4">Afternoon</div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <input
                            placeholder="AF"
                            name="AF"
                            min="0"
                            onChange={(e) =>
                              +e.target.value >= 0 && this.intakeOnChange(e)
                            }
                            value={this.state.intake_time.AF || ""}
                            className="form-control"
                            type="number"
                          />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <Select
                            isDisabled={this.state.intake_time.AF == 0}
                            required
                            name="AF_AM"
                            value={
                              this.state.am_bm_stat.AF_AM.value === "2"
                                ? null
                                : this.state.am_bm_stat.AF_AM
                            }
                            onChange={(val) =>
                              this.AM_BM_OnChange(val, "AF_AM")
                            }
                            isSearchable={false}
                            options={AM_BM_Stat}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4">Evening</div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <input
                            placeholder="EV"
                            name="EV"
                            min="0"
                            onChange={(e) =>
                              +e.target.value >= 0 && this.intakeOnChange(e)
                            }
                            value={this.state.intake_time.EV || ""}
                            className="form-control"
                            type="number"
                          />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <Select
                            isDisabled={this.state.intake_time.EV == 0}
                            required
                            name="EV_AM"
                            value={
                              this.state.am_bm_stat.EV_AM.value === "2"
                                ? null
                                : this.state.am_bm_stat.EV_AM
                            }
                            onChange={(val) =>
                              this.AM_BM_OnChange(val, "EV_AM")
                            }
                            isSearchable={false}
                            options={AM_BM_Stat}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4">Night</div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <input
                            placeholder="NT"
                            name="NT"
                            min="0"
                            onChange={(e) =>
                              +e.target.value >= 0 && this.intakeOnChange(e)
                            }
                            value={this.state.intake_time.NT || ""}
                            className="form-control"
                            type="number"
                          />
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <Select
                            isDisabled={this.state.intake_time.NT == 0}
                            required
                            name="NT_AM"
                            value={
                              this.state.am_bm_stat.NT_AM.value === "2"
                                ? null
                                : this.state.am_bm_stat.NT_AM
                            }
                            onChange={(val) =>
                              this.AM_BM_OnChange(val, "NT_AM")
                            }
                            isSearchable={false}
                            options={AM_BM_Stat}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-sm-6"
                    style={{
                      display:
                        this.state.medicine.name.length >= 3 ? "block" : "none",
                    }}
                  >
                    <div className="form-group">
                      <label>
                        Duration (Days) <span className="text-danger">*</span>
                      </label>
                      <input
                        name="num_days"
                        min={"1"}
                        required
                        onChange={(e) =>
                          +e.target.value >= 0 && this.medicinesOnChange(e)
                        }
                        value={this.state.medicine.num_days || ""}
                        className="form-control"
                        type="number"
                      />
                    </div>
                    <div className="form-group">
                      <label>Quantity</label>
                      <input
                        name="qty"
                        readOnly={true}
                        value={this.state.medicine.qty || ""}
                        className="form-control"
                        type="number"
                      />
                    </div>
                  </div>
                </div>

                <div className="m-b-20 text-center">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={
                      !(
                        Object.values(this.state.intake_time).reduce(
                          (acc, curr) => acc + curr,
                          0
                        ) > 0 &&
                        !!this.state.medicine.name &&
                        this.state.medicine.num_days > 0
                      )
                    }
                  >
                    Add Medicine
                  </button>
                </div>
              </form>
              {this.state.medicineTable.length !== 0 && (
                <div className="table-responsive mb-4">
                  <table className="table table-border table-striped custom-table datatable-medicines m-b-0">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Name</th>
                        <th>Intake Time</th>
                        <th>After Meal - Before Meal</th>
                        <th>Duration</th>
                        <th>Quantity</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.medicineTable.length !== 0
                        ? this.state.medicineTable.map((medicine, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                {medicine.name} <br />{" "}
                                <span>{medicine.subtitle}</span>
                              </td>
                              <td>
                                {Object.keys(medicine.intake_time)
                                  .filter((key) => !!medicine.intake_time[key])
                                  .join("-")}
                              </td>
                              <td>{medicine.am_bm_stat.join("-")}</td>
                              <td>{medicine.num_days}</td>
                              <td>{medicine.qty}</td>
                              {/* Delete Action */}
                              <td>
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.handleMedicineEdit(
                                      medicine?.prescriptionId,
                                      medicine?.medIndex,
                                      index
                                    );
                                  }}
                                >
                                  Edit
                                </button>
                              </td>
                              <td>
                                <button
                                  className="btn btn-danger btn-sm"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    this.handleMedicineDelete(
                                      medicine?.prescriptionId,
                                      medicine?.medIndex,
                                      index
                                    );
                                  }}
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </table>
                </div>
              )}
              <div className="row">
                <div className="col-sm-12 ">
                  <h4 className="page-title">Variable Fees</h4>
                </div>
              </div>

              <div className="table-responsive variable-fees-table">
                <table className="table table-border table-striped custom-table datatable-medicines m-b-0">
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Video Call</th>
                      <th>Medicines</th>
                      <th>Consultation</th>
                      <th>Misc.</th>
                      <th>Base Fee</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <Formik
                    validationSchema={Yup.object({
                      video_call: Yup.number(),
                      medicine: Yup.number(),
                      consultation: Yup.number(),
                      misc: Yup.number(),
                      base_fee: Yup.number(),
                    })}
                    initialValues={{
                      ...this.state.fees,
                    }}
                    enableReinitialize={true}
                  >
                    {({ values, setFieldValue }) => (
                      <tr>
                        <td className="table-data-bold">Fees</td>
                        {/* Video call fees */}
                        <td>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span
                                class="input-group-text"
                                id="inputGroupPrepend3"
                              >
                                $
                              </span>
                            </div>

                            <input
                              placeholder="0"
                              min={"0"}
                              onChange={(e) => {
                                setFieldValue(
                                  "video_call",
                                  isNaN(e.target.valueAsNumber)
                                    ? null
                                    : +e.target.valueAsNumber
                                );
                                this.setState({
                                  isFormDirty: true,
                                  fees: {
                                    ...values,
                                    video_call: isNaN(e.target.valueAsNumber)
                                      ? 0
                                      : +e.target.valueAsNumber,
                                    total:
                                      values.base_fee +
                                      values.consultation +
                                      values.medicine +
                                      values.misc +
                                      (isNaN(e.target.valueAsNumber)
                                        ? 0
                                        : +e.target.valueAsNumber),
                                  },
                                });
                              }}
                              value={values.video_call}
                              className="form-control"
                              type="number"
                            />
                          </div>
                        </td>

                        {/* Medicine fees */}
                        <td>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span
                                class="input-group-text"
                                id="inputGroupPrepend3"
                              >
                                $
                              </span>
                            </div>
                            <input
                              min={0}
                              placeholder="0"
                              onChange={(e) => {
                                setFieldValue(
                                  "medicine",
                                  isNaN(e.target.valueAsNumber)
                                    ? null
                                    : +e.target.valueAsNumber
                                );
                                this.setState({
                                  isFormDirty: true,
                                  fees: {
                                    ...values,
                                    medicine: isNaN(e.target.valueAsNumber)
                                      ? 0
                                      : +e.target.valueAsNumber,
                                    total:
                                      values.base_fee +
                                      values.consultation +
                                      values.video_call +
                                      values.misc +
                                      (isNaN(e.target.valueAsNumber)
                                        ? 0
                                        : +e.target.valueAsNumber),
                                  },
                                });
                              }}
                              value={values.medicine}
                              className="form-control"
                              type="number"
                            />
                          </div>
                        </td>

                        {/* Consultation fees */}
                        <td>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span
                                class="input-group-text"
                                id="inputGroupPrepend3"
                              >
                                $
                              </span>
                            </div>
                            <input
                              placeholder="0"
                              min={0}
                              onChange={(e) => {
                                setFieldValue(
                                  "consultation",
                                  isNaN(e.target.valueAsNumber)
                                    ? null
                                    : +e.target.valueAsNumber
                                );
                                this.setState({
                                  isFormDirty: true,
                                  fees: {
                                    ...values,
                                    consultation: isNaN(e.target.valueAsNumber)
                                      ? 0
                                      : +e.target.valueAsNumber,
                                    total:
                                      values.base_fee +
                                      values.video_call +
                                      values.medicine +
                                      values.misc +
                                      (isNaN(e.target.valueAsNumber)
                                        ? 0
                                        : +e.target.valueAsNumber),
                                  },
                                });
                              }}
                              value={values.consultation}
                              className="form-control"
                              type="number"
                            />
                          </div>
                        </td>

                        {/* Misc fees */}
                        <td>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span
                                class="input-group-text"
                                id="inputGroupPrepend3"
                              >
                                $
                              </span>
                            </div>
                            <input
                              placeholder="0"
                              min={0}
                              onChange={(e) => {
                                setFieldValue(
                                  "misc",
                                  isNaN(e.target.valueAsNumber)
                                    ? null
                                    : +e.target.valueAsNumber
                                );
                                this.setState({
                                  isFormDirty: true,
                                  fees: {
                                    ...values,
                                    misc: isNaN(e.target.valueAsNumber)
                                      ? 0
                                      : +e.target.valueAsNumber,
                                    total:
                                      values.base_fee +
                                      values.consultation +
                                      values.medicine +
                                      values.video_call +
                                      (isNaN(e.target.valueAsNumber)
                                        ? 0
                                        : +e.target.valueAsNumber),
                                  },
                                });
                              }}
                              value={values.misc}
                              className="form-control"
                              type="number"
                            />
                          </div>
                        </td>

                        {/* Base fees */}
                        <td>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span
                                class="input-group-text"
                                id="inputGroupPrepend3"
                              >
                                $
                              </span>
                            </div>
                            <input
                              style={{
                                border: "1px solid #ced4da",
                                borderLeftWidth: 0,
                              }}
                              placeholder="0"
                              min={0}
                              readOnly
                              value={values.base_fee}
                              className="form-control"
                              type="number"
                            />
                          </div>
                        </td>

                        {/* Total fees */}
                        <td>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span
                                class="input-group-text"
                                id="inputGroupPrepend3"
                              >
                                $
                              </span>
                            </div>
                            <input
                              style={{
                                border: "1px solid #ced4da",
                                borderLeftWidth: 0,
                              }}
                              placeholder="0"
                              min={0}
                              readOnly
                              value={this.state.fees.total}
                              className="form-control"
                              type="number"
                            />
                          </div>
                        </td>
                      </tr>
                    )}
                  </Formik>
                  <tbody>
                    {/* {this.state.medicineTable.length !== 0
                      ? this.state.medicineTable.map((medicine, index) => ( */}

                    {/* ))
                      : null} */}
                  </tbody>
                </table>
              </div>

              <div className="row">
                <div className="col-sm-4">
                  {/* <div className="form-group">
                    <div className="profile-upload">
                      <div className="upload-input">
                        <input
                          type="file"
                          onChange={(e) => this.handleFileChange(e)}
                          accept={allowedFiles}
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div> */}
                  {this.state.fileExtError && (
                    <div className="alert alert-danger fade show" role="alert">
                      {this.state.fileExtError}
                    </div>
                  )}
                  {this.state.fileSizeError && (
                    <div className="alert alert-danger fade show" role="alert">
                      {this.state.fileSizeError}
                    </div>
                  )}
                </div>
                {/* <div className="col-sm-2">
                  <Button variant="primary" onClick={() => this.uploadFile()}>
                    Upload
                  </Button>
                </div> */}
              </div>
              <div className="m-t-20 text-center">
                <Button variant="primary" onClick={(e) => this.updateData(e)}>
                  Save Changes
                </Button>
              </div>

              {this.props?.location?.state?.name ? (
                <>
                  {/* Internally COmment Modal */}
                  <InternalChat
                    internally={this.state.Internally}
                    internalChat={() => this.internalChat()}
                    pp_url={this.state.pp_url}
                    appointment={this.props?.location?.state?.name}
                    userData={this.props.userData}
                  />
                    {/* Externally COmment Modal */}
                  <ExternalChat
                    externally={this.state.Externally}
                    externalChat={() => this.externalChat()}
                    pp_url={this.state.pp_url}
                    appointment={this.props?.location?.state?.name}
                    userData={this.props.userData}
                  />
                </>

              ) : null}
            </form>
          </div>
        </div>
        <Modal
          show={this.state.deleteMedicineModal}
          onHide={() => {
            rejector(new Error());
          }}
          animation={true}
        >
          {this.state.modalLoading && <Loader />}
          <Modal.Header closeButton>
            <Modal.Title>Delete Speciality</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete medicine?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                rejector(new Error());
              }}
            >
              Close
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                resolver(true);
              }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}
