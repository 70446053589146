// jshint esversion:6

import "bootstrap/dist/js/bootstrap.js";
import React, { Component, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import ReactGA from "react-ga";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import AddHospital from "./Components/Admin/AddHospital";
import AddHospitalChain from "./Components/Admin/AddHospitalChain";
import AddSpeciality from "./Components/Admin/AddSpeciality";
import AddUser from "./Components/Admin/AddUser";
import EditHospital from "./Components/Admin/EditHospital"; 
/** Components Admin **/
import HospitalList from "./Components/Admin/HospitalList";
import UsersList from "./Components/Admin/UsersList";
import AgoraUi from "./Components/Agora/Agora_UI";
import RouteChangeTracker from "./Components/Analytics/RouteChangeTracker";
import ChangePassword from "./Components/ChangePassword";
import Comp404 from "./Components/comp404";
import AddAppointment from "./Components/Doctor/AddAppointment";
import AddPatient from "./Components/Doctor/AddPatient";
import AddSchedule from "./Components/Doctor/AddSchedule";
import AppointmentsList from "./Components/Doctor/AppointmentsList";
import DoctorRegister from "./Components/Doctor/DoctorRegister";
import EditAppointment from "./Components/Doctor/EditAppointment";
import EditPatient from "./Components/Doctor/EditPatient";
import PatientSingleAppointments from "./Components/Doctor/PatientAppointments";
/**Components Doctor **/
import PatientsList from "./Components/Doctor/PatientsList";
import ForgetPassword from "./Components/ForgetPassword";
import AddDoctor from "./Components/Hospital/AddDoctor";
import AddReceptionist from "./Components/Hospital/AddReceptionist";
import ProfileD from "./Components/Doctor/Profile"
import EditProfileD from "./Components/Doctor/Edit_profile"
/** Components Hospital **/
import DoctorsList from "./Components/Hospital/DoctorsList";
import ReceptionistsList from "./Components/Hospital/ReceptionistsList";
import ViewRequests from "./Components/Hospital/ViewRequests";
import Patient from "./Components/Hospital/Patient";
import ViewPatient from "./Components/Hospital/ViewPatient";
import AppointmentsListH from "./Components/Hospital/AppointmentsList";
import EditAppoinmentH from "./Components/Hospital/EditAppoinment";
import PatientsListH from "./Components/Hospital/PatientsList";
import ProfileH from "./Components/Hospital/Profile";
/** Components General **/
import LoginPage from "./Components/Login/Login";
import AddAppointmentP from "./Components/Patient/AddAppointments";
import AddFamilyMember from "./Components/Patient/AddFamilyMember";
import PatientAppointments from "./Components/Patient/Appointments";
import Appointment_details from "./Components/Patient/Appointment_details";
import Compliance from "./Components/Patient/Compliance";
import Contact from "./Components/Patient/Contact";
import Edit_profile from "./Components/Patient/Edit_profile";
import EUGDPR from "./Components/Patient/EU-GDPR";
import FamilyAccount from "./Components/Patient/FamilyAccount";
/**Components Receptionist **/
// import AppointmentsListP from './Components/Patient_old/AppointmentsList';
// import Profile from './Components/Patient_Old/Profile';
import Homepage from "./Components/Patient/Homepage";
import PatientLogin from "./Components/Patient/Login";
import MobileAppTerms from "./Components/Patient/MobileTerms";
import Monitoring from "./Components/Patient/Monitoring";
import NewS from "./Components/Patient/new";
import NewSearch from "./Components/Patient/newsearch";
import Otp from "./Components/Patient/Otp";
import Practitioner from "./Components/Patient/Practitioner_benefits";
import Prescriptions from "./Components/Patient/Prescription";
import PrivacyPolicy from "./Components/Patient/PrivacyPolicy";
import PrivacyPolicyAus from "./Components/Patient/PrivacyPolicyAus";
import Product from "./Components/Patient/Product";
import PatientProfile from "./Components/Patient/Profile";
import Register from "./Components/Patient/Register";
import Requests from "./Components/Patient/Requests";
import Scanned from "./Components/Patient/Scanned";
import SearchResults from "./Components/Patient/SearchResults";
import Team from "./Components/Patient/Team";
import Treatment from "./Components/Patient/Treatment";
import USHippaPolicy from "./Components/Patient/USHippaPolicy";
import WebsiteTerms from "./Components/Patient/WebsiteTerms";
/**Components Receptionist **/
import AddAppointmentR from "./Components/Receptionist/AddAppointment";
import AddPatientR from "./Components/Receptionist/AddPatient";
import AppointmentsListR from "./Components/Receptionist/AppointmentsList";
import PatientsListR from "./Components/Receptionist/PatientsList";
import DashboardLayoutRoute from "./Layouts/DashboardLayout";
import EditAppoinmentR from './Components/Receptionist/EditAppoinment'
import ProfileR from "./Components/Receptionist/Profile"
/** Layouts **/
import LoginLayoutRoute from "./Layouts/LoginLayout";
import MobileLayoutRoute from "./Layouts/MobileLayout";
import PatientLayoutRoute from "./Layouts/PatientLayout";
import ResetPassword from "./Components/Patient/Reset-Password";
import ChangePassword2 from '../src/Components/Patient/ChangePassword'
import PublicPrescriptions from "./Components/Patient/PublicPrescription";

class Helloworld extends Component {
  render() {
    return <div>Coming Soon</div>;
  }
}

// basename="/demos/medvatic"
/*
   App
 */
function App() {
  const [values, setValues] = React.useState({ alert: false });

  useEffect(() => {
    ReactGA.initialize("G-1S539P1ENZ");
    return () => {};
  }, []);

  //Listen to your custom event
  window.addEventListener("showAlert", function (e) {
    // console.log('printer state changed', e);
    const { detail } = e;
    // console.log(detail, "detail");
    if (detail) {
      setValues({
        ...values,
        alert: true,
        type: detail.type,
        title: detail.title,
      });
    }
  });

  const hideAlert = () => {
    window.removeEventListener("showAlert", function () {}, false);
    setValues({
      ...values,
      alert: false,
    });
  };
  const alertFunc = () => {
    return values.alert ? (
      values.type === "success" ? (
        <SweetAlert success title="Success" onConfirm={hideAlert}>
          {" "}
          {values.title}{" "}
        </SweetAlert>
      ) : values.type === "error" ? (
        <SweetAlert error title="Error" onConfirm={hideAlert}>
          {" "}
          {values.title}{" "}
        </SweetAlert>
      ) : null
    ) : null;
  };

  return (
    <>
      <Router>
        <RouteChangeTracker />
        <Switch>
          <PatientLayoutRoute
            exact
            path="/"
            component={Homepage}
          ></PatientLayoutRoute>

          <LoginLayoutRoute path="/admin/login" component={LoginPage} />

          <DashboardLayoutRoute
            userType="1"
            path="/admin/hospitals"
            component={HospitalList}
          />
          <DashboardLayoutRoute
            userType="1"
            path="/admin/add-hospital"
            component={AddHospital}
          />
          <DashboardLayoutRoute
            userType="1"
            path="/admin/edit-hospital"
            component={EditHospital}
          />
          <DashboardLayoutRoute
            userType="1"
            path="/admin/add-hospital-chain"
            component={AddHospitalChain}
          />
          <DashboardLayoutRoute
            userType="1"
            path="/admin/add-speciality"
            component={AddSpeciality}
          />
          <DashboardLayoutRoute
            userType="1"
            path="/admin/users"
            component={UsersList}
          />
          <DashboardLayoutRoute
            userType="1"
            path="/admin/add-user"
            component={AddUser}
          />
          <DashboardLayoutRoute
            userType="1"
            path="/admin/change-password"
            component={ChangePassword}
          />

          <LoginLayoutRoute path="/hospital/login" component={LoginPage} />
          <DashboardLayoutRoute
            userType="2"
            path="/hospital/doctors"
            component={DoctorsList}
          />
          <DashboardLayoutRoute
            userType="2"
            path="/hospital/add-doctor"
            component={AddDoctor}
          />
          <DashboardLayoutRoute
            userType="2"
            path="/hospital/receptionists"
            component={ReceptionistsList}
          />
          <DashboardLayoutRoute
            userType="2"
            path="/hospital/appointment-list"
            component={AppointmentsListH}
          />
          <DashboardLayoutRoute
            userType="2"
            path="/hospital/edit-appoinment"
            component={EditAppoinmentR}
            
          />
          <DashboardLayoutRoute
            userType="2"
            path="/hospital/patients-list"
            component={PatientsListH}
            
          />
           <DashboardLayoutRoute
            userType="2"
            path="/hospital/profile"
            component={ProfileH}
            
          />

             <DashboardLayoutRoute
            userType="2"
            path="/hospital/patient"
            component={Patient}
          />
          <DashboardLayoutRoute
            userType="2"
            path="/hospital/add-receptionist"
            component={AddReceptionist}
          />
          <DashboardLayoutRoute
            userType="2"
            path="/hospital/view-requests"
            component={ViewRequests}
          />
           <DashboardLayoutRoute
            userType="2"
            path="/hospital/view-patients"
            component={ViewPatient}
          />
          <DashboardLayoutRoute
            userType="2"
            path="/hospital/change-password"
            component={ChangePassword}
          />

          <LoginLayoutRoute path="/doctor/login" component={LoginPage} />
          <DashboardLayoutRoute
            userType="3"
            path="/doctor/patients"
            component={PatientsList}
          />
          <DashboardLayoutRoute
            userType="3"
            path="/doctor/add-patient"
            component={AddPatient}
          />
          <DashboardLayoutRoute
            userType="3"
            path="/doctor/appointments"
            component={AppointmentsList}
          />
          <DashboardLayoutRoute
            userType="3"
            path="/doctor/add-appointment"
            component={AddAppointment}
          />
          <DashboardLayoutRoute
            userType="3"
            path="/doctor/edit-appointment"
            component={EditAppointment}
          />
          <DashboardLayoutRoute
            userType="3"
            path="/doctor/edit-patient"
            component={EditPatient}
          />
          <DashboardLayoutRoute
            userType="3"
            path="/doctor/patient-appointment/:uid"
            component={PatientSingleAppointments}
          />
          <DashboardLayoutRoute
            userType="3"
            path="/doctor/add-schedule"
            component={AddSchedule}
          />
          <DashboardLayoutRoute
            userType="3"
            path="/doctor/edit-profile"
            component={EditProfileD}
          />
          <DashboardLayoutRoute
            userType="3"
            path="/doctor/view-schedule"
            component={Helloworld}
          />
          <DashboardLayoutRoute
            userType="3"
            path="/doctor/change-password"
            component={ChangePassword}
          />
          <DashboardLayoutRoute
            userType="3"
            path="/doctor/my-profile"
            component={ProfileD}
          />
          <LoginLayoutRoute path="/receptionist/login" component={LoginPage} />
          <DashboardLayoutRoute
            userType="4"
            path="/receptionist/patients"
            component={PatientsListR}
          />
          <DashboardLayoutRoute
            userType="4"
            path="/receptionist/add-patient"
            component={AddPatientR}
          />
          <DashboardLayoutRoute
            userType="4"
            path="/receptionist/profile"
            component={ProfileR}
          />
          <DashboardLayoutRoute
            userType="4"
            path="/receptionist/appointments"
            component={AppointmentsListR}
          />
          <DashboardLayoutRoute
            userType="4"
            path="/receptionist/add-appointment"
            component={AddAppointmentR}
          />
          <DashboardLayoutRoute
            userType="4"
            path="/receptionist/change-password"
            component={ChangePassword}
          />

          <DashboardLayoutRoute
            userType="4"
            path="/receptionist/edit-patient"
            component={EditPatient}
          />

          <DashboardLayoutRoute
            userType="4"
            path="/receptionist/edit-appoinment"
            component={EditAppoinmentR}
          />
           <DashboardLayoutRoute
            userType="4"
            path="/receptionist/add-schedule"
            component={AddSchedule}
          />

          <PatientLayoutRoute
            userType="5"
            path="/patient/change-password"
            component={ChangePassword2}
          />
          <PatientLayoutRoute userType="5" path="/team" component={Team} />
          <PatientLayoutRoute
            userType="5"
            path="/product"
            component={Product}
          />
          <PatientLayoutRoute
            userType="5"
            path="/practitioner"
            component={Practitioner}
          />
          <PatientLayoutRoute
            userType="5"
            path="/treatment"
            component={Treatment}
          />
          <PatientLayoutRoute
            userType="5"
            path="/monitoring"
            component={Monitoring}
          />
          <PatientLayoutRoute
            userType="5"
            path="/contact"
            component={Contact}
          />
          <PatientLayoutRoute
            userType="5"
            title="Login"
            path="/login"
            component={PatientLogin}
          />
          <PatientLayoutRoute
            userType="5"
            title="Reset Password"
            path="/passwordReset"
            component={ResetPassword}
          />

          <PatientLayoutRoute
            userType="5"
            title="Forgot Password"
            path="/forget-password"
            component={ForgetPassword}
          />

          <PatientLayoutRoute
            userType="5"
            title="Welcome Doctor!"
            path="/doctor/welcome"
            component={PatientLogin}
          />
          <PatientLayoutRoute
            userType="5"
            path="/register"
            component={Register}
          />
          <PatientLayoutRoute
            userType="5"
            path="/doctor/register"
            component={DoctorRegister}
          />
          <PatientLayoutRoute userType="5" path="/otp" component={Otp} />
          <PatientLayoutRoute userType="5" path="/news" component={NewSearch} />
          <PatientLayoutRoute
            userType="5"
            path="/my-profile"
            component={PatientProfile}
          />
          <PatientLayoutRoute
            userType="5"
            path="/requests"
            component={Requests}
          />
          <PatientLayoutRoute
            userType="5"
            path="/family-account"
            component={FamilyAccount}
          />
          <PatientLayoutRoute
            userType="5"
            path="/add-member"
            component={AddFamilyMember}
          />
          <PatientLayoutRoute
            userType="5"
            path="/edit-profile"
            component={Edit_profile}
          />
          <PatientLayoutRoute
            userType="5"
            exact
            path="/my-appointments"
            component={PatientAppointments}
          />
          <PatientLayoutRoute
            userType="5"
            exact
            path="/my-appointments/:id"
            component={Appointment_details}
          />
          <PatientLayoutRoute path="/prescriptions/:id" component={PublicPrescriptions} />
          <PatientLayoutRoute
            userType="5"
            path="/my-appointments/:id/prescriptions"
            component={Prescriptions}
          />
          <PatientLayoutRoute
            userType="5"
            path="/add-appointment"
            component={AddAppointmentP}
          />
          <PatientLayoutRoute
            userType="5"
            path="/privacy-policy-india"
            component={PrivacyPolicy}
          />

          <PatientLayoutRoute
            userType="5"
            path="/privacy-policy-australia"
            component={PrivacyPolicyAus}
          />
          <PatientLayoutRoute
            userType="5"
            path="/us-hippa-policy"
            component={USHippaPolicy}
          />
          <PatientLayoutRoute userType="5" path="/EU-GDPR" component={EUGDPR} />
          <PatientLayoutRoute
            userType="5"
            path="/Compliance"
            component={Compliance}
          />
          <PatientLayoutRoute
            userType="5"
            path="/MobileAppTerms"
            component={MobileAppTerms}
          />
          <PatientLayoutRoute
            userType="5"
            path="/WebsiteTerms"
            component={WebsiteTerms}
          />
          <PatientLayoutRoute
            userType="5"
            path="/searched"
            component={SearchResults}
          />
          <PatientLayoutRoute userType="5" path="/search2" component={NewS} />
          <MobileLayoutRoute userType="5" path="/scanned" component={Scanned} />

          <Route path="/videocall" component={AgoraUi} />
          {/* <Route path="/agora-ui" component={AgoraUi} /> */}

          <Route component={Comp404} />
        </Switch>
        {alertFunc()}{" "}
      </Router>
    </>
  );
}

export default App;
