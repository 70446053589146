import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCKVeT3U4SNnaXqjmovByhrqoK6DkbkXpg",
  authDomain: "medvatic-dev.firebaseapp.com",
  projectId: "medvatic-dev",
  storageBucket: "medvatic-dev.appspot.com",
  messagingSenderId: "582385254630",
  appId: "1:582385254630:web:eca34060bec2d7ac04bb27",
  measurementId: "G-MX16WXXLYG"
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const storage = firebase.storage();
export const firestore = firebase.firestore();
export default firebase;