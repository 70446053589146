import React, { Component } from "react";
import BaseSelect from "react-select";
import BaseSelect2 from "react-select/creatable";
import RequiredSelect from "../../Helpers/requiredSelect";
import { Link } from "react-router-dom";
import Loader from "../Loader/Loader";
import Apilib from "../../api";
import { Modal, Button } from "react-bootstrap";

import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

export default class ViewRequests extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: false,
        doctors: [],
      };
      this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({
          loading: true,
        });
        let API = new Apilib();
        API.fetchStoredDoctors(this.props.userData.hospital_code)
        .then((res) => res.json())
        .then((res) => {
            if (this._isMounted) {
                this.setState({
                    loading: false,
                    doctors: res.data,
                },() => {
                    if ($(".datatable").length > 0) {
                        $(".datatable").DataTable({});
                    }
                }
                );
            }
        });
    }

    updateRegistration(docId,processed){
        console.log(docId,processed);
        this.setState({
            loading: true,
        });
        let API = new Apilib();
        API.updateDoctorRegistration(JSON.stringify({
            "id":"documentid",
            "processed":true
        })).then((res) => {
            console.log(res);
            this.componentDidMount();
        }).catch((err) => {
            console.log(err);
            this.setState({
                loading: false
            });
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        if (this.state.loading) {
          return <Loader />;
        } else {
          return (
            <React.Fragment>
                <div className="row">
                    <div className="col-sm-4 col-3">
                        <h4 className="page-title">Doctors</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="table-responsive">
                            <table className="table table-border table-striped custom-table datatable m-b-0">
                                <thead>
                                    <tr>
                                        <th>Full Name</th>
                                        <th>Speciality</th>
                                        <th>Qualifications</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.doctors &&
                                    this.state.doctors.map((doctor, index) => (
                                        <tr key={index}>
                                            <td>
                                                {doctor.firstName + " " + doctor.lastName}
                                            </td>
                                            <td>
                                                {doctor.specialities &&
                                                doctor.specialities.join(", ")}
                                            </td>
                                            <td>
                                                {doctor.qualifications &&
                                                doctor.qualifications.join(", ")}
                                            </td>
                                            <td className="d-flex">
                                                <button onClick={() => this.updateRegistration(doctor.id,false)} title="Reject" type="button" className="btn btn-danger mr-3" >
                                                    <i className="fa fa-times" />
                                                </button>
                                                <button onClick={() => this.updateRegistration(doctor.id,false)} title="Approve" type="button" className="btn btn-primary" >
                                                    <i className="fa fa-check" />
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
        }
    }
}