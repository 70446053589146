import React from 'react';
import { Container, Row, Col } from "reactstrap";


const Comp404 = () => (
  // <div className="d-flex justify-content-center align-items-center h-100">
  //     <h1 className="mr-3 pr-3 align-top border-right inline-block align-content-center">404</h1>
  //     <div className="inline-block align-middle">
  //         <h2 className="font-weight-normal lead" id="desc">The page you requested was not found.</h2>
  //     </div>
  // </div>
  <React.Fragment>
    <section className="my-5 pt-sm-5">
      <Container>
        <Row>
          <Col xs="12" className="text-center">
            <div className="home-wrapper">
              <div className="mb-5">
                {/* <img src={logo} alt="logo" height="24" /> */}
              </div>

              <Row className="justify-content-center">
                <Col sm={4}>
                  <div className="maintenance-img">
                    <img
                      src="assets/img/maintenance.png"
                      alt=""
                      className="img-fluid mx-auto d-block"
                    />
                  </div>
                </Col>
              </Row>
              <h3 className="mt-5">Site is Under Maintenance</h3>
              <p>Please check back in sometime.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  </React.Fragment>
);

export default Comp404;