// jshint esversion:6

import React, { Component } from "react";
import { Link } from "react-router-dom";
import { auth } from "../../firebase";
import Loader from "../Loader/Loader";
import loginSVG from "./loginSVG.svg";
import "./style.css";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hide: true,
      email: "",
      password: "",
      loading: false,
      error: "",
      fbRes: null,
      showModal: false,
      isMulti: false,
    };
  }

  onChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit(e) {
    e.preventDefault();
    this.setState({
      error: "",
      loading: true,
    });
    auth
      .signInWithEmailAndPassword(this.state.email, this.state.password)
      .catch((error) => {
        let errorMsg = "";

        switch (error.code) {
          case "auth/wrong-password":
            errorMsg = "The password that you've entered is incorrect";
            break;
          case "auth/user-not-found":
            errorMsg = "No user found";
            break;
          default:
            errorMsg = error.message;
            break;
        }

        this.setState({
          error: errorMsg,
          loading: false,
        });
      });
  }

  render() {
    return (
      <>
        <div className="main-content account-content" style={{ paddingTop: 0 }}>
          <div
            className="content box-bottom-padding pb-60"
            style={{ paddingTop: 0 }}
          >
            <div className="container" style={{ width: "95%" }}>
              <div className="sub-header">
                <div className="sub-link">
                  <Link to="#" id="loginlink">
                    Login
                  </Link>
                </div>
                <div>
                  <Link to="/register" id="login-reg">
                    Register
                  </Link>
                </div>
              </div>
              <div className="row">
                {this.state.loading && <Loader />}
                <div className="d-none d-md-block col-md-6 login-svg">
                  <div>
                    <img src={loginSVG} height="100%" width="100%" />
                  </div>
                </div>
                <div className="col-md-1"></div>
                <div className="col-12 col-md-5 login-info login-outer">
                  <form
                    onSubmit={(e) => {
                      this.onSubmit(e);
                    }}
                    className="form-signin"
                    action="#"
                  >
                    <div className="account-logo">
                      <a>
                        <img src="assets/img/logo-dark.png" alt="Medvatic" />
                      </a>
                    </div>
                    <div className="account-title">
                      <h3>{this.props.title}</h3>
                    </div>
                    {this.state.error && (
                      <div
                        className="alert alert-danger alert-dismissible fade show"
                        role="alert"
                      >
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                          <span className="sr-only">Close</span>
                        </button>
                        {this.state.error}
                      </div>
                    )}
                    <div className="form-group">
                      <label htmlFor="email">Email Address</label>
                      <span className="required">*</span>
                      <input
                        type="email"
                        id="email"
                        className="form-control"
                        autoFocus
                        required
                        value={this.state.email}
                        name="email"
                        onChange={(e) => this.onChange(e)}
                      />
                    </div>
                    <div className="form-group pass-input">
                      <label>Password</label>
                      <span className="required">*</span>
                      <input
                        type={this.state.hide ? "password" : "text"}
                        id="password"
                        className="form-control"
                        required
                        value={this.state.password}
                        name="password"
                        onChange={(e) => this.onChange(e)}
                      />
                      <div>
                        <i
                          onClick={() => this.setState({ hide: false })}
                          class={
                            !this.state.hide
                              ? "hide-eye-icon eye-icon"
                              : "fa fa-eye eye-icon"
                          }
                          aria-hidden="true"
                        ></i>
                        <i
                          onClick={() => this.setState({ hide: true })}
                          class={
                            this.state.hide
                              ? "hide-eye-icon eye-icon"
                              : "fa fa-eye-slash eye-icon"
                          }
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                    {/* <div className="form-group text-right">
                    <a>Forgot your password?</a>
                  </div> */}
                    <div className="form-group text-center mt-50">
                      <a>
                        <button
                          style={{
                            width: "100%",
                            padding: "2%",
                            backgroundColor: "#50D8FA",
                            color: "#fff",
                          }}
                          className="btn btn-primary submit-btn account-btn"
                        >
                          Login
                        </button>
                      </a>
                    </div>
                  </form>
                  {this.props.title === "Login" ? (
                    <>
                      <div className="row">
                        <div className="text-center text-sm-left register-link col-sm-4">
                          {/* Forgot password ? */}
                          {/* <Link to={Register}> Register Now </Link> */}
                          {/* Register Now */}
                          <Link
                            to="/forget-password"
                            className=""
                            style={{ color: "#787887", fontSize: "1rem" }}
                          >
                            Forgot password?
                          </Link>
                        </div>
                        <div className="text-center text-sm-right register-link col-sm-8">
                          Don&#x2019;t have an account?
                          {/* <Link to={Register}> Register Now </Link> */}
                          {/* Register Now */}
                          <Link
                            to="/register"
                            className="login-signup-redirect-link"
                          >
                            {" "}
                            Register Now{" "}
                          </Link>
                        </div>
                      </div>

                      <div className="text-center register-link pt-sm-3">
                        <span
                          style={{
                            color: "#4B76A9",
                            fontWeight: "bold",
                            fontSize: "1.1rem",
                          }}
                        >
                          Are you doctor?
                        </span>
                        {/* <Link to={Register}> Register Now </Link> */}
                        {/* Register Now */}
                        <Link
                          to="/doctor/welcome"
                          className="login-signup-redirect-link"
                          style={{
                            color: "#4B76A9",
                          }}
                        >
                          {" "}
                          Click Here!{" "}
                        </Link>
                      </div>
                    </>
                  ) : (
                    <div className="text-center register-link">
                      <Link
                        to="/doctor/register"
                        className="login-signup-redirect-link"
                      >
                        {" "}
                        Register as a doctor{" "}
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
