import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loader from "../Loader/Loader";
import Apilib from "../../api";
import { Modal, Button } from "react-bootstrap";
import { storage } from "../../firebase";
import './doctorStyles.css';

export default class PatientAppointments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            appointments: [],
        }
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.setState({
          loading: true,
        });
        // console.log(this.props.match.params.uid);
        let {userData,type} = this.props;
        let API = new Apilib();
        API.listSinglePatientAppointments(userData.hospitalId,userData.uid,this.props.match.params.uid).then((res) => res.json())
        .then((res) => {
            console.log("Appointment", res.appointments);
            if (this._isMounted) {
                this.setState({
                    loading: false,
                    appointments: res.appointments,
                });
            }
        })
        .catch((error) => {
            if (this._isMounted) {
            this.setState({
                loading: false,
            });
            }
        });
    }

    render() {
        if (this.state.loading) {
          return <Loader />;
        } else {
          return (
              <>
                <div className="row">
                    <div className="col-sm-12">
                        {this.state.appointments.length ? 
                        <h4 className="page-title">Appointments</h4>
                        :
                        <h4 className="page-title">No Appointments Found</h4>
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 patient-cards">
                        {this.state.appointments.map((appointment,i) => (
                            <Link to={{
                                pathname: "/doctor/edit-appointment",
                                state: {
                                  name: this.state.appointments[i],
                                },
                              }} className="card mb-3">
                                <div className="row">
                                    <div className="date col-md-1">
                                    <p>{appointment.slot_date}</p>
                                    </div>
                                    <div className="col-md-10">
                                        <div className="card-body">
                                            <div className="d-md-flex justify-content-between">
                                                <h5 className="card-title mb-3">{this.props.userData.firstName+' '+this.props.userData.lastName}</h5>
                                                <h5 className="appointment_status">{appointment.appointment_status}</h5>
                                            </div>
                                            <p className="appointment-time">
                                                <small className="text-muted"><i className="fa fa-clock-o" aria-hidden="true"></i>{appointment.slot_time}</small> <br/>
                                            </p>
                                            <p className="card-text">
                                                <small><i className="fa-blue fa fa-envelope" aria-hidden="true"></i>{appointment.doctor_email}</small> <br/>
                                            </p>
                                            <div className="card-text"></div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
              </>
          )
        }
    }
}