import { Avatar } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import SendRoundedIcon from "@material-ui/icons/SendRounded";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import React, { useRef, useState } from "react";
import "./ChatSupport.scss";

const ChatSupport = ({ phoneNumber }) => {
  const wrapRef = useRef(null);
  const [message, setMessage] = useState("");

  const handleWrapToggle = () => {
    if (wrapRef.current) {
      wrapRef.current.classList.toggle("ChatSupport__container-wrap--close");
      wrapRef.current.classList.toggle("ChatSupport__container-wrap--open");
    }
  };
  return (
    <div className="ChatSupport">
      <div className="ChatSupport__container">
        <div
          ref={wrapRef}
          className={
            "ChatSupport__container-wrap ChatSupport__container-wrap--close"
          }
        >
          <button className="ChatSupport__cross-btn" onClick={handleWrapToggle}>
            <CloseIcon />
          </button>
          <div className="ChatSupport__content">
            <Avatar
              src="/assets/img/ChatSupport/user-male.svg"
              style={{
                width: 60,
                height: 60,
              }}
            />
            <div>
              <div className="ChatSupport__content-wrapper">
                <div className="ChatSupport__content-head">
                  <p>
                    Our customer support team is here to answer your questions.
                    Ask us anything!
                  </p>
                </div>
                <div className="ChatSupport__content-footer">
                  Hi! How can I help?
                </div>
              </div>
              <div className="ChatSupport__chat">
                <input
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  type="text"
                  name="message"
                  placeholder="Type your query here..."
                  id=""
                  className="ChatSupport__chat-input"
                />
                <button
                  className="ChatSupport__send-btn"
                  onClick={() => {
                    let sendMessage = message;
                    !!sendMessage &&
                      window.open(
                        `https://wa.me/91${phoneNumber}?text=${encodeURI(
                          sendMessage
                        )}`
                      ) &&
                      setMessage("");
                  }}
                >
                  <SendRoundedIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
        <button className="ChatSupport__cta-btn" onClick={handleWrapToggle}>
          <WhatsAppIcon /> &nbsp; Hi! How can I help?
        </button>
      </div>
    </div>
  );
};

export default ChatSupport;
