import "datatables.net-dt/css/jquery.dataTables.min.css";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import $ from "jquery";
import "jquery/dist/jquery.min.js";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import Apilib from "../../api";
import Notify from "../../Notify";
import Loader from "../Loader/Loader";

export default class ReceptionistsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      receptionists: [],
      modalopen: false,
      deleteModal: false,
      editedRecep: [],
    };
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;
    this.setState({
      loading: true,
    });
    let API = new Apilib();
    API.getUsers(4, this.props.userData.hospitalId)
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        if (this._isMounted) {
          this.setState(
            {
              loading: false,
              receptionists: res.receptionists,
            },
            () => {
              if ($(".datatable").length > 0) {
                $(".datatable").DataTable({
                  columnDefs: [
                    { orderable: false, targets: 4, width: "200px" },
                  ],
                });
              }
            }
          );
        }
      })
      .catch((error) => {
        if (this._isMounted) {
          this.setState({
            loading: false,
          });
        }
      });
  }

  onChange = (e) => {
    let formObj = { ...this.state.editedRecep };
    formObj[e.target.name] = e.target.value;
    this.setState({
      editedRecep: formObj,
    });
  };

  editedReceptionists(value) {
    let data = {
      ...value,
    };
    delete data.formIsValid;
    delete data.formData;
    console.log("Edited recep:::", data);
    let API = new Apilib();
    API.updateUser(JSON.stringify(data)).then((res) => {
      if (res.ok) {
        this.setState({
          loading: false,
          receptionists: [],
          modalOpen: false,
          editedRecep: [],
        });
        this.componentDidMount();
        Notify({
          alert: true,
          type: "success",
          title: "Receptionist Updated Successfully",
        });
      } else {
        Notify({
          alert: true,
          type: "error",
          title: "Error occured",
        });
      }
    });
  }
  deleteReceptionists(value) {
    let data = {
      type: 4,
      uid: value.uid,
    };
    console.log("Edited recep:::", data);
    let API = new Apilib();
    this.setState({
      loading: true,
    });
    API.deleteReceptionist(JSON.stringify(data)).then((res) => {
      if (res.ok) {
        Notify({
          title: "Receptionist deleted successfully",
          alert: true,
          type: "success",
        });
        this.setState({
          loading: false,
          receptionists: [],
          deleteModal: false,
          editedRecep: [],
        });
        this.componentDidMount();
      } else {
        Notify({
          alert: true,
          type: "error",
          title: "Error occured",
        });
        this.setState({
          loading: false,
        });
      }
    });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleOpen(e) {
    e.preventDefault();
    this.setState({
      modalOpen: true,
    });
  }
  handleClose() {
    this.setState({
      modalOpen: false,
    });
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    } else {
      return (
        <React.Fragment>
          <div className="row">
            <div className="col-sm-4 col-3">
              <h4 className="page-title">Receptionists</h4>
            </div>
            <div className="col-sm-8 col-9 text-right m-b-20">
              <Link
                to="/hospital/add-receptionist"
                className="btn btn-primary float-right btn-rounded"
              >
                <i className="fa fa-plus"></i> Add Receptionist
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <table className="table table-border table-striped custom-table datatable m-b-0">
                  <thead>
                    <tr>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Email Address</th>
                      <th>Mobile No</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.receptionists &&
                      this.state.receptionists.map((receptionist, index) => (
                        <tr>
                          <td>{receptionist.firstName}</td>
                          <td>{receptionist.lastName}</td>
                          <td>{receptionist.email}</td>
                          <td>{receptionist.mobile}</td>
                          <td>
                            <button
                              onClick={(e) => {
                                this.handleOpen(e);
                                this.setState({
                                  editedRecep: receptionist,
                                });
                              }}
                              className="btn btn-small btn-secondary mr-2"
                            >
                              <span
                                class="iconify"
                                data-icon="akar-icons:edit"
                                data-inline="false"
                                style={{
                                  marginRight: ".5em",
                                  marginTop: "-.1em",
                                  display: "inline-block",
                                }}
                              ></span>
                              Edit
                            </button>
                            <button
                              onClick={(e) => {
                                this.setState({
                                  deleteModal: true,
                                  editedRecep: receptionist,
                                });
                              }}
                              className="btn btn-danger btn-small mr-2"
                            >
                              <span
                                class="iconify"
                                data-icon="ant-design:delete-twotone"
                                data-inline="false"
                                style={{
                                  marginRight: ".5em",
                                  marginTop: "-.1em",
                                  display: "inline-block",
                                }}
                              ></span>
                              Delete
                            </button>
                          
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <Modal
            show={this.state.modalOpen}
            onHide={() => this.handleClose()}
            animation={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Receptionist Update</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      First Name <span className="text-danger">*</span>
                    </label>
                    <input
                      name="firstName"
                      value={this.state.editedRecep.firstName}
                      onChange={(e) => this.onChange(e)}
                      required
                      className="form-control"
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Last Name <span className="text-danger">*</span>
                    </label>
                    <input
                      name="lastName"
                      value={this.state.editedRecep.lastName}
                      onChange={(e) => this.onChange(e)}
                      required
                      className="form-control"
                      type="text"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Email <span className="text-danger">*</span>
                    </label>
                    <input
                      name="email"
                      readOnly
                      value={this.state.editedRecep.email}
                      onChange={(e) => this.onChange(e)}
                      required
                      className="form-control"
                      type="email"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Mobile No.<span className="text-danger">*</span>
                    </label>
                    <input
                      name="mobile"
                      
                      value={this.state.editedRecep.mobile}
                      onChange={(e) => this.onChange(e)}
                      required
                      className="form-control"
                      type="number"
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.handleClose()}>
                Close
              </Button>
              <Button
                variant="primary"
                onClick={() => this.editedReceptionists(this.state.editedRecep)}
              >
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Delete Modal */}
          <Modal
            show={this.state.deleteModal}
            onHide={() =>
              this.setState({
                deleteModal: false,
              })
            }
            animation={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete Receptionist</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="p-2">
                Are you sure you want delete receptionist{" "}
                <b>{`${this.state.editedRecep.firstName} ${this.state.editedRecep.lastName}`}</b>
                ?
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() =>
                  this.setState({
                    deleteModal: false,
                  })
                }
              >
                Close
              </Button>
              <Button
                variant="danger"
                onClick={() => this.deleteReceptionists(this.state.editedRecep)}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </React.Fragment>
      );
    }
  }
}
