// jshint esversion:6

import React, { Component } from "react";
// import { Dropdown, NavDropdown, NavItem } from "react-bootstrap";

export default class Compliance extends Component {
  async componentDidMount(){
    var coll = document.getElementsByClassName("collapsible");
    var i;
    for (i = 0; i < coll.length; i++) {
      coll[i].addEventListener("click", function () {
        this.classList.toggle("active");
        var content = this.nextElementSibling;
        // if (content.style.maxHeight) {
        //   content.style.maxHeight = null;
        // } else {
        //   content.style.maxHeight = content.scrollHeight + "px";
        // }
      });
    }
  }
  render() {
    return (
      <>
        <section className="section services-banner row-middle">
          <div className="inner-bg"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="section-header text-center">
                  <h3
                    className="header-title treatment-header"
                    // style="margin-top: 30px; color: #fff"
                  >
                    COMPLIANCE
                  </h3>
                  <div className="line"></div>
                  <p style={{"color": "#fff"}}>
                    Medvatic strives to provide a highly secure patient-centered
                    digital healthcare services. In compliance with the above
                    online privacies policies.
                    </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="compliance">
        <button className="collapsible" data-toggle="collapse" href="#ISO" aria-expanded="false" aria-controls="ISO">ISO 27001-ISO 27001</button>
        <div className="content2 collapse" id="ISO">
          <p>
            A widely adopted global security standard that lines the requirement
            for information security management systems.
          </p>
        </div>
        <button className="collapsible" data-toggle="collapse" href="#HIPAA" aria-expanded="false" aria-controls="HIPAA">
          HIPAA (Health Insurance Portability and Accountability Act)
        </button>
        <div className="content2 collapse" id="HIPAA">
          <p>
            Health Insurance Portability and Accountability Act contains strict
            security and compliance standards for organizations processing or
            storing protected health information.
          </p>
        </div>
        <button className="collapsible" data-toggle="collapse" href="#cloud" aria-expanded="false" aria-controls="cloud">DoD Cloud Security Model (CSM)</button>
        <div className="content2 collapse" id="cloud">
          <p>
            Standards for cloud computing issued by the U.S. Defense information
            Systems Agency (DISA) and documented in the Department of Defense
            (DoD) Security Requirement Guide (SRG).Provide an authorization
            process for DoD workload owners who have unique arhitectural
            requirement depending on impact level.
          </p>
        </div>
      </div>
      <div className="compliance2">
        <h5>
          Australian Privacy principles under the privacy Act 1988(Cth) Business
          Associate Agreement.
        </h5>
        <button className="collapsible" data-toggle="collapse" href="#DoD" aria-expanded="false" aria-controls="DoD">DoD Cloud Security Model (CSM)</button>
        <div className="content2 collapse" id="DoD">
          <ul>
            <li>
              Australian Data protection Act 1988 and it Amendment Act 2003
            </li>
            <li>EC Privacy and Electronic Communication Regulations 2011</li>
            <li>
              New EU General Data Protection Regulations 2016 (to be effective
              may 2018)
            </li>
            <li>Electronic Frontier Australia (EFA) Australia Crypto FAQ</li>
            <li>European Privacy Initiative</li>
          </ul>
        </div>
      </div>
      <div className="compliance2">
        <h5>
          Medvatic comply with four main HIPAA-secure encryption Messaging
          requirements:
        </h5>
        <button className="collapsible" data-toggle="collapse" href="#Administration" aria-expanded="false" aria-controls="Administration">Administration</button>
        <div className="content2 collapse" id="Administration">
          <ul>
            <li>
              Medvatic will provide a 24/7 online cybersecurity support service
              based
            </li>
            <li>
              Provide the ability to terminate all save files in your personal
              device if you report it has been stolen
            </li>
            <li>We will block unauthorised access to your health records</li>
            <li>
              Possibility for us shutting down the app and all access to PHR
              would be shut down
            </li>
            <li>
              Provide you access to reactivate your account after shutdown
            </li>
          </ul>
        </div>
        <button className="collapsible" data-toggle="collapse" href="#Technical" aria-expanded="false" aria-controls="Technical">Technical Safeguards</button>
        <div className="content2 collapse" id="Technical">
          <ul>
            <li>
              Medvatic provide means of testing all secure encryption of PHI
              before used
            </li>
            <li>
              We use 256-bit secure encryption in all our service transmissions
              in serve
            </li>
            <li>
              Health professionals are to be more extra careful in protecting
              their devices in a safe place at all times
            </li>
            <li>Protected by SSAE16 Type 2 data center compliance</li>
            <li>
              Automatic intelligence intuitive system protection and monitoring
              feedback system
            </li>
          </ul>
          </div>
        <button className="collapsible" data-toggle="collapse" href="#Redundant" aria-expanded="false" aria-controls="Redundant">
          Redundant and highly satisfied secured Platform
        </button>
        <div className="content2 collapse" id="Redundant">
          <ul>
            <li>No encryption key management for all Medvatic users</li>
            <li>24/7 cybersecurity monitoring system and support team</li>
            <li>
              All transmission is encrypted with 256-key encryption standards
            </li>
            <li>
              Ultra-secure, HIPAA, SAS70 and SSAE private cloud compliance
            </li>
            <li>
              No PHI is stored in any of your devices all data of PHI are stored
              on our server where the system enables emergency recovery
              procedures
            </li>
            <li>
              Continue back up procedures and devices disaster recovery action
              in case of loss or fire
            </li>
          </ul>
        </div>
        </div>
      </>
    );
  }
}
