import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datetime/css/react-datetime.css';
import * as serviceWorker from './serviceWorker';
import reducer, {initialState} from "./reducer";
import { StateProvider } from './StateProvider';
// import AOS from 'aos';
// import 'aos/dist/aos.css';

// AOS.init({
//   duration: 1000
// });

ReactDOM.render(
    <StateProvider initialState={initialState} reducer={reducer}>
      <App />
    </StateProvider>,
  document.getElementById("root")
);
