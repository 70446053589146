import "datatables.net-dt/css/jquery.dataTables.min.css";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "jquery/dist/jquery.min.js";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import BaseSelect from "react-select";
import Apilib from "../../api";
import RequiredSelect from "../../Helpers/requiredSelect";
import Notify from "../../Notify";
import Loader from "../Loader/Loader";
import Table from "../Table";

const Select = (props) => (
  <RequiredSelect
    {...props}
    SelectComponent={BaseSelect}
    options={props.options}
  />
);

export default class HospitalList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth_token: "",
      loading: false,
      modalLoading: false,
      hospitals: [],
      currentHospital: [],
      currentIndex: "",
      countries: [],
      date: Date().toLocaleString(),
      states: [],
      cities: [],
      hospital_chains: [],
      selectedChain: null,
      selectedCountry: null,
      selectedState: null,
      selectedCity: null,
      modalOpen: false,
      success: false,
      show: false,
      deleteHospital: null,
    };
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({
      loading: true,
    });
    let API = new Apilib();

    API.getUsers(2)
      .then((res) => res.json())
      .then((res) => {
        console.log("Response: ", res.hospital_admins);
        if (this._isMounted) {
          let data = [];
          data = res.hospital_admins;
          console.log("DATA>>>>>>>", data);
          data.sort((a, b) => b.created_at - a.created_at); //created_at
          console.log("SORT DATA>>>>>>>", data);
          this.setState(
            {
              loading: false,
              hospitals: [...data],
            },
            () => {
              // if ($(".datatable").length > 0) {
              //   $(".datatable").DataTable({
              //     "order": [],
              //   });
              // }
            }
          );
        }
      })
      .then(() => {
        API.getCountries()
          .then((res) => res.json())
          .then((res) => {
            this.setState({
              auth_token: "",
              countries: res.data.map((country) => ({
                country_name: country.name,
              })),
            });

            API.viewHospitalChain()
              .then((res) => res.json())
              .then((res) => {
                this.setState({
                  hospital_chains: res.hospitals,
                  loading: false,
                });
              });
          });
      })
      .catch((error) => {
        console.log("error: ", error);
        if (this._isMounted) {
          this.setState({
            loading: false,
          });
        }
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onChange = (e) => {
    let formObj = { ...this.state.currentHospital };
    formObj[e.target.name] = e.target.value;
    this.setState({
      currentHospital: formObj,
    });
  };

  onSelectChain = (val) => {
    let formObj = { ...this.state.form_data };
    formObj["hospital_chain"] = val.label;
    formObj["hospitalChainId"] = val.value;
    this.setState({
      form_data: formObj,
      currentHospital: {
        ...this.state.currentHospital,
        hospital_chain: val.label,
        hospitalChainId: val.value,
      },
      selectedChain: val,
    });
  };

  onSelectCountry = (val) => {
    let formObj = { ...this.state.form_data };
    formObj["country"] = val.value;

    this.setState(
      {
        form_data: formObj,
        currentHospital: { ...this.state.currentHospital, country: val.value },
        selectedCountry: val,
      },
      () => {
        let API = new Apilib();
        this.setState({
          loading: true,
          currentHospital: { ...this.state.currentHospital, state: "" },
          selectedState: null,
          selectedCity: null,
        });
        API.getStates(val.value)
          .then((res) => res.json())
          .then((res) => {
            this.setState({
              states: res.data.states.map((state) => ({
                state_name: state.name,
              })),
              loading: false,
            });
          });
      }
    );
  };

  onSelectState = (val) => {
    let formObj = { ...this.state.form_data };
    formObj["state"] = val.value;
    this.setState(
      {
        form_data: formObj,
        currentHospital: { ...this.state.currentHospital, state: val.value },
        selectedState: val,
      },
      () => {
        let API = new Apilib();
        this.setState({
          loading: true,
          selectedCity: null,
        });
        API.getCities(this.state.selectedCountry.value, val.value)
          .then((res) => res.json())
          .then((res) => {
            this.setState({
              cities: res.data.map((city) => ({ city_name: city })),
              loading: false,
            });
          });
      }
    );
  };

  updateData = (e) => {
    e.preventDefault();
    let data = { ...this.state.currentHospital };
    let hospitals = [...this.state.hospitals];
    hospitals[this.state.currentIndex] = data;
    this.setState({
      modalLoading: true,
      hospitals,
    });
    //delete data.email;
    let API = new Apilib();
    API.updateUser(JSON.stringify(data))
      .then((res) => {
        this.setState({
          modalLoading: false,
        });
        if (res.ok) {
          this.setState({
            modalLoading: false,
            currentHospital: {},
            selectedCountry: null,
            selectedState: null,
            modalOpen: false,
            success: true,
          });
          Notify({
            alert: true,
            type: "success",
            title: "Edit Successfully",
          });
        } else {
          res.text().then((data) => {
            this.setState({
              modalOpen: false,
              error: JSON.parse(data).message,
            });
          });
        }
      })
      .catch((error) => {
        this.setState({
          error: error.message,
          loading: false,
        });
      });
  };

  handleOpen(e, index) {
    e.preventDefault();
    this.setState({
      modalOpen: true,
      currentHospital: this.state.hospitals[index],
      currentIndex: index,
      error: null,
      success: false,
    });
  }
  handleClose() {
    this.setState({
      modalOpen: false,
      currentHospital: {},
      selectedCountry: null,
      selectedState: null,
      currentIndex: "",
    });
  }

  deleteHospital = (value) => {
    this.setState({
      loading: true,
    });
    console.log("deleted hospital>>>>>>>", value);
    let API = new Apilib();
    API.deleteHospital(
      JSON.stringify({ type: value.type, uid: value.uid })
    ).then(async (res) => {
      if (res.ok) {
        let MSG = await res.json();

        this.setState({
          show: false,
        });

        //recollect data
        API.getUsers(2)
          .then((res) => res.json())
          .then((res) => {
            console.log("Response: ", res.hospital_admins);
            let data = [];
            data = res.hospital_admins;
            data.sort((a, b) => b.created_at - a.created_at);
            this.setState({
              loading: false,
              hospitals: [...data],
            });
            Notify({
              alert: true,
              type: "success",
              title: MSG.msg,
            });
          })
          .catch((error) => {
            console.log("error: ", error);
            if (this._isMounted) {
              this.setState({
                loading: false,
              });
            }
          });
      } else {
        Notify({
          alert: true,
          type: "danger",
          title: "Error occured",
        });
      }
    });
  };
  render() {
    let formData = {
      hospiChain: this.state.currentHospital.hospiChain,
    };
    let chains = [];
    if (this.state.hospital_chains.length) {
      this.state.hospital_chains.map((item, i) => {
        chains.push({
          value: item.id,
          label: item.chain_name,
        });
      });
    }

    let countries = [];
    if (this.state.countries.length) {
      this.state.countries.map((item, i) => {
        countries.push({
          value: item.country_name,
          label: item.country_name,
        });
      });
    }

    let states = [];
    if (this.state.states.length) {
      this.state.states.map((item, i) => {
        states.push({
          value: item.state_name,
          label: item.state_name,
        });
      });
    }
    if (this.state.loading) {
      return <Loader />;
    } else {
      return (
        <React.Fragment>
          <div className="row">
            <div className="col-sm-4 col-3">
              <h4 className="page-title">Hospitals</h4>
            </div>
            <div className="col-sm-8 col-9 text-right m-b-20">
              <Link
                to="/admin/add-hospital"
                className="btn btn-primary float-right btn-rounded"
              >
                <i className="fa fa-plus"></i> Add Hospital
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {this.state.error && (
                <div
                  className="alert alert-danger alert-dismissible fade show"
                  role="alert"
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                    <span className="sr-only">Close</span>
                  </button>
                  {this.state.error}
                </div>
              )}
              {/* {this.state.success && (
                <div
                  className="alert alert-success alert-dismissible fade show"
                  role="alert"
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                    <span className="sr-only">Close</span>
                  </button>
                  Hospital updated successfully
                </div>
              )} */}
              <div className="table-responsive">
                {!this.state.loading && (
                  <Table
                    tableOptions={{
                      order: [],
                      columnDefs: [{ orderable: false, targets: 5 }],
                    }}
                    columns={[
                      "Hospital Chain Name",
                      "Hospital Name",
                      "Country",
                      "Date",
                      "Hospital Code",
                      "Pin Code",
                      "Actions",
                    ]}
                    rows={this.state.hospitals.map((hospital, index) => (
                      <tr key={index}>
                        <td onClick={(e) => this.handleOpen(e, index)}>
                          {hospital.hospital_chain || hospital.hospitalName}
                        </td>
                        <td>
                          {hospital.hospital_name || hospital.hospitalName}
                        </td>
                        <td>{hospital.country}</td>
                        <td>{hospital.date}</td>
                        <td>{hospital.hospital_code}</td>
                        <td>{hospital.pincode}</td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "start",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              <button
                                onClick={(e) => this.handleOpen(e, index)}
                                type="button"
                                class="btn btn-secondary"
                              >
                                <span
                                  class="iconify"
                                  data-icon="akar-icons:edit"
                                  data-inline="false"
                                ></span>
                                Edit
                              </button>
                            </div>
                            <div style={{ marginLeft: "2%" }}>
                              <button
                                onClick={(e) => {
                                  this.setState({
                                    show: true,
                                    deleteHospital: hospital,
                                  });
                                }}
                                type="button"
                                class="btn btn-danger"
                              >
                                <span
                                  style={{ marginBottom: "1%" }}
                                  class="iconify"
                                  data-icon="ant-design:delete-twotone"
                                  data-inline="false"
                                ></span>
                                Delete
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  />
                )}

               {/* {!this.state.loading &&(
                  <table className="table table-border table-striped custom-table datatable m-b-0">
                  <thead>
                    <tr>
                      <th>Hospital Chain Name</th>
                      <th>Hospital Name</th>
                      <th>Country</th>
                      <th>Date</th>
                      <th>Pin Code</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.hospitals &&
                      this.state.hospitals.map((hospital, index) => (
                        <tr key={index}>
                          <td onClick={(e) => this.handleOpen(e, index)}>
                            {hospital.hospital_chain}
                          </td>
                          <td>{hospital.hospital_name}</td>
                          <td>{hospital.country}</td>
                          <td>{hospital.pincode}</td>
                          <td>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "start",
                                alignItems: "center",
                              }}
                            >
                              <div>
                                <button
                                  onClick={(e) => this.handleOpen(e, index)}
                                  type="button"
                                  class="btn btn-secondary"
                                >
                                  <span
                                    class="iconify"
                                    data-icon="akar-icons:edit"
                                    data-inline="false"
                                  ></span>
                                  Edit
                                </button>
                              </div>
                              <div style={{ marginLeft: "2%" }}>
                                <button
                                  onClick={(e)=>{
                                    this.setState({
                                      show:true,
                                      deleteHospital:hospital
                                    })
                                    
                                  }}
                                 type="button" class="btn btn-danger">
                                  <span
                                    style={{ marginBottom: "1%" }}
                                    class="iconify"
                                    data-icon="ant-design:delete-twotone"
                                    data-inline="false"
                                  ></span>
                                  Delete
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                )}  */}
              </div>
            </div>
          </div>

          <Modal
            show={this.state.show}
            onHide={() =>
              this.setState({
                show: false,
              })
            }
            animation={false}
          >
            <Modal.Header>
              <Modal.Title>Delete hospital</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure, You want to delete Hospital
              {this.state.deleteHospital ? (
                <>
                  <strong>
                    {" " + this.state.deleteHospital.hospital_name}
                  </strong>
                </>
              ) : null}{" "}
              ?
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={() => this.deleteHospital(this.state.deleteHospital)}
              >
                Yes
              </Button>
              <Button
                variant="danger"
                onClick={() =>
                  this.setState({
                    show: false,
                  })
                }
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={this.state.modalOpen}
            onHide={() => this.handleClose()}
            animation={false}
          >
            {this.state.modalLoading && <Loader />}
            <form onSubmit={(e) => this.updateData(e)}>
              <Modal.Header closeButton>
                <Modal.Title>Hospital Update</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Hospital Chain Name</label>
                      <input
                        required
                        className="form-control"
                        name="hospitalName"
                        type="hospitalName"
                        readOnly
                        // onChange={(e) => this.onChange(e)}
                        value={
                          this.state.currentHospital.hospitalName

                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Hospital Name</label>
                      <input
                        className="form-control"
                        required
                        name="hospital_name"
                        onChange={(e) => this.onChange(e)}
                        type="text"
                        value={
                          this.state.currentHospital.hospital_name ||
                          this.state.currentHospital.hospitalName
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label>Address</label>
                  <textarea
                    name="address"
                    required
                    onChange={(e) => this.onChange(e)}
                    value={this.state.currentHospital.address}
                    cols="30"
                    rows="4"
                    className="form-control"
                  >
                    {this.state.currentHospital.address}
                  </textarea>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>
                        Country<span className="text-danger">*</span>
                      </label>
                      <Select
                        required
                        value={
                          !!this.state.selectedCountry
                            ? this.state.selectedCountry
                            : {
                              value: this.state.currentHospital.country,
                              label: this.state.currentHospital.country,
                            }
                        }
                        onChange={(item) => this.onSelectCountry(item)}
                        options={countries}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>
                        State<span className="text-danger">*</span>
                      </label>
                      <Select
                        required
                        value={
                          !!this.state.selectedState
                            ? this.state.selectedState
                            : !!this.state.currentHospital.state
                              ? {
                                value: this.state.currentHospital.state,
                                label: this.state.currentHospital.state,
                              }
                              : null
                        }
                        onChange={(item) => this.onSelectState(item)}
                        options={states}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Pincode</label>
                      <input
                        className="form-control"
                        required
                        name="pincode"
                        onChange={(e) => this.onChange(e)}
                        type="number"
                        value={this.state.currentHospital.pincode}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Email Address</label>
                      <input
                        required
                        className="form-control"
                        name="email"
                        type="email"
                        readOnly
                        //onChange={(e) => this.onChange(e)}
                        value={this.state.currentHospital.email}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Contact Number</label>
                      <input
                        className="form-control"
                        required
                        name="contact"
                        onChange={(e) => this.onChange(e)}
                        type="text"
                        value={this.state.currentHospital.emergency_contact}
                        pattern="^[0-9]{8,}$"
                        onInvalid={function (e) {
                          e.target.setCustomValidity("");
                          if (!e.target.validity.valid) {
                            e.target.setCustomValidity(
                              "Conatct no should be minimum 8 digits"
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => this.handleClose()}>
                  Close
                </Button>
                <button class="btn btn-primary" type="submit" variant="primary">
                  Update Hospital
                </button>
              </Modal.Footer>
            </form>
            {/* // )}}

            // </Formik> */}
          </Modal>
        </React.Fragment>
      );
    }
  }
}
