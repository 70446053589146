// jshint esversion:6

import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Route, useHistory, useLocation } from "react-router-dom";
import Apilib from "../api";
import ChatSupport from "../Components/Patient/ChatSupport";
import Footer from "../Components/Patient/Footer";
import Header from "../Components/Patient/Header";
import { auth, storage } from "../firebase";
import { getDefaultPageUrl, isFooterLink } from "../Helpers/constants";
import { useStateValue } from "../StateProvider";
import Loader from "./../Components/Loader/Loader";
// import './public.css';

export const PatientLayout = (
  { children, type, path, user, userData, ...rest },
  props
) => {
  console.log("path", props);
  return (
    <>
      <div className="main-wrapper" style={{ position: "relative" }}>
        <ChatSupport phoneNumber={"8828103808"} />
        <Header user={user} userData={userData} type={type} />
        {children}
        <Footer />
      </div>
    </>
  );
};

let resolver, rejector;

const PatientLayoutRoute = ({
  component: Component,
  userType,
  title,
  path,
  ...rest
}) => {
  const [redirect, setRedirect] = useState(null);
  const [user, setUser] = useState();
  const [userData, setUserData] = useState("loading");
  const [userDisplayName, setUserDisplayName] = useState(true);
  const [loading, setLoading] = useState(true);
  const [userIsLoading, setUserIsLoading] = useState(false);
  const [gettingLocation, setgettingLocation] = useState(false);
  const [pp_url, setpp_url] = useState();
  const [{ userPP }, dispatch] = useStateValue();
  const [prefModalOpen, setprefModalOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();

  function getLoginPreference() {
    console.log("===== getting preference");
    setLoading(false);
    setprefModalOpen(true);
    return new Promise((res, rej) => {
      resolver = res;
      rejector = rej;
    });
  }

  useEffect(() => {
    let API = new Apilib();
    let isSubscribed = true;
    auth.onAuthStateChanged(async (user) => {
      try {
        console.log(user, "logged in user");
        if (user) {
          setLoading(true);
          let displayName = user.displayName;
          if (user.displayName.length > 1) {
            if (localStorage.getItem("@prefLogin")) {
              displayName = localStorage.getItem("@prefLogin");
            } else {
              displayName = await getLoginPreference();
              localStorage.setItem("@prefLogin", displayName);
            }
            setLoading(true);
          }
          await API.getUserDetails(displayName, user.uid)
            .then((res) => {
              if (!res.ok) throw new Error("Error in fetching user details");
              return res.json();
            })
            .then(async (res) => {
              if (isSubscribed) {
                if (res.user.profile_picture) {
                  await makeDownloadUrl(res.user.profile_picture);
                }
                localStorage.setItem("@userData", JSON.stringify(res));
                localStorage.setItem("@displayName", displayName);
                setUser(user);
                setUserDisplayName(displayName);
                setUserData(res.user);
                setRedirect(null);
                setLoading(false);
                if (!isFooterLink(location.pathname)) {
                  history.push(getDefaultPageUrl(displayName.toString()));
                }
              }
            })
            .catch((error) => {
              auth.signOut();
              setUserData(null);
              setUser(null);
              localStorage.removeItem("@userData");
              localStorage.removeItem("@displayName");
              localStorage.removeItem("@prefLogin");
              setLoading(false);
              console.log(error);
            });
        } else {
          setLoading(false);
          setUserData(null);
        }
      } catch (error) {
        auth.signOut();
        setUserData(null);
        setUser(null);
        localStorage.removeItem("@userData");
        localStorage.removeItem("@displayName");
        localStorage.removeItem("@prefLogin");
        setLoading(false);
        console.log(error);
      }
    });

    API.getLocation()
      .then((res) => res.json())
      .then((res) => {
        dispatch({
          type: "SET_LOCATION",
          lat: res.latitude,
          long: res.longitude,
          country_code: res.country_code,
        });
      })
      .catch((err) => {
        console.log("geoError", err);
      });

    // https://ipapi.co/json

    return () => (isSubscribed = false);
  }, []);

  const makeDownloadUrl = async (path) => {
    setLoading(true);
    let gsReference = storage.refFromURL(
      "gs://medvatic-438af.appspot.com/" + path
    );
    try {
      const url_1 = await gsReference
        .getDownloadURL();
      console.log(url_1);
      dispatch({
        type: "SET_USER_PP",
        url: url_1,
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loader />;
  } else {
    return (
      <Route
        {...rest}
        render={(matchProps) => (
          <PatientLayout
            user={user}
            userData={userData}
            path={path}
            type={userDisplayName}
          >
            <Component
              type={userDisplayName}
              userData={userData}
              updateUserData={setUserData}
              user={user}
              title={title}
              {...matchProps}
            />
            <Modal
              show={prefModalOpen}
              onHide={() => {
                setprefModalOpen(false);
                rejector(new Error("No preference chosen"));
              }}
              // onHide={handleClose}
              backdrop="static"
              keyboard={false}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Choose Your Preference</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="account-title">
                  <h3 className="account-head">Login As</h3>
                </div>
              </Modal.Body>
              <div className="preference-buttons">
                <button
                  className="btn btn-primary preference-btn account-btn"
                  onClick={() => {
                    resolver(2);
                    setprefModalOpen(false);
                  }}
                  style={{ marginRight: "10px" }}
                >
                  Hospital Admin
                </button>
                <button
                  className="btn btn-primary preference-btn account-btn"
                  onClick={() => {
                    resolver(3);
                    setprefModalOpen(false);
                  }}
                  style={{ marginLeft: "10px" }}
                >
                  Doctor
                </button>
              </div>
            </Modal>
          </PatientLayout>
        )}
      />
    );
  }
};

export default PatientLayoutRoute;
