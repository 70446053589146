import { Formik } from "formik";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import BaseSelect from "react-select";
import * as Yup from "yup";
import Apilib from "../../api";
import RequiredSelect from "../../Helpers/requiredSelect";
import Notify from "../../Notify";
import Loader from "../Loader/Loader";
import Table from "../Table";
const Select = (props) => (
  <RequiredSelect
    {...props}
    SelectComponent={BaseSelect}
    options={props.options}
  />
);

const specialities = [
  { value: "Cardiologist", label: "Cardiologist" },
  { value: "Dentist", label: "Dentist" },
  { value: "Gynaecologist", label: "Gynaecologist" },
  { value: "Paediatrician", label: "Paediatrician" },
  { value: "Psychiatrists", label: "Psychiatrists" },
];

// const options = [

//   {
//     label: '<Fontawesome class="fas fa-briefcase-medical"></i>',
//     value: '<i class="fas fa-briefcase-medical"></i>',
//   },
//   {
//     label: "Icon2",
//     value: "Icon2",
//   },
//   {
//     label: "Icon3",
//     value: "Icon3",
//   },
//   {
//     label: "Icon4",
//     value: "Icon4",
//   },
// ];
export default class AddSpeciality extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: "",
      success: false,
      chain_name: "",
      createdSpecialities: [],

      // update test
      selectedSpeciality: {
        speciality: "",
        id: "",
      },

      // models state
      modalOpen: false,
      modelLoading: false,

      confirmModelLoading: false,
      confirmModalOpen: false,
    };
  }

  componentDidMount() {
    this.getSpeciality();
  }

  handleClose() {
    this.setState({
      modalOpen: false,
      selectedSpeciality: {
        speciality: "",
        id: "",
      },
    });
  }

  handleComfirmModalClose = () => {
    this.setState({
      confirmModalOpen: false,
      selectedSpeciality: {
        speciality: "",
        id: "",
      },
    });
  };

  onChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = async (e, { resetForm }) => {
    try {
      this.setState({
        error: "",
        loading: true,
      });
      let API = new Apilib();

      await API.addNewSpeciality(
        JSON.stringify({ speciality: this.state.selectedSpeciality.speciality })
      ).then(async (res) => {
        if (!res.ok) {
          let msg = await res.json();
          throw new Error(msg.message);
        }
      });

      Notify({
        alert: true,
        type: "success",
        title: "Speciality created",
      });
      resetForm();
      this.setState({
        selectedSpeciality: {
          speciality: "",
          id: "",
        },
      });
      this.getSpeciality();
    } catch (error) {
      console.log(error);
      this.setState(
        {
          loading: false,
        },
        () =>
          Notify({
            alert: true,
            type: "error",
            title: error.message,
          })
      );
    }
  };

  onSpecialitySelect(value) {
    let arr = [];
    value.map((val, index) => {
      arr.push(val.value);
    });
    this.setState({
      specialities: arr,
      specialitiesObj: value,
    });
  }

  getSpeciality = async () => {
    try {
      this.setState({
        loading: true,
      });
      const API = new Apilib();
      let specialities = await (await API.getSpecialities()).json();
      if (specialities.code === 200) {
        return this.setState({
          createdSpecialities: specialities.data,
          loading: false,
        });
      }
      return this.setState({
        loading: false,
        error: "Unable to fetch specialities",
        success: false,
      });
    } catch (error) {
      this.setState({
        loading: false,
        error: error.message,
        success: false,
      });
    }
  };

  handleSpecialityUpdate = async () => {
    try {
      const API = new Apilib();
      await API.updateSpeciality(
        JSON.stringify(this.state.selectedSpeciality)
      ).then(async (res) => {
        if (!res.ok) {
          let msg = await res.json();
          throw new Error(msg.message);
        }
      });
      this.setState(
        {
          modelLoading: false,
          modalOpen: false,
        },
        () => {
          Notify({
            alert: true,
            type: "success",
            title: "Update Successful",
          });
          this.setState({
            selectedSpeciality: {
              speciality: "",
              id: "",
            },
          });
          this.getSpeciality();
        }
      );
    } catch (error) {
      console.log(error);
      this.setState(
        {
          modelLoading: false,
        },
        () => {
          Notify({
            alert: true,
            type: "error",
            title: error.message,
          });
        }
      );
    }
  };

  handleSpecialityDelete = async () => {
    try {
      const API = new Apilib();
      await API.deleteSpeciality(
        JSON.stringify({ id: this.state.selectedSpeciality.id })
      );
      this.setState(
        {
          confirmModelLoading: false,
          confirmModalOpen: false,
          selectedSpeciality: {
            speciality: "",
            id: "",
          },
        },
        () => {
          Notify({
            alert: true,
            type: "success",
            title: "Delete Successful",
          });
          this.getSpeciality();
        }
      );
    } catch (error) {
      console.log(error);
      this.setState(
        {
          confirmModelLoading: false,
          confirmModalOpen: false,
        },
        () => {
          Notify({
            alert: true,
            type: "error",
            title: "Failed to delete",
          });
        }
      );
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.state.loading && <Loader />}
        <div className="row">
          <div className="col-sm-4 col-3">
            <h4 className="page-title">Add Speciality </h4>
          </div>
        </div>
        {this.state.error && (
          <div
            className="alert alert-danger alert-dismissible fade show"
            role="alert"
          >
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
              <span className="sr-only">Close</span>
            </button>
            {this.state.error}
          </div>
        )}
        {this.state.success && (
          <div
            className="alert alert-success alert-dismissible fade show"
            role="alert"
          >
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
              <span className="sr-only">Close</span>
            </button>
            Speciality added successfully
          </div>
        )}

        <div className="row">
          <div className="col-md-12">
            <Formik
              validationSchema={Yup.object().shape({
                speciality: Yup.string()
                  .required()
                  .min(3, "Speciality name should be of minimum 3 charachters")
                  .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for speciality name")
                  .label("Speciality name")
                  .trim(),
              })}
              onSubmit={this.onSubmit}
              initialValues={{ speciality: "" }}
            >
              {({
                handleSubmit,
                submitForm,
                values,
                errors,
                handleChange,
                setErrors,
                setFieldValue,
              }) => (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    this.setState({
                      selectedSpeciality: {
                        speciality: values.speciality.trim(),
                        id: this.state.selectedSpeciality.id,
                      },
                    });
                    submitForm();
                  }}
                >
                  <div className="row">
                    <div className="col-12">
                      <label>
                        Speciality Name<span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="form-group col-sm-6">
                      {/* <Select
                      required
                      isMulti
                      onChange={(val) => this.onSpecialitySelect(val)}
                      value={this.state.specialitiesObj}
                      options={specialities}
                    /> */}
                      <input
                        required
                        className="form-control"
                        placeholder="Enter the speciality"
                        type="text"
                        onBlur={() => {
                          if (!!values.speciality === false)
                            setErrors({ speciality: "" });
                        }}
                        onChange={(e) => {
                          this.setState({
                            selectedSpeciality: {
                              speciality: e.target.value,
                              id: this.state.selectedSpeciality.id,
                            },
                          });
                          setFieldValue("speciality", e.target.value);
                        }}
                        value={values.speciality}
                      />
                      <small className="form-text text-danger">
                        {errors?.speciality}
                      </small>
                    </div>







                    <div className="col-12">
                      <label>
                        Choose Icon<span className="text-danger">*</span>
                      </label>
                    </div>
                    <div className="form-group col-sm-6">

                      <select className="form-control" type="text">
                        <option>Select...</option>
                        <option data-icon="glyphicon glyphicon-eye-open" data-subtext="petrification">Doctor</option>

                      </select>

                    </div>
                    <div className="col-sm-6 form-group d-flex align-items-start">
                      <div className="text-center">
                        <button className="btn btn-primary submit-btn">
                          Create Speciality
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <div
                        className="alert alert-blue alert-dismissible fade show"
                        role="alert"
                        style={{
                          marginTop: 0,
                        }}
                      >
                        Note :- Speciality added here will be used in add doctor
                        section
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
        {!this.state.loading && (
          <Table
            tableOptions={{
              columnDefs: [{ orderable: false, targets: 1, width: "180px" }],
            }}
            columns={["Doctor Speciality", "Actions"]}
            rows={this.state.createdSpecialities.map((speciality) => (
              <tr>
                <td>{speciality.speciality}</td>
                <td>
                  <button
                    onClick={() =>
                      this.setState({
                        modalOpen: true,
                        selectedSpeciality: speciality,
                      })
                    }
                    className="btn btn-small btn-secondary mr-2"
                  >
                    <span
                      class="iconify"
                      data-icon="akar-icons:edit"
                      data-inline="false"
                      style={{
                        marginRight: ".5em",
                        marginTop: "-.1em",
                        display: "inline-block",
                      }}
                    ></span>
                    Edit
                  </button>
                  <button
                    onClick={() => {
                      this.setState({
                        confirmModalOpen: true,
                        selectedSpeciality: speciality,
                      });
                    }}
                    className="btn btn-small btn-danger mr-2"
                  >
                    <span
                      style={{
                        marginRight: ".5em",
                        marginTop: "-.1em",
                        display: "inline-block",
                      }}
                      class="iconify"
                      data-icon="ant-design:delete-twotone"
                      data-inline="false"
                    ></span>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          />
        )}
        <Modal
          show={this.state.modalOpen}
          onHide={() => this.handleClose()}
          animation={false}
        >
          {this.state.modalLoading && <Loader />}
          <Modal.Header closeButton>
            <Modal.Title>Speciality Update</Modal.Title>
          </Modal.Header>
          <Formik
            validationSchema={Yup.object().shape({
              speciality: Yup.string()
                .required()
                .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for speciality name")
                .min(3, "Speciality name should be of minimum 3 charachters")
                .label("Speciality name")
                .trim(),
            })}
            onSubmit={this.handleSpecialityUpdate}
            initialValues={{
              speciality: this.state.selectedSpeciality.speciality,
            }}
          >
            {({
              handleSubmit,
              submitForm,
              values,
              errors,
              handleChange,
              setErrors,
              setFieldValue,
            }) => (
              <>
                <Modal.Body>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Speciality Name</label>
                        <input
                          className="form-control"
                          placeholder="Enter the speciality"
                          type="text"
                          onChange={(e) => {
                            setFieldValue("speciality", e.target.value);
                          }}
                          onBlur={() => {
                            if (!!values.speciality === false)
                              setErrors({ speciality: "" });
                          }}
                          value={values.speciality}
                        />
                        <span className="form-text text-danger">
                          {errors.speciality}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>Icon</label>

                        <select className="form-control" type="text">
                          <option>Select...</option>
                          <option data-icon="glyphicon glyphicon-eye-open" data-subtext="petrification">Doctor</option>

                        </select>
                        <span className="form-text text-danger">
                          {errors.speciality}
                        </span>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => {
                      this.handleClose();
                    }}
                  >
                    Close
                  </Button>
                  <Button
                    disabled={this.state.modelLoading}
                    variant="primary"
                    onClick={() => {
                      this.setState({
                        selectedSpeciality: {
                          speciality: values.speciality.trim(),
                          id: this.state.selectedSpeciality.id,
                        },
                      });
                      submitForm();
                    }}
                  >
                    Update
                  </Button>
                </Modal.Footer>
              </>
            )}
          </Formik>
        </Modal>
        <Modal
          show={this.state.confirmModalOpen}
          onHide={() => this.handleConfirmModalClose()}
          animation={false}
        >
          {this.state.modalLoading && <Loader />}
          <Modal.Header closeButton>
            <Modal.Title>Delete Speciality</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Are you sure you want to delete{" "}
              <b>{this.state.selectedSpeciality.speciality}</b>?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                this.handleComfirmModalClose();
              }}
            >
              Close
            </Button>
            <Button
              disabled={this.state.confirmModelLoading}
              variant="danger"
              onClick={this.handleSpecialityDelete}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}
