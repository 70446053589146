import React, { Component } from "react";
import { Link } from "react-router-dom";
import Loader from "../Loader/Loader";
import Apilib from "../../api";
import { Modal, Button } from "react-bootstrap";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import { IconButton } from "@material-ui/core";
export default class PatientsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      modalLoading: false,
      patients: [],
      currentPatient: {},
      currentIndex: "",
      modalOpen: false,
      familyPatients:[]
    };
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({
      loading: true,
    });
    let API = new Apilib();
    API.listFamilyMembers(this.props.userData.uid)
      .then(res=>res.json())
      .then((res) => {
        if (this._isMounted) {

          let data = res.family_members;
          this.setState(
            {
              loading: false,
              familyPatients: [...data],
            },
            () => {
              if ($(".datatable").length > 0) {
                $(".datatable").DataTable({
                  order: [],
                });
              }
            }
          );
        }
      })
      .catch((error) => {
        if (this._isMounted) {
          this.setState({
            loading: false,
          });
        }
      });
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    } else {
      return (
        <React.Fragment>
          <div className="all-members">
            <div className="row ">
              <div className="col-sm-4 col-3 head-family-acc">
                <h4 className="page-title">Family Members</h4>
                <Link to="/requests" className="dropdown-req req-alternate">
                  Requests
                </Link>
                {/* <DropdownButton
                  id="dropdown-basic-button"
                  title="Requests"
                  className="dropdown-req"
                >
                  <Dropdown.ItemText>
                    Deep Mistry{" "}
                    <IconButton>
                      <ThumbUpIcon />
                    </IconButton>
                    <IconButton>
                      <ThumbDownIcon />
                    </IconButton>
                  </Dropdown.ItemText>
                  <Dropdown.ItemText>
                    John Snow{" "}
                    <IconButton>
                      <CheckIcon />
                    </IconButton>
                    <IconButton>
                      <CloseIcon />
                    </IconButton>
                  </Dropdown.ItemText>
                </DropdownButton> */}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="table-responsive">
                  <table className="table table-border table-striped custom-table datatable m-b-0">
                    <thead>
                      <tr>
                        <th>Full Name</th>
                        <th>Email Id</th>
                        <th>Relation</th>
                        <th>Blood Group</th>
                        <th>Appointments</th>
                      </tr>
                    </thead>
                    <tbody>
                          {this.state.familyPatients && this.state.familyPatients.map(familyPatient =>(<>
                            <tr>
                            <td>
                              <Link
                                to={{
                                  pathname: "/my-profile",
                                }}
                              >
                                {familyPatient.name}
                              </Link>
                            </td>
                            <td>{familyPatient.email}</td>
                            <td>{familyPatient.relation}</td>
                            <td>{familyPatient.bloodGroup}</td>
                            <td>
                          <Link to="#">
                            <button className="btn custom-btn btn-primary">
                              View Appointments
                            </button>
                          </Link>
                        </td>
                          </tr>
                          </>))}
                        </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}
