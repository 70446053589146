// jshint esversion:6

import React, { Component } from "react";
// import { Dropdown, NavDropdown, NavItem } from "react-bootstrap";

export default class Monitoring extends Component {
  render() {
    return (
      <>
        <section className="section services-banner row-middle">
          <div className="inner-bg"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="section-header text-center">
                  <h3
                    className="header-title monitoring-head"
                    // style="margin-top: 30px; color: #fff"
                  >
                    Medvatic Monitoring Services
                  </h3>
                  <div className="line"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="section section-features1"
          //   style="background-color: #d3d3d385"
        >
          <div className="container">
            <div className="row monitoring-row ">
              <div className="col-md-6 wrap-about pb-md-5 ftco-animate">
                <div className="heading-section mb-5 pl-md-5">
                  <div className="ml-md-5">
                    <h2 className="mb-4 monitoring-h2 ">HEART RATE</h2>
                    <div className="line monitoring-line "></div>
                  </div>
                </div>
                <div className="pl-md-5 ml-md-5 mb-5">
                  <p className="monitoring-p">
                    Heart rate sensor allows us to monitor and measure your
                    heart rate in real-time.
                  </p>
                </div>
              </div>

              <div className="col-md-6 features-img">
                <img
                  src="assets/img/rsz_bpm1-hero-1-jnc.png"
                  //   style="max-width: 60%; float: right"
                  className="img-fluid monitoring-img1"
                  alt="Feature"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="section section-features">
          <div className="container">
            <div className="row monitoring-row ">
              <div className="col-md-6 features-img">
                <img
                  src="assets/img/ihealth.png"
                  //   style="max-width: 80%; float: left"
                  className="img-fluid monitoring-img"
                  alt="Feature"
                />
              </div>
              <div className="col-md-6 wrap-about pb-md-5 ftco-animate">
                <div className="heading-section mb-5 pl-md-5">
                  <div className="ml-md-5">
                    <h2 className="mb-4 monitoring-h2">
                      BLOOD PRESSURE MONITOR
                    </h2>
                    <div className="line monitoring-line"></div>
                  </div>
                </div>
                <div className="pl-md-5 ml-md-5 mb-5">
                  <p className="monitoring-p">
                    Monitoring your blood pressure using smart devices let your
                    doctor monitor your blood pressure in your daily activity.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="section section-features1"
          //   style="background-color: #d3d3d385"
        >
          <div className="container">
            <div className="row monitoring-row ">
              <div className="col-md-6 wrap-about pb-md-5 ftco-animate">
                <div className="heading-section mb-5 pl-md-5">
                  <div className="ml-md-5">
                    <h2 className="mb-4 monitoring-h2">OXYGEN LEVEL</h2>
                    <div className="line monitoring-line"></div>
                  </div>
                </div>
                <div className="pl-md-5 ml-md-5 mb-5">
                  <p className="monitoring-p">
                    monitoring oxygen level closely lets your doctors keep
                    oxygen level within a specific range and plan a personalised
                    asthma action plan for people with asthma.
                  </p>
                </div>
              </div>

              <div className="col-md-6 features-img">
                <img
                  src="assets/img/airIhealth0.png"
                  //   style="max-width: 70%; margin-top: -70px; float: right"
                  className="img-fluid monitoring-img1"
                  alt="Feature"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="section section-features">
          <div className="container">
            <div className="row monitoring-row ">
              <div className="col-md-6 features-img">
                <img
                  src="assets/img/fitbit2.png"
                  //   style="max-width: 80%; float: left"
                  className="img-fluid monitoring-img"
                  alt="Feature"
                />
              </div>
              <div className="col-md-6 wrap-about pb-md-5 ftco-animate">
                <div className="heading-section mb-5 pl-md-5">
                  <div className="ml-md-5">
                    <h2 className="mb-4 monitoring-h2">
                      BLOOD PRESSURE MONITOR
                    </h2>
                    <div className="line monitoring-line"></div>
                  </div>
                </div>
                <div className="pl-md-5 ml-md-5 mb-5">
                  <p className="monitoring-p">
                    Monitoring your blood pressure using smart devices let your
                    doctor monitor your blood pressure in your daily activity.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="section section-features1"
          //   style="background-color: #d3d3d385"
        >
          <div className="container">
            <div className="row monitoring-row ">
              <div className="col-md-6 wrap-about pb-md-5 ftco-animate">
                <div className="heading-section mb-5 pl-md-5">
                  <div className="ml-md-5">
                    <h2 className="mb-4 monitoring-h2">SLEEP MONITOR</h2>
                    <div className="line monitoring-line"></div>
                  </div>
                </div>
                <div className="pl-md-5 ml-md-5 mb-5">
                  <p className="monitoring-p">
                    Sleep Monitor: By tracking your movements and your motion
                    using different smart devices as well as smartwatches or
                    Fitbit, your doctor can monitor your sleep behaviour.
                  </p>
                </div>
              </div>

              <div className="col-md-6 features-img1">
                <img
                  src="assets/img/fitbit3.png"
                  //   style="max-width: 70%; margin-top: -70px; float: right"
                  className="img-fluid monitoring-img"
                  alt="Feature"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="section section-features">
          <div className="container">
            <div className="row monitoring-row ">
              <div className="col-md-6 features-img">
                <img
                  src="assets/img/fitbit.png"
                  //   style="max-width: 80%; float: left"
                  className="img-fluid monitoring-img"
                  alt="Feature"
                />
              </div>
              <div className="col-md-6 wrap-about pb-md-5 ftco-animate">
                <div className="heading-section mb-5 pl-md-5">
                  <div className="ml-md-5">
                    <h2 className="mb-4 monitoring-h2">FITBIT</h2>
                    <div className="line monitoring-line"></div>
                  </div>
                </div>
                <div className="pl-md-5 ml-md-5 mb-5">
                  <p className="monitoring-p">
                    It tracks your day to day activities as well as exercise,
                    food and sleep to help your doctor to keep monitoring your
                    health.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="row ">
          <div className="col-12">
            <div className="see-all m-t-0  product-bottom">
              <p className="product-p"> WANT TO KNOW MORE? </p>
              <a
                type="button"
                href="#/Contact"
                className="btn btn-primary see-all-btn practitioner-button"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </>
    );
  }
}
