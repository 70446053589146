import React, { Component } from "react";
import ReactDOM from "react-dom";
import Downshift from "downshift";
import axios from "axios";

import "./style.css";

class NewSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
    };

    this.fetchMovies = this.fetchMovies.bind(this);
    this.inputOnChange = this.inputOnChange.bind(this);
  }

  inputOnChange(event) {
    if (!event.target.value) {
      return;
    }
    this.fetchMovies(event.target.value);
  }

  downshiftOnChange(item) {
    alert(`your favourite movie is ${item}`);
  }

  fetchMovies() {
    axios
      .get(
        "https://australia-southeast1-medvatic-438af.cloudfunctions.net/appointment/searchForAppointment?name=s"
      )
      .then((res) => {
        this.setState({ movies: res.data.results });
      });
  }

  render() {
    return (
      <Downshift
        onChange={this.downshiftOnChange}
        itemToString={(item) => (item ? item.title : "")}
      >
        {({
          selectedItem,
          getInputProps,
          getItemProps,
          highlightedIndex,
          isOpen,
          inputValue,
          getLabelProps,
        }) => (
          <div>
            <label
              style={{ marginTop: "1rem", display: "block" }}
              {...getLabelProps()}
            >
              Choose your favourite movie
            </label>{" "}
            <br />
            <input
              {...getInputProps({
                placeholder: "Search movies",
                onChange: this.inputOnChange,
              })}
            />
            {isOpen ? (
              <div className="downshift-dropdown">
                {this.state.items
                  .filter(
                    (item) =>
                      !inputValue ||
                      item.title
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                  )
                  .slice(0, 10)
                  .map((item, index) => (
                    <div
                      className="dropdown-item"
                      {...getItemProps({ key: index, index, item })}
                      style={{
                        backgroundColor:
                          highlightedIndex === index ? "lightgray" : "white",
                        fontWeight: selectedItem === item ? "bold" : "normal",
                      }}
                    >
                      {item.title}
                    </div>
                  ))}
              </div>
            ) : null}
          </div>
        )}
      </Downshift>
    );
  }
}
export default NewSearch;
