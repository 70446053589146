import React, { Component, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { sideNavs } from "../../Helpers/constants";

const ExpandOption = ({ label, icon, options }) => {
  const location = useLocation();
  const [collapseState, setcollapseState] = useState(false);

  const handleCollapsing = () => {
    setcollapseState(!collapseState);
  };

  return (
    <li>
      <Link to={null} >
        <div className="d-flex align-items-center" onClick={handleCollapsing}>
          <i className={`fa fa-${icon}`}></i>
          <span style={{ display: "inline-block" }}>{label}</span>
          <i
            style={{ marginLeft: "auto" }}
            className={`fa fa-${collapseState ? "angle-up" : "angle-down"}`}
          ></i>
        </div>
      </Link>
      <div className="sidebar-menu">
        {collapseState && <ul className="" style={{ padding: 0 }}>
          {options.map((option,index) => (
            <li className={location.pathname === option.link ? "active" : ""} key={index.toString()}>
              <Link
                to={option["link"]}
                style={{
                  fontSize: "0.9em",
                  marginLeft: "30px",
                  paddingLeft: "10px",
                }}
              >
                <i className={`fa fa-${option["icon"]}`}></i>
                <span>{option["label"]}</span>
              </Link>
            </li>
          ))}
        </ul>}
      </div>
    </li>
  );
};

export default class Sidebar extends Component {
  componentDidMount() {}

  render() {
    return (
      <div className="sidebar" id="sidebar">
        <div className="sidebar-inner slimscroll">
          <div id="sidebar-menu" className="sidebar-menu">
            <ul>
              {/* <li className="menu-title">Main</li> */}
              {this.props.type &&
                sideNavs[localStorage.getItem("@displayName") - 1].map(
                  (value, index) => {
                    if (value.isExpanding) {
                      return <ExpandOption {...value} />;
                    }
                    return (
                      <li
                        className={
                          this.props.path === value["link"] ? "active" : ""
                        }
                        key={index}
                      >
                        <Link to={value["link"]}>
                          <i className={`fa fa-${value["icon"]}`}></i>
                          <span>{value["label"]}</span>
                        </Link>
                      </li>
                    );
                  }
                )}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
