import { Formik } from "formik";
import React, { Component } from "react";
import * as Yup from "yup";
import Apilib from "../../api";
import Notify from "../../Notify";
import Loader from "../Loader/Loader";
export default class AddReceptionist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: "",
      success: false,
    };
  }

  onSubmit = (values, { resetForm }) => {
    let baseState = { ...this.state };
    this.setState({
      error: "",
      loading: true,
    });
    let API = new Apilib();
    let data = {
      ...this.state,
      ...values,
      type: 4,
      uid: this.props.userData.uid,
      hospitalId: this.props.userData.hospitalId,
      hospitalChainId: this.props.userData.hospitalChainId,
      password: "pwd@12",
    };
    delete data.formData;
    delete data.formIsValid;
    delete data.loading;
    delete data.error;
    delete data.success;
    API.create(JSON.stringify(data))
      .then((res) => {
        this.setState({
          loading: false,
        });
        if (res.ok) {
          resetForm();
          this.setState({
            loading: false,
            error: "",
            success: true,
          });
          Notify({
            alert: true,
            type: "success",
            title: "Receptionist added successfully",
          });
          this.props.history.push("/hospital/receptionists");
        } else {
          res.text().then((data) => {
            this.setState({
              error: JSON.parse(data).message,
            });
          });
        }
      })
      .catch((error) => {
        this.setState({
          error: error.message,
          loading: false,
        });
      });
  };


  render() {
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  
    // else {
    //   alert('Invalid phone number');
    // }

    return (

      <React.Fragment>
        {this.state.loading && <Loader />}
        <div className="row">
          <div className="col-md-12">
            <h4 className="page-title">Add Receptionist</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                mobile: "",
              }}
              validationSchema={Yup.object({
                firstName: Yup.string()
                  .matches(/^[a-zA-Z/']+$/, "Only alphabets allowed")
                  .min(3)
                  .required()
                  .label("First Name"),
                lastName: Yup.string()
                  .matches(/^[a-zA-Z/']+$/, "Only alphabets allowed")
                  .min(3)
                  .required()
                  .label("Last Name"),
                email: Yup.string().email().required().label("Email"),
                mobile: Yup.string()
               
            
                
                  .matches(phoneRegExp, /*alert('Maximum 15 digits allowed')*/ 'It is not valid')
                
              })}
              onSubmit={this.onSubmit}
            >
              {({
                handleSubmit,
                values,
                errors,
                handleChange,
                touched,
                setFieldTouched,
              }) => (
                <form onSubmit={handleSubmit}>
                  {this.state.error && (
                    <div
                      className="alert alert-danger alert-dismissible fade show"
                      role="alert"
                    >
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                        <span className="sr-only">Close</span>
                      </button>
                      {this.state.error}
                    </div>
                  )}
                  {this.state.success && (
                    <div
                      className="alert alert-success alert-dismissible fade show"
                      role="alert"
                    >
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                        <span className="sr-only">Close</span>
                      </button>
                      Receptionist added successfully
                    </div>
                  )}
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>
                          First Name <span className="text-danger">*</span>
                        </label>
                        <input
                          onFocus={() => setFieldTouched("firstName", true)}
                          name="firstName"
                          value={values.firstName}
                          onChange={handleChange("firstName")}
                          required
                          className="form-control"
                          type="text"
                        />
                        <span className="form-text text-danger">
                          {touched.firstName && errors.firstName}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>
                          Last Name <span className="text-danger">*</span>
                        </label>
                        <input
                          onFocus={() => setFieldTouched("lastName", true)}
                          name="lastName"
                          value={values.lastName}
                          onChange={handleChange("lastName")}
                          required
                          className="form-control"
                          type="text"
                        />
                        <span className="form-text text-danger">
                          {touched.lastName && errors.lastName}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>
                          Email <span className="text-danger">*</span>
                        </label>
                        <input
                          onFocus={() => setFieldTouched("email", true)}
                          name="email"
                          value={values.email}
                          onChange={handleChange("email")}
                          required
                          className="form-control"
                          type="email"
                        />
                        <span className="form-text text-danger">
                          {touched.email && errors.email}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label>
                          Mobile No.<span className="text-danger">*</span>
                        </label>
                        <input
                          onFocus={() => setFieldTouched("mobile", true)}
                          name="mobile"
                          value={values.mobile}
                          onChange={handleChange("mobile")}
                          required
                          className="form-control"
                          type="number"
                        />
                        <span className="form-text text-danger">
                          {touched.mobile && errors.mobile}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="m-t-20 text-center">
                    <button className="btn btn-primary submit-btn">
                      Add Receptionist
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
