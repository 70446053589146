// jshint esversion:6

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Api from "../../api";
import Notify from "../../Notify";
import { SplashLoader } from "../Loader/Loader";
import "./style.css";
var QRCode = require("qrcode.react");

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  table: {
    minWidth: 700,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(no, name, frequency, time, duration, quantity, comments) {
  return { no, name, frequency, time, duration, quantity, comments };
}

const rows = [
  createData(1, "54gddf", "MN - NT", "After - After", 20, 4),
  createData(2, "rgter", "MN - NT", "After - Before", 37, 5),
  createData(3, "dsfs", "MN - NT", "Before - Before", 24, 2),
  createData(4, "rtdrte", "MN - NT", "After - After", 67, 7),
  createData(5, "Odexa-m", "NT", "After", 49, 9),
];

export default function Prescriptions({ userData }) {
  const classes = useStyles();
  const { id: appointmentId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [appointment, setAppointment] = useState(null);

  const fetchAppointment = async (appointmentId) => {
    try {
      setIsLoading(true);
      let API = new Api();
      const appointmentDetails = await (
        await API.getAppointmentDetails(appointmentId)
      ).json();
      const doctorDetails = await (
        await API.getUserDetails(
          3,
          appointmentDetails.appointment_details.doctorId
        )
      ).json();
      setAppointment({ data: appointmentDetails, doctor: doctorDetails?.user });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      Notify({
        alert: true,
        title: "Failed to fetch prescription details",
        type: "error",
      });
    }
  };
  useEffect(() => {
    fetchAppointment(appointmentId);
    return () => {};
  }, [appointmentId]);

  if (isLoading) return <SplashLoader />;

  return (
    <>
      <ul className="newul2 container">
        <li className="newli vertical-height doc-info">
          <h4 className="doctor-name-presc">{`Dr. ${appointment.doctor.firstName} ${appointment.doctor.lastName}`}</h4>
          <p className="doctor-number-presc">
            Tel: {appointment.doctor.mobile}
          </p>
          <p className="doctor-number-presc">
            Email: {appointment.doctor.email}
          </p>
        </li>
        <li className="newli2">
          <QRCode
            className="qr-code"
            value={`${
              window.location.origin + "/#/prescriptions/"
            }${appointmentId}`}
          />
        </li>
        <li className="newli2 vertical-height">
          <h4 className="doctor-detail-presc">Reg Number</h4>
          <p className="doctor-detail-value">{appointment.doctor.regnumber}</p>
        </li>
        <li className="newli2 vertical-height">
          <h4 className="doctor-detail-presc">Qualification</h4>
          <p className="doctor-detail-value">
            {appointment.doctor.qualifications.join(", ")}
          </p>
        </li>
      </ul>
      <div className="second-component">
        <ul
          className="newul container"
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <li className="newli">
            <div>
              <h4 className="doctor-detail-presc">Patient Name</h4>
              <p className="doctor-detail-value">{`${userData.firstName} ${userData.lastName}`}</p>
            </div>
          </li>
          <li className="newli">
            <div>
              <h4 className="doctor-detail-presc">Gender</h4>
              <p
                style={{ textTransform: "capitalize" }}
                className="doctor-detail-value"
              >{`${userData.gender}`}</p>
            </div>
          </li>
          <li className="newli">
            <div>
              <h4 className="doctor-detail-presc">Height</h4>
              <p className="doctor-detail-value">{`${userData.height}`}</p>
            </div>
          </li>
          <li className="newli">
            <div>
              <h4 className="doctor-detail-presc">Weight</h4>
              <p className="doctor-detail-value">{`${userData.weight}`}</p>
            </div>
          </li>
          <li className="newli">
            <div>
              <h4 className="doctor-detail-presc">Appointment Day</h4>
              <p className="doctor-detail-value">
                {moment(
                  appointment.data.appointment_details.slot_date,
                  "DD/MM/YYYY"
                ).format("LL")}
              </p>
            </div>
          </li>
          <li className="newli">
            <div>
              <h4 className="doctor-detail-presc">Appointment Time</h4>
              <p className="doctor-detail-value">
                {moment(appointment.data.appointment_details.from).format("LT")}
                -{moment(appointment.data.appointment_details.to).format("LT")}
              </p>
            </div>
          </li>
        </ul>

        <div className="detailed-section">
          <div class="container">
            <div class="row">
              <div class="col-sm">
                <div className="accordian-section">
                  <div className={classes.root}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.heading}>
                          CHIEF COMPLAINTS
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          {!!appointment.data.appointment_details
                            .chiefComplaints ? (
                            appointment.data.appointment_details.chiefComplaints
                          ) : (
                            <span className="d-flex justify-content-center align-items-center w-100">
                              <span>-</span>
                            </span>
                          )}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography className={classes.heading}>
                          RELEVANT POINTS FROM HISTORY
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          {!!appointment.data.appointment_details
                            .pointsFromHistory ? (
                            appointment.data.appointment_details
                              .pointsFromHistory
                          ) : (
                            <span className="d-flex justify-content-center align-items-center w-100">
                              <span>-</span>
                            </span>
                          )}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography className={classes.heading}>
                          EXAMINATION/LAB FINDINGS
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          {!!appointment.data.appointment_details
                            .labFindings ? (
                            appointment.data.appointment_details.labFindings
                          ) : (
                            <span className="d-flex justify-content-center align-items-center w-100">
                              <span>-</span>
                            </span>
                          )}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography className={classes.heading}>
                          SUGGESTED INVESTIGATIONS
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          {!!appointment.data.appointment_details
                            .suggestedInvestigations ? (
                            appointment.data.appointment_details
                              .suggestedInvestigations
                          ) : (
                            <span className="d-flex justify-content-center align-items-center w-100">
                              <span>-</span>
                            </span>
                          )}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              </div>
              <div class="col-sm">
                <div className="diagnosis-section">
                  <div className="card diagnosed-card">
                    <p className="diagnosis-title">DIAGNOSIS</p>
                    <hr className="diagnosis-hr" />
                    <p className="diagnosis-info">
                      {!!appointment.data.appointment_details.diagnosis ? (
                        appointment.data.appointment_details.diagnosis
                      ) : (
                        <span className="d-flex justify-content-center align-items-center w-100 p-3">
                          <span>No diagnosis available</span>
                        </span>
                      )}
                    </p>
                  </div>
                  <div className="card diagnosed-card">
                    <p className="diagnosis-title">PROVISIONAL DIAGNOSIS</p>
                    <hr className="diagnosis-hr" />
                    <p className="diagnosis-info">
                      {!!appointment.data.appointment_details
                        .provisionalDiagnosis ? (
                        appointment.data.appointment_details
                          .provisionalDiagnosis
                      ) : (
                        <span className="d-flex justify-content-center align-items-center w-100 p-3">
                          <span>No provisional diagnosis available</span>
                        </span>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="container">
          <div class="row">
            <div class="col">
              <div className="medicine-table">
                <h4>Medicines:</h4>
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="centre">No</StyledTableCell>
                        <StyledTableCell align="centre">
                          Medicine
                        </StyledTableCell>
                        <StyledTableCell align="centre">
                          Frequency
                        </StyledTableCell>
                        <StyledTableCell align="centre">
                          Intake Time
                        </StyledTableCell>
                        <StyledTableCell align="centre">
                          Duration
                        </StyledTableCell>
                        <StyledTableCell align="centre">
                          Quantity
                        </StyledTableCell>
                        <StyledTableCell align="centre">
                          Comments
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {appointment.data.prescription_details.length === 0 && (
                        <tr className="d-flex justify-content-center align-items-center w-100 p-3">
                          <span>No medicines available</span>
                        </tr>
                      )}
                      {appointment.data.prescription_details
                        .reduce((acc, curr) => {
                          acc = [...acc, ...curr.medicines];
                          return acc;
                        }, [])
                        .map((med, index) =>
                          createData(
                            index + 1,
                            med.name,
                            med.intake_time,
                            med.am_bm_stat.map((stat) => (
                              <>
                                {stat} Meal
                                <br />
                              </>
                            )),
                            med.num_days,
                            med.qty,
                            med.subtitle
                          )
                        )
                        .map((row) => (
                          <StyledTableRow key={row.name}>
                            <StyledTableCell
                              component="th"
                              scope="row"
                              width={10}
                            >
                              {row.no}
                            </StyledTableCell>
                            <StyledTableCell component="th" scope="row">
                              {row.name}
                            </StyledTableCell>
                            <StyledTableCell align="centre">
                              {Object.keys(row.frequency)
                                .filter((key) => !!row.frequency[key])
                                .map((key) => (
                                  <>
                                    {key} - {row.frequency[key]}
                                    <br />
                                  </>
                                ))}
                            </StyledTableCell>
                            <StyledTableCell align="centre">
                              {row.time}
                            </StyledTableCell>
                            <StyledTableCell align="centre">
                              {row.duration}
                            </StyledTableCell>
                            <StyledTableCell align="centre">
                              {row.quantity}
                            </StyledTableCell>
                            <StyledTableCell align="centre">
                              {!!row.comments ? row.comments : "-"}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
