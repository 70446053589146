import MenuItem from "@material-ui/core/MenuItem";
import Select2 from "@material-ui/core/Select";
import csc from "country-state-city";
import { useFormik } from "formik";
import ProfilePicture from "@dsalvagni/react-profile-picture";
import "@dsalvagni/react-profile-picture/build/ProfilePicture.css";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BaseSelect from "react-select/creatable";
import * as Yup from "yup";
import Apilib from "../../api";
import { auth, storage } from "../../firebase";
import RequiredSelect from "../../Helpers/requiredSelect";
import Notify from "../../Notify";
import { useStateValue } from "../../StateProvider";
import Loader from "../Loader/Loader";
import "../Patient/style.css";

const Select = (props) => (
  <RequiredSelect
    {...props}
    SelectComponent={BaseSelect}
    options={props.options}
  />
);

const AddPatient = (props) => {
  const profilePictureRef = React.createRef();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [change_pp, setChange_pp] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [error, setError] = useState("");
  const [image, setImage] = useState("");
  const [pp_url, setpp_url] = useState("");
  const [country_id, setCountry_id] = useState("");
  const [state_id, setState_id] = useState("");
  const [city_id, setCity_id] = useState("");
  const [{ userPP }, dispatch] = useStateValue();

  const formik = useFormik({
    initialValues: {
      firstName: null,
      lastName: null,
      emergencyContactDetails: null,
      height: null,
      weight: null,
      address: null,
      postalcode: null,
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required()
        .min(3)
        .matches(
          /^[A-Za-z/' ]+$/,
          "First Name should contain alphabets and may contain '"
        )
        .label("First Name"),
      lastName: Yup.string()
        .required()
        .min(3)
        .matches(
          /^[A-Za-z/' ]+$/,
          "Last Name should contain alphabets and may contain '"
        )
        .label("Last Name"),
      emergencyContactDetails: Yup.string()
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(8)
        .label("Emergency Contact no"),
      height: Yup.number(),
      weight: Yup.number(),
      address: Yup.string().required().min(3).label("Address"),
      postalcode: Yup.string()
        .required()
        .min(4)
        .matches(/^[0-9]+$/, "Must be only digits")
        .label("Postal Code"),
    }),
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  const makeDownloadUrl = async (path) => {
    setLoading(true);
    let gsReference = storage.refFromURL(
      "gs://medvatic-438af.appspot.com/" + path
    );
    try {
      const url_1 = await gsReference.getDownloadURL();
      console.log(url_1);
      setpp_url(url_1);
      dispatch({
        type: "SET_USER_PP",
        url: url_1,
      });
      setChange_pp(false);
      setLoading(false);
    } catch (err) {
      setChange_pp(true);
      setLoading(false);
      Notify({
        alert: true,
        type: "error",
        title: err.message,
      });
    }
  };

  useEffect(() => {
    let API = new Apilib();
    auth.onAuthStateChanged((user) => {
      if (user) {
        setLoading(true);
        API.getUserDetails(user.displayName, user.uid)
          .then((res) => res.json())
          .then((res) => {
            console.log("ressss", res.user);
            setLoading(false);
            setUserDetails(res.user);
            // props.updateUserData(res.user);
            if (res.user.profile_picture) {
              makeDownloadUrl(res.user.profile_picture);
            }
          })
          .catch((err) => {
            setLoading(false);
            console.log(err);
          });
      } else {
        props.history.push("/login");
      }
    });
    let data = csc.getAllCountries();
    setCountries(data);
  }, []);

  useEffect(() => {
    if (userDetails)
      formik.setValues({
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        emergencyContactDetails: userDetails.emergencyContactDetails,
        height: !!userDetails.height ? userDetails.height.split(" ")[0] : "",
        weight: !!userDetails.weight ? userDetails.weight.split(" ")[0] : "",
        address: userDetails.address,
        postalcode: userDetails.postalcode,
      });
    return () => {};
  }, [userDetails]);

  useEffect(() => {
    if (userDetails) {
      for (let key of countries) {
        if (userDetails.country && key.name === userDetails.country) {
          setCountry_id(key.id);
          let stateData = csc.getStatesOfCountry(key.id);
          setStates(stateData);
        }
      }
    }
  }, [userDetails]);

  useEffect(() => {
    if (userDetails) {
      for (let key of states) {
        if (userDetails.State && key.name === userDetails.State) {
          setState_id(key.id);
          let cityData = csc.getCitiesOfState(key.id);
          setCities(cityData);
        }
      }
    }
  }, [states]);

  useEffect(() => {
    if (userDetails) {
      for (let key of cities) {
        if (userDetails.city && key.name === userDetails.city) {
          setCity_id(key.id);
        }
      }
    }
  }, [cities]);

  const handleUpload = (e) => {
    e.preventDefault();
    const PP = profilePictureRef.current;
    const imageData = PP.getData();
    const file = imageData.file;
    const imageAsDataURL = PP.getImageAsDataUrl(1);
    console.log("file", PP);

    const ref = storage.ref();
    if (imageAsDataURL) {
      const name = userDetails.uid;
      const path = "profile_images/doctors/" + name;
      setpp_url(imageAsDataURL);
      const task = ref.child(path).putString(imageAsDataURL, "data_url");
      task
        .then(() => {
          setLoading(true);
          setError("");
          let API = new Apilib();
          let data = {
            ...userDetails,
            type: 3,
            uid: props.user.uid,
            profile_picture: path,
          };
          API.updateUser(JSON.stringify(data))
            .then(async (res) => {
              setLoading(false);

              if (res.ok) {
                setLoading(false);
                setChange_pp(false);
                await makeDownloadUrl(path);
                Notify({
                  alert: true,
                  type: "success",
                  title: "Profile picture uploaded successfully!",
                });
              } else {
                res.text().then((data) => {
                  setError(JSON.parse(data).message);
                  setChange_pp(false);
                  Notify({
                    alert: true,
                    type: "error",
                    title: JSON.parse(data).message,
                  });
                });
              }
            })
            .catch((error) => {
              setError(error.message);
              setChange_pp(false);
              setLoading(false);
            });
        })
        .catch((err) => {
          setLoading(false);
          setChange_pp(false);
          Notify({
            alert: true,
            type: "error",
            title: err.message,
          });
        });
    }
  };

  const onChange = (e) => {
    let inputName = e.target.name;
    let data = { ...userDetails };
    let value = "";

    switch (inputName) {
      case "country":
        console.log("country", countries);
        for (let key of countries) {
          if (key.id === e.target.value) {
            value = key.name;
            setCountry_id(e.target.value);
          }
        }
        let stateData = csc.getStatesOfCountry(e.target.value);
        setStates(stateData);
        setState_id("");
        setCity_id("");
        break;

      case "State":
        for (let key of states) {
          if (key.id === e.target.value) {
            value = key.name;
            setState_id(e.target.value);
          }
        }
        let cityData = csc.getCitiesOfState(e.target.value);
        setCities(cityData);
        setCity_id("");
        break;

      case "city":
        for (let key of cities) {
          if (key.id === e.target.value) {
            value = key.name;
            setCity_id(e.target.value);
          }
        }
        break;

      // no default
    }
    if (
      inputName === "country" ||
      inputName === "State" ||
      inputName === "city"
    ) {
      data[inputName] = value;
    } else if (inputName === "weight") {
      data[inputName] = e.target.value + " kg";
    } else if (inputName === "height") {
      data[inputName] = e.target.value + " cm";
    } else {
      data[inputName] = e.target.value;
    }
    setUserDetails(data);
  };

  const onSelect = (val) => {
    let formObj = { ...userDetails };
    // console.log("Initial formobj--->", formObj);
    console.log("val--->", val);
    let arr = [];
    if (val && val.length) {
      val.map((value, index) => {
        arr.push(value.value);
      });
    }
    formObj["allergies"] = arr;
    setUserDetails(formObj);
    // console.log("formdata", formObj, val);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!formik.isValid) return;
    setError("");
    setLoading(true);

    let API = new Apilib();
    let data = {
      ...userDetails,
      type: 3,
      uid: props.user.uid,
    };
    delete data.loading;
    delete data.error;
    delete data.success;
    API.updateUser(JSON.stringify(data))
      .then((res) => {
        setLoading(false);
        if (res.ok) {
          setLoading(false);
          setUserDetails(null);
          setError("");
          setSuccess(true);
          props.history.push("/my-profile");
        } else {
          res.text().then((data) => {
            setError(JSON.parse(data).message);
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        setError(error.message);
      });
  };

  let allBloodGroups = [
    {
      id: "1",
      name: "A+",
    },
    {
      id: "2",
      name: "A-",
    },
    {
      id: "3",
      name: "B+",
    },
    {
      id: "4",
      name: " B-",
    },
    {
      id: "5",
      name: "AB+",
    },
    {
      id: "6",
      name: "AB-",
    },
    {
      id: "7",
      name: "O+",
    },
    {
      id: "8",
      name: "O-",
    },
  ];
  let arr = [
    "Brazil nuts",
    "Almonds",
    "Cashews",
    "Macadamia nuts",
    "Pistachios",
    "Pine nuts",
    "Walnuts",

    "Cow’s Milk Allergies",

    "Milk",
    "Milk powder",
    "Cheese",
    "Butter",
    "Margarine",
    "Yogurt",
    "Cream",
    "Ice cream",

    "Shellfish Allergies",

    "Shrimp",
    "Prawns",
    "Crayfish",
    "Lobster",
    "Squid",
    "Scallops",

    "Peanuts Allergies",

    "Eggs Allergies",

    "Fish Allergies",

    "Soy Allergies",

    "Dog Allergies",

    "Pollen Allergies",

    "Pet Hair Allergies",

    "Dust Mites Allergies",

    "Insect bites Allergies",

    "Wheat Allergies",

    "  Antibiotic Allergies",
  ];
  let allegies = arr.map((item) => {
    return {
      value: item,
      label: item,
    };
  });
  let bloodGroup = null;
  if (allBloodGroups.length !== 0) {
    bloodGroup = allBloodGroups.map((item) => {
      return (
        <MenuItem key={item.id} value={item.name}>
          {item.name}
        </MenuItem>
      );
    });
  }

  let allergies = [];
  if (userDetails) {
    if (userDetails.allergies && userDetails.allergies.length) {
      userDetails.allergies.map((allergy, i) => {
        allergies.push({
          value: allergy,
          label: allergy,
        });
      });
    }
  }
  let button = change_pp ? (
    <button
      onClick={(e) => {
        e.preventDefault();
        handleUpload(e);
      }}
      className="btn custom-btn btn-primary"
    >
      Save Image
    </button>
  ) : (
    <button
      onClick={(e) => {
        e.preventDefault();
        setChange_pp(true);
      }}
      className="btn custom-btn btn-primary"
    >
      Change Profile Pic
    </button>
  );

  let countriesMenu = null;
  if (countries && countries.length !== 0) {
    countriesMenu = countries.map((item) => {
      return (
        <MenuItem key={item.id} value={item.id}>
          {item.name}
        </MenuItem>
      );
      // console.log(item);
    });
  }

  let statesMenu = null;
  if (states && states.length !== 0) {
    statesMenu = states.map((item) => {
      return (
        <MenuItem key={item.id} value={item.id}>
          {item.name}
        </MenuItem>
      );
      // console.log(item);
    });
  }

  let citiesMenu = null;
  if (cities && cities.length !== 0) {
    citiesMenu = cities.map((item) => {
      return (
        <MenuItem key={item.id} value={item.id}>
          {item.name}
        </MenuItem>
      );
      // console.log(item);
    });
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-header profile-header">
        <div className="content profile-content edit-profile">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h4 className="page-title about-doctor">Edit Profile</h4>
              </div>
            </div>
            {userDetails ? (
              <div className="row">
                <div className="col-md-10 mx-auto">
                  <form
                    onSubmit={(e) => {
                      if (
                        document.activeElement.classList.contains(
                          "eXEoGG26iwSi6UiZD8FI6"
                        )
                      ) {
                        e.preventDefault();
                        return;
                      }
                      onSubmit(e);
                    }}
                  >
                    {error && (
                      <div
                        className="alert alert-danger alert-dismissible fade show"
                        role="alert"
                      >
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                          <span className="sr-only">Close</span>
                        </button>
                        {error}
                      </div>
                    )}
                    {success && (
                      <div
                        className="alert alert-success alert-dismissible fade show"
                        role="alert"
                      >
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                          <span className="sr-only">Close</span>
                        </button>
                        Profile Updated successfully
                      </div>
                    )}
                    <div className="row">
                      <div className="col-sm-12 row p-0 m-0">
                        <div className="col-sm-3">
                          <div
                            className="form-group"
                            style={{ textAlign: "center" }}
                          >
                            {change_pp ? (
                              <ProfilePicture
                                minImageSize={0}
                                ref={profilePictureRef}
                                useHelper={true}
                                frameFormat="rounded-square"
                                image={pp_url !== "" ? pp_url : ""}
                              />
                            ) : (
                              <img className="Profile_pic" src={pp_url} />
                            )}
                            {button}
                          </div>
                        </div>
                        <div className="col-sm-9 row pr-0 m-0">
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>
                                First Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                name="firstName"
                                value={userDetails.firstName}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "firstName",
                                    e.target.value
                                  );
                                  onChange(e);
                                }}
                                required
                                className="form-control"
                                type="text"
                              />
                              <small className="form-text text-danger">
                                {formik.errors.firstName}
                              </small>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>
                                Last Name <span className="text-danger">*</span>
                              </label>
                              <input
                                name="lastName"
                                value={userDetails.lastName}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "lastName",
                                    e.target.value
                                  );
                                  onChange(e);
                                }}
                                required
                                className="form-control"
                                type="text"
                              />
                              <small className="form-text text-danger">
                                {formik.errors.lastName}
                              </small>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>Phone No.</label>
                              <input
                                name="phoneno"
                                readOnly
                                value={userDetails.mobile}
                                onChange={(e) => onChange(e)}
                                required
                                className="form-control"
                                type="text"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>Email</label>
                              <input
                                name="email"
                                readOnly
                                value={userDetails.email}
                                onChange={(e) => onChange(e)}
                                required
                                className="form-control"
                                type="text"
                              />
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>Hospital Name</label>
                              <input
                                name="emergencyContactDetails"
                                value={userDetails.hospitalName}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "emergencyContactDetails",
                                    e.target.value
                                  );
                                  onChange(e);
                                }}
                                disabled
                                className="form-control"
                                type="phone"
                              />
                              <small className="form-text text-danger">
                                {formik.errors.emergencyContactDetails}
                              </small>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label>Experience</label>
                              <input
                                name="emergencyContactDetails"
                                value={userDetails.experience}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "emergencyContactDetails",
                                    e.target.value
                                  );
                                  onChange(e);
                                }}
                                disabled
                                className="form-control"
                                type="text"
                              />
                              <small className="form-text text-danger">
                                {formik.errors.emergencyContactDetails}
                              </small>
                            </div>
                          </div>
                          {/* <div className="col-sm-6">
                            <div className="form-group">
                              <label>
                                Blood Group{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Select2
                                required
                                fullWidth
                                name="bloodgroup"
                                onChange={(e) => onChange(e)}
                                value={userDetails.bloodgroup}
                              >
                                {bloodGroup}
                              </Select2>
                            </div>
                          </div>
                        </div>
                      </div> */}

                          {/* <div className="col-sm-12 row">
                                                        
                                                    </div> */}
                          {/* 
                      <div className="col-sm-6">
                        <div className="form-group">
                          <div className="d-flex flex-row justify-content-start ">
                            <div>
                              <label>Height</label>
                            </div>
                            <div
                              style={{
                                marginLeft: "10px",
                                backgroundColor: "#d4edda",
                                width: "60px",
                                height: "25px",
                                borderColor: "#c3e6cb",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <span>In CM</span>
                            </div>
                          </div>
                          <input
                            name="height"
                            value={formik.values.height}
                            onChange={(e) => {
                              formik.setFieldValue("height", e.target.value);
                              onChange(e);
                            }}
                            step="any"
                            className="form-control"
                            type="number"
                          />
                          <small className="form-text text-danger">
                            {formik.errors.height}
                          </small>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <div className="d-flex flex-row justify-content-start ">
                            <div>
                              <label>Weight</label>
                            </div>
                            <div
                              style={{
                                marginLeft: "10px",
                                backgroundColor: "#d4edda",
                                width: "60px",
                                height: "25px",
                                borderColor: "#c3e6cb",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <span>In KG</span>
                            </div>
                          </div>
                          <input
                            name="weight"
                            value={formik.values.weight}
                            onChange={(e) => {
                              console.log(formik.values);
                              formik.setFieldValue("weight", e.target.value);
                              onChange(e);
                            }}
                            step="any"
                            className="form-control"
                            type="number"
                          />
                          <small className="form-text text-danger">
                            {formik.errors.weight}
                          </small>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>Allergies</label>
                          {/* <input name="bmr" value={state.userDetails.bmr} onChange={(e) => onChange(e)} className="form-control"  type="text"/> */}
                        </div>
                      </div>
                    </div>
                    <div className="m-t-20 text-center">
                      <Link to="/my-profile">
                        <button className="btn custom-btn btn-secondary mr-3">
                          Close
                        </button>
                      </Link>
                      <button
                        className="btn custom-btn btn-primary"
                        type="submit"
                      >
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddPatient;
