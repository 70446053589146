import { DownOutlined, LinkOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import "datatables.net-dt/css/jquery.dataTables.min.css";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import $ from "jquery";
import "jquery/dist/jquery.min.js";
import moment from "moment";
import React, { Component, useEffect, useState } from "react";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import Apilib from "../../api";
import { firestore, storage } from "../../firebase";
import Notify from "../../Notify";
import Loader from "../Loader/Loader";
import "./doctorStyles.css";
import { UploadOutlined } from "@ant-design/icons";
import { Button as AntButton, Upload } from "antd";
import { uuid } from "uuidv4";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

let allowedFiles = ".pdf,.doc,.docx,.jpg,.png,.jpeg"; // feature moved to editappointment..need to clean this code later

const JoinButton = ({ appointment, openVideoCall, userData }) => {
  const [isEnabled, setIsEnabled] = useState(
    moment().isSameOrAfter(appointment.from) &&
      moment().isSameOrBefore(appointment.to)
  );

  useEffect(() => {
    const timeInterval = setInterval(() => {
      if (
        moment().isSameOrAfter(appointment.from) &&
        moment().isSameOrBefore(appointment.to)
      )
        setIsEnabled(true);
    }, 60000);
    return () => {
      clearInterval(timeInterval);
    };
  }, []);

  if (!isEnabled)
    return (
      <OverlayTrigger
        placement="right"
        overlay={
          <Tooltip id="tooltip-disabled">
            {moment().isAfter(appointment.to)
              ? "Your meeting was at"
              : "Your can join this meeting on"}{" "}
            {moment(appointment.from).format("MMMM D, h:mm a")}
          </Tooltip>
        }
      >
        <span className="d-inline-block">
          <button
            style={{ cursor: "not-allowed" }}
            // className="btn btn-primary text-white mt-2 px-3 btn-rounded disabled"
            className="btn btn-primary text-white mt-2 px-3 btn-rounded"
          >
            Join Now
          </button>
        </span>
      </OverlayTrigger>
    );

  return (
    <button
      onClick={(e) => {
        window.open(
          window.location.origin +
            "/#/videocall?meetingid=" +
            appointment.meetingid +
            "&appointmentid=" +
            appointment.appointmendId +
            "&uid=" +
            userData.uid,
          "_blank"
        );
      }}
      className="btn btn-primary text-white mt-2 px-3 btn-rounded"
    >
      Join Now
    </button>
  );
};

function validateFileSize(file) {
  let fileSize = file.size / 1024 / 1024;
  if (fileSize > 5) {
    Notify({
      alert: true,
      type: "error",
      title: "Sorry, Max filesize allowed is 5MB",
    });
    return false;
  } else {
    return true;
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={index}
      aria-labelledby={index}
      {...other}
    >
      {children}
    </div>
  );
}

const FileUpload = ({ appointment, userData, setLoading }) => {
  const [FileList, setFileList] = useState(
    appointment.doctor_docs
      ? appointment.doctor_docs.map((doc) => ({
          name: doc.name,
          uid: doc.id,
          status: "done",
          url: doc.publicUrl,
        }))
      : []
  );
  const fileUploadProps = {
    onChange: (info) => {
      console.log(info, "on change");
      let fileList = [...info.fileList];
      fileList = fileList
        .filter((file) => Object.keys(file).includes("status"))
        .map((file) => {
          if (file.response) {
            file.url = file.response.data.publicUrl;
            file.uid = file.response.data.id;
          }
          return file;
        });

      setFileList(fileList);
    },
    accept: allowedFiles,
    beforeUpload: (file, fileList) => {
      console.log(fileList);
      return validateFileSize(file);
    },
    onRemove: async (file) => {
      try {
        setLoading(true);
        let API = new Apilib();
        let res = await API.deleteDoctorAppointmentReport(
          JSON.stringify({
            id: appointment.appointmendId,
            fileId: file.uid,
          })
        );
        if (!res.ok) {
          throw new Error("Failed to delete file " + file.name);
        }
        Notify({
          alert: true,
          type: "success",
          title: "File deleted successfully",
        });
        setLoading(false);
        return true;
      } catch (error) {
        Notify({
          alert: true,
          type: "error",
          title: error.message,
        });
        setLoading(false);
        return false;
      }
    },
    customRequest: async ({
      action,
      data,
      file,
      filename,
      headers,
      onError,
      onProgress,
      onSuccess,
      withCredentials,
    }) => {
      try {
        let chainId = userData.hospitalChainId;
        let hospitalId = appointment.hospitalId;
        let appId = appointment.appointmendId;
        const newFileName = uuid() + "-" + file.name;
        const filePath = `/${chainId}/${hospitalId}/appointments/${appId}/doctorDocs/${newFileName}`;
        const uploadTask = storage.ref(filePath).put(file, {
          contentDisposition: `inline; filename="${file.name}"`,
        });
        uploadTask.on(
          "state_changed",
          (snapShot) => {
            onProgress(
              {
                percent:
                  (snapShot.bytesTransferred / snapShot.totalBytes) * 100,
              },
              file
            );
          },
          (err) => {
            onError(err);
            console.log(err);
          },
          async () => {
            try {
              let data = {};
              data["id"] = appId;
              data["filePath"] = filePath;
              data["fileName"] = file.name;
              let API = new Apilib();
              const publicUrl = await storage
                .refFromURL("gs://medvatic-438af.appspot.com" + filePath)
                .getDownloadURL();
              data["publicUrl"] = publicUrl;
              await API.addDoctorAppointmentReport(JSON.stringify(data))
                .then((res) => {
                  if (res.ok) {
                    return res.json();
                  }
                  throw new Error("Failed to upload file!");
                })
                .then((res) => {
                  onSuccess(res, file);
                })
                .catch((error) => {
                  Notify({
                    alert: true,
                    type: "error",
                    title: "Failed to upload file!",
                  });
                  onError(error);
                });
            } catch (error) {
              Notify({
                alert: true,
                type: "error",
                title: "Failed to upload file!",
              });
              onError(error);
            }
          }
        );
      } catch (error) {
        Notify({
          alert: true,
          type: "error",
          title: "Failed to upload file!",
        });
        onError(error);
      }
    },
  };
  return (
    <div
      style={{
        maxWidth: 200,
      }}
    >
      <Upload {...fileUploadProps} listType="text" fileList={FileList}>
        <AntButton icon={<UploadOutlined />}>Upload</AntButton>
      </Upload>
    </div>
  );
};

export default class AppointmentsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      appointments: [],
      upcomming: [],
      today: [],
      past: [],
      modalOpen: false,
      readOnly: false,
      fileModalOpen: false,
      fileDeleteModalOpen: false,
      reportDeleteModalOpen: false,
      modalLoading: false,
      currentAppointment: {},
      inputFileData: "",
      fileExtError: "",
      fileSizeError: "",
      currentAppId: "",
      success: false,
      tab: "one",
    };
    console.log("props", props);
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;
    this.fetchAppointments(true);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  fetchAppointments(flag) {
    this.setState({
      loading: true,
    });
    let API = new Apilib();
    API.listAppointmentsDoctor(
      this.props.userData.hospitalId,
      this.props.userData.uid,
      localStorage.getItem("@displayName")
    )
      .then((res) => res.json())
      .then((res) => {
        console.log("Appointment", res.appointments);
        if (this._isMounted) {
          let sorted = res.appointments;
          sorted = sorted.sort((a, b) => {
            return b.created_at - a.created_at;
          });

          let date = new Date();
          date.setHours(0, 0, 0, 0);
          let startDate = date.getTime();
          date.setHours(23, 59, 59, 0);
          let endDate = date.getTime();

          let upcommingArr = [];
          let todayArr = [];
          let pastArr = [];

          sorted.forEach((doc) => {
            if (doc.from >= startDate && doc.from <= endDate) {
              todayArr.push(doc);
            } else if (doc.from < startDate) {
              pastArr.push(doc);
            } else if (doc.from > endDate) {
              upcommingArr.push(doc);
            }
          });
          this.setState(
            {
              loading: false,
              appointments: sorted,
              upcomming: upcommingArr,
              today: todayArr,
              past: pastArr,
            },
            () => {
              if (flag) {
                if ($(".datatable").length > 0) {
                  $(".datatable").DataTable({
                    order: [],
                  });
                }
              }
            }
          );
        }
      })
      .catch((error) => {
        if (this._isMounted) {
          this.setState({
            loading: false,
          });
        }
      });
  }

  updateData = (e) => {
    e.preventDefault();
    let data = { ...this.state.currentAppointment };
    data["id"] = this.state.currentAppointment.appointmendId;
    data["chiefComplaints"] = this.state.currentAppointment.chiefComplaints;
    data["pointsFromHistory"] = this.state.currentAppointment.pointsFromHistory;
    data["labFindings"] = this.state.currentAppointment.labFindings;
    data["suggestedInvestigations"] =
      this.state.currentAppointment.suggestedInvestigations;
    this.setState({
      modalLoading: true,
    });
    let API = new Apilib();
    API.updateAppointment(JSON.stringify(data))
      .then((res) => {
        this.setState({
          modalLoading: false,
        });
        if (res.ok) {
          this.fetchAppointments();
          this.setState({
            modalLoading: false,
            currentAppointment: {},
            modalOpen: false,
            success: true,
          });
        } else {
          res.text().then((data) => {
            this.setState({
              modalOpen: false,
              error: JSON.parse(data).message,
            });
          });
        }
      })
      .catch((error) => {
        this.setState({
          error: error.message,
          loading: false,
        });
      });
  };

  deleteAppointment(currentAppointment) {
    const API = new Apilib();
    let data = {
      id: currentAppointment.appointmendId,
    };
    this.setState({
      loading: true,
    });
    API.deleteAppointment(JSON.stringify(data))
      .then((res) => res.json())
      .then((res) => {
        this.fetchAppointments();
        this.setState({
          show: false,
          currentAppointment: {},
        });
        Notify({
          alert: true,
          type: "success",
          title: "Deleted successfully",
        });
      })
      .catch((err) => {
        this.setState({
          show: false,
          loading: false,
          currentAppointment: {},
        });
        Notify({
          alert: true,
          type: "error",
          title: err.message,
        });
      });
  }

  handleDeleteOpen(e, appointment) {
    e.preventDefault();
    this.setState({
      currentAppointment: appointment,
      error: null,
      success: false,
    });
  }

  handleOpen(e, appointment) {
    // e.preventDefault();
    this.setState({
      //   modalOpen: true,
      currentAppointment: appointment,
      error: null,
      success: false,
    });
  }

  validateFileSize(file) {
    let fileSize = file.size / 1024 / 1024;
    if (fileSize > 5) {
      this.setState(
        {
          fileSizeError: "Sorry, Max filesize allowed is 5MB",
        },
        () => {
          return false;
        }
      );
    } else {
      return true;
    }
  }

  validateFileExtension(file) {
    var _validFileExtensions = allowedFiles.split(",");
    var sFileName = file.name;
    if (sFileName.length > 0) {
      var blnValid = false;
      for (var j = 0; j < _validFileExtensions.length; j++) {
        var sCurExtension = _validFileExtensions[j];
        if (
          sFileName
            .substr(
              sFileName.length - sCurExtension.length,
              sCurExtension.length
            )
            .toLowerCase() == sCurExtension.toLowerCase()
        ) {
          blnValid = true;
          break;
        }
      }

      if (!blnValid) {
        this.setState(
          {
            fileExtError:
              "Sorry, " +
              sFileName +
              " is invalid, allowed extensions are: " +
              _validFileExtensions.join(", "),
          },
          () => {
            return false;
          }
        );
      } else {
        return true;
      }
    }
  }

  handleFileChange(e) {
    // console.log(e.target.files[0]);
    let file = e.target.files[0];
    this.setState({
      fileExtError: "",
      fileSizeError: "",
    });
    if (this.validateFileExtension(file)) {
      if (this.validateFileSize(file)) {
        this.setState({
          inputFileData: file,
        });
      }
    }
  }

  uploadFile() {
    if (
      this.state.fileExtError == "" &&
      this.state.fileSizeError == "" &&
      this.state.inputFileData !== ""
    ) {
      ///chainid/hospitalid/appointments/appointmentid/prescriptions

      let chainId = this.props.userData.hospitalChainId;
      let hospitalId = this.props.userData.hospitalId;
      let appId = this.state.currentAppId;

      let file = this.state.inputFileData;
      this.setState(
        {
          modalLoading: true,
        },
        () => {
          const uploadTask = storage
            .ref(
              `/${chainId}/${hospitalId}/appointments/${appId}/prescriptions/${file.name}`
            )
            .put(file);

          uploadTask.on(
            "state_changed",
            (snapShot) => {
              console.log(snapShot);
            },
            (err) => {
              console.log(err);
            },
            () => {
              let data = {};
              data["id"] = appId;
              data[
                "prescription_path"
              ] = `/${chainId}/${hospitalId}/appointments/${appId}/prescriptions/${file.name}`;
              let API = new Apilib();
              API.updateAppointment(JSON.stringify(data))
                .then((res) => {
                  this.setState({
                    modalLoading: false,
                  });
                  if (res.ok) {
                    let dataApp = {
                      ...this.state.currentAppointment,
                    };
                    dataApp[
                      "prescription_path"
                    ] = `/${chainId}/${hospitalId}/appointments/${appId}/prescriptions/${file.name}`;

                    this.setState({
                      fileModalOpen: false,
                      fileUploaded: true,
                      currentAppId: "",
                    });
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          );
        }
      );
    }
  }

  handleFileModalOpen(e, appointmendId) {
    e.preventDefault();
    this.setState({
      fileModalOpen: true,
      currentAppId: appointmendId,
    });
  }

  handleFiledeleteModal(e, appointmendId, appointment) {
    e.preventDefault();
    this.setState({
      fileDeleteModalOpen: true,
      currentAppId: appointmendId,
      currentAppointmentToDelete: appointment.id,
    });
  }

  handleReportdeleteModal(e, appointmendId, appointment) {
    e.preventDefault();
    this.setState({
      reportDeleteModalOpen: true,
      currentAppId: appointmendId,
      currentAppointmentToDelete: appointment.id,
    });
  }

  handleClose() {
    this.setState({
      modalOpen: false,
      currentAppointment: {},
    });
  }

  handleFileModalClose() {
    this.setState({
      fileModalOpen: false,
      inputFileData: "",
      fileExtError: "",
      fileSizeError: "",
      currentAppId: "",
    });
  }
  //delete files
  async handleDeleteAttachment(currentAppId) {
    console.log(currentAppId);
    try {
      var desertRef = storage.ref(currentAppId);
      firestore
        .doc("appointments/" + this.state.currentAppointmentToDelete)
        .update({ prescription_path: "" })
        .then(() => desertRef.delete())
        .then(() => {
          this.setState({
            fileDeleteModalOpen: false,
          });

          this.state.appointments.map((el) => {
            if (el.prescription_path == currentAppId) {
              el.prescription_path = "";
            }
          });

          Notify({
            alert: true,
            type: "success",
            title: "File deleted",
          });
        })
        .catch((err) => {
          this.setState({
            fileDeleteModalOpen: false,
          });
          Notify({
            alert: true,
            type: "error",
            title: "Failed to delete file",
          });
        });
    } catch (error) {
      this.setState({
        fileDeleteModalOpen: false,
      });
      Notify({
        alert: true,
        type: "Failed to delete file",
        title: error,
      });
    }
  }

  //delete report
  async handleDeleteReport(currentAppId) {
    console.log(currentAppId);
    try {
      var desertRef = storage.ref(currentAppId);
      firestore
        .doc("appointments/" + this.state.currentAppointmentToDelete)
        .update({ report_path: "" })
        .then(() => desertRef.delete())
        .then(() => {
          this.setState({
            reportDeleteModalOpen: false,
          });

          this.state.appointments.map((el) => {
            if (el.report_path == currentAppId) {
              el.report_path = "";
            }
          });

          Notify({
            alert: true,
            type: "success",
            title: "Report deleted",
          });
        })
        .catch((err) => {
          this.setState({
            fileDeleteModalOpen: false,
          });
          Notify({
            alert: true,
            type: "error",
            title: "Failed to delete Report",
          });
        });
    } catch (error) {
      this.setState({
        fileDeleteModalOpen: false,
      });
      Notify({
        alert: true,
        type: "Failed to delete Report",
        title: error,
      });
    }
  }

  openVideoCall(e, appointment) {
    e.preventDefault();
    window.open(
      window.location.origin +
        "/#/videocall?meetingid=" +
        appointment.meetingid +
        "&appointmentid=" +
        appointment.appointmendId +
        "&uid=" +
        this.props.userData.uid,
      "_blank"
    );
  }

  viewFile(e, ref) {
    e.preventDefault();
    storage
      .ref(ref)
      .getDownloadURL()
      .then((path) => {
        window.open(path, "_blank");
      });
  }

  onChange = (e) => {
    let formObj = { ...this.state.currentAppointment };
    formObj[e.target.name] = e.target.value;
    this.setState({
      currentAppointment: formObj,
    });
  };

  handleTabChange(event, newValue) {
    this.setState({ tab: newValue });
  }

  getAppointmentDuration(res) {
    let duration = moment(res.to).diff(moment(res.from), "minutes");
    duration = duration + ` min${duration > 1 && "s"}`;
    return duration;
  }

  render() {
    console.log("appointments", this.state.appointments);

    return (
      <React.Fragment>
        {this.state.loading && <Loader />}
        <div className="row">
          <div className="col-sm-4 col-3">
            <h4 className="page-title">Appointments</h4>
          </div>
          <div className="col-sm-8 col-9 text-right m-b-20">
            <Link
              to="/doctor/add-appointment"
              className="btn btn-primary float-right btn-rounded"
            >
              <i className="fa fa-plus"></i> Add Appointment
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            {this.state.error && (
              <div
                className="alert alert-danger alert-dismissible fade show"
                role="alert"
              >
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                  <span className="sr-only">Close</span>
                </button>
                {this.state.error}
              </div>
            )}
            {this.state.success && (
              <div
                className="alert alert-success alert-dismissible fade show"
                role="alert"
              >
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                  <span className="sr-only">Close</span>
                </button>
                Appointment updated successfully
              </div>
            )}

            {this.state.fileUploaded && (
              <div
                className="alert alert-success alert-dismissible fade show"
                role="alert"
              >
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                  <span className="sr-only">Close</span>
                </button>
                File uploaded successfully
              </div>
            )}
            <Tabs
              variant="fullWidth"
              centered
              className="mb-3"
              value={this.state.tab}
              onChange={(e, v) => this.handleTabChange(e, v)}
              aria-label="wrapped label tabs example"
            >
              <Tab value="one" label="Upcoming" wrapped />
              <Tab value="two" label="Today" wrapped />
              <Tab value="three" label="Past" wrapped />
            </Tabs>
            <TabPanel value={this.state.tab} index="one">
              <div className="table-responsive">
                <table className="table table-border table-striped custom-table datatable m-b-0">
                  <thead>
                    <tr>
                      <th>Patient Name</th>
                      <th>Appointment Date</th>
                      <th>Appointment Time</th>
                      <th>Appointment Type</th>
                      <th>Appointment Duration</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.upcomming &&
                      this.state.upcomming.map((appointment) => (
                        <tr key={appointment.appointmendId}>
                          <td>
                            <Link
                              to={{
                                pathname: "/doctor/edit-appointment",
                                state: {
                                  name: appointment,
                                },
                              }}
                            >
                              {appointment.name}
                            </Link>
                          </td>
                          <td>{appointment.slot_date}</td>
                          <td>{appointment.slot_time}</td>
                          <td>
                            {appointment.appointmentType == 1
                              ? "Physical"
                              : "Video"}
                            <br />
                            {appointment.appointmentType == 2 && (
                              <JoinButton
                                appointment={appointment}
                                openVideoCall={this.openVideoCall}
                                userData={this.props.userData}
                              />
                            )}
                          </td>
                          <td>{this.getAppointmentDuration(appointment)}</td>
                          <td>
                            <span className="text-capitalize">
                              {appointment.appointment_status}
                            </span>
                          </td>

                          <td className="text-right">
                            <div className="dropdown dropdown-action">
                              <a
                                className="action-icon dropdown-toggle"
                                data-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fa fa-ellipsis-v"></i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-right">
                                <a
                                  className="dropdown-item"
                                  href="{#}"
                                  onClick={(e) => {
                                    this.setState({
                                      readOnly: true,
                                    });
                                    this.handleOpen(e, appointment);
                                  }}
                                >
                                  <i class="fa fa-eye" aria-hidden="true"></i>{" "}
                                  View Only
                                </a>
                                <Link
                                  className="dropdown-item"
                                  to={{
                                    pathname: "/doctor/edit-appointment",

                                    state: {
                                      patient: appointment,
                                    },
                                  }}
                                >
                                  <i className="fa fa-pencil m-r-5"></i> Edit
                                </Link>

                                <a
                                  className="dropdown-item"
                                  href="{#}"
                                  onClick={(e) => {
                                    this.setState({
                                      readOnly: false,
                                      show: true,
                                    });
                                    this.handleDeleteOpen(e, appointment);
                                  }}
                                >
                                  <i
                                    class="fa fa-trash-o"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  Delete
                                </a>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </TabPanel>
            <TabPanel value={this.state.tab} index="two">
              <div className="table-responsive">
                <table className="table table-border table-striped custom-table datatable m-b-0">
                  <thead>
                    <tr>
                      <th>Patient Name</th>
                      <th>Appointment Date</th>
                      <th>Appointment Time</th>
                      <th>Appointment Type</th>
                      <th>Appointment Duration</th>
                      <th>Status</th>
                      <th className="text-right">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.today &&
                      this.state.today.map((appointment) => (
                        <tr key={appointment.appointmendId}>
                          <td>
                            <Link
                              to={{
                                pathname: "/doctor/edit-appointment",
                                state: {
                                  name: appointment,
                                },
                              }}
                            >
                              {appointment.name}
                            </Link>
                          </td>
                          <td>{appointment.slot_date}</td>
                          <td>{appointment.slot_time}</td>
                          <td>
                            {appointment.appointmentType == 1
                              ? "Physical"
                              : "Video"}
                            <br />
                            {appointment.appointmentType == 2 && (
                              <JoinButton
                                appointment={appointment}
                                openVideoCall={this.openVideoCall}
                                userData={this.props.userData}
                              />
                            )}
                          </td>
                          <td>{this.getAppointmentDuration(appointment)}</td>
                          <td>
                            <span className="text-capitalize">
                              {appointment.appointment_status}
                            </span>
                          </td>

                          <td className="text-right">
                            <div className="dropdown dropdown-action">
                              <a
                                className="action-icon dropdown-toggle"
                                data-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fa fa-ellipsis-v"></i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-right">
                                <a
                                  className="dropdown-item"
                                  href="{#}"
                                  onClick={(e) => {
                                    this.setState({
                                      readOnly: true,
                                    });
                                    this.handleOpen(e, appointment);
                                  }}
                                >
                                  <i class="fa fa-eye" aria-hidden="true"></i>{" "}
                                  View Only
                                </a>
                                <Link
                                  className="dropdown-item"
                                  to={{
                                    pathname: "/doctor/edit-appointment",

                                    state: {
                                      patient: appointment,
                                    },
                                  }}
                                >
                                  <i className="fa fa-pencil m-r-5"></i> Edit
                                </Link>

                                <a
                                  className="dropdown-item"
                                  href="{#}"
                                  onClick={(e) => {
                                    this.setState({
                                      readOnly: false,
                                      show: true,
                                    });
                                    this.handleDeleteOpen(e, appointment);
                                  }}
                                >
                                  <i
                                    class="fa fa-trash-o"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  Delete
                                </a>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </TabPanel>
            <TabPanel value={this.state.tab} index="three">
              <div className="table-responsive">
                <table className="table table-border table-striped custom-table datatable m-b-0">
                  <thead>
                    <tr>
                      <th>Patient Name</th>
                      <th>Appointment Date</th>
                      <th>Appointment Time</th>
                      <th>Appointment Type</th>
                      <th>Appointment Duration</th>
                      <th>Status</th>
                      <th className="text-right">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.past &&
                      this.state.past.map((appointment) => (
                        <tr key={appointment.appointmendId}>
                          <td>
                            <Link
                              to={{
                                pathname: "/doctor/edit-appointment",
                                state: {
                                  name: appointment,
                                },
                              }}
                            >
                              {appointment.name}
                            </Link>
                          </td>
                          <td>{appointment.slot_date}</td>
                          <td>{appointment.slot_time}</td>
                          <td>
                            {appointment.appointmentType == 1
                              ? "Physical"
                              : "Video"}
                            <br />
                            {appointment.appointmentType == 2 && (
                              <JoinButton
                                appointment={appointment}
                                openVideoCall={this.openVideoCall}
                                userData={this.props.userData}
                              />
                            )}
                          </td>
                          <td>{this.getAppointmentDuration(appointment)}</td>
                          <td>
                            <span className="text-capitalize">
                              {appointment.appointment_status}
                            </span>
                          </td>

                          <td className="text-right">
                            <div className="dropdown dropdown-action">
                              <a
                                className="action-icon dropdown-toggle"
                                data-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fa fa-ellipsis-v"></i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-right">
                                <a
                                  className="dropdown-item"
                                  href="{#}"
                                  onClick={(e) => {
                                    this.setState({
                                      readOnly: true,
                                    });
                                    this.handleOpen(e, appointment);
                                  }}
                                >
                                  <i class="fa fa-eye" aria-hidden="true"></i>{" "}
                                  View Only
                                </a>
                                <Link
                                  className="dropdown-item"
                                  to={{
                                    pathname: "/doctor/edit-appointment",

                                    state: {
                                      patient: appointment,
                                    },
                                  }}
                                >
                                  <i className="fa fa-pencil m-r-5"></i> Edit
                                </Link>

                                <a
                                  className="dropdown-item"
                                  href="{#}"
                                  onClick={(e) => {
                                    this.setState({
                                      readOnly: false,
                                      show: true,
                                    });
                                    this.handleDeleteOpen(e, appointment);
                                  }}
                                >
                                  <i
                                    class="fa fa-trash-o"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  Delete
                                </a>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </TabPanel>
          </div>
        </div>

        <Modal
          show={this.state.reportDeleteModalOpen}
          onHide={() =>
            this.setState({
              reportDeleteModalOpen: false,
            })
          }
          animation={false}
        >
          <Modal.Header>
            <Modal.Title>Delete Report</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure, You want to delete the report?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => this.handleDeleteReport(this.state.currentAppId)}
            >
              Yes
            </Button>
            <Button
              variant="danger"
              onClick={() =>
                this.setState({
                  reportDeleteModalOpen: false,
                })
              }
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.fileDeleteModalOpen}
          onHide={() =>
            this.setState({
              fileDeleteModalOpen: false,
            })
          }
          animation={false}
        >
          <Modal.Header>
            <Modal.Title>Delete File</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure, You want to delete the file?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() =>
                this.handleDeleteAttachment(this.state.currentAppId)
              }
            >
              Yes
            </Button>
            <Button
              variant="danger"
              onClick={() =>
                this.setState({
                  fileDeleteModalOpen: false,
                })
              }
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.modalOpen}
          onHide={() => this.handleClose()}
          animation={false}
        >
          {this.state.modalLoading && <Loader />}
          <Modal.Header closeButton>
            <Modal.Title>Appointment Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label>
                    Patient Name <span className="text-danger">*</span>
                  </label>
                  <input
                    name="name"
                    value={this.state.currentAppointment.name}
                    readOnly
                    className="form-control"
                    type="text"
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>
                    Appointment Type <span className="text-danger">*</span>
                  </label>
                  <input
                    name="appointmenttype"
                    value={
                      this.state.currentAppointment.appointmentType == 1
                        ? "Physical"
                        : "Video"
                    }
                    readOnly
                    className="form-control"
                    type="text"
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Time</label>
                  <div className="time-icon">
                    <input
                      name="time"
                      value={this.state.currentAppointment.slot_time}
                      readOnly
                      type="text"
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>
                    Patient Phone Number <span className="text-danger">*</span>
                  </label>
                  <input
                    name="phonenumber"
                    value={this.state.currentAppointment.phoneno}
                    readOnly
                    className="form-control"
                    type="email"
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label>Patient Email</label>
                  <input
                    className="form-control"
                    value={this.state.currentAppointment.email}
                    type="email"
                    name="patientemail"
                    readOnly
                  />
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label>Chief Complaints</label>
                  <textarea
                    readOnly={this.state.readOnly}
                    name="chiefComplaints"
                    onChange={(e) => this.onChange(e)}
                    className="form-control"
                    value={this.state.currentAppointment.chiefComplaints || ""}
                  >
                    {this.state.currentAppointment.chiefComplaints || ""}
                  </textarea>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label>Relevant points from history</label>
                  <textarea
                    readOnly={this.state.readOnly}
                    name="pointsFromHistory"
                    onChange={(e) => this.onChange(e)}
                    className="form-control"
                    value={
                      this.state.currentAppointment.pointsFromHistory || ""
                    }
                  >
                    {this.state.currentAppointment.pointsFromHistory || ""}
                  </textarea>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <label>Examination / Lab Findings</label>
                  <textarea
                    readOnly={this.state.readOnly}
                    name="labFindings"
                    onChange={(e) => this.onChange(e)}
                    className="form-control"
                    value={this.state.currentAppointment.labFindings || ""}
                  >
                    {this.state.currentAppointment.labFindings || ""}
                  </textarea>
                </div>
              </div>

              <div className="col-sm-12">
                <div className="form-group">
                  <label>Suggested Investigations</label>
                  <textarea
                    readOnly={this.state.readOnly}
                    name="suggestedInvestigations"
                    onChange={(e) => this.onChange(e)}
                    className="form-control"
                    value={
                      this.state.currentAppointment.suggestedInvestigations ||
                      ""
                    }
                  >
                    {this.state.currentAppointment.suggestedInvestigations ||
                      ""}
                  </textarea>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.handleClose()}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.show}
          onHide={() =>
            this.setState({
              show: false,
            })
          }
          animation={false}
        >
          <Modal.Header>
            <Modal.Title>Delete patient appointment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure, You want to delete Patient appointment
            {this.state.currentAppointment ? (
              <strong> {this.state.currentAppointment.name}</strong>
            ) : null}
            ?
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() =>
                this.deleteAppointment(this.state.currentAppointment)
              }
            >
              Yes
            </Button>
            <Button
              variant="danger"
              onClick={() => this.setState({ show: false })}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          centered={true}
          show={this.state.fileModalOpen}
          onHide={() => this.handleFileModalClose()}
          animation={false}
        >
          {this.state.modalLoading && <Loader />}
          <Modal.Header closeButton>
            <Modal.Title>Upload Attachments</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <div className="profile-upload">
                    <div className="upload-input">
                      <input
                        type="file"
                        onChange={(e) => this.handleFileChange(e)}
                        accept={allowedFiles}
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                {this.state.fileExtError && (
                  <div className="alert alert-danger fade show" role="alert">
                    {this.state.fileExtError}
                  </div>
                )}
                {this.state.fileSizeError && (
                  <div className="alert alert-danger fade show" role="alert">
                    {this.state.fileSizeError}
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => this.handleFileModalClose()}
            >
              Close
            </Button>
            <Button variant="primary" onClick={() => this.uploadFile()}>
              Upload
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}
