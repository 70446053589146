// jshint esversion:6

import $ from "jquery";
import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, Redirect, useLocation } from "react-router-dom";
import { auth } from "../../firebase";
import { frontNavs, Usertypes } from "../../Helpers/constants";
import Notify from "../../Notify";
import { useStateValue } from "../../StateProvider";
import Loader from "../Loader/Loader";
import { Button, Modal } from "react-bootstrap";
import "./style.css";

const Header = (props) => {
  const [show, setShow] = useState(false);
  const [user, setUser] = useState(null);
  const [userName, setUserName] = useState(null);
  const [redirect, setRedirect] = useState(null);
  const [search, setSearch] = useState("");
  const [getResult, setGetResult] = useState(false);
  const [loading, setLoading] = useState(false);
  const [profile_pic, setProfile_pic] = useState(null);
  const [{ userPP, lat, long, country_code }, dispatch] = useStateValue();
  const [init, setInit] = useState(true);
  const location = useLocation();

  const sidebarRef = useRef(null);

  const toggleSidebar = () => {
    sidebarRef.current.classList.toggle("opened");
  };

  const [scrolled, setScrolled] = React.useState(false);
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  },[]);

  useEffect(() => {
    $(".toggle-menu").on("click", function (e) {
      e.preventDefault();
      var $target = $($(this).attr("href"));
      if ($target.length) {
        $target.toggleClass("opened");
        $(".sidebar-overlay").toggleClass("opened");
        $("body").toggleClass("menu-opened");
        $(".sidebar-overlay").attr("data-reff", $(this).attr("href"));
      }
    });

    $(".menu-toggle").on("click", function (e) {
      e.preventDefault();
      $(this)
        .parents(".dropdownToggle")
        .children(".mobile-submenu-wrapper")
        .slideToggle(300);
      return false;
    });

    auth.onAuthStateChanged((user) => {
      setLoading(true);
      if (user) {
        setUser(user);
        setLoading(false);
        setInit(true);
      } else {
        setUser(false);
        setLoading(false);
        setInit(true);
      }
    });
  }, []);

  useEffect(() => {
    if (init) {
      setInit(false);
      $(".menu-toggle1").on("click", function (e) {
        e.preventDefault();
        $(this)
          .parents(".dropdownToggle")
          .children(".mobile-submenu-wrapper")
          .slideToggle(300);
        return false;
      });
    }
  }, [init]);

  useEffect(() => {
    if (userPP) {
      setProfile_pic(userPP);
    }
  }, [userPP]);

  useEffect(() => {
    if (props.userData) {
      setUserName(`${props.userData?.firstName} ${props.userData?.lastName}`);
    }
  }, [props]);

  useEffect(() => {
    if (getResult) {
      setGetResult(false);
      setSearch("");
    }
  }, [getResult]);

  const logout = () => {
    setLoading(true);
    auth
      .signOut()
      .then(() => {
        localStorage.removeItem("@userData");
        localStorage.removeItem("@displayName");
        localStorage.removeItem("@prefLogin");
        setUser(false);
        setRedirect("/");
        setLoading(false);
        dispatch({
          type: "SET_USER_PP",
          url: "",
        });
        Notify({
          alert: true,
          type: "success",
          title: "Logged out successfully.",
        });
      })
      .catch(() => setLoading(false));
  };

  const renderUserLink = () => {
    return Usertypes[props.type - 1][1];
  };

  const closeSidebar = (e) => {
    // e.preventDefault();
    // var $target = $($(".toggle-menu").attr("href"));
    // if ($target.length) {
    //   $target.removeClass("opened");
    //   $(".sidebar-overlay").removeClass("opened");
    //   $("body").removeClass("menu-opened");
    //   $(".sidebar-overlay").attr("data-reff", $(this).attr("href"));
    // }
    toggleSidebar();
  };

  const searchHandler = (e) => {
    e.preventDefault();
    var $target = $($(".toggle-menu").attr("href"));
    if ($target.length) {
      $target.removeClass("opened");
      $(".sidebar-overlay").removeClass("opened");
      $("body").removeClass("menu-opened");
      $(".sidebar-overlay").attr("data-reff", $(this).attr("href"));
    }
    setGetResult(true);
  };

  const searchChangeHandher = (e) => {
    let value = e.target.value;
    setSearch(value);
  };
  // console.log("userData", props.userData);
  let ddNavs = [];
  if (props.type) {
    ddNavs = frontNavs[localStorage.getItem("@displayName") - 1];
  }

  // if (redirect) {
  //   return <Redirect to={"/"} />;
  // }
  if (loading) {
    return (
      <>
        <div id="loading"></div>
        <Loader />
      </>
    );
  }

  return (
    <>
      {redirect && <Redirect to={"/"} />}

      {getResult && (
        <Redirect
          to={{
            pathname: "/searched",
            search: "?name=" + search,
          }}
        />
      )}
      {/* <div id="loading"></div>
      {loading ? <Loader /> : null} */}
      <header className={scrolled ? "header scrolled" : "header"}>
        <div
          className="container"
          style={{ padding: props.user ? "1em 0 1.1em 0" : "1em 0 1.3em 0" }}
        >
          <div className="d-flex justify-content-between align-items-end">
            <div className="mr-3">
              <div className="logo">
                <Link to="/">
                  <img
                    // className="top_logo"
                    src="assets/img/logo1.png"
                    alt="Medvatic"
                    className="medvatic-logo"
                  />
                </Link>
              </div>
            </div>
            <div className="flex-grow-1 h-100 mt-100">
              <nav className="navbar navbar-expand-md p-0 h-100">
                <div className="navbar-collapse" id="navbar">
                  <ul className="nav navbar-nav main-nav nav-patient float-left mr-auto topnav my-auto">
                    <li
                      className={
                        location.pathname.includes("/Product") &&
                        "solution-item"
                      }
                    >
                      <NavLink
                        to="/Product"
                        style={{ fontWeight: "normal", fontSize: "0.95em" }}
                        className=""
                      >
                        Product
                      </NavLink>
                    </li>
                    <li
                      className={
                        location.pathname.includes("/Practitioner") &&
                        "solution-item"
                      }
                    >
                      <NavLink
                        to="/Practitioner"
                        style={{ fontWeight: "normal", fontSize: "0.95em" }}
                        className=""
                      >
                        Practitioner Benefits
                      </NavLink>
                    </li>
                    <li
                      className={
                        location.pathname.includes("/Monitoring") &&
                        "solution-item"
                      }
                    >
                      <NavLink
                        to="/Monitoring"
                        style={{ fontWeight: "normal", fontSize: "0.95em" }}
                        className=""
                      >
                        Monitoring Services
                      </NavLink>
                    </li>
                    <li
                      className={
                        location.pathname.includes("/Treatment") &&
                        "solution-item"
                      }
                    >
                      <NavLink
                        to="/Treatment"
                        style={{ fontWeight: "normal", fontSize: "0.95em" }}
                        className=""
                      >
                        Treatment Management Plan
                      </NavLink>
                    </li>
                    <li
                      className={
                        location.pathname.includes("/Team") && "solution-item"
                      }
                    >
                      <NavLink
                        to="/Team"
                        // className="nav-link"
                        style={{ fontWeight: "normal", fontSize: "0.95em" }}
                      >
                        About Us
                      </NavLink>
                    </li>
                    <li
                      className={
                        location.pathname.includes("/Contact") &&
                        "solution-item"
                      }
                    >
                      <NavLink
                        to="/Contact"
                        // className="nav-link"
                        style={{ fontWeight: "normal", fontSize: "0.95em" }}
                      >
                        Contact Us
                      </NavLink>
                    </li>
                  </ul>
                  <ul className="nav navbar-nav main-nav nav-patient float-right ml-auto topnav">
                    {user == null ? null : (
                      <>
                        {user === false ? (
                          <li className="nav-item">
                            <NavLink
                              to="/Login"
                              // className="btn btn-primary appoint-btn nav-link mr-2"
                              style={{
                                padding: ".4em .7em",
                                border: "1px solid grey",
                                borderRadius: "5px",
                                fontSize: "1em",
                                boxSizing: "border-box",
                                fontWeight: "normal",
                              }}
                              className="login-sign-btn"
                            >
                              Login / Register
                            </NavLink>
                          </li>
                        ) : (
                          <>
                            <li className="nav-item dropdown has-arrow">
                              <a
                                href="#"
                                className="dropdown-toggle nav-link user-link"
                                data-toggle="dropdown"
                              >
                                <span className="user-img mr-2">
                                  <img
                                    className="rounded-circle"
                                    src={
                                      profile_pic
                                        ? profile_pic
                                        : "assets/img/user.jpg"
                                    }
                                    width="40"
                                    alt="Admin"
                                  />
                                </span>
                                {props.user && props.userData ? (
                                  <span>{props.userData.firstName}</span>
                                ) : (
                                  "Hi, User"
                                )}
                              </a>
                              <div className="dropdown-menu">
                                <ul className="dropdown-list">
                                  {ddNavs && ddNavs.length !== 0
                                    ? ddNavs.map((nav, i) => (
                                        <li key={i}>
                                          <Link
                                            to={nav.link}
                                            className="dropdown-item"
                                          >
                                            {nav.label}
                                          </Link>
                                        </li>
                                      ))
                                    : null}
                                  {/* <li><Link to="/my-appointments" className="dropdown-item" >My Appointments</Link></li>
                                      <li><Link to="/my-profile" className="dropdown-item">View Profile</Link></li> */}
                                  <li>
                                    <a
                                      onClick={() => setShow(true)}
                                      className="dropdown-item"
                                    >
                                      Logout
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </li>

                            {/* <li className="nav-item">
                                  <button
                                    onClick={() => logout()}
                                    className="btn btn-primary logout appoint-btn nav-link"
                                  >
                                    Logout
                                  </button>
                                </li> */}
                          </>
                        )}
                      </>
                    )}
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
        {/* <div style={{ height: "1.3em", backgroundColor: "#E8E8E8" }}></div> */}
      </header>

      {/* <header className="mobile-header">
        <div className="panel-control-left">
          <a className="toggle-menu" onClick={toggleSidebar}>
            <i className="fa fa-bars"></i>
          </a>
        </div>
        <div className="page_title">
          <Link to="/">
            <img
              src="assets/img/logo1.png"
              alt="Logo"
              className="img-fluid"
              width="60"
              height="60"
            />
          </Link>
        </div>
      </header> */}

      <header className="mobile-header">
        <div className="panel-control-left">
          <a className="toggle-menu" onClick={toggleSidebar}>
            <i className="fa fa-bars"></i>
          </a>
        </div>
        <div className="page_title">
          <Link to="/">
            <img
              src="assets/img/logo1.png"
              alt="Logo"
              className="img-fluid"
              width="136"
            />
          </Link>
        </div>
      </header>

      <div className="sidebar sidebar-menu" ref={sidebarRef} id="side_menu">
        <div className="sidebar-inner slimscroll">
          <a id="close_menu" href="#">
            <i className="fa fa-close"></i>
          </a>
          <ul className="mobile-menu-wrapper" style={{ display: "block" }}>
            <div
              style={{ width: "100%" }}
              className="nav-item mr-2 search-container"
            >
              {/* <div style={{ width: 300 }} className="nav-item search-container"> */}
              {/* <form
                onSubmit={(e) => searchHandler(e)}
                style={{ display: "flex" }}
              >
                <input
                  onChange={(e) => searchChangeHandher(e)}
                  type="text"
                  placeholder="Search doctors, hospitals, etc."
                  name="search"
                  value={search}
                  className="m-0"
                />
                <button type="submit">
                  <i style={{ color: "#424242" }} className="fa fa-search"></i>
                </button>
              </form> */}

              {/* </div> */}
            </div>
            {/* <li className="dropdownToggle"> */}
            {/* <div className="mobile-menu-item clearfix">
                <a onClick={(e) => {}} href="" className="menu-toggle">
                  Solutions <i className="fa fa-chevron-down menu-toggle"></i>
                </a>
              </div> */}
            <ul
              className="mobile-submenu-wrapper"
              // style={{ display: "none" }}
            >
              <li onClick={(e) => closeSidebar(e)}>
                <span
                  class="iconify"
                  data-icon="system-uicons:graph-increase"
                  data-inline="false"
                  style={{ color: "grey", fontSize: "em" }}
                ></span>
                <Link
                  to="/practitioner"
                  style={{ display: "inline", paddingLeft: "0.5em" }}
                >
                  Practitioner benefits{" "}
                </Link>
              </li>
              <li onClick={(e) => closeSidebar(e)}>
                <span
                  class="iconify"
                  data-icon="ant-design:medicine-box-outlined"
                  data-inline="false"
                  style={{ color: "grey", fontSize: "em" }}
                ></span>
                <Link
                  to="/product"
                  style={{ display: "inline", paddingLeft: "0.5em" }}
                >
                  Product
                </Link>
              </li>
              <li onClick={(e) => closeSidebar(e)}>
                <span
                  class="iconify"
                  data-icon="fluent:desktop-pulse-48-regular"
                  data-inline="false"
                  style={{ color: "grey", fontSize: "em" }}
                ></span>
                <Link
                  to="/monitoring"
                  style={{ display: "inline", paddingLeft: "0.5em" }}
                >
                  Monitoring Services
                </Link>
              </li>
              {/* </ul>
              <ul
                className="mobile-submenu-wrapper"
                // style={{ display: "none" }}
              > */}
              <li onClick={(e) => closeSidebar(e)}>
                <span
                  class="iconify"
                  data-icon="bi:people"
                  data-inline="false"
                  style={{ color: "grey", fontSize: "em" }}
                ></span>
                <Link
                  to="/team"
                  style={{ display: "inline", paddingLeft: "0.5em" }}
                >
                  Our Team
                </Link>
              </li>
              <li onClick={(e) => closeSidebar(e)}>
                <span
                  class="iconify"
                  data-icon="fluent:call-28-regular"
                  data-inline="false"
                  style={{ color: "grey", fontSize: "em" }}
                ></span>
                <Link
                  to="/contact"
                  style={{
                    display: "inline",
                    paddingLeft: "0.5em",
                  }}
                >
                  Contact
                </Link>
              </li>
            </ul>

            {user == null ? null : (
              <>
                {user === false ? (
                  <li>
                    <div
                      onClick={(e) => closeSidebar(e)}
                      // className="mobile-menu-item clearfix"
                    >
                      <span
                        class="iconify"
                        data-icon="carbon:login"
                        data-inline="false"
                        style={{
                          color: "grey",
                          fontSize: "em",
                          display: "inline",
                          marginLeft: "20px",
                        }}
                      ></span>
                      <Link
                        to="/login"
                        style={{
                          display: "inline",
                          paddingLeft: "0.5em",
                          width: "auto",
                          float: "none",
                        }}
                      >
                        Login
                      </Link>
                    </div>
                  </li>
                ) : (
                  <>
                    <li className="dropdownToggle">
                      <div className="mobile-menu-item clearfix">
                        <a href="" className="menu-toggle1">
                          {props.user &&
                            userName?.substring(0, 7) +
                              (userName?.length > 7 ? ".." : "")}
                          <i className="fa fa-chevron-down menu-toggle1"></i>
                        </a>
                      </div>
                      <ul
                        className="mobile-submenu-wrapper"
                        style={{ display: "none" }}
                      >
                        <li onClick={(e) => closeSidebar(e)}>
                          <Link to="/my-appointments" className="dropdown-item">
                            My Appointments
                          </Link>
                        </li>
                        <li onClick={(e) => closeSidebar(e)}>
                          <Link to="/my-appointments" className="dropdown-item">
                            Family Account
                          </Link>
                        </li>
                        <li>
                          <Link to="/my-profile" className="dropdown-item">
                            View Profile
                          </Link>
                        </li>
                        <li>
                          <a
                            onClick={() => setShow(true)}
                            className="dropdown-item"
                          >
                            Logout
                          </a>
                        </li>
                      </ul>
                    </li>
                  </>
                )}
              </>
            )}
          </ul>
        </div>
      </div>

      <Modal show={show} onHide={() => setShow(false)} animation={false}>
        <Modal.Header>
          <Modal.Title>Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to log out?</Modal.Body>
        <Modal.Footer>
          <Button
            style={{ backgroundColor: "#50D8FA" }}
            variant="primary"
            onClick={(e) => {
              setShow(false);
              closeSidebar(e);
              logout();
            }}
          >
            Yes
          </Button>
          <Button variant="danger" onClick={() => setShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Header;
