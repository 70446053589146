// jshint esversion:6

import React, { useState, useEffect } from "react";
import { log } from "./utils/utils";
import "./Agora.css";
import { Button, notification } from "antd";
import { Input } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import { startBasicCall, leaveCall, toggleVideo } from "./Agora_RTC";
import { event } from "jquery";

function gup(name) {
  var url = window.location.href;
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regexS = "[\\?&]" + name + "=([^&#]*)";
  var regex = new RegExp(regexS);
  var results = regex.exec(url);
  return results == null ? "" : results[1];
}

export default function AgoraUi() {
  const [appid, setAppid] = useState("7b9fa21ad2ed4216a5e0edad08e119fd");
  const [channel, setChannel] = useState(gup("meetingid"));
  const [uid, setuid] = useState(gup("uid"));
  let localStream = {};
  console.log(gup("uid"));
  // const [channel, setChannel] = useState("WzZ80VPtTF");
  // const [uid, setuid] = useState("8tVEZ3newzgxxA2kVF1YYq2E2t93");
  const [token, setToken] = useState("");
  const [isjoin, setIsJoin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showbuttons, setShowButtons] = useState(true);

  const handleCamera = (e) => {
    toggleVideo();
    // e.currentTarget.classList.toggle("off");
    // this.localStream.isVideoOn()
    //   ? this.localStream.disableVideo()
    //   : this.localStream.enableVideo();
  };

  const getToken = () => {
    return fetch(
      "https://australia-southeast1-medvatic-438af.cloudfunctions.net/appointment/apttoken?uid=" +
        uid +
        "&roomid=" +
        channel,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };

  useEffect(() => {
    getToken().then((res) => {
      res.json().then((res) => {
        setToken(res.token);
      });
    });
  }, []);

  const bindInputAppid = (event) => {
    setAppid(event.target.value);
  };

  const bindInputToken = (event) => {
    setToken(event.target.value);
  };

  const bindInputChannel = (event) => {
    setChannel(event.target.value);
  };

  // const [start, setStart] = useState(false);

  // const handleClickJoin = () => {
  //   setShowButtons(false);
  // };
  const handleClickJoin = () => {
    if (!appid || !channel || !token) {
      if (!appid) {
        openNotification("appid");
      }
      if (!channel) {
        openNotification("channel");
      }
      if (!token) {
        openNotification("token");
      }
      // setStart(true);
      return;
    }
    setShowButtons(false);

    let options = {
      appId: appid,
      channel: channel,
      token: token,
      uid: uid,
    };
    startBasicCall(options);
    log("join channel success");
    setIsJoin(true);
  };

  const handleClickLeave = () => {
    leaveCall();
    log("client leaves channel success");
    setIsJoin(false);
    setShowButtons(true);
    // setStart(false);
  };

  const openNotification = (placement) => {
    notification.open({
      message: "Please enter complete information",
      description: `The ${placement} is empty`,
      icon: <SmileOutlined style={{ color: "#108ee9" }} />,
    });
  };

  const videoOn =
    "M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5z";
  const videoClose =
    "M10.961 12.365a1.99 1.99 0 0 0 .522-1.103l3.11 1.382A1 1 0 0 0 16 11.731V4.269a1 1 0 0 0-1.406-.913l-3.111 1.382A2 2 0 0 0 9.5 3H4.272l6.69 9.365zm-10.114-9A2.001 2.001 0 0 0 0 5v6a2 2 0 0 0 2 2h5.728L.847 3.366zm9.746 11.925l-10-14 .814-.58 10 14-.814.58z";
  const [video, setVideo] = useState(true);
  function videoOff() {
    setVideo(!video);
  }

  const mute = (
    <>
      <path d="M5 3a3 3 0 0 1 6 0v5a3 3 0 0 1-6 0V3z" />
      <path
        fillRule="evenodd"
        d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z"
      />
    </>
  );
  const closeMute = (
    <path
      fillRule="evenodd"
      d="M12.734 9.613A4.995 4.995 0 0 0 13 8V7a.5.5 0 0 0-1 0v1c0 .274-.027.54-.08.799l.814.814zm-2.522 1.72A4 4 0 0 1 4 8V7a.5.5 0 0 0-1 0v1a5 5 0 0 0 4.5 4.975V15h-3a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-3v-2.025a4.973 4.973 0 0 0 2.43-.923l-.718-.719zM11 7.88V3a3 3 0 0 0-5.842-.963L11 7.879zM5 6.12l4.486 4.486A3 3 0 0 1 5 8V6.121zm8.646 7.234l-12-12 .708-.708 12 12-.708.707z"
    />
  );
  const [mic, setMic] = useState(true);

  function micOff() {
    setMic(!mic);
  }

  const expandScreen =
    "M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707zm0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707zm-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707z";
  const shrinkScreen =
    "M5.5 0a.5.5 0 0 1 .5.5v4A1.5 1.5 0 0 1 4.5 6h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5zm5 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 10 4.5v-4a.5.5 0 0 1 .5-.5zM0 10.5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 6 11.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zm10 1a1.5 1.5 0 0 1 1.5-1.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4z";
  const [screen, setScreen] = useState(false);

  function fullScreen() {
    // setScreen("col - xs - 12");
    setScreen(!screen);
  }

  return (
    // Header
    <div className="home-box agora-wrapper">
      <div className="title-box">
        <span id="title-agora">
          <img src="/assets/img/logo-main.png" className="logo" />
        </span>
      </div>
      <div className="message-box">
        <div>
          <span className="text-agora">AppID</span>
          <div className="input-box">
            {/* <Input placeholder="Enter Appid" value={appid} onChange={bindInputAppid} readOnly allowClear='true'/> */}
            <Input placeholder="Enter Appid" value={appid} readOnly />
          </div>
        </div>
        <div>
          <span className="text-agora">Token</span>
          <div className="input-box">
            {/* <Input placeholder="Enter Token" value={token} onChange={bindInputToken} readOnly allowClear='true'/> */}
            <Input placeholder="Enter Token" value={token} readOnly />
          </div>
        </div>
        <div>
          <span className="text-agora">Channel</span>
          <div className="input-box">
            <Input
              placeholder="Enter Channel Name"
              value={channel}
              onChange={bindInputChannel}
              readOnly
              allowClear="true"
            />
            {/* <Input placeholder="Enter Channel Name" value={channel} readOnly /> */}
          </div>
        </div>
        <div>
          <span className="text-agora">UID</span>
          <div className="input-box">
            <Input
              placeholder="Enter Channel Name"
              value={channel}
              onChange={bindInputChannel}
              readOnly
            />
            {/* <Input placeholder="Enter UID" value={uid} readOnly /> */}
          </div>
        </div>
      </div>

      <div className={screen ? "video-agora-box stretched" : "video-agora-box"}>
        <div id="video-agora-local"></div>
      </div>

      <div className="page-footer">
        <hr className="hrbottom" />
        <div className="page-footer-inner">
          {token ? (
            <div className="click-box">
              {showbuttons ? (
                <button
                  className="button"
                  type="primary"
                  onClick={handleClickJoin}
                >
                  Join Now
                </button>
              ) : (
                <div className="page-footer-inner">
                  {/* <svg
                    width="3em"
                    height="3em"
                    viewBox="0 0 16 16"
                    className="bi bi-mic-fill"
                    fill="black"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={micOff}
                  >
                    {mic ? mute : closeMute}
                  </svg>
                  <svg
                    width="3em"
                    height="3em"
                    viewBox="0 0 16 16"
                    fill="black"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={handleCamera}
                  >
                    <path
                      fillRule="evenodd"
                      d={video ? videoOn : videoClose}
                    />
                  </svg> */}
                  <svg
                    width="2.5em"
                    height="2.5em"
                    viewBox="0 0 16 16"
                    className="bi bi-arrows-fullscreen"
                    fill="black"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={fullScreen}
                  >
                    <path
                      fillRule="evenodd"
                      d={screen ? shrinkScreen : expandScreen}
                    />
                  </svg>
                  <svg
                    width="3em"
                    height="3em"
                    viewBox="0 0 16 16"
                    className="bi bi-telephone-fill"
                    fill="red"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={handleClickLeave}
                    disabled={!isjoin}
                  >
                    <path
                      fillRule="evenodd"
                      d="M2.267.98a1.636 1.636 0 0 1 2.448.152l1.681 2.162c.309.396.418.913.296 1.4l-.513 2.053a.636.636 0 0 0 .167.604L8.65 9.654a.636.636 0 0 0 .604.167l2.052-.513a1.636 1.636 0 0 1 1.401.296l2.162 1.681c.777.604.849 1.753.153 2.448l-.97.97c-.693.693-1.73.998-2.697.658a17.47 17.47 0 0 1-6.571-4.144A17.47 17.47 0 0 1 .639 4.646c-.34-.967-.035-2.004.658-2.698l.97-.969z"
                    />
                  </svg>
                </div>
              )}
            </div>
          ) : null}
        </div>
        <div className="scroll-to-top">
          <i className="icon-arrow-up"></i>
        </div>
      </div>
    </div>
  );
}
