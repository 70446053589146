import moment from "moment";
import React, { Component } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import * as Datetime from "react-datetime";
import BaseSelect from "react-select";
import Apilib from "../../api";
import RequiredSelect from "../../Helpers/requiredSelect";
import Notify from "../../Notify";
import Loader from "../Loader/Loader";
import "./schedule.css";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import momentPlugin from "@fullcalendar/moment";
import "../../assets/css/FullCalendar.css";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

let available_days = [
  { value: 1, label: "Monday" },
  { value: 2, label: "Tuesday" },
  { value: 3, label: "Wednesday" },
  { value: 4, label: "Thursday" },
  { value: 5, label: "Friday" },
  { value: 6, label: "Saturday" },
  { value: 7, label: "Sunday" },
];

const Select = (props) => (
  <RequiredSelect
    {...props}
    SelectComponent={BaseSelect}
    options={props.options}
  />
);

let deleteEvent = null;

export default class AddSchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDays: [],
      selectedDaysObj: null,
      startTime: "10:00 am",
      endTime: "12:00 pm",
      slot: "",
      loading: false,
      userData: this.props.userData,
      success: false,
      deleted: false,
      deleteEvent: null,
      isDeleteModalOpen: false,
      doctors: [],
      // Full Calendar
      weekendsVisible: true,
      currentEvents: [],
      eventGuid: 0,
      calendar: [],
    };
    // this.renderEventContent = this.renderEventContent.bind(this);
  }

  componentDidMount() {
    this.fetchDoctors();
    this.createCalendarEvents(this.state.userData);
  }

  openDeleteModal = (e) => {
    this.setState({
      isDeleteModalOpen: true,
      deleteEvent: e,
    });
  };

  closeDeleteModal = () => {
    this.setState({
      isDeleteModalOpen: false,
    });
  };

  componentDidUpdate() {
    console.log("state con", this.state, this.props);
  }

  onDaySelect(day) {
    let arr = [];
    arr.push(day?.value);
    this.setState({
      selectedDays: arr,
      selectedDaysObj: day,
    });
  }

  onTimeSelect(value, label) {
    this.setState({
      [label]: moment(value).format("h:mm a"),
    });
  }

  resetState() {
    this.setState({
      selectedDays: [],
      selectedDaysObj: null,
      startTime: "10:00 am",
      endTime: "12:00 pm",
      slot: "",
    });
  }

  hideAlerts() {
    setTimeout(() => {
      this.setState({
        success: false,
        deleted: false,
        error: "",
      });
    }, 5000);
  }

  onSubmit(e) {
    e.preventDefault();
    var beginningTime = moment(this.state.startTime, "h:mm a");
    var endTime = moment(this.state.endTime, "h:mm a");
    if (endTime.isBefore(beginningTime)) {
      alert("Start Time should be less than end time");
    } else {
      let millis = endTime.diff(beginningTime);
      let mins = millis / 60000;
      if (mins < 60) {
        alert("Start and end time duration should be atleast 60 mins");
      } else {
        const promises = [];
        let API = new Apilib();
        for (let obj of this.state.selectedDaysObj) {
          // let fromObj = {};
          // let toObj = {};
          // this.state.selectedDays.map((day, index) => {
          //   fromObj[day.value] = [this.state.startTime];
          //   toObj[day.value] = [this.state.endTime];
          // });
          let data = {
            type: 3,
            uid: this.state.userData.uid,
            days: [obj.value],
            slot_duration: this.state.slot + ":00",
            from: { [obj.value]: [this.state.startTime] },
            to: { [obj.value]: [this.state.endTime] },
          };
          promises.push(API.addSchedule(JSON.stringify(data)));
        }

        this.setState({
          loading: true,
        });

        Promise.all(promises)
          .then((res) => {
            // if (res.status == 200) {
            let API = new Apilib();
            API.getUserDetails(
              this.state.userData.type,
              this.state.userData.uid
            )
              .then((res) => res.json())
              .then((res) => {
                console.log(res);
                Notify({
                  type: "success",
                  alert: true,
                  title: "Schedule added successfully",
                });
                this.setState(
                  {
                    loading: false,
                    // success: true,
                    userData: res.user,
                  },
                  () => {
                    this.props.updateUser(res.user);
                    this.resetState();
                  }
                );
                // only receptionist can call this API
                if (this.props?.type == 4) {
                  this.fetchDoctors();
                }
              })
              .catch((error) => {
                console.log(error);
              });
            // } else {
            //   res.text().then((data) => {
            //     Notify({
            //       type: "error",
            //       alert: true,
            //       title: "Failed to add schedule",
            //     });
            //     this.setState({
            //       loading: false,
            //       // error: JSON.parse(data).message,
            //     });
            //   });
            // }
            this.hideAlerts();
          })
          .catch((error) => {
            this.setState({
              error: error.message,
              loading: false,
            });
            this.hideAlerts();
          });
      }
    }
  }

  handleDelete(e) {
    let data = {
      ...e,
      type: 3,
      // uid: this.state.userData.uid,
      // days: [parseInt(e.target.dataset.day)],
      // from: {
      //   [e.target.dataset.day]: [e.target.dataset.from],
      // },
      // to: {
      //   [e.target.dataset.day]: [e.target.dataset.to],
      // },
    };
    delete data.date;
    console.log(data);
    this.setState({
      loading: true,
    });
    let API = new Apilib();
    API.deleteSchedule(JSON.stringify(data))
      .then((res) => {
        if (res.ok) {
          let API = new Apilib();
          API.getUserDetails(this.state.userData.type, this.state.userData.uid)
            .then((res) => res.json())
            .then((res) => {
              console.log("userrrrr", res.user);
              Notify({
                type: "success",
                alert: true,
                title: "Schedule deleted successfully",
              });
              this.setState(
                {
                  isDeleteModalOpen: false,
                  loading: false,
                  // deleted: true,
                  userData: res.user,
                },
                () => {
                  this.props.updateUser(res.user);
                  this.resetState();
                }
              );
              if (this.props?.type == 4) {
                this.fetchDoctors();
              }
            })
            .catch((error) => {
              console.log(error);
              Notify({
                type: "error",
                alert: true,
                title: "Failed to delete schedule",
              });
            });
        } else {
          res.text().then((data) => {
            this.setState(
              {
                loading: false,
                // error: JSON.parse(data).message,
              },
              () =>
                Notify({
                  type: "error",
                  alert: true,
                  title: "Failed to delete schedule",
                })
            );
          });
        }
        this.hideAlerts();
      })
      .catch((error) => {
        this.hideAlerts();
        console.log(error);
      });
  }

  renderSchedule(from, to, day) {
    if (from) {
      return (
        <>
          {from.map((d, i) => (
            <>
              <div key={i} className="schedule-row">
                <p>
                  {d} - {to[i]}
                </p>
                <button
                  data-day={day + 1}
                  data-from={d}
                  data-to={to[i]}
                  onClick={(e) => {
                    e.preventDefault();
                    this.openDeleteModal({
                      target: { dataset: { ...e.target.dataset } },
                    });
                  }}
                  className="btn btn-outline-danger btn-sm  px-3 text-sm"
                >
                  Delete Schedule
                </button>
              </div>
              {i !== from.length - 1 && (
                <div className="dropdown-divider"></div>
              )}
            </>
          ))}
        </>
      );
    } else {
      return null;
    }
  }

  fetchDoctors() {
    this.setState({
      loading: true,
    });
    let API = new Apilib();
    API.getUsers(3, this.props.userData.hospitalId)
      .then((res) => res.json())
      .then((res) => {
        if (res?.doctors) {
          this.setState({
            doctors: res.doctors,
            loading: false,
          });
        }
      })
      .catch((error) => {
        if (this._isMounted) {
          this.setState({});
        }
      });
  }

  onSelect = (val, name) => {
    console.log("val-->", val);
    // let formObj = { ...this.state.formData };
    // formObj[name] = val.value;
    // if (name == "patientId") {
    //   formObj["name"] = val.label || "";
    //   formObj["phoneno"] = val.phoneno || "";
    //   formObj["email"] = val.email || "";
    //   formObj["patient_email"] = val.email || "";
    // }
    if (name == "doctorId") {
      // formObj["doctor_email"] = val.email;
      // formObj["doctorName"] = val.label;
      // formObj["base_fee"] = val.base_fee;

      // this.fetchSchedule(val.value);
      this.setState({
        userData: { ...val },
      });
      this.createCalendarEvents(val);
    }
  };

  //------- Start of FullCalendar --------//
  createCalendarEvents(userData) {
    console.log({ userData });
    // set Calendar Schedule
    let daysBooked = userData.days || [];
    let calendarEvents = [];
    if (userData?.days) {
      daysBooked.map((user_day) => {
        var start = moment(),
          end = moment().add(90, "days"),
          day = user_day != 7 ? user_day : 0;

        var result = [];
        var current = start.clone().day(day);

        if (current.isSameOrAfter(start)) {
          result.push(current.clone());
        }

        while (current.day(7 + day).isBefore(end)) {
          result.push(current.clone());
        }

        result.map((res) => {
          console.log(
            "test date:",
            start.format("YYYY-MM-DD") == res.format("YYYY-MM-DD"),
            start == res
          );
          userData.from[user_day].map((time, i) => {
            let valid = true;
            if (start.format("YYYY-MM-DD") == res.format("YYYY-MM-DD")) {
              if (moment() > moment(userData.to[user_day][i], "h:mm a")) {
                valid = false;
              }
            }

            // check if slot end time is not greater than current time(temporary check)
            if (valid) {
              calendarEvents.push({
                id: this.createEventId(),
                title: "",
                start:
                  res.format("YYYY-MM-DD") +
                  "T" +
                  moment(userData.from[user_day][i], "h:mm a").format("H:mm"),
                end:
                  res.format("YYYY-MM-DD") +
                  "T" +
                  moment(userData.to[user_day][i], "h:mm a").format("H:mm"),
                data: {
                  // Delete Body
                  uid: userData.uid,
                  days: [user_day],
                  from: {
                    [user_day]: [userData.from[user_day][i]],
                  },
                  to: {
                    [user_day]: [userData.to[user_day][i]],
                  },
                  date: res,
                },
              });
            }
          });
        });
      });
      console.log({ calendarEvents });
      this.setState({
        calendar: calendarEvents,
      });
    }
  }

  handleDateSelect(selectInfo) {
    let title = prompt("Please enter a new title for your event");
    let calendarApi = selectInfo.view.calendar;

    calendarApi.unselect(); // clear date selection

    if (title) {
      calendarApi.addEvent({
        id: this.createEventId(),
        title,
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        allDay: selectInfo.allDay,
      });
    }
  }

  renderEventContent = (eventInfo) => {
    console.log("renderEventContent", eventInfo);
    let data = eventInfo?.event?._def?.extendedProps?.data,
      day = data.days[0],
      date = data.date;
    const popover = (
      <Popover id="popover-basic" style={{ minWidth: "200px" }}>
        <Popover.Title as="h5" style={{ margin: 0 }}>
          {/* {eventInfo.event.title} */}
          <>{moment(date).format("dddd, MMMM Do YYYY")}</>
        </Popover.Title>
        <Popover.Content>
          <Row>
            <Col lg="5">
              <b>From:</b> {data.from[day][0]}
              <br />
              <b>To:</b> {data.to[day][0]}
            </Col>
            <Col lg="7">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  this.openDeleteModal(data);
                }}
                className="btn btn-outline-danger btn-sm  px-3 text-sm"
              >
                Delete Schedule
              </button>
            </Col>
          </Row>
          {/* <>
            Teacher :{" "}
            {eventInfo?.event?._def?.extendedProps?.data?.teacher_name} <br />
            Mode :{" "}
            {eventInfo?.event?._def?.extendedProps?.data?.mode == 1
              ? "Lecture"
              : "Practical"}{" "}
            <br />
            Time:{" "}
            {
              <>
                {eventInfo?.event?._def?.extendedProps?.data?.time
                  ? moment(
                      eventInfo?.event?._def?.extendedProps?.data?.time
                    ).format("hh:mm a")
                  : null}{" "}
                -{" "}
                {eventInfo?.event?._def?.extendedProps?.data?.endtime
                  ? moment(
                      eventInfo?.event?._def?.extendedProps?.data?.endtime
                    ).format("hh:mm a")
                  : null}
                <br />
              </>
            }
          </> */}
        </Popover.Content>
      </Popover>
    );
    console.log("renderEventContent2");
    return (
      <OverlayTrigger
        placement="left"
        overlay={popover}
        trigger="click"
        rootClose
      >
        <div className="hide-overflow" style={{ width: "100%" }}>
          <span className="fc-event-title">
            {data.from[day][0]} - {data.to[day][0]}
          </span>
        </div>
      </OverlayTrigger>
    );
    // return (
    //   <>
    //     <div className="hide-overflow">
    //       {eventInfo.timeText}
    //       <br />
    //       {eventInfo.event.title}
    //     </div>
    //   </>
    // );
  };

  newDateFormat(date) {
    let newDate =
      moment(date.start).format("D") +
      " - " +
      moment(date.end).format("D MMM, YYYY");
    return newDate;
  }

  createEventId() {
    this.setState({ eventGuid: this.state.eventGuid++ });
    return String(this.state.eventGuid);
  }
  // End of FullCalendar

  render() {
    let daysTxt = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    let userData = this.state.userData;
    let daysBooked = userData.days || [];
    if (daysBooked.length) {
      daysBooked.sort();
    }
    let daysToShow = [];
    available_days.map((day, i) => {
      // if(!daysBooked.includes(day.value)){
      daysToShow.push(day);
      // }
    });
    // console.log(daysToShow);
    daysTxt.map((value, index) => {
      console.log("-----Schedule--------", value);
      let dayArr = userData.from ? userData.from[index + 1] : [];
      console.log(dayArr);
    });
    let doctors = [];
    if (this.state.doctors.length) {
      this.state.doctors.map((item, i) => {
        doctors.push({
          ...item,
          value: item.uid,
          label: item.firstName + " " + item.lastName,
        });
      });
    }

    return (
      <React.Fragment>
        {this.state.loading ? <Loader /> : null}
        <div className="row">
          <div className="col-md-12">
            {this.state.error && (
              <div className="alert alert-danger fade show" role="alert">
                {this.state.error}
              </div>
            )}
            {this.state.success && (
              <div className="alert alert-success fade show" role="alert">
                Schedule added successfully
              </div>
            )}
            {this.state.deleted && (
              <div className="alert alert-success fade show" role="alert">
                Schedule deleted successfully
              </div>
            )}
            <form onSubmit={(e) => this.onSubmit(e)}>
              <div className="row">
                {this.props?.type == 4 && (
                  <div className="col-sm-6">
                    <div className="form-group">
                      <label>
                        Doctor <span className="text-danger">*</span>
                      </label>
                      <Select
                        required
                        value={this.state.userData}
                        onChange={(item) => this.onSelect(item, "doctorId")}
                        options={doctors}
                      />
                    </div>
                  </div>
                )}
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Available Days</label>
                    <Select
                      required
                      onChange={(val) => this.onDaySelect(val)}
                      value={this.state.selectedDaysObj}
                      options={daysToShow}
                      noOptionsMessage={() => "All Days Booked"}
                      isMulti={true}
                      closeMenuOnSelect={false}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Slot Duration (mins)</label>
                    <input
                      className="form-control"
                      type="number"
                      required
                      name="slot_duration"
                      value={this.state.slot}
                      onChange={(e) => this.setState({ slot: e.target.value })}
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6 text-center">
                  <div className="form-group">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        label="Start Time"
                        value={moment(this.state.startTime, "h:mm a")}
                        onChange={(val) => this.onTimeSelect(val, "startTime")}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                      />
                    </MuiPickersUtilsProvider>

                    {/* <label>Start Time</label>
                    <div className="time-icon">
                      <Datetime
                        inputProps={{ required: true }}
                        value={this.state.startTime}
                        onChange={(val) => this.onTimeSelect(val, "startTime")}
                        input={false}
                        dateFormat={false}
                        viewMode="time"
                      />
                    </div> */}
                  </div>
                </div>
                <div className="col-md-6 text-center">
                  <div className="form-group">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        label="End Time"
                        value={moment(this.state.endTime, "h:mm a")}
                        onChange={(val) => this.onTimeSelect(val, "endTime")}
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                      />
                    </MuiPickersUtilsProvider>

                    {/* <label>End Time</label>
                    <div className="time-icon">
                      <Datetime
                        inputProps={{ required: true }}
                        value={this.state.endTime}
                        onChange={(val) => this.onTimeSelect(val, "endTime")}
                        input={false}
                        dateFormat={false}
                        viewMode="time"
                      /> */}
                    {/* </div> */}
                  </div>
                </div>
              </div>
              <div className="m-t-20 text-center">
                <button className="btn btn-primary submit-btn">
                  Save Schedule
                </button>
              </div>
            </form>
          </div>
          {userData.days ? (
            <div className="col-md-12 mt-3">
              <div className="d-flex justify-content-between">
                <h3>Current Schedule</h3>
                <span
                  className="py-1 px-2 ml-1"
                  style={{
                    backgroundColor: "rgb(249 249 249)",
                    boxSizing: "border-box",
                    borderRadius: 5,
                    fontWeight: "600",
                    fontSize: "1rem",
                    color: "#828282",
                    width: "fit-content",
                    height: "fit-content",
                  }}
                >
                  Slot Duration : {userData.slot_duration.split(":")[0]} mins
                </span>
              </div>
              <div className="full-calendar">
                <div className="full-calendar-main">
                  <FullCalendar
                    plugins={[
                      dayGridPlugin,
                      timeGridPlugin,
                      interactionPlugin,
                      momentPlugin,
                    ]}
                    headerToolbar={{
                      left: "prev,next today",
                      center: "title",
                      // right: "timeGridWeek,timeGridDay",
                      right: "dayGridMonth,timeGridWeek,timeGridDay",
                    }}
                    initialView="timeGridWeek"
                    editable={false}
                    selectable={false}
                    selectMirror={true}
                    dayMaxEvents={true}
                    weekends={true}
                    views={{
                      timeGridWeek: {
                        titleFormat: this.newDateFormat,
                      },
                    }}
                    dayHeaderFormat={({ date }) => {
                      return moment(date).format("D MMM");
                    }}
                    initialEvents={[]} // alternatively, use the `events` setting to fetch from a feed
                    select={this.handleDateSelect}
                    eventContent={this.renderEventContent} // custom render function
                    events={this.state.calendar}
                    contentHeight="auto"
                  />
                </div>
              </div>
              {/* <div className="table-responsive">
                <table
                  className="table table-striped table-bordered dt-responsive nowrap"
                  cellSpacing="0"
                  width="100%"
                >
                  <thead>
                    <tr>
                      <th>Day</th>
                      <th>Slot Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {daysTxt.map((value, index) => (
                      <tr key={index}>
                        <td>{value}</td>
                        <td>
                          {this.renderSchedule(
                            userData.from[index + 1],
                            userData.to[index + 1],
                            index
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div> */}
            </div>
          ) : null}

          {/* <div className="col-md-12 col-sm-12 col-12">
            
          </div> */}
        </div>
        <Modal
          show={this.state.isDeleteModalOpen}
          onHide={this.closeDeleteModal}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Schedule</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="p-2">
              Are you sure you want delete schedule of{" "}
              <b>{daysTxt[+this.state.deleteEvent?.days[0] - 1]}</b>{" "}
              <b>{`${
                this.state.deleteEvent?.from[this.state.deleteEvent?.days[0]][0]
              } to ${
                this.state.deleteEvent?.to[this.state.deleteEvent?.days[0]][0]
              }`}</b>
              ?
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeDeleteModal}>
              Close
            </Button>
            <Button
              variant="danger"
              onClick={() => this.handleDelete(this.state.deleteEvent)}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  }
}
