import axios from "axios";

let baseUrl = "https://australia-southeast1-medvatic-438af.cloudfunctions.net/";
// let baseUrl = "http://localhost:5001/medvatic-438af/australia-southeast1/";

export default class Api {
  constructor() {}

  create(data) {
    return fetch(baseUrl + "user/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });
  }

  addHospitalChain(data) {
    return fetch(baseUrl + "hospital/addHospitalChain", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });
  }

  editHospitalChain(data) {
    return fetch(baseUrl + "hospital/editHospitalChain", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });
  }

  addHospital(data) {
    return fetch(baseUrl + "hospital/addHospital", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });
  }

  deleteHospital(data) {
    //"http://localhost:5001/medvatic-438af/australia-southeast1/"
    return fetch(baseUrl + "user/delete", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });
  }

  deleteAdmin(data) {
    return fetch(baseUrl + "user/deleteAdmin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });
  }

  deleteReceptionist(data) {
    return fetch(baseUrl + "user/delete", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });
  }

  deletePatient(data) {
    return fetch(baseUrl + "user/delete", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });
  }
  deleteDoctor(data) {
    return fetch(baseUrl + "user/delete", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });
  }
  deleteAppointment(data) {
    return fetch(baseUrl + "appointment/deleteAppointment", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });
  }

  deleteMemberFamily(data) {
    return fetch(baseUrl + "user/deleteMemberFamily", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });
  }

  rejectRequest(data) {
    return fetch(baseUrl + "user/rejectRequest", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });
  }

  listFamilyMembers(uid) {
    return fetch(baseUrl + "user/viewFamilyMembers?patient_id=" + uid, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  viewHospitalChain() {
    return fetch(baseUrl + "hospital/viewHospitalChain", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getAllHospitals() {
    return fetch(baseUrl + "hospital/viewHospital", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  updateUser(data) {
    return fetch(baseUrl + "user/update", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });
  }

  addSchedule(data) {
    return fetch(baseUrl + "appointment/addSchedule", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });
  }

  deleteSchedule(data) {
    return fetch(baseUrl + "appointment/deleteSchedule", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });
  }

  changePassword(data) {
    return fetch(baseUrl + "user/changePwd", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });
  }

  getUserDetails(type, uid) {
    return fetch(
      baseUrl + "user/fetchUserDetails?type=" + type + "&uid=" + uid,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }
  //type = 2 hospital Admins fetching

  getUsers(type, hospitalId = "") {
    //http://localhost:5001/medvatic-438af/australia-southeast1/
    return fetch(
      baseUrl + "user/view?type=" + type + "&hospitalId=" + hospitalId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  //View Doctor Requests
  fetchStoredDoctors(hospital_code) {
    return fetch(
      baseUrl + "content/fetchStoredDoctors?hospital_code=" + hospital_code,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  //Update Doctor Registration
  updateDoctorRegistration(data) {
    return fetch(baseUrl + "content/updateDoctorRegistration", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });
  }

  forgetPassword(type, email) {
    return fetch(baseUrl + "user/forgotPwd?type=" + type + "&email=" + email, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  addAppointment(data) {
    return fetch(baseUrl + "appointment/addAppointment", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });
  }

  listAppointments(hospitalId) {
    return fetch(
      baseUrl + "appointment/viewAppointment?hospitalId=" + hospitalId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  listAppointmentsReceptionist(hospitalId) {
    return fetch(
      baseUrl +
        "appointment/viewAppointment?hospitalId=" +
        hospitalId +
        "&userType=4",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }
  listAppointmentsHospital(hospitalId) {
    return fetch(
      baseUrl +
        "appointment/viewAppointment?hospitalId=" +
        hospitalId +
        "&userType=2",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }
  listAppointmentsDoctor(hospitalId, doctorId, type) {
    return fetch(
      baseUrl +
        "appointment/viewAppointment?hospitalId=" +
        hospitalId +
        "&doctorId=" +
        doctorId +
        "&userType=" +
        type,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  listPatientAppointments(hospitalId, uid) {
    return fetch(
      baseUrl +
        "appointment/viewAppointment?hospitalId=" +
        hospitalId +
        "&patientId=" +
        uid +
        "&userType=5",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  listSinglePatientAppointments(hospitalId, uid, patientID) {
    return fetch(
      baseUrl +
        "appointment/viewAppointment?hospitalId=" +
        hospitalId +
        "&doctorId=" +
        uid +
        "&userType=3&patientId=" +
        patientID,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  updateAppointment(data) {
    return fetch(baseUrl + "appointment/updateAppointment", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });
  }

  updateAppointmentRecep(data) {
    return fetch(baseUrl + "appointment/updateAppointmentRecep", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });
  }

  addAppointmentReport(data) {
    return fetch(baseUrl + "appointment/addAppointmentReport", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });
  }

  deleteAppointmentReport(data) {
    return fetch(baseUrl + "appointment/deleteAppointmentReport", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });
  }

  addDoctorAppointmentReport(data) {
    return fetch(baseUrl + "appointment/addDoctorAppointmentReport", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });
  }

  deleteDoctorAppointmentReport(data) {
    return fetch(baseUrl + "appointment/deleteDoctorAppointmentReport", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });
  }

  // baseUrl + "appointment/listSchedule?doctorId="+doctorId+"&from="+from+"&to="+to
  listSchedule(doctorId, from, to) {
    return fetch(
      baseUrl +
        "appointment/listSchedule?doctorId=" +
        doctorId +
        "&fromDate=" +
        from +
        "&toDate=" +
        to,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  getToken() {
    return fetch("https://www.universal-tutorial.com/api/getaccesstoken", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "api-token":
          "1R13xUH_kpgbgReWc5tK-eqwORdWtxLEcq-9upGiZ2w4kyngfje1kKPzzZhzrGq933U",
        "user-email": "murtazamzk@gmail.com",
      },
    });
  }

  getCountries() {
    return fetch("https://countriesnow.space/api/v0.1/countries/positions", {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    });
  }

  getStates(country, token) {
    return fetch("https://countriesnow.space/api/v0.1/countries/states", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ country }),
    });
  }

  getCities(country, state) {
    return fetch("https://countriesnow.space/api/v0.1/countries/state/cities", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        country,
        state,
      }),
    });
  }

  contactUs(data) {
    return fetch(baseUrl + "content/sendMail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });
  }

  addPatient(data) {
    return fetch(baseUrl + "user/addPatient", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });
  }

  joinFamily(data) {
    return fetch(baseUrl + "user/joinFamily", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });
  }

  getFamilyRequests(uid) {
    return fetch(baseUrl + "user/fetchFamilyRequests?uid=" + uid, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  addFamilyMember(data) {
    return fetch(baseUrl + "user/addMemberFamily", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });
  }

  isUserExist(data) {
    return fetch(baseUrl + "user/isUserExist", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });
  }

  getDoctorSchedule(id) {
    return fetch(baseUrl + "appointment/scheduleDetails?doctor_id=" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getAppointmentDetails(id) {
    return fetch(
      baseUrl + "appointment/fetchAppointmentDetails?appointment_id=" + id,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  selfRegistrationDoctor(data) {
    return fetch(baseUrl + "content/collectDoctors", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });
  }
  //search-1
  getLocation() {
    return fetch("https://ipapi.co/json", {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    });
  }
  //search-2
  getSpecialities() {
    return fetch(baseUrl + "content/listSpeciality", {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    });
  }

  addNewSpeciality(data) {
    return fetch(baseUrl + "content/addSpeciality", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });
  }

  updateSpeciality(data) {
    return fetch(baseUrl + "content/editSpeciality", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });
  }

  deleteSpeciality(data) {
    return fetch(baseUrl + "content/deleteSpeciality", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });
  }

  fetchComments(id, commenttype) {
    return fetch(
      baseUrl +
        "appointment/viewComments?appointment_id=" +
        id +
        "&commenttype=" +
        commenttype,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );
  }

  addComment(data) {
    return fetch(baseUrl + "appointment/addComment", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });
  }
  editComment(data) {
    return fetch(baseUrl + "appointment/editComment", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });
  }
}
