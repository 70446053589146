import { Formik } from "formik";
import React, { Component } from "react";
import * as Yup from "yup";
import Apilib from "../../api";
import { getDefaultPageUrl } from "../../Helpers/constants";
import Notify from "../../Notify";
import Loader from "../Loader/Loader";

export default class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldHide:true,
      newHide:true,
      reEnterHide:true,
      loading: false,
      error: "",
      success: false,
      // password:"",
      password0: "",
      password1: "",
      password2: "",
    };
    this.props = props;
  }

  onChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = async (values, { resetForm }) => {
    if (values.password1 !== values.password2) {
      Notify({
        alert: true,
        type: "error",
        title: "Password not match",
      });
      return;
    }

    const api = new Apilib();

    try {
      this.setState({ loading: true });
      const response = await (
        await api.changePassword(
          JSON.stringify({
            type: +this.props.user.displayName,
            uid: this.props.user.uid,
            password: values.password2,
            oldPassword: values.password0,
          })
        )
      ).json();
      if (response.code && response.code !== 200) {
        this.setState({ loading: false });
        Notify({
          alert: true,
          type: "error",
          title: response.message,
        });
        return;
      }
      this.setState({
        loading: false,
        password0: "",
        password1: "",
        password2: "",
      });
      Notify({
        alert: true,
        type: "success",
        title: "Password changed successfully",
      });
      this.props.history.push(getDefaultPageUrl(this.props.user.displayName));
      resetForm();
    } catch (error) {
      this.setState({ loading: false });
      Notify({
        alert: true,
        type: "alert",
        title: "Failed to change password",
      });
    }

    // await auth.signInWithEmailAndPassword(this.props.user.email,values.password2)
    // .then((res)=>{
    //   if(!res.user){
    //     Notify({
    //       alert: true,
    //       type: "error",
    //       title: "Old password not match",
    //     })
    //     return
    //   }
    // }).catch((e)=>{
    //   if(e){
    //     Notify({
    //       alert: true,
    //       type: "error",
    //       title: "Old password not match",
    //     })
    //     return
    //   }
    // })
  };

  render() {
    return (
      <React.Fragment>
        {/* <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection}}>
          <div>

          </div>
          <div>

          </div>
        </div> */}
        {this.state.loading && <Loader />}
        <div className="container py-4">
        <div className="row justify-content-center align-items-conter">
          <div className="col-sm-4 col-3 d-flex flex-row justify-content-center align-items-conter">
            <h4 className="page-title">Change Password</h4>
          </div>
        </div>
        {this.state.error && (
          <div
            className="alert alert-danger alert-dismissible fade show"
            role="alert"
          >
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
              <span className="sr-only">Close</span>
            </button>
            {this.state.error}
          </div>
        )}
        {this.state.success && (
          <div
            className="alert alert-success alert-dismissible fade show"
            role="alert"
          >
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
              <span className="sr-only">Close</span>
            </button>
            Password changed successfully
          </div>
        )}
        <div className="row justify-content-center">
          <div className="d-flex flex-row justify-content-center align-items-center">
          <div className="col-md-12">
            <Formik
              initialValues={{
                password0: "",
                password1: "",
                password2: "",
              }}
              onSubmit={this.onSubmit}
              validationSchema={Yup.object({
                password0: Yup.string()
                  .required("Old Password is required")
                  .trim(),
                password1: Yup.string()
                  .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
                    "Password length must be 6 and contain 1 Uppercase, 1 Lowercase, 1 Special Character, 1 Number."
                  )
                  .label("New password"),
                password2: Yup.string()
                  .oneOf([Yup.ref("password1"), null], "Password should match with New Password!")
                  .label("Confirm Password"),
              })}
            >
              {({ handleSubmit, handleChange, values, errors }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                      <div className="pass-input">
                        <label>
                          Old Password<span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          name="password0"
                          required
                          onChange={handleChange("password0")}
                          value={values.password0}
                          type="password"
                          placeholder="Enter Password"
                          type={this.state.oldHide ? "password" : "text"}
                        />
                        <div>
                        <i onClick={()=> this.setState({oldHide:false})} class={!this.state.oldHide ? "hide-eye-icon eye-icon" : "fa fa-eye eye-icon"} aria-hidden="true"></i>
                        <i onClick={()=> this.setState({oldHide:true})} class={this.state.oldHide ? "hide-eye-icon eye-icon" : "fa fa-eye-slash eye-icon"} aria-hidden="true"></i>
                      </div>
                        </div>
                        <small className="text-danger form-text">
                          {errors.password0}
                        </small>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                      <div className="pass-input">
                        <label>
                          New Password<span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          name="password1"
                          required
                          onChange={handleChange("password1")}
                          value={values.password1}
                          type={this.state.newHide ? "password" : "text"}
                          placeholder="Enter Password"
                        />
                        <div>
                        <i onClick={()=> this.setState({newHide:false})} class={!this.state.newHide ? "hide-eye-icon eye-icon" : "fa fa-eye eye-icon"} aria-hidden="true"></i>
                        <i onClick={()=> this.setState({newHide:true})} class={this.state.newHide ? "hide-eye-icon eye-icon" : "fa fa-eye-slash eye-icon"} aria-hidden="true"></i>
                        </div>
                        </div>
                        <small className="text-danger form-text">
                          {errors.password1}
                        </small>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                      <div className="pass-input">
                        <label>
                          Re-enter Password
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          name="password2"
                          required
                          onChange={handleChange("password2")}
                          value={values.password2}
                          type="password"
                          placeholder="Enter Password"
                          type={this.state.reEnterHide ? "password" : "text"}
                        />
                        <div>
                        <i onClick={()=> this.setState({reEnterHide:false})} class={!this.state.reEnterHide ? "hide-eye-icon eye-icon" : "fa fa-eye eye-icon"} aria-hidden="true"></i>
                        <i onClick={()=> this.setState({reEnterHide:true})} class={this.state.reEnterHide ? "hide-eye-icon eye-icon" : "fa fa-eye-slash eye-icon"} aria-hidden="true"></i>
                      </div>
                        </div>
                        <small className="text-danger form-text">
                          {errors.password2}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="m-t-20 d-flex justify-content-center align-items-center">
                    <button style={{backgroundColor:"#50D8FA"}} className="btn btn-primary submit-btn">
                      Change Password
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
          </div>
          
        </div>
        </div>
      </React.Fragment>
    );
  }
}
