import React, { Component } from 'react'

export default class MobileTerms extends Component {
    render() {
        return (
            <section class="section-padding">
          <div class="section-header2">
                <h3 class="header-title text-center">MOBILE APP TERMS AND CONDITIONS OF USE</h3>
                <div class="line"></div>
                <div class="policy" >
                    <ol>
                        <li class="policy_heading" >About the Application</li>
                        <ol>
                            <li class="policy_heading">Welcome to Medvatic (the 'Application '). The Application Post-consultative
                                patient care management, measurement request recording, medication
                                remainder, appointment scheduling, Care team management, exercise
                                activities , treatment plan creation and management, Lab reports, Incidents
                                report etc. (the 'Services').
                            </li>
                            <li class="policy_heading">The Application is operated by Medvatic PTY. LTD. (ABN 18621062486) .
                                Access to and use of the Application, or any of its associated Products or
                                Services, is provided by Medvatic. Please read these terms and conditions
                                (the 'Terms') carefully. By using, browsing and/or reading the Application, this
                                signifies that you have read, understood and agree to be bound by the Terms.
                                If you do not agree with the Terms, you must cease usage of the Application,
                                or any of its Services, immediately.
                                
                            </li>
                            <li class="policy_heading">
                                Medvatic reserves the right to review and change any of the Terms by updating
                                this page at its sole discretion. When Medvatic updates the Terms, it will use
                                reasonable endeavours to provide you with notice of updates to the Terms. Any
                                changes to the Terms take immediate effect from the date of their publication.
                                Before you continue, we recommend you keep a copy of the Terms for your
                                records.
                            </li>
                        </ol>
                        <li class="policy_heading">Acceptance of the Terms</li>
                        <ol style={{"listStyle": "none"}}>
                            <li class="policy_heading">You accept the Terms by using or browsing the Application. You may also accept the
                                Terms by clicking to accept or agree to the Terms where this option is made available to
                                you by Medvatic in the user interface.</li>
                        </ol>
                        <li class="policy_heading">Registration to use the Services</li>
                        <ol>
                            <li class="policy_heading">In order to access the Services, you must first register for an account
                                through the Application (the 'Account').
                            </li>
                            <li class="policy_heading" >
                                As part of the registration process, or as part of your continued use of the
                                Services, you may be required to provide personal information about yourself
                                (such as identification or contact details), including:

                            </li>
                            <li class="policy_heading" >
                                You warrant that any information you give to Medvatic in the course of
                                completing the registration process will always be accurate, correct and up to
                                date.
                                <ol style={{"listStyle": "lower-alpha"}}>
                                    <li class="policy_heading">
                                        Email address
                                    </li>
                                    <li class="policy_heading">
                                        Mailing address
                                    </li>
                                    <li class="policy_heading">
                                        Telephone number
                                    </li>
                                    <li class="policy_heading">
                                        Password
                                    </li>
                                    <li class="policy_heading">
                                        Full name , date of birth, place of birth, gender , employment type
                                    </li>
                                </ol>
                            </li>
                            <li class="policy_heading" >
                                Once you have completed the registration process, you will be a registered
                                member of the Application ('Member ') and agree to be bound by the Terms.
                            </li>
                            <li class="policy_heading">
                                You may not use the Services and may not accept the Terms if:
                                <ol style={{"listStyle": "lower-alpha"}}>
                                    <li class="policy_heading">
                                        you are not of legal age to form a binding contract with Medvatic; or
                                    </li>
                                    <li class="policy_heading">
                                        you are a person barred from receiving the Services under the laws of
                                        Australia or other countries including the country in which you are resident
                                        or from which you use the Services.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        <li class="policy_heading">Your obligations as a Member</li>
                        <ol>
                            <li class="policy_heading">As a Member, you agree to comply with the following:
                                <ol style={{"listStyle": "lower-alpha"}}>
                                    <li class="policy_heading">
                                        you will use the Services only for purposes that are permitted by:
                                        <ol style={{"listStyle": "lower-roman"}}>
                                            <li class="policy_heading">the Terms; and</li>
                                            <li class="policy_heading">any applicable law, regulation or generally accepted practices or
                                                guidelines in the relevant jurisdictions;
                                                </li>
                                        </ol>
                                    </li>
                                    <li class="policy_heading">
                                        you have the sole responsibility for protecting the confidentiality of your
                                        password and/or email address. Use of your password by any other
                                        person may result in the immediate cancellation of the Services;

                                    </li>
                                    <li class="policy_heading">
                                        any use of your registration information by any other person, or third
                                        parties, is strictly prohibited. You agree to immediately notify Medvatic of
                                        any unauthorised use of your password or email address or any breach of
                                        security of which you have become aware;
                                    </li>
                                    <li class="policy_heading">
                                        access and use of the Application is limited, non-transferable and allows
                                        for the sole use of the Application by you for the purposes of Medvatic
                                        providing the Services;
                                    </li>
                                    <li class="policy_heading">
                                        you will not use the Services or the Application in connection with any
                                        commercial endeavours except those that are specifically endorsed or
                                        approved by the management of Medvatic;
                                    </li>
                                    <li class="policy_heading">
                                        you will not use the Services or Application for any illegal and/or
                                        unauthorised use which includes collecting email addresses of Members
                                        by electronic or other means for the purpose of sending unsolicited email
                                        or unauthorised framing of or linking to the Application;
                                    </li>
                                    <li class="policy_heading">
                                        you agree that commercial advertisements, affiliate links, and other forms
                                        of solicitation may be removed from the Application without notice and
                                        may result in termination of the Services. Appropriate legal action will be
                                        taken by Medvatic for any illegal or unauthorised use of the Application;
                                        and
                                    </li>
                                    <li class="policy_heading">
                                        you acknowledge and agree that any automated use of the Application or
                                        its Services is prohibited.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        <li class="policy_heading">Payment</li>
                        <ol>
                            <li class="policy_heading"> Where the option is given to you, you may make payment for the Services
                                (the 'Services Fee ') by way of:
                                <ol style={{"listStyle": "lower-alpha"}}>
                                    <li class="policy_heading">
                                        Credit Card Payment ('Credit Card ')
                                    </li>
                                    <li class="policy_heading">
                                        PayPal ('PayPal')
                                    </li>
                                </ol>
                            </li>
                            <li class="policy_heading">
                                All payments made in the course of your use of the Services are made using
                                Stripe or PayPal. In using the Application, the Services or when making any
                                payment in relation to your use of the Services, you warrant that you have
                                read, understood and agree to be bound by the Stripe or PayPal terms and
                                conditions which are available on their Application.
                            </li>
                            <li class="policy_heading">
                                You acknowledge and agree that where a request for the payment of the
                                Services Fee is returned or denied, for whatever reason, by your financial
                                institution or is unpaid by you for any other reason, then you are liable for any
                                costs, including banking fees and charges, associated with the Services Fee.
                            </li>
                            <li class="policy_heading">
                                You agree and acknowledge that Medvatic can vary the Services Fee at any
                                time .
                            </li>
                        </ol>

                        <li class="policy_heading">Refund Policy</li>
                        <ol style={{"listStyle": "none"}}>
                            <li class="policy_heading">Medvatic will only provide you with a refund of the Services Fee in the event they are
                                unable to continue to provide the Services or if the manager of Medvatic makes a
                                decision, at its absolute discretion, that it is reasonable to do so under the circumstances
                                (the 'Refund ').
                            </li>
                        </ol>
                        <li class="policy_heading">Copyright and Intellectual Property</li>
                        <ol>
                            <li class="policy_heading">The Application, the Services and all of the related products of Medvatic are
                                subject to copyright. The material on the Application is protected by copyright
                                under the laws of Australia and through international treaties. Unless otherwise
                                indicated, all rights (including copyright) in the Services and compilation of the
                                Application (including but not limited to text, graphics, logos, button icons, video
                                images, audio clips, Application, code, scripts, design elements and interactive
                                features) or the Services are owned or controlled for these purposes, and are
                                reserved by Medvatic or its contributors.
                            </li>
                            <li class="policy_heading">
                                All trademarks, service marks and trade names are owned, registered and/or
                                licensed by Medvatic, who grants to you a worldwide, non-exclusive, royaltyfree, revocable license whilst you are a Member to:
                                <ol style={{"listStyle": "lower-alpha"}}>
                                    <li class="policy_heading">use the Application pursuant to the Terms;</li>
                                    <li class="policy_heading">copy and store the Application and the material contained in the
                                        Application in your device's cache memory; and</li>
                                    <li class="policy_heading">print pages from the Application for your own personal and noncommercial use.
                                    </li>
                                </ol>
                                Medvatic does not grant you any other rights whatsoever in relation to the
                                Application or the Services. All other rights are expressly reserved by
                                Medvatic.
                            </li>
                            
                            <li class="policy_heading">
                                Medvatic retains all rights, title and interest in and to the Application and all
                                related Services. Nothing you do on or in relation to the Application will transfer any:
                                <ol style={{"listStyle": "lower-alpha"}}>
                                <li class="policy_heading">
                                    business name, trading name, domain name, trade mark, industrial
                                    design, patent, registered design or copyright, or
                                </li>
                                <li class="policy_heading">
                                    a right to use or exploit a business name, trading name, domain name,
                                    trade mark or industrial design, or
                                </li>
                                <li class="policy_heading">
                                    a thing, system or process that is the subject of a patent, registered
                                    design or copyright (or an adaptation or modification of such a thing,
                                    system or process),
                                </li>
                            </ol>
                                to you.
                            </li>
                            <li class="policy_heading">
                                You may not, without the prior written permission of Medvatic and the
                                permission of any other relevant rights owners: broadcast, republish, up-load to
                                a third party, transmit, post, distribute, show or play in public, adapt or change
                                in any way the Services or third party Services for any purpose, unless
                                otherwise provided by these Terms. This prohibition does not extend to
                                materials on the Application, which are freely available for re-use or are in the
                                public domain.
                            </li>
                        </ol>
                        <li class="policy_heading">Privacy</li>
                        <ol style={{"listStyle": "none"}}>
                            <li class="policy_heading">Medvatic takes your privacy seriously and any information provided through your use of
                                the Application and/or Services are subject to Medvatic's Privacy Policy, which is
                                available on the Application and at www.Medvatic.com/privacy-policy.
                                
                            </li>
                        </ol>
                        
                        <li class="policy_heading">General Disclaimer</li>
                        <ol>
                            <li class="policy_heading">Nothing in the Terms limits or excludes any guarantees, warranties,
                                representations or conditions implied or imposed by law, including the
                                Australian Consumer Law (or any liability under them) which by law may not be
                                limited or excluded.
                            </li>
                            <li class="policy_heading">Subject to this clause, and to the extent permitted by law:
                                <ol style={{"listStyle": "lower-alpha"}}>
                                    <li class="policy_heading">all terms, guarantees, warranties, representations or conditions which are
                                        not expressly stated in the Terms are excluded; and</li>
                                    <li class="policy_heading">Medvatic will not be liable for any special, indirect or consequential loss or
                                        damage (unless such loss or damage is reasonably foreseeable resulting
                                        from our failure to meet an applicable Consumer Guarantee), loss of
                                        profit or opportunity, or damage to goodwill arising out of or in connection
                                        with the Services or these Terms (including as a result of not being able
                                        to use the Services or the late supply of the Services), whether at
                                        common law, under contract, tort (including negligence), in equity,
                                        pursuant to statute or otherwise.</li>
                                </ol>
                            </li>
                            <li class="policy_heading">Use of the Application and the Services is at your own risk. Everything on the
                                Application and the Services is provided to you "as is" and "as available"
                                without warranty or condition of any kind. None of the affiliates, directors,
                                officers, employees, agents, contributors and licensors of Medvatic make any
                                express or implied representation or warranty about the Services or any
                                products or Services (including the products or Services of Medvatic) referred
                                to on the Application. This includes (but is not restricted to) loss or damage
                                you might suffer as a result of any of the following:
                                <ol style={{"listStyle": "lower-alpha"}}>
                                    <li class="policy_heading">failure of performance, error, omission, interruption, deletion, defect,
                                        failure to correct defects, delay in operation or transmission, computer
                                        virus or other harmful component, loss of data, communication line failure,
                                        unlawful third party conduct, or theft, destruction, alteration or
                                        unauthorised access to records;</li>
                                    <li class="policy_heading">the accuracy, suitability or currency of any information on the Application,
                                        the Services, or any of its Services related products (including third party
                                        material and advertisements on the Application);</li>
                                    <li class="policy_heading">costs incurred as a result of you using the Application, the Services or any
                                        of the products of Medvatic; and
                                        </li>
                                    <li class="policy_heading">the Services or operation in respect to links which are provided for your
                                        convenience.
                                        </li>
                                </ol>
                            </li>
                        </ol>
                        <li class="policy_heading">Education Services
                            <ol>
                                <li class="policy_heading">By using our services, you agree that Medvatic is not to be held liable for any
                                    decisions you make based on any of our services or guidance and any
                                    consequences, as a result, are your own. Under no circumstances can you
                                    hold Medvatic liable for any actions you take nor can you hold us or any of
                                    our employees liable for any loss or costs incurred by you as a result of any
                                    guidance, advice, coaching, materials or techniques used or provided by
                                    Medvatic.</li>
                                <li class="policy_heading">All our information on both the website and in consultations is intended to assist
                                    you and does not in any way, nor is it intended to substitute professional,
                                    financial or legal advice. Results are not guaranteed and Medvatic takes no
                                    responsibility for your actions, choices or decisions.
                                    </li>
                            </ol>
                        </li>
                        <li class="policy_heading">Competitors
                            <ol style={{"listStyle": "none"}}>
                                <li class="policy_heading">If you are in the business of providing similar Services for the purpose of providing them
                                    to users for a commercial gain, whether business users or domestic users, then you are
                                    a competitor of Medvatic. Competitors are not permitted to use or access any
                                    information or content on our Application. If you breach this provision, Medvatic will hold
                                    you fully responsible for any loss that we may sustain and hold you accountable for all
                                    profits that you might make from such a breach.
                                    </li>
                            </ol>
                        </li>
                        <li class="policy_heading">Limitation of liability
                            <ol>
                                <li class="policy_heading">Medvatic's total liability arising out of or in connection with the Services or
                                    these Terms, however arising, including under contract, tort (including
                                    negligence), in equity, under statute or otherwise, will not exceed the resupply
                                    of the Services to you.
                                </li>
                                <li class="policy_heading">
                                    You expressly understand and agree that Medvatic, its affiliates, employees,
                                    agents, contributors and licensors shall not be liable to you for any direct,
                                    indirect, incidental, special consequential or exemplary damages which may be
                                    incurred by you, however caused and under any theory of liability. This shall
                                    include, but is not limited to, any loss of profit (whether incurred directly or
                                    indirectly), any loss of goodwill or business reputation and any other intangible
                                    loss.
                                </li>
                            </ol>
                        </li>
                        <li class="policy_heading">Termination of Contract
                            <ol>
                                <li class="policy_heading">The Terms will continue to apply until terminated by either you or by
                                    Medvatic as set out below.</li>
                                <li class="policy_heading">If you want to terminate the Terms, you may do so by:
                                    <ol style={{"listStyle": "lower-alpha"}}>
                                        <li class="policy_heading">not renewing the Subscription prior to the end of the Subscription Period;</li>
                                        <li class="policy_heading">providing Medvatic with 14 days' notice of your intention to terminate;
                                            and</li>
                                        <li class="policy_heading">closing your accounts for all of the services which you use, where
                                            Medvatic has made this option available to you</li>
                                    </ol>
                                    Your notice should be sent, in writing, to Medvatic via the 'Contact Us' link on
                                    our homepage.
                                </li>
                                <li class="policy_heading">Medvatic may at any time, terminate the Terms with you if:
                                    <ol style={{"listStyle": "lower-alpha"}}>
                                        <li class="policy_heading">you do not renew the Subscription at the end of the Subscription Period;</li>
                                        <li class="policy_heading">you have breached any provision of the Terms or intend to breach any
                                            provision;</li>
                                        <li class="policy_heading">Medvatic is required to do so by law;</li>
                                        <li class="policy_heading">the provision of the Services to you by Medvatic is, in the opinion of
                                            Medvatic, no longer commercially viable.</li>
                                    </ol>
                                </li>
                                <li class="policy_heading">Subject to local applicable laws, Medvatic reserves the right to discontinue or
                                    cancel your membership at any time and may suspend or deny, in its sole
                                    discretion, your access to all or any portion of the Application or the Services
                                    without notice if you breach any provision of the Terms or any applicable law or
                                    if your conduct impacts Medvatic's name or reputation or violates the rights of
                                    those of another party.</li>
                            </ol>
                        </li>
                        <li class="policy_heading">Indemnity
                            <ol>
                              <li class="policy_heading">You agree to indemnify Medvatic, its affiliates, employees, agents,
                                contributors, third party content providers and licensors from and against:
                                <ol style={{"listStyle": "lower-alpha"}}>
                                  <li class="policy_heading">all actions, suits, claims, demands, liabilities, costs, expenses, loss and
                                    damage (including legal fees on a full indemnity basis) incurred, suffered
                                    or arising out of or in connection with Your Content;</li>
                                  <li class="policy_heading">any direct or indirect consequences of you accessing, using or transacting
                                    on the Application or attempts to do so; and/or</li>
                                  <li class="policy_heading">any breach of the Terms.</li>
                                </ol>
                              </li>
                            </ol>
                        </li>
                        <li class="policy_heading"> Dispute Resolution
                          <ol>
                            <li class="policy_heading">Compulsory:
                              <ol style={{"listStyle": "none"}}>
                                <li class="policy_heading">If a dispute arises out of or relates to the Terms, either party may not
                                  commence any Tribunal or Court proceedings in relation to the dispute, unless
                                  the following clauses have been complied with (except where urgent
                                  interlocutory relief is sought).
                                </li>
                              </ol>
                            </li>
                            <li class="policy_heading">Notice:
                              <ol style={{"listStyle": "none"}}>
                                <li class="policy_heading">A party to the Terms claiming a dispute ('Dispute ') has arisen under the
                                  Terms, must give written notice to the other party detailing the nature of the
                                  dispute, the desired outcome and the action required to settle the Dispute.
                                </li>
                              </ol>
                            </li>
                            <li class="policy_heading">Resolution:
                              <ol style={{"listStyle": "none"}}>
                                <li class="policy_heading">On receipt of that notice ('Notice ') by that other party, the parties to the Terms
                                  ('Parties') must:
                                  <ol style={{"listStyle": "lower-alpha"}}>
                                    <li class="policy_heading">Within 14 days of the Notice endeavour in good faith to resolve the
                                      Dispute expeditiously by negotiation or such other means upon which they
                                      may mutually agree;</li>
                                    <li class="policy_heading">If for any reason whatsoever, 30 days after the date of the Notice, the
                                      Dispute has not been resolved, the Parties must either agree upon
                                      selection of a mediator or request that an appropriate mediator be
                                      appointed by the President of the Australian Mediation Association;
                                      Conflict Resolution Service or his or her nominee;</li>
                                    <li class="policy_heading">The Parties are equally liable for the fees and reasonable expenses of a
                                      mediator and the cost of the venue of the mediation and without limiting
                                      the foregoing undertake to pay any amounts requested by the mediator as
                                      a pre-condition to the mediation commencing. The Parties must each pay
                                      their own costs associated with the mediation;</li>
                                    <li class="policy_heading">The mediation will be held in Unit 10/3 Jamison Centre, Macquarie Canberra, ACT 2614.</li>
                                  </ol>                                  
                                </li>
                              </ol>
                            </li>
                            <li class="policy_heading">Confidential:
                              <ol style={{"listStyle": "none"}}>
                                <li class="policy_heading">All communications concerning negotiations made by the Parties arising out of
                                  and in connection with this dispute resolution clause are confidential and to the
                                  extent possible, must be treated as "without prejudice" negotiations for the
                                  purpose of applicable laws of evidence.
                                </li>
                              </ol>
                            </li>
                            <li class="policy_heading">Termination of Mediation:
                              <ol style={{"listStyle": "none"}}>
                                <li class="policy_heading">If 2 weeks have elapsed after the start of a mediation of the Dispute and the
                                  Dispute has not been resolved, either Party may ask the mediator to terminate
                                  the mediation and the mediator must do so.
                                </li>
                              </ol>
                            </li>
                          </ol>
                        </li>
                        <li class="policy_heading">Venue and Jurisdiction
                          <ol style={{"listStyle": "none"}}>
                            <li>The Services offered by Medvatic is intended to be viewed by residents of Australia. In
                              the event of any dispute arising out of or in relation to the Application, you agree that the
                              exclusive venue for resolving any dispute shall be in the courts of Australian Capital
                              Territory, Australia.
                              </li>
                          </ol>
                        </li>
                        <li class="policy_heading">Governing Law
                          <ol style={{"listStyle": "none"}}>
                            <li>The Terms are governed by the laws of Australian Capital Territory, Australia. Any
                              dispute, controversy, proceeding or claim of whatever nature arising out of or in any way
                              relating to the Terms and the rights created hereby shall be governed, interpreted and
                              construed by, under and pursuant to the laws of Australian Capital Territory, Australia,
                              without reference to conflict of law principles, notwithstanding mandatory rules. The
                              validity of this governing law clause is not contested. The Terms shall be binding to the
                              benefit of the parties hereto and their successors and assigns.
                              </li>
                          </ol>
                        </li>
                        <li class="policy_heading">Independent Legal Advice
                          <ol style={{"listStyle": "none"}}>
                            <li>Both parties confirm and declare that the provisions of the Terms are fair and
                              reasonable and both parties having taken the opportunity to obtain independent legal
                              advice and declare the Terms are not against public policy on the grounds of inequality
                              or bargaining power or general grounds of restraint of trade.</li>
                          </ol>
                        </li>
                        <li class="policy_heading">Severance
                          <ol style={{"listStyle": "none"}}>
                            <li>If any part of these Terms is found to be void or unenforceable by a Court of competent
                              jurisdiction, that part shall be severed and the rest of the Terms shall remain in force.</li>
                          </ol>
                        </li>
                    </ol>
                </div>
        </div>
       </section> 
    )
    }
}
