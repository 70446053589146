import "datatables.net-dt/css/jquery.dataTables.min.css";
// Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import $ from "jquery";
import "jquery/dist/jquery.min.js";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import Apilib from "../../api";
import Notify from "../../Notify";
import Loader from "../Loader/Loader";

const typeUser = [
  {
    id: "Super Admin",
    type: "Admin",
  },
  {
    id: "Admin",
    type: "Admin",
  },
  {
    id: "Sales Executive",
    type: "Doctor",
  },
  {
    id: "4",
    type: "Receptionist",
  },
  {
    id: "5",
    type: "Patient",
  },
];

export const TypeAccount = ({ options, value }) => {
  let arrayOfType = options.filter((option) => +option.id === value);
  // console.log("arrray",arrayOfType)
  return <div>{arrayOfType[0].type}</div>;
};

export default class UsersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      modalLoading: false,
      users: [],
      currentUser: {},
      currentIndex: "",
      modalOpen: false,
      success: false,
      deletedUser: null,
      show: false,
    };
    this.myTable = React.createRef();
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({
      loading: true,
    });
    let API = new Apilib();
    API.getUsers(1)
      .then((res) => res.json())
      .then((res) => {
        let data = res.admins;
        data.sort((a, b) => b.created_at - a.created_at)
        if (this._isMounted) {
          this.setState(
            {
              loading: false,
              users: [...data],
            },
            () => {
              if ($(".datatable").length > 0) {
                $(".datatable").DataTable({
                  "order": [],
                  columnDefs: [{ orderable: false, targets: 3 }],
                });
              }
            }
          );
        }
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
        if (this._isMounted) {
          this.setState({
            loading: false,
          });
        }
      });
  }

  onChange = (e) => {
    let formObj = { ...this.state.currentUser };
    formObj[e.target.name] = e.target.value;
    this.setState({
      currentUser: formObj,
    });
  };

  updateData = (e) => {
    e.preventDefault();
    let data = { ...this.state.currentUser };
    console.log("data", data);
    let users = [...this.state.users];
    users[this.state.currentIndex] = data;
    this.setState({
      modalLoading: true,
      users,
    });
    // delete data.email;
    let API = new Apilib();
    API.updateUser(JSON.stringify(data))
      .then((res) => {
        this.setState({
          modalLoading: false,
        });
        if (res.ok) {
          this.setState({
            modalLoading: false,
            currentUser: {},
            modalOpen: false,
            success: true,
          });
          Notify({
            alert: true,
            type: "success",
            title: "User updated successfully",
          });
        } else {
          res.text().then((data) => {
            this.setState({
              modalOpen: false,
              error: JSON.parse(data).message,
            });
          });
        }
      })
      .catch((error) => {
        this.setState({
          error: error.message,
          loading: false,
        });
      });
  };

  handleOpen(e, index) {
    e.preventDefault();
    this.setState({
      modalOpen: true,
      currentUser: this.state.users[index],
      currentIndex: index,
      error: null,
      success: false,
    });
  }
  handleClose() {
    this.setState({
      modalOpen: false,
      currentUser: {},
      currentIndex: "",
    });
  }

  deletedUser = (value) => {
    this.setState({
      loading: true,
    });
    let API = new Apilib();
    API.deleteAdmin(JSON.stringify({ type: value.type, uid: value.uid })).then(
      async (res) => {
        if (res.ok) {
          let MSG = await res.json();
          Notify({
            alert: true,
            type: "success",
            title: MSG.msg,
          });
          this.setState({
            show: false,
          });

          let API = new Apilib();
          API.getUsers(1)
            .then((res) => res.json())
            .then((res) => {
              if (this._isMounted) {
                this.setState({
                  loading: false,
                  users: res.admins,
                });
              }
            })
            .catch((error) => {
              this.setState({
                error: error.message,
                loading: false,
              });
            });
        } else {
          Notify({
            alert: true,
            type: "danger",
            title: "Error occured",
          });
        }
      }
    );
  };
  render() {
    if (this.state.loading) {
      return <Loader />;
    } else {
      return (
        <React.Fragment>
          <div className="row">
            <div className="col-sm-4 col-3">
              <h4 className="page-title">Users</h4>
            </div>
            <div className="col-sm-8 col-9 text-right m-b-20">
              <Link
                to="/admin/add-user"
                className="btn btn-primary float-right btn-rounded"
              >
                <i className="fa fa-plus"></i> Add User
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {this.state.error && (
                <div
                  className="alert alert-danger alert-dismissible fade show"
                  role="alert"
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                    <span className="sr-only">Close</span>
                  </button>
                  {this.state.error}
                </div>
              )}
              {/* {this.state.success && (
                <div
                  className="alert alert-success alert-dismissible fade show"
                  role="alert"
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                    <span className="sr-only">Close</span>
                  </button>
                  User updated successfully
                </div>
              )} */}
              <div className="table-responsive">
                <table className="table table-border table-striped custom-table datatable m-b-0">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Type</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody style={{ overflowY: "scroll" }}>
                    {this.state.users &&
                      this.state.users.map((user, index) => (
                        <tr key={index}>
                          <td>
                            {user.name}
                          </td>
                          <td>{user.email}</td>
                          <td>
                            {/* <TypeAccount options={typeUser} value={user.type}/> */}
                            {/* {typeUser.map(el=>{
                              if(el.id==user.type){
                                return el.type
                              }
                            })} */}
                            {user["access-level"]}
                          </td>
                          <td>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "start",
                                alignItems: "center",
                              }}
                            >
                              <div>
                                <button
                                  // disabled={this.props.userData.uid==user.uid}
                                  onClick={(e) => this.handleOpen(e, index)}
                                  type="button"
                                  class="btn btn-secondary"
                                >
                                  <span
                                    class="iconify"
                                    style={{
                                      marginRight: ".5em",
                                      marginTop: "-.1em",
                                      display: "inline-block",
                                    }}
                                    data-icon="akar-icons:edit"
                                    data-inline="false"
                                  ></span>
                                  Edit
                                </button>
                              </div>
                              <div style={{ marginLeft: "2%" }}>
                                <button
                                  disabled={this.props.userData.uid == user.uid}
                                  onClick={() => {
                                    this.setState({
                                      show: true,
                                      deletedUser: user,
                                    });
                                  }}
                                  type="button"
                                  class="btn btn-danger"
                                >
                                  <span
                                    style={{
                                      marginRight: ".5em",
                                      marginTop: "-.1em",
                                      display: "inline-block",
                                    }}
                                    class="iconify"
                                    data-icon="ant-design:delete-twotone"
                                    data-inline="false"
                                  ></span>
                                  Delete
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <Modal
            show={this.state.show}
            onHide={() =>
              this.setState({
                show: false,
              })
            }
            animation={false}
          >
            <Modal.Header>
              <Modal.Title>Delete user</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure, You want to delete user
              {this.state.deletedUser ? (
                <>
                  <strong>{" " + this.state.deletedUser.name + " ?"}</strong>
                </>
              ) : null}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={() => this.deletedUser(this.state.deletedUser)}
              >
                Yes
              </Button>
              <Button
                variant="danger"
                onClick={() =>
                  this.setState({
                    show: false,
                  })
                }
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={this.state.modalOpen}
            onHide={() => this.handleClose()}
            animation={false}
          >
            {this.state.modalLoading && <Loader />}
            <Modal.Header closeButton>
              <Modal.Title>User Update</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Name<span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      name="name"
                      value={this.state.currentUser.name}
                      required
                      onChange={(e) => this.onChange(e)}
                      type="text"
                      placeholder="Enter Last Name"
                    />
                  </div>
                  <div className="col-sm-6" />
                  <div className="form-group">
                    <label>
                      Phone Number<span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      name="number"
                      value={this.state.currentUser.number}
                      required
                      onChange={(e) => this.onChange(e)}
                      type="text"
                      placeholder="Enter your number"
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                <div className="form-group">
                  <label>
                    Role<span className="text-danger">*</span>
                  </label>
                  <select  className="form-control">
                    <option value="volvo">Admin</option>
                    <option value="saab">Super Admin</option>
                    <option value="opel">Sales Executive</option>
                  </select>
              


                  {/* <input
                    className="form-control"
                    name="number"
                    value={this.state.currentUser.role}
                    required
                    onChange={(e) => this.onChange(e)}
                    type="text"
                    placeholder="Enter your number"
                  /> */}
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-group">
                  <label>
                    Email <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    name="email"
                    value={this.state.currentUser.email}
                    type="email"
                    readOnly
                    placeholder="Enter Email Id"
                  />
                </div>
              </div>
              </div>
            </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.handleClose()}>
              Close
            </Button>
            <Button variant="primary" onClick={(e) => this.updateData(e)}>
              Update User
            </Button>
          </Modal.Footer>
          </Modal>
        </React.Fragment >
      );
    }
  }
}
