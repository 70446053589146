import "datatables.net-dt/css/jquery.dataTables.min.css";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import $ from "jquery";
import "jquery/dist/jquery.min.js";
import React, { useEffect, useRef } from "react";
import { uuid } from "uuidv4";


const Table = ({ columns, rows, deleteAction, editAction, id, tableOptions }) => {
  const IdRef = useRef(uuid());
  const tableRef = useRef();
  const tableInstance = useRef();
  useEffect(() => {
    // if (tableRef.current) tableRef.current.destroy();
    tableInstance.current = $(tableRef.current).DataTable(tableOptions ? tableOptions : {});
    return () => {};
  }, []);
  return (
    <div className="table-responsive">
      <table
        ref={tableRef}
        class="table table-border table-striped custom-table datatable m-b-0"
        style={{ width: "100%", overflowX: "scroll" }}
      >
        <thead>
          <tr>
            {columns.map((column) => (
              <th>{column}</th>
            ))}
          </tr>
        </thead>
        <tbody>{rows.map((row) => row)}</tbody>
        <tfoot></tfoot>
      </table>
    </div>
  );
};

export default Table;
