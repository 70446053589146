import MenuItem from "@material-ui/core/MenuItem";
import Select2 from "@material-ui/core/Select";
import csc from "country-state-city";
import firebase from "firebase";
import React, { Component } from "react";
import BaseSelect from "react-select/creatable";
import Apilib from "../../api";
import RequiredSelect from "../../Helpers/requiredSelect";
import "../../Helpers/style.css";
import Notify from "../../Notify";
import Loader from "../Loader/Loader";
import UploadFiles from "../UploadFiles/UploadFiles";
import CreatableSelect from "react-select/creatable";

var QRCode = require("qrcode.react");

const Select = (props) => (
  <RequiredSelect
    {...props}
    SelectComponent={BaseSelect}
    options={props.options}
  />
);

export default class AddPatient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profilePicture: null,
      countries: [],
      states: [],
      cities: [],
      modal: false,
      loading: false,
      formData: {
        firstName: {
          value: "",
          validation: {
            minLength: 3,
            required: true,
            onlyAlphabets: true,
          },
          errMsg: "Invalid First Name!",
          valid: true,
        },
        lastName: {
          value: "",
          validation: {
            minLength: 3,
            required: true,
            onlyAlphabets: true,
          },
          errMsg: "Invalid last Name!",
          valid: true,
        },
        email: {
          value: "",
          validation: {
            required: true,
            isEmail: true,
          },
          errMsg: "Invalid Email!",
          valid: true,
        },
        phoneno: {
          value: "",
          validation: {
            minLength: 10,
            maxLength: 15,
            required: true,
            isPhoneNumber: true,
            // isNumeric: true,
          },
          errMsg: "Maximum 15 digits are allowed!",
          valid: true,
        },
        height: {
          value: "",
          validation: {
            minLength: 2,
            required: false,
            isNumeric: true,
          },
          errMsg: "Invalid Height!",
          valid: true,
        },
        weight: {
          value: "",
          validation: {
            minLength: 2,
            required: false,
            isNumeric: true,
          },
          errMsg: "Invalid Weight!",
          valid: true,
        },
        emergencyContactDetails: {
          value: "",
          validation: {
            minLength: 10,
            maxLength: 15,
            isPhoneNumber: true,
            isNumeric: true,
          },
          errMsg: "Maximum 15 digits are allowed for emergency contact!",
          valid: true,
        },
        bloodgroup: {
          value: "",
          validation: {
            required: false,
          },
          valid: true,
        },
        gender: {
          value: "",
          valid: true,
        },
        address: {
          value: "",
          validation: {
            required: true,
            minLength: 1,
          },
          errMsg: "Invalid address!",
          valid: true,
        },
        country: {
          value: "",
          country_id: "",
          valid: true,
        },
        city: {
          value: "",
          validation: {
            minLength: 3,
            required: true,
            onlyAlphabets: true,
          },
          errMsg: "Invalid City Name!",
          valid: true,
        },
        State: {
          value: "",
          state_id: "",
          valid: true,
        },
        postalcode: {
          value: "",
          validation: {
            // minLength: 5,
            required: true,
            isNumeric: true,
          },
          errMsg: "Invalid Postal Code!",
          valid: true,
        },
        allergies: {
          value: [],
          valid: true,
        },
      },
      formIsValid: false,
      disable: true,
      error: "",
      success: false,
      redirect: false,
      long: "",
      lat: "",
      country_code: "",
    };
    console.log("Props addpatient", props);
  }

  componentDidMount() {
    this.setState({ loading: true });
    // if (this.props.location.state !== undefined) {
    //   if (this.props.location.state.redirect) {
    //     this.setState({ redirect: true });
    //   }
    // }
    let data = csc.getAllCountries();
    this.setState({ countries: data });
    let API = new Apilib();
    // Get geolocation of a user's browser.
    API.getLocation()
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          loading: false,
          lat: res.latitude,
          long: res.longitude,
          country_code: res.country_code,
        });
        API.getUsers(5, this.props.userData.hospitalId)
          .then((res) => res.json())
          .then((res) => {
            let patientList = [];
            patientList = res.patients;
            this.setState({
              patientList: patientList,
            });
          });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log("geoError", err);
      });
  }

  onChange = (e) => {
    let inputName = e.target.name;
    const updatedForm = { ...this.state.formData };
    const updatedFormElement = { ...updatedForm[inputName] };
    let value = "";
    switch (inputName) {
      case "country":
        console.log("country", this.state.countries);
        for (let key of this.state.countries) {
          if (key.id === e.target.value) {
            value = key.name;
            updatedFormElement.country_id = e.target.value;
          }
        }
        let stateData = csc.getStatesOfCountry(e.target.value);
        this.setState({ states: stateData });
        updatedForm.State.value = "";
        updatedForm.State.state_id = "";
        updatedForm.State.valid = false;
        // updatedForm.city.value = "";
        // updatedForm.city.city_id = "";
        // updatedForm.city.valid = false;
        break;

      case "State":
        for (let key of this.state.states) {
          if (key.id === e.target.value) {
            value = key.name;
            updatedFormElement.state_id = e.target.value;
          }
        }
        // let cityData = csc.getCitiesOfState(e.target.value);
        // this.setState({ cities: cityData });
        // updatedForm.city.value = "";
        // updatedForm.city.city_id = "";

        // updatedForm.city.valid = false;
        break;

      // case "city":
      //   for (let key of this.state.cities) {
      //     if (key.id === e.target.value) {
      //       value = key.name;
      //       updatedFormElement.city_id = e.target.value;
      //     }
      //   }
      // break;

      // no default
    }
    if (
      inputName === "country" ||
      inputName === "State"
      // inputName === "city"
    ) {
      updatedFormElement.value = value;
    } else {
      updatedFormElement.value = e.target.value;
    }
    updatedForm[inputName] = updatedFormElement;
    updatedForm[inputName].valid = this.checkValidity(
      updatedForm[inputName].value,
      updatedForm[inputName].validation
    );
    let formValid = true;
    for (let key in updatedForm) {
      formValid = updatedForm[key].valid && formValid;
    }
    console.log("form valid", formValid);
    this.setState({
      formIsValid: formValid,
      disable: !formValid,
      formData: updatedForm,
    });
  };

  onSelect = (val) => {
    console.log("value ---->", val);

    let formObj = { ...this.state.formData };
    // console.log("Initial formobj--->", formObj);
    console.log("val--->", val);
    let arr = [];
    if (val && val.length) {
      val.map((value, index) => {
        arr.push(value.value);
      });
    }
    formObj["allergies"].value = arr;
    this.setState({
      formData: formObj,
      allergies: val,
    });
    // console.log("formdata", formObj, val);
  };

  checkValidity(value, rules) {
    if (rules != undefined) {
      let isValid = true;

      if (rules.required) {
        isValid = value.trim() !== "" && isValid;
      }

      if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid;
      }

      if (rules.maxLength) {
        isValid = value.length <= rules.minLength && isValid;
      }

      if (rules.isPhoneNumber) {
        const pattern = /^[0-9]{8,}$/;
        isValid = pattern.test(value) && isValid;
      }

      // if (rules.isNumeric) {
      //   const pattern = /^[0-9.]+$/;
      //   isValid = pattern.test(value) && isValid;
      // }

      if (rules.isEmail) {
        const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        isValid = pattern.test(value) && isValid;
      }

      if (rules.onlyAlphabets) {
        const pattern = /^[A-Za-z/' ]+$/;
        isValid = pattern.test(value) && isValid;
      }

      return isValid;
    } else {
      return true;
    }
  }

  async handleProfileUpload(fileBlob, uid) {
    try {
      const file = fileBlob;
      const ref = firebase.storage().ref();
      const name = "profile_images/patients/" + uid;
      const metadata = {
        contentType: file.type,
      };
      return ref
        .child(name)
        .put(file, metadata)
        .then(() => {
          let API = new Apilib();
          return API.updateUser(
            JSON.stringify({
              uid,
              profile_picture: name,
              type: 5,
            })
          );
        });
    } catch (error) {
      console.error(error, "Error in uploading profile image");
      throw error;
    }
  }

  phoneChecking(phoneno) {
    let patientPhoneCheck = this.state.patientList;
    for (var i = 0; i < patientPhoneCheck.length; i++) {
      if (patientPhoneCheck[i].phoneno) {
        return false;
      }
    }
    return true;
  }

  onSubmit = (e) => {
    e.preventDefault();
    if (this.state.formIsValid) {
      console.log("success");
      let baseState = { ...this.state };
      this.setState({
        error: "",
        loading: true,
      });
      let API = new Apilib();
      let data = {};
      for (let inputType in this.state.formData) {
        data[inputType] = this.state.formData[inputType].value;
        data = {
          ...data,
          type: 5,
          uid: this.props.user.uid,
          hospitalId: this.props.userData.hospitalId,
          hospitalChainId: this.props.userData.hospitalChainId,
          password: "pwd@123",
          created_at: Date.parse(new Date()),
        };
      }
      // let phCheck = this.phoneChecking(data.phoneno)

      // if (!phCheck) {
      //   this.setState({
      //     loading: false
      //   })
      //   Notify({
      //     type: "error",
      //     alert: true,
      //     title: "Phone no is already used",
      //   });
      //   return
      // }
      data.long = baseState.long;
      data.lat = baseState.lat;
      data.country_code = baseState.country_code;
      delete data.loading;
      delete data.error;
      delete data.success;
      console.log("API data::", data);
      API.create(JSON.stringify(data))
        .then(async (res) => {
          if (res.ok) {
            if (this.state.profilePicture) {
              const resJson = await res.json();
              const fileName = await this.handleProfileUpload(
                this.state.profilePicture,
                resJson.data.uid
              ).then((res) => {
                if (!res.ok) {
                  throw new Error("Failed to upload profile");
                }
                this.setState({
                  loading: false,
                  firstName: "",
                  lastName: "",
                  phoneno: "",
                  email: "",
                  emergencyContactDetails: "",
                  bloodgroup: "",
                  height: "",
                  weight: "",
                  bmr: "",
                  gender: "",
                  address: "",
                  country: "",
                  city: "",
                  State: "",
                  postalcode: "",
                  error: "",
                  success: true,
                });
              });
            } else {
              this.setState({
                loading: false,
                firstName: "",
                lastName: "",
                phoneno: "",
                email: "",
                emergencyContactDetails: "",
                bloodgroup: "",
                height: "",
                weight: "",
                bmr: "",
                gender: "",
                address: "",
                country: "",
                city: "",
                State: "",
                postalcode: "",
                error: "",
                success: true,
              });
            }
            Notify({
              type: "success",
              alert: true,
              title: "Patient added successfully1",
            });

            this.props.history.push({
              pathname: "/doctor/patients",
              state: { addPatientSuccess: true },
            });

            // console.log("state.redirect", this.state.redirect);

            // this.props.history("/doctor/patients")
          } else {
            res.text().then((data) => {
              this.setState({
                loading: false,
                error: JSON.parse(data).message,
              });
            });
          }
        })
        .catch((error) => {
          this.setState({
            error: error.message,
            loading: false,
          });
        });
    } else {
      this.setState({
        error: "Invalid Form",
        loading: false,
      });
      // setSendRequest(false);
      // setDisable(true);
      return;
    }
  };

  render() {
    let allBloodGroups = [
      {
        id: "1",
        name: "A+",
      },
      {
        id: "2",
        name: "A-",
      },
      {
        id: "3",
        name: "B+",
      },
      {
        id: "4",
        name: " B-",
      },
      {
        id: "5",
        name: "AB+",
      },
      {
        id: "6",
        name: "AB-",
      },
      {
        id: "7",
        name: "O+",
      },
      {
        id: "8",
        name: "O-",
      },
    ];
    let arr = [
      "Brazil nuts",
      "Almonds",
      "Cashews",
      "Macadamia nuts",
      "Pistachios",
      "Pine nuts",
      "Walnuts",

      "Cow’s Milk Allergies",

      "Milk",
      "Milk powder",
      "Cheese",
      "Butter",
      "Margarine",
      "Yogurt",
      "Cream",
      "Ice cream",

      "Shellfish Allergies",

      "Shrimp",
      "Prawns",
      "Crayfish",
      "Lobster",
      "Squid",
      "Scallops",

      "Peanuts Allergies",

      "Eggs Allergies",

      "Fish Allergies",

      "Soy Allergies",

      "Dog Allergies",

      "Pollen Allergies",

      "Pet Hair Allergies",

      "Dust Mites Allergies",

      "Insect bites Allergies",

      "Wheat Allergies",

      "  Antibiotic Allergies",
    ];
    let allegies = arr.map((item) => {
      return {
        value: item,
        label: item,
      };
    });
    console.log("allergies", allegies);
    let bloodGroup = null;
    if (allBloodGroups.length !== 0) {
      bloodGroup = allBloodGroups.map((item) => {
        return (
          <MenuItem key={item.id} value={item.name}>
            {item.name}
          </MenuItem>
        );
      });
    }

    let countriesMenu = null;
    if (this.state.countries && this.state.countries.length !== 0) {
      countriesMenu = this.state.countries.map((item) => {
        return (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        );
        // console.log(item);
      });
    }

    let statesMenu = null;
    if (this.state.states && this.state.states.length !== 0) {
      statesMenu = this.state.states.map((item) => {
        return (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        );
        // console.log(item);
      });
    }

    let citiesMenu = null;
    if (this.state.cities && this.state.cities.length !== 0) {
      citiesMenu = this.state.cities.map((item) => {
        return (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        );
        // console.log(item);
      });
    }

    return (
      <React.Fragment>
        {this.state.loading && <Loader />}
        {/* <UploadFiles /> */}

        <div className="row">
          <div className="col-md-12">
            <form onSubmit={(e) => this.onSubmit(e)}>
              {this.state.error && (
                <div
                  className="alert alert-danger alert-dismissible fade show"
                  role="alert"
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                    <span className="sr-only">Close</span>
                  </button>
                  {this.state.error}
                </div>
              )}
              {this.state.success && (
                <div
                  className="alert alert-success alert-dismissible fade show"
                  role="alert"
                >
                  <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                    <span className="sr-only">Close</span>
                  </button>
                  Patient added successfully
                </div>
              )}
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      First Name <span className="text-danger">*</span>
                    </label>
                    <input
                      name="firstName"
                      error={
                        this.state.formData.firstName.valid ? "false" : "true"
                      }
                      value={this.state.formData.firstName.value}
                      //   value={this.state.firstName}
                      onChange={(e) => this.onChange(e)}
                      required
                      className="form-control"
                      type="text"
                      //   isValid={true}
                    />
                    {/* <i class="fa fa-check"></i> */}
                    {!this.state.formData["firstName"].valid ? (
                      <p className="error-messege">
                        <small>{this.state.formData["firstName"].errMsg}</small>
                      </p>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Last Name <span className="text-danger">*</span>
                    </label>
                    <input
                      name="lastName"
                      error={
                        this.state.formData.lastName.valid ? "false" : "true"
                      }
                      value={this.state.formData.lastName.value}
                      //   value={this.state.lastName}
                      onChange={(e) => this.onChange(e)}
                      required
                      className="form-control"
                      type="text"
                    />
                  </div>
                  {!this.state.formData["lastName"].valid ? (
                    <p className="error-messege">
                      <small>{this.state.formData["lastName"].errMsg}</small>
                    </p>
                  ) : null}
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Phone No. <span className="text-danger">*</span>
                    </label>
                    <input
                      name="phoneno"
                      error={
                        this.state.formData["phoneno"].valid ? "false" : "true"
                      }
                      value={this.state.formData["phoneno"].value}
                      //   value={this.state.phoneno}
                      onChange={(e) => this.onChange(e)}
                      required
                      className="form-control"
                      type="number"
                    />
                  </div>
                  {!this.state.formData["phoneno"].valid ? (
                    <p className="error-messege">
                      <small>{this.state.formData["phoneno"].errMsg}</small>
                    </p>
                  ) : null}
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Email <span className="text-danger">*</span>
                    </label>
                    <input
                      name="email"
                      error={this.state.formData.email.valid ? "false" : "true"}
                      value={this.state.formData["email"].value}
                      //   value={this.state.email}
                      onChange={(e) => this.onChange(e)}
                      required
                      className="form-control"
                      type="text"
                    />
                  </div>
                  {!this.state.formData["email"].valid ? (
                    <p className="error-messege">
                      <small>{this.state.formData["email"].errMsg}</small>
                    </p>
                  ) : null}
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Emergency Contact Details</label>
                    <input
                      name="emergencyContactDetails"
                      value={this.state.emergencyContactDetails}
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                      type="number"
                      error={
                        this.state.formData["emergencyContactDetails"].valid
                          ? "false"
                          : "true"
                      }
                      value={
                        this.state.formData["emergencyContactDetails"].value
                      }
                    />
                  </div>
                  {!this.state.formData["emergencyContactDetails"].valid ? (
                    <p className="error-messege">
                      <small>
                        {this.state.formData["emergencyContactDetails"].errMsg}
                      </small>
                    </p>
                  ) : null}
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Blood Group</label>
                    <Select2
                      className="form-control"
                      // required
                      fullWidth
                      name="bloodgroup"
                      onChange={(e) => this.onChange(e)}
                      value={this.state.bloodgroup}
                    >
                      {bloodGroup}
                    </Select2>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <div className="d-flex flex-row justify-content-start ">
                      <div>
                        <label>Height</label>
                      </div>
                      <div
                        style={{
                          marginLeft: "10px",
                          backgroundColor: "#d4edda",
                          width: "60px",
                          height: "25px",
                          borderColor: "#c3e6cb",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span>In CM</span>
                      </div>
                    </div>

                    <input
                      name="height"
                      error={
                        this.state.formData["height"].valid ? "false" : "true"
                      }
                      value={this.state.formData["height"].value}
                      //   value={this.state.height}
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                      type="text"
                    />

                    {!this.state.formData["height"].valid ? (
                      <p className="error-messege">
                        <small>{this.state.formData["height"].errMsg}</small>
                      </p>
                    ) : null}
                  </div>
                </div>

                <div className="col-sm-3">
                  <div className="form-group">
                    <div className="d-flex flex-row justify-content-start ">
                      <div>
                        <label>Weight</label>
                      </div>
                      <div
                        style={{
                          marginLeft: "10px",
                          backgroundColor: "#d4edda",
                          width: "60px",
                          height: "25px",
                          borderColor: "#c3e6cb",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span>In KG</span>
                      </div>
                    </div>
                    <input
                      name="weight"
                      error={
                        this.state.formData["weight"].valid ? "false" : "true"
                      }
                      value={this.state.formData["weight"].value}
                      //   value={this.state.weight}
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                      type="text"
                    />

                    {!this.state.formData["weight"].valid ? (
                      <p className="error-messege">
                        <small>{this.state.formData["weight"].errMsg}</small>
                      </p>
                    ) : null}
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="form-group gender-select">
                    <label className="gen-label">
                      Gender <span className="text-danger">*</span>
                    </label>
                    <div className="form-check-inline">
                      <label className="form-check-label">
                        <input
                          required
                          name="gender"
                          type="radio"
                          onChange={(e) => this.onChange(e)}
                          checked={this.state.formData.gender.value == "male"}
                          value="male"
                          className="form-check-input"
                        />
                        Male
                      </label>
                    </div>
                    <div className="form-check-inline">
                      <label className="form-check-label">
                        <input
                          required
                          type="radio"
                          onChange={(e) => this.onChange(e)}
                          name="gender"
                          checked={this.state.formData.gender.value == "female"}
                          value="female"
                          className="form-check-input"
                        />
                        Female
                      </label>
                    </div>
                    <div className="form-check-inline">
                      <label className="form-check-label">
                        <input
                          required
                          type="radio"
                          onChange={(e) => this.onChange(e)}
                          name="gender"
                          checked={this.state.formData.gender.value == "other"}
                          value="other"
                          className="form-check-input"
                        />
                        Other
                      </label>
                    </div>
                    <div className="form-check-inline">
                      <label className="form-check-label">
                        <input
                          required
                          type="radio"
                          onChange={(e) => this.onChange(e)}
                          name="gender"
                          checked={
                            this.state.formData.gender.value ==
                            "I wish not to disclose "
                          }
                          value="I wish not to disclose "
                          className="form-check-input"
                        />
                        I wish not to Disclose
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Address <span className="text-danger">*</span>
                    </label>
                    <input
                      required
                      type="text-area"
                      name="address"
                      error={
                        this.state.formData["address"].valid ? "false" : "true"
                      }
                      value={this.state.formData.address.value}
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                    />
                    {!this.state.formData["address"].valid ? (
                      <p className="error-messege">
                        <small>{this.state.formData["address"].errMsg}</small>
                      </p>
                    ) : null}
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="form-group">
                    <label>Allergies</label>
                    <Select
                      required
                      placeholder="Enter Allergies"
                      onChange={(item) => this.onSelect(item)}
                      // value={this.state.selectedDaysObj}
                      options={allegies}
                      noOptionsMessage={() => "Enter allergy and press enter"}
                      isMulti={true}
                      closeMenuOnSelect={false}
                    />
                    {/* <select
                      multiple
                      size="1"
                      placeholder="Enter Allergies"
                      onChange={(item) => this.onSelect(item)}
                      noOptionsMessage={() => "Enter allergy and press enter"}
                      className="form-control"
                    >
                      <option value="Peanuts Allergies">Brazil nuts</option>
                      <option value="Peanuts Allergies">Almonds</option>
                      <option value="Peanuts Allergies">Cashews</option>
                      <option value="Peanuts Allergies">Macadamia nuts</option>
                      <option value="Peanuts Allergies">Pistachios</option>
                      <option value="Peanuts Allergies"> Pine nuts</option>
                      <option value="Peanuts Allergies">Walnuts</option>
                      <option value="Peanuts Allergies">Milk</option>
                      <option value="Peanuts Allergies"> Milk powder</option>
                      <option value="Peanuts Allergies">Cheese</option>
                      <option value="Peanuts Allergies">Butter</option>
                      <option value="Peanuts Allergies">Margarine</option>
                      <option value="Peanuts Allergies">Yogurt</option>
                      <option value="Peanuts Allergies">Cream</option>
                      <option value="Peanuts Allergies"> Ice cream</option>
                      <option value="Peanuts Allergies">Shrimp</option>
                      <option value="Peanuts Allergies">Prawns</option>
                      <option value="Peanuts Allergies">Crayfish</option>
                      <option value="Peanuts Allergies">Lobster</option>
                      <option value="Peanuts Allergies">Squid</option>
                      <option value="Peanuts Allergies">Scallops</option>
                      <option value="Peanuts Allergies">Lobster</option>
                      <option value="Peanuts Allergies">Pistachios</option>
                      <option value="Peanuts Allergies">Brazil nuts</option>
                      <option value="Peanuts Allergies">
                        Peanuts Allergies
                      </option>
                      <option value="Eggs Allergies">Eggs Allergies</option>
                      <option value="Fish Allergies">Fish Allergies</option>
                      <option value="Soy Allergies">Soy Allergies</option>
                      <option value="Dog Allergies">Dog Allergies</option>
                      <option value="Pollen Allergies">Pollen Allergies</option>
                      <option value="Dust Mites Allergies">
                        Dust Mites Allergies
                      </option>
                      <option value="Insect bites Allergies">
                        Insect bites Allergies
                      </option>
                      <option value="Wheat Allergies">Wheat Allergies</option>
                      <option value="Antibiotic Allergies">
                        Antibiotic Allergies
                      </option>
                    </select> */}
                  </div>
                </div>

                <div className="col-sm-6 col-md-6 col-lg-3">
                  <div className="form-group">
                    <label>
                      Country <span className="text-danger">*</span>
                    </label>
                    <Select2
                      required
                      className="form-control"
                      fullWidth
                      name="country"
                      onChange={(e) => this.onChange(e)}
                      value={this.state.formData.country.country_id}
                    >
                      {countriesMenu}
                    </Select2>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-3">
                  <div className="form-group">
                    <label>
                      State/Province <span className="text-danger">*</span>
                    </label>
                    <Select2
                      required
                      className="form-control"
                      fullWidth
                      name="State"
                      onChange={(e) => this.onChange(e)}
                      value={this.state.formData.State.state_id}
                    >
                      <MenuItem disabled={true} value="">
                        Please Select Country
                      </MenuItem>
                      {statesMenu}
                    </Select2>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-3">
                  <div className="form-group">
                    <label>
                      City <span className="text-danger">*</span>
                    </label>
                    <input
                      error={this.state.formData.city.valid ? "false" : "true"}
                      required
                      type="text"
                      className="form-control"
                      fullWidth
                      name="city"
                      value={this.state.formData.city.value}
                      onChange={(e) => this.onChange(e)}
                    />
                    {/* // <MenuItem disabled={true} value="">
                      //   Please Select State
                      // </MenuItem>
                      // {citiesMenu} */}
                  </div>
                </div>

                <div className="col-sm-6 col-md-6 col-lg-3">
                  <div className="form-group">
                    <label>
                      Postal Code <span className="text-danger">*</span>
                    </label>
                    <input
                      required
                      type="pincode"
                      name="postalcode"
                      value={this.state.formData.postalcode.value}
                      onChange={(e) => this.onChange(e)}
                      className="form-control"
                      error={
                        this.state.formData["postalcode"].valid
                          ? "false"
                          : "true"
                      }
                      value={this.state.formData["postalcode"].value}
                    />
                    {!this.state.formData["postalcode"].valid ? (
                      <p className="error-messege">
                        <small>
                          {this.state.formData["postalcode"].errMsg}
                        </small>
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="m-t-20 text-center">
                <button
                  type="submit"
                  //   disabled={this.state.disable}
                  className="btn btn-primary submit-btn"
                >
                  Add Patient
                </button>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
