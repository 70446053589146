import $ from "jquery";
import React, { Component, useEffect, useState } from "react";
import { Link, Redirect, useLocation } from "react-router-dom";
import { auth } from "../../firebase";
import Notify from "../../Notify";
import Loader from "../Loader/Loader";
import { Button, Modal } from "react-bootstrap";

let arrayOfLinks = [
  {
    location: "/admin/add-hospital-chain",
    label: "Add Hospital Chain",
  },
  {
    location: "/admin/add-speciality",
    label: "Add Speciality",
  },
  {
    location: "/admin/hospitals",
    label: "All Hospitals",
  },
  {
    location: "/admin/users",
    label: "All Users",
  },
  {
    location: "/admin/change-password",
    label: "Change Password",
  },
  //hospitals
  {
    location: "/hospital/doctors",
    label: "All Doctors",
  },
  {
    location: "/hospital/add-doctor",
    label: "Add Doctor",
  },
  {
    location: "/hospital/receptionists",
    label: "Receptionists",
  },
  {
    location: "/hospital/add-receptionist",
    label: "Add Receptionist",
  },
  {
    location: "/hospital/view-requests",
    label: "View Requests",
  },
  {
    location: "/hospital/view-patients",
    label: "View Patients",
  },
  {
    location: "/hospital/change-password",
    label: "Change Password",
  },
  //receptionist
  {
    location: "/receptionist/patients",
    label: "All Patients",
  },
  {
    location: "/receptionist/add-patient",
    label: "Add patient",
  },
  {
    location: "/receptionist/appointments",
    label: "All Appointments",
  },
  {
    location: "/receptionist/add-appointment",
    label: "Add Appointment",
  },
  {
    location: "/receptionist/change-password",
    label: "Change Password",
  },
  //doctors
  {
    location: "/doctor/patients",
    label: "All Patients",
  },
  {
    location: "/doctor/add-patient",
    label: "Add Patient",
  },
  {
    location: "/doctor/appointments",
    label: "Appointments",
  },
  {
    location: "/doctor/add-appointment",
    label: "Add Appointments",
  },
  {
    location: "/doctor/add-schedule",
    label: "Add Schedule",
  },
  {
    location: "/doctor/change-password",
    label: "Change Password",
  },
];

export const Pagetitle = ({ options }) => {
  const [title, setTitle] = useState();

  const location = useLocation();

  useEffect(() => {
    let values = options.filter(
      (option) => option.location === location.pathname
    );
    if (values.length == 0) {
      return;
    }
    setTitle(values[0].label);
  }, [location]);

  console.log(title);
  // console.log("location",location.pathname)

  // console.log("values of arry",values)
  return <div>{title}</div>;
};

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      redirect: null,
      logoDestinationLink: "/",
      loading: false,
    };
  }

  componentDidMount() {
    const userType = this.props.user.displayName;
    switch (userType) {
      case "1":
        this.setState({
          logoDestinationLink: "/admin/users",
        });
        break;
      case "2":
        this.setState({
          logoDestinationLink: "/hospital/doctors",
        });
        break;
      case "3":
        this.setState({
          logoDestinationLink: "/doctor/patients",
        });
        break;
      case "4":
        this.setState({
          logoDestinationLink: "/receptionist/patients",
        });
        break;

      default:
        break;
    }
  }

  logoutUser(e) {
    e.preventDefault();
    let Usertype = this.props.type;
    this.setState({
      loading: true,
    });
    auth
      .signOut()
      .then(() => {
        let type = localStorage.getItem("@displayName");
        localStorage.removeItem("@userData");
        localStorage.removeItem("@displayName");
        localStorage.removeItem("@prefLogin");
        this.setState({
          redirect: "/",
          loading: false,
        });
        Notify({
          alert: true,
          type: "success",
          title: "Logged out successfully.",
        });
      })
      .catch(() =>
        this.setState({
          loading: false,
        })
      );
  }

  renderName() {
    switch (parseInt(localStorage.getItem("@displayName"))) {
      case 1:
        return this.props.userData.name;
      case 2:
        return this.props.userData.hospital_name
          ? this.props.userData.hospital_name
          : this.props.userData.hospitalName;
      case 3:
        return this.props.userData.firstName;
      case 4:
        return this.props.userData.firstName;
      case 5:
        return this.props.userData.firstName;
    }
  }

  toggleMobileMenu(e, target) {
    e.preventDefault();
    var $sidebarOverlay = $(".sidebar-overlay");
    var $target = $(target);
    if ($target.length) {
      $target.toggleClass("opened");
      $sidebarOverlay.toggleClass("opened");
      $("html").toggleClass("menu-opened");
      $sidebarOverlay.attr("data-reff", target);
      var $wrapper = $(".main-wrapper");
      $wrapper.toggleClass("slide-nav");
    }
    $sidebarOverlay.on("click", function (e) {
      var $target = $($(this).attr("data-reff"));
      if ($target.length) {
        $target.removeClass("opened");
        $("html").removeClass("menu-opened");
        $(this).removeClass("opened");
        $(".main-wrapper").removeClass("slide-nav");
      }
      e.preventDefault();
    });
  }

  render() {
    if (this.state.loading) {
      return <Loader />;
    }
    const userType = this.props.user.displayName;
    return (
      <>
        <div className="header navbar-head">
          {this.state.redirect ? <Redirect to={this.state.redirect} /> : null}
          <div className="header-left">
            <Link
              to={this.state.logoDestinationLink}
              className="logo"
              style={{ height: "40px" }}
            >
              <img
                src="assets/img/logo1.png"
                height="30"
                alt="Medvatic"
                style={{ height: "100%", width: "auto" }}
              />
            </Link>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginLeft: "14%",
            }}
          >
            <span style={{ fontSize: "1.25rem" }}>
              <Pagetitle options={arrayOfLinks} />
            </span>
          </div>
          <a
            onClick={(e) => this.toggleMobileMenu(e, "#sidebar")}
            className="mobile_btn float-left text-white"
          >
            <i className="fa fa-bars"></i>
          </a>

          <ul className="nav user-menu float-right">
            <li className="nav-item dropdown has-arrow">
              <a
                href="#"
                className="dropdown-toggle nav-link user-link"
                data-toggle="dropdown"
              >
                <span className="user-img mr-2">
                  <img
                    className="rounded-circle"
                    src="assets/img/user.jpg"
                    width="40"
                    alt="Admin"
                  />
                </span>
                {this.props.user && <span>{this.renderName()}</span>}
              </a>
              <div className="dropdown-menu">
                {/* <h4 className="dropdown-head">MY ACCOUNT</h4> */}
                <Link to="/admin/change-password" className="dropdown-item">
                  Change Password
                </Link>
                <Link
                  // onClick={()=>this.setState({show:true})}
                  to={
                    userType == 3
                      ? "/doctor/my-profile"
                      : userType == 2
                      ? "/hospital/profile"
                      : "/receptionist/profile"
                  }
                  className="dropdown-item"
                >
                  Profile
                </Link>
                <a
                  onClick={() => this.setState({ show: true })}
                  className="dropdown-item"
                >
                  Logout
                </a>
              </div>
            </li>
          </ul>
        </div>
        <div></div>

        <Modal
          show={this.state.show}
          onHide={() => {
            this.setState({ show: false });
          }}
          animation={false}
        >
          <Modal.Header>
            <Modal.Title>Logout</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to log out?</Modal.Body>
          <Modal.Footer>
            <Button
              onClick={(e) => {
                this.setState({ show: false });
                this.logoutUser(e);
              }}
              style={{ backgroundColor: "#50D8FA" }}
              variant="primary"
            >
              Yes
            </Button>
            <Button
              onClick={() => this.setState({ show: false })}
              variant="danger"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
