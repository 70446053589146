// jshint esversion:6

import React, { Component } from "react";
// import { Dropdown, NavDropdown, NavItem } from "react-bootstrap";

export default class Team extends Component {
  render() {
    return (
      <>
        <section className="section team-banner row-middle">
          <div className="inner-bg"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-9 col-md-9">
                <div className="banner-content">
                  <h2 className="intro">
                    MEDVATIC’S COMPETITIVE ADVANTAGE IS OUR FOCUS ON HEALTH
                    OUTCOMES RATHER THAN ADMINISTRATIVE ‘EFFICIENCY’ AS OUR
                    PRIMARY MISSION STATEMENT.
                  </h2>
                  <p className="intro">
                    There are already a number of patient management
                    applications available in the marketplace, however their
                    main focus is on improving the administrative performance of
                    a medical practice, handling the management of patient
                    details, booking appointments, processing health insurance
                    claims and the like. Whilst these tools are designed to
                    assist the performance and profitability of a healthcare
                    operation, they do not directly address the ongoing health
                    outcomes for patients themselves.
                  </p>
                  {/* <!-- <a title="Consult" className="btn btn-primary consult-btn" href="">Check Out Team</a> --> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section departments team">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-header text-center">
                  <h3 className="header-title">Board of Directors</h3>
                  <div className="line"></div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 bod">
                <div className="dept-box2">
                  <div className="dept-img2">
                    <a>
                      <img
                        className="team-img"
                        width="47"
                        height="80"
                        alt="ENT Department"
                        src="assets/img/Team/NIGEL PHAIR.jpg"
                      />
                    </a>
                  </div>
                  <h3>NIGEL PHAIR</h3>
                  <h6>CENTRE FOR INTERNET SAFETY</h6>
                  {/* <p>
                    Nigel helps organisations to design their technology
                    strategy.He also delivers value through his diverse
                    background in senior management and company directorship in
                    law enforcement, technology, academia and consulting. Along
                    with a prominent company director who advises boards on
                    strategy, governance and risk surrounding the use of
                    technology he also has strong professional, government and
                    community links and well-established national and
                    international networks.
                  </p> */}
                  <a href="https://au.linkedin.com/in/nigelphair">
                    <img
                      src="assets/img/Team/linkedin.png"
                      alt="LinkedIn"
                      style={{ maxWidth: "40px" }}
                    ></img>
                  </a>
                </div>
              </div>
              <div className="col-md-4 bod">
                <div className="dept-box2">
                  <div className="dept-img2">
                    <a>
                      <img
                        width="67"
                        height="80"
                        alt="Dentists"
                        src="assets/img/Team/George-Maclean.jpg"
                      />
                    </a>
                  </div>
                  <h3>GEORGE MACLEAN</h3>
                  <h6>FOUNDER & CEO</h6>
                  {/* <p>
                    George is a highly experienced entrepreneur in the medical
                    industry, having taken his first role as a counter drug
                    sales assistant at age 10. By high school George had taken a
                    management role in the store and obtained a license to
                    operate as a registered pharmacy, providing essential
                    medical supplies to less affluent regional areas.
                  </p> */}
                  <a href="https://au.linkedin.com/in/george-maclean-8b98b254">
                    <img
                      src="assets/img/Team/linkedin.png"
                      alt="LinkedIn"
                      style={{ maxWidth: "40px" }}
                    ></img>
                  </a>
                </div>
              </div>
              <div className="col-md-4 bod">
                <div className="dept-box2">
                  <div className="dept-img2">
                    <a>
                      <img
                        width="92"
                        height="80"
                        alt="Opthalmology"
                        src="assets/img/Team/GREGORY MOWLE.jpg"
                      />
                    </a>
                  </div>
                  <h3>GREGORY MOWLE</h3>
                  <h6>CHIEF FINANCIAL OFFICER (CFO)</h6>
                  {/* <p>
                    Gregory is Chief Commercialisation Officer (CCO) at
                    Medvatic. Gregory oversees the end to end go-to-market
                    process. He is a Fellow of the Institute of Public
                    Accountants and is acknowledged as one of Australia’s
                    leading experts in financial literacy. His expertise comes
                    not just from academic knowledge but practical work
                    experience. After a career in finance and credit he joined
                    the Community sector and had senior roles at leading social
                    enterprises such as Lifeline and The Smith Family.
                  </p> */}
                  <a href="https://au.linkedin.com/in/gregory-mowle-70a7ba145">
                    <img
                      src="assets/img/Team/linkedin.png"
                      alt="LinkedIn"
                      style={{ maxWidth: "40px" }}
                    ></img>
                  </a>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="section-header text-center">
                  <h3 className="header-title">Our Team</h3>
                  <div className="line"></div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="dept-box2">
                  <div className="dept-img2">
                    <a>
                      <img
                        width="63"
                        height="80"
                        alt="Neurology"
                        src="assets/img/Team/George-Maclean.jpg"
                      />
                    </a>
                  </div>
                  <h3>GEORGE MACLEAN</h3>
                  <h6>FOUNDER & CEO</h6>
                  <p>
                    George is a highly experienced entrepreneur in the medical
                    industry, having taken his first role as a counter drug
                    sales assistant at age 10. By high school George had taken a
                    management role in the store and obtained a license to
                    operate as a registered pharmacy, providing essential
                    medical supplies to less affluent regional areas.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="dept-box2">
                  <div className="dept-img2">
                    <a>
                      <img
                        width="63"
                        height="80"
                        alt="Neurology"
                        src="assets/img/Team/GREGORY MOWLE.jpg"
                      />
                    </a>
                  </div>
                  <h3>GREGORY MOWLE</h3>
                  <h6>CHIEF FINANCIAL OFFICER (CFO)</h6>
                  <p>
                    Gregory is Chief Financial Officer (CFO) at Medvatic. He is
                    a Fellow of the Institute of Public Accountants and is
                    acknowledged as one of Australia’s leading experts in
                    financial literacy. His expertise comes not just from
                    academic knowledge but practical work experience. After a
                    career in finance and credit he joined the Community sector
                    and had senior roles at leading social enterprises such as
                    Lifeline and The Smith Family
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="dept-box2">
                  <div className="dept-img2">
                    <a>
                      <img
                        width="63"
                        height="80"
                        alt="Neurology"
                        src="assets/img/Team/UTAH NIRVANA.jpg"
                      />
                    </a>
                  </div>
                  <h3>UTAH NIRVANA</h3>
                  <h6>CHIEF TECHNOLOGY OFFICER (CTO)</h6>
                  <p>
                    Utah is Chief Technology Officer (CTO) at Medvatic. Utah
                    oversees all technical aspect and infrastructure management
                    of Medvatic. He is a technologist, with a vast experience in
                    ICT and hardware system innovation for the past 15 years.
                    With an academic background in systems engineering and
                    management, he has supported start-ups, enterprise solutions
                    and consultancy engagements. Enjoying practical solutions,
                    he also leverages a systems engineering and IT background to
                    develop machine automation, end-to-end hardware and software
                    systems.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="dept-box2">
                  <div className="dept-img2">
                    <a>
                      <img
                        width="47"
                        height="80"
                        alt="ENT Department"
                        src="assets/img/Team/Deep Patel3.jpeg"
                      />
                    </a>
                  </div>
                  <h3>DEEP PATEL</h3>
                  <h6>VP BUSINESS</h6>
                  <p>
                    Deep is VP Business who manages the clients relations and
                    our cloud platform. His prodigious interest in the field of
                    client relations and cloud computing makes him an adept to
                    the position. With his proficiencies spawned in different
                    domains, his knowledge base serves as an additional help.
                    Not to mention his eccentric approach towards the
                    challenges, be it of business or technological importance
                    makes him more prudent and worthwhile for our organisation
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="dept-box2">
                  <div className="dept-img2">
                    <a>
                      <img
                        width="40"
                        height="80"
                        alt="Orthopedics"
                        src="assets/img/Team/MOHSEN GOLESTANEH.jpg"
                      />
                    </a>
                  </div>
                  <h3>MOHSEN GOLESTANEH</h3>
                  <h6>WEB DEVELOPER</h6>
                  <p>
                    Mohsen is a web developer and entrepreneur. He received his
                    undergrad degree from the University of Portsmouth in
                    Computing, and gained experience working as a system
                    administrator and web developer. In 2014 Mohsen started his
                    Master in information technology and systems at the
                    University of Canberra. In 2018 after graduating from his
                    studies, Mohsen worked as an intern software engineer on
                    Cloud robotics research project at the University of
                    Canberra.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="dept-box2">
                  <div className="dept-img2">
                    <a>
                      <img
                        width="47"
                        height="80"
                        alt="ENT Department"
                        src="assets/img/Team/JAI SHAILESH PATEL.png"
                      />
                    </a>
                  </div>
                  <h3>JAI SHAILESH PATEL</h3>
                  <h6>BUSINESS ANALYST</h6>
                  <p>
                    Jai is a business analyst and highly dedicated member of our team. 
                    He got his computer science degree from NMIMS. In 2019 he got his 
                    masters of Data Science degree from Western Sydney University. 
                    His expertise and knowledge come from his high dedication towards 
                    his field and his keen acumen to solve business problems.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section advisory-boards">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-header text-center">
                  <h3 className="header-title">Advisory Board</h3>
                  <div className="line"></div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 advisory-tabs">
                <nav>
                  <div
                    className="nav nav-tabs nav-fill"
                    id="nav-tab"
                    role="tablist"
                  >
                    <a
                      className="nav-item nav-link active team-a"
                      id="nav-robin-tab"
                      data-toggle="tab"
                      href="#nav-robin"
                      role="tab"
                      aria-controls="nav-robin"
                      aria-selected="true"
                    >
                      Robin Eckermann (AM FIEAust)
                    </a>
                    <a
                      className="nav-item nav-link team-a"
                      id="nav-james-tab"
                      data-toggle="tab"
                      href="#nav-james"
                      role="tab"
                      aria-controls="nav-james"
                      aria-selected="false"
                    >
                      James Hodge
                    </a>
                    <a
                      className="nav-item nav-link team-a"
                      id="nav-malcolm-tab"
                      data-toggle="tab"
                      href="#nav-malcolm"
                      role="tab"
                      aria-controls="nav-malcolm"
                      aria-selected="false"
                    >
                      Dr Malcolm Thomson
                    </a>
                    <a
                      className="nav-item nav-link team-a"
                      id="nav-adesina-tab"
                      data-toggle="tab"
                      href="#nav-adesina"
                      role="tab"
                      aria-controls="nav-adesina"
                      aria-selected="false"
                    >
                      Dr Adesina Adesanya
                    </a>
                    <a
                      className="nav-item nav-link team-a"
                      id="nav-diane-tab"
                      data-toggle="tab"
                      href="#nav-diane"
                      role="tab"
                      aria-controls="nav-diane"
                      aria-selected="false"
                    >
                      Diane Phillips
                    </a>
                    <a
                      className="nav-item nav-link team-a"
                      id="nav-jacquie-tab"
                      data-toggle="tab"
                      href="#nav-jacquie"
                      role="tab"
                      aria-controls="nav-jacquie"
                      aria-selected="false"
                    >
                      Jacquie Mikhail
                    </a>
                  </div>
                </nav>
                <div
                  className="tab-content py-3 px-3 px-sm-0"
                  id="nav-tabContent"
                >
                  <div
                    className="tab-pane fade show active"
                    id="nav-robin"
                    role="tabpanel"
                    aria-labelledby="nav-robin-tab"
                  >
                    <div className="col-12 pt-3 d-md-flex">
                      <div className="col-md-3 text-center">
                        <img src="assets/img/advisory/robin.png" alt="Robin" />
                      </div>
                      <div className="col-md-9">
                        <p>
                          Robin Eckermann is an independent consultant,
                          specialising in telecommunications infrastructure.
                          From 1996 to 2000, he led the development of TransACT
                          through its incubation phase, and from 2000-2003
                          during the rollout of TransACT’s FTTK/VDSL network,
                          served as the Company’s Chief Architect. Since 2003,
                          he has been actively involved in most of the
                          pioneering FTTP (Fibre to the Premises) initiatives in
                          Australia, and has contributed to policy deliberations
                          at Commonwealth, State and Local Government levels. He
                          was a member of the 2011/2012 Regional
                          Telecommunications Independent Review Committee,
                          reporting to Parliament in May 2012. Robin was
                          appointed an Adjunct Professor in
                          network/communications technologies, business models
                          and project management at the University of Canberra
                          in 2005 is a Fellow of Engineers Australia.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-james"
                    role="tabpanel"
                    aria-labelledby="nav-james-tab"
                  >
                    <div className="col-12 pt-3 d-md-flex">
                      <div className="col-md-3 text-center">
                        <img src="assets/img/advisory/james.png" alt="James" />
                      </div>
                      <div className="col-md-9">
                        <p>
                          James is an accomplished CEO with experience across
                          software development, retail and automotive tech
                          industries. James founded his first software company
                          in the late 1990s, developing quotation and customer
                          management solutions for small retail businesses, and
                          shifted focus in 2007 to become CEO of one of
                          Australia’s first iPhone app development companies.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-malcolm"
                    role="tabpanel"
                    aria-labelledby="nav-malcolm-tab"
                  >
                    <div className="col-12 pt-3 d-md-flex">
                      <div className="col-md-3 text-center">
                        <img
                          src="assets/img/advisory/malcolm.png"
                          alt="Malcolm"
                        />
                      </div>
                      <div className="col-md-9">
                        <p>
                          Dr Thomson arrived in Canberra in 1992, having been an
                          ED chief and then surgical trainee, to do a further 2
                          years of surgical training before seeing the light
                          (rays) and turning to Radiology. Dr Thomson has been a
                          consultant radiologist in Canberra since 1998, and has
                          developed recognised interests in musculoskeletal and
                          spinal radiology, as well as expertise in
                          musculoskeletal and spinal procedures. Dr Thomson does
                          not accept that people need to put up with spinal pain
                          or brachalgia / sciatica. As well as general
                          radiology, he also participates extensively in
                          oncological radiology, particularly with regard to
                          therapeutic trials and their assessments. Dr Thomson
                          is dedicated to Canberra and enjoys contributing back
                          into our community and enjoys interacting with
                          referring doctors to see how he can help them and
                          their patients.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-adesina"
                    role="tabpanel"
                    aria-labelledby="nav-adesina-tab"
                  >
                    <div className="col-12 pt-3 d-md-flex">
                      <div className="col-md-3 text-center">
                        <img
                          src="assets/img/advisory/adesanya.png"
                          alt="Adesanya"
                        />
                      </div>
                      <div className="col-md-9">
                        <p>
                          Dr Adesanya is a Senior Clinical Consultant and Fellow
                          of the Royal Australian and New Zealand College of
                          Psychiatrists (FRANZCP) with over 20 years of clinical
                          and administrative experience within the Australian
                          Health System. He collaborates with GPs, other medical
                          specialists and health systems managers in his daily
                          clinical practice, and is a passionate advocate for
                          clinical quality and excellence.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-diane"
                    role="tabpanel"
                    aria-labelledby="nav-diane-tab"
                  >
                    <div className="col-12 pt-3 d-md-flex">
                      <div className="col-md-3 text-center">
                        <img src="assets/img/advisory/diane.png" alt="Diane" />
                      </div>
                      <div className="col-md-9">
                        <p>
                          Diane joined the University of Canberra (UC) after
                          educational, research management and teaching roles in
                          seven countries. Diane has a master’s in educational
                          psychology and is currently finishing a PhD in
                          Exploring the Effects of Neoliberalism on Research
                          Governance and Practice. From the faculty of Business
                          Government and Law and focuses in on units which
                          introduce research, critical thinking, logic and
                          argument to first year degree students and teaches
                          creativity, innovation and entrepreneurship in the
                          management discipline and supports and mentor’s
                          students and their start-ups and social enterprises.
                          Diane has a research led teaching philosophy and has
                          strong contacts with industry for research and
                          engagement in local, national and international
                          communities. Diane believes that quality teaching is
                          achieved through the delivery of: student centred;
                          engaging; adaptive; world className; innovative
                          courses.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-jacquie"
                    role="tabpanel"
                    aria-labelledby="nav-jacquie-tab"
                  >
                    <div className="col-12 pt-3 d-md-flex">
                      <div className="col-md-3 text-center">
                        <img
                          src="assets/img/advisory/jacquie.jpg"
                          alt="Jacquie"
                        />
                      </div>
                      <div className="col-md-9">
                        <p>
                          Jacquie Mikhail joined Medvatic as VP of Sales and
                          Marketing. Jacquie is an experienced Sales and
                          Marketing professional in the Aerospace and Defence
                          Industry. Jacquie started her career as an Oil
                          Technologist, after she graduated with a Bachelor of
                          Science with Honours from the University of New South
                          Wales. She completed her Masters of Business
                          Administration and has held various roles from
                          business development to contract management.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
